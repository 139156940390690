import React from 'react';

const Footer = () => {
    return (
        <div className="app-footer">
            <div>
                <span>
                    <a href="https://www.nursinghomes.com/" target="_blank">NursingHomes.com</a> is a website owned by A Place for Mom, Inc. <br/>&copy; 2024 A Place for Mom, Inc. All Rights Reserved.
                </span>
                <ul>
                    <li>
                        <a href="https://www.nursinghomes.com/review-policy/" target="_blank">
                            Review Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nursinghomes.com/privacy/" target="_blank">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nursinghomes.com/terms/" target="_blank">
                            Terms of Use
                        </a>
                    </li>
                    <li>
                        <a href="https://www.nursinghomes.com/personal-information/" target="_blank">
                            Personal Information
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
