import React, { ButtonHTMLAttributes, FC } from 'react';
import cx from 'classnames';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    variant?: 'primary' | 'secondary' | 'danger' | 'light' | 'success' | 'info';
    styleType?: 'solid' | 'outline' | 'link';
    size?: 'small' | 'medium' | 'large' | 'auto';
    disabled?: boolean;
}

const sizeMapper = {
    auto: 'auto',
    small: 'sm',
    medium: 'md',
    large: 'lg'
};

const Button: FC<Props> = (props) => {
    const {
        className,
        isLoading,
        children,
        variant = 'primary',
        size = 'medium',
        styleType = 'solid',
        disabled,
        ...rest
    } = props;

    const buttonClassName: string = cx(
        'btn',
        { 'btn-sm w-100': size === 'auto'},
        {[`btn-${sizeMapper[size]}`]: size !== 'auto'},
        { [`btn-outline btn-outline-${variant}`]: styleType === 'outline' },
        { [`btn-${variant}`]: styleType === 'solid' },
        { 'btn-link': styleType === 'link' },
        className
    );

    const spinnerClassName: string = cx(
        'spinner-border spinner-border-sm position-absolute m-0',
        { invisible: !isLoading }
    );

    return (
        <button
            className={buttonClassName}
            disabled={disabled || isLoading}
            {...rest}
        >
            <div className={spinnerClassName} role="status" />
            <span className={cx({ invisible: isLoading })}>{children}</span>
        </button>
    );
};

export { Button };
export type { Props as ButtonProps };
