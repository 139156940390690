import React, { FC } from 'react';
import { FormSelect } from '../../../../../components/common/form_select';

type SelectInputFieldConfig = {
    inputName: string;
    options: { label: string; value: any }[];
    selectedValue: any;
};

interface IProps {
    data: Record<string, SelectInputFieldConfig>;
}

const ExtrasFields: FC<IProps> = ({ data }) => {
    return (
        <>
            {Object.entries(data).map(([subkey, subvalue], index: number) => {
                return (
                    <div className="ps-2" key={index}>
                        <FormSelect
                            maxWidth='350px'
                            isClearable={true}
                            label={subkey}
                            options={subvalue.options}
                            name={subvalue.inputName}
                            inlineLabelWidth="large"
                        />
                    </div>
                );
            })}
        </>
    );
};

export { ExtrasFields, type SelectInputFieldConfig };