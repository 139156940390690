import _ from 'lodash';
import React, { FormEvent, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import { 
    createUser, 
    getUser, 
    getUserByEmailID, 
    sendActivationEmail, 
    sendPasswordResetEmail, 
    deletePortalUser,
    updateUser, 
    updatePortalUserEntity,
    getReviewCardPrimaryUserCount,
    getEntityContacts
} from '../../api/user';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { ISelectOption } from '../../interfaces/form';
import {
    ISessionState,
    SessionType as SessionTypeKeyName,
    SessionTypeID as SessionType
} from '../../interfaces/session';
import { IPortalUser, IPortalUserByEmail, IPortalUserPayload, IGetReviewCardPrimaryUsers } from '../../interfaces/user';
import { showToast, Toast, ToastType } from '../../utils/toast';
import { validateEmailAddress } from '../../utils/validation';
import { updateSessionData } from '../../utils/session'; 
import EmailExistsForm from './user_email_exists_form';
import UserTableExpandableRows from '../../components/users/user_table_expandable_rows';

interface IFormData {
    FirstName: string;
    LastName: string;
    JobTitle: string;
    EmailAddress: string;
    CanReplyToReview: boolean;
    CanManageUsers: boolean;
    CanReceiveNotifications: boolean;
    SessionTypeID: SessionType;
    Properties: ISelectOption[];
    UserID?: number;
    PortalUserID?: number;
    HasActivated: boolean;
    IsReviewCardPrimaryContact: boolean;
    IsPrimaryContact: boolean;
    IsPrimarySubscriptionContact: boolean;
}

interface ICreateUserProps {
    nhIDs: number[];
    orgNHID: number;
}

interface ICanExistingUserBeLinked {
    IsAllowedToLink: boolean;
    Message: string;
}

const defaultFormData: IFormData = {
    FirstName: '',
    LastName: '',
    JobTitle: '',
    EmailAddress: '',
    CanReplyToReview: false,
    CanManageUsers: false,
    CanReceiveNotifications: false,
    SessionTypeID: null,
    Properties: null,
    HasActivated: false,
    IsReviewCardPrimaryContact: false,
    IsPrimaryContact: false,
    IsPrimarySubscriptionContact: false
};

const EditUser = ({ nhIDs, orgNHID }: ICreateUserProps) => { 
    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );
    const [warningMessages, setWarningMessages] = useState<ReactNode[]>([]);
 
    const location = useLocation();
    const navigate = useNavigate();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ confirmDelete, setConfirmDelete ] = useState<boolean>(false);
    const [ formData, setFormData ] = useState<IFormData>(defaultFormData);
    const [ isUserEmailExists, setIsUserEmailExists ] = useState<boolean>(true);
    const [ isUserEmailDeleted, setIsUserEmailDeleted ] = useState<boolean>(true);
    const [ isUserEmailHasActivated, setIsUserEmailHasActivated ] = useState<boolean>(true);
    const [ doShowUserEmailExistsHtML, setDoShowUserEmailExistsHtML ] = useState<boolean>(false);
    const [ isExistingUser, setIsExistingUser ] = useState<boolean>(false);
    const [ existingUser, setExistingUser ] = useState<IPortalUser>(null);
    const [deselectPrimaryUserWarning, setDeselectPrimaryUserWarning] = useState<boolean>(false);
    const [primaryUserWarning, setPrimaryUserWarning] = useState<boolean>(false);
    const [ propertiesAlreadyHavePrimaryUser, setPropertiesAlreadyHavePrimaryUser ] = useState<any[]>(null);
    

    useEffect(() => {
        const state = location?.state as any;
        const userID = state?.UserID;

        if (userID) {
            getUserData(userID);
        }
        
        updateFormData(
            'SessionTypeID', 
            isOrgSession ? SessionType.Org : SessionType.Property
        );
    }, [ nhIDs, orgNHID ]);
    
    const canManageUsers = _.get(sessionState, 'Session.Perms.CanManageUsers', false);
    const isOrgSession = sessionState?.Session?.SessionType === SessionTypeKeyName.SessionTypeOrg;
        
    const getUserData = async (userID: number) => {
        setIsLoading(true);

        try {
            const user: IPortalUser = await getUser(userID);
            mapUserToFormData(user);
        } catch (err) {
            showToast('Error retrieving the user', ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const canExistingUserBeLinked = (() : ICanExistingUserBeLinked => {
        const sessionProperties: any[] = _.get(sessionState, 'Properties');
        const entityName = isOrgSession ? sessionState.Properties[0].OrgName : sessionState.Properties.length > 1 ? sessionState.Properties[0].OrgName : sessionState.Properties[0].Name;
        const returnValue : ICanExistingUserBeLinked = {
            IsAllowedToLink: false,
            Message: `Please either contact your account manager or email reviews@aplaceformom.com to ask if ${formData.EmailAddress} can be relinked to ${entityName}`
        };
        if(existingUser) {
            //Org User
            if(existingUser.SessionTypeID === SessionType.Org ) {
                return {
                    IsAllowedToLink: false,
                    Message: `${existingUser.EmailAddress} ( ${existingUser.FirstName} ${existingUser.LastName} ) already has access.`
                };
            } else {
                const sessionNHIDs: number[] = sessionProperties.map((m: any) => m.NHID);
                const existingUserNHIDs: number[] = [];
                if(sessionNHIDs.length === existingUser.Entities.length) {
                    existingUser.Entities.forEach((m: any) => { 
                        if( m.EntityType === 'property' && m.EntityID > 0  && !sessionNHIDs.includes(m.EntityID)) {
                            existingUserNHIDs.push(m.EntityID);
                        }
                    });
                } else {
                    existingUser.Entities.forEach((m: any) => { 
                        if( m.EntityType === 'property' && m.EntityID > 0) {
                            existingUserNHIDs.push(m.EntityID);
                        }
                    });
                }
                //Property User
                if(existingUserNHIDs.length === 0) {
                    return {
                        IsAllowedToLink: false,
                        Message: `${existingUser.EmailAddress} ( ${existingUser.FirstName} ${existingUser.LastName} ) already has access.`
                    };
                } else {
                    return {
                        IsAllowedToLink: true,
                        Message: `Please confirm if you would like to link ${existingUser.EmailAddress} to ${entityName} instead of the above.`
                    };
                }
            }
        }

        return returnValue;
    })();

    const handleLinkingExistingUser = async () => {
       if(existingUser) {
            setIsLoading(true);
            try {
                await updatePortalUserEntity(existingUser.PortalUserID);
                return navigate('/users');
            } catch (e) {
                showToast(`Error linking user (Email: ${existingUser.EmailAddress})`, ToastType.Error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleEmailExists = async (emailToCheckValue: string) => {
       updateFormData('EmailAddress', emailToCheckValue);
        
        if (emailToCheckValue.trim().length === 0) {
            return;
        }

        setIsLoading(true);

        try {
            const userData: IPortalUserByEmail = await getUserByEmailID(`${encodeURIComponent(emailToCheckValue)}`);

            if (!userData) {
                throw new Error();
            }

            if(userData.IsUserEmailExists) {
                setDoShowUserEmailExistsHtML(true);
                setIsUserEmailExists(true);
                setIsUserEmailDeleted(userData.IsUserEmailDeleted);
                setExistingUser(userData.User);
                setIsUserEmailHasActivated(userData.IsUserEmailHasActivated);
               
            } else {
                setDoShowUserEmailExistsHtML(false);
                setIsUserEmailExists(false);
                setIsUserEmailDeleted(false);
                setExistingUser(null);
                setIsUserEmailHasActivated(false);
            }
        } catch (e) {
            showToast(`Error retrieving user by email (Email: ${emailToCheckValue})`, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormData = () => {
        setFormData(defaultFormData); 
        setIsExistingUser(false);
        setIsUserEmailExists(true);
        setIsUserEmailDeleted(false);
        setDoShowUserEmailExistsHtML(false);
        setExistingUser(null);
        setIsUserEmailHasActivated(false);
        return navigate('/users/add');  
    };

    const renderExistingUserEmailHTML = () => {
        
        const {IsAllowedToLink, Message} =  canExistingUserBeLinked;

        if (isUserEmailExists && isUserEmailDeleted) {
            return (
                <div className="row mt-4 col-sm-12">
                    <div className='col-sm-12'>
                        There is already a user account for { formData.EmailAddress } but it has been deleted
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <Link to="/users">
                                <button className="btn btn-secondary">Return to User List</button>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }

        if (isUserEmailExists && !isUserEmailHasActivated) {
            return (
                <div className="row mt-4 col-sm-12">
                    <div className='col-sm-12'>
                        There is already a user account for { formData.EmailAddress } but the user has not completed sign up
                    </div>
                    <div className="row mt-4">
                        <div className="col-6">
                            <Link to="/users">
                                <button className="btn btn-secondary">Return to User List</button>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="row mt-4 col-sm-12">
                 <div className='col-sm-12'>
                    There is already a user account for { formData.EmailAddress }
                    {
                        IsAllowedToLink ?  
                            <>       
                                 <div className="mt-2">
                                    They are linked to the following:
                                    { <UserTableExpandableRows  data={existingUser} />}
                                </div> 
                                <div className="col-12">
                                    {Message}
                                </div>
                                <div className="row mt-4">
                                    { !isOrgSession ?
                                         <div className="col-6">
                                            <button className="btn btn-primary" onClick={handleLinkingExistingUser}>Confirm</button>
                                        </div>
                                    :
                                        <div className="col-6">
                                            <Link to="/users/edit" state={{ UserID:existingUser.UserID }}>
                                                <button className="btn btn-primary">Edit this User</button>
                                            </Link>
                                        </div>
                                    }
                                    <div className="col-6">
                                        <button className="btn btn-secondary" onClick={resetFormData}>No, please do not make any changes</button>
                                    </div>
                                </div>
                            </>
                        : 
                            <>
                                <div className="col-12 mt-4">
                                    {Message}
                                </div>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <Link to="/users">
                                            <button className="btn btn-secondary">Return to User List</button>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            
                    }
                </div>
            </div>
        );
    };

    const mapUserToFormData = (user: IPortalUser) => {
        const {
            UserID,
            PortalUserID,
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            SessionTypeID,
            KeyName,
            HasActivated,
            IsReviewCardPrimaryContact,
            IsPrimarySubscriptionContact = false,
            IsPrimaryContact = false
        } = user;

        let properties: ISelectOption[] = [];
        
        if (isOrgSession && KeyName === SessionTypeKeyName.SessionTypeProperty) {
            properties = buildPropertyOptions(user.Entities);
        }

        setFormData({
            ...formData,
            UserID,
            PortalUserID,
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            SessionTypeID,
            Properties: properties,
            HasActivated,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact 
        });     
        if(UserID) {
            setIsExistingUser(true);
        }
    };

    const buildPropertyOptions = (properties: any[]): ISelectOption[] => {
        return properties.map((m: any) => { 
            const name = m.Name ?? m.EntityName;
            const NHID = m.NHID ?? m.EntityID;
            const city = m.CityName ?? m.EntityCity;
            const cityStr = city?.trim().length ? `, ${city}`: '';

            return {
                label: `${name}${cityStr} (ID: ${NHID})`,
                value: NHID
            };    
        });
    };

    const propertyOptions = ((): ISelectOption[] => {
        if (isOrgSession) {
            return buildPropertyOptions(sessionState.Properties);
        }

        return [];
    })();

    const updateFormData = (key: any, value: any) => {
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const getEntityIDs = (): number[] => {
        if (isOrgSession) {
            if (formData.SessionTypeID === SessionType.Property) {
                return formData.Properties.map((m: ISelectOption) => m.value);
            }   

            return [orgNHID];
        }

        return nhIDs;
    };

    const saveUser = async (e: FormEvent) => {
        e.preventDefault();

        if (isLoading || !validateFormData()) {
            return;
        }

        setIsLoading(true);

        const {
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            SessionTypeID,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            PortalUserID,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact
        } = formData;

        const payload: IPortalUserPayload = {
            FirstName,
            LastName,
            JobTitle,
            EmailAddress,
            SessionTypeID,
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            EntityIDs: getEntityIDs(),
            PortalUserID: PortalUserID,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact
            
        };

        try {
            if(
                !isExistingUser &&
                payload.IsReviewCardPrimaryContact && 
                !payload.EntityIDs.length){
                return setDeselectPrimaryUserWarning(true);
            }

             if (payload.EntityIDs.length && !warningMessages.length) {
                let warnings: ReactNode[] = [];
                const contactsOfEntities = await getEntityContacts(payload.EntityIDs);
                if (IsPrimaryContact) {
                    const portalUserIsPrimaryContact = contactsOfEntities.some((entity) => entity.PortalUserID === PortalUserID && entity.PrimaryContact);
                    const otherPrimaryContacts = contactsOfEntities.filter((entity) => entity.PortalUserID !== PortalUserID && entity.PrimaryContact);
                    if (!portalUserIsPrimaryContact && otherPrimaryContacts.length) {

                        const primaryContactWarnings = otherPrimaryContacts.map((contact:any, index: number) => {
                            return <div key={`pc-${index}`}>
                            <span className="font-weight-bold red">
                                {contact.FirstName} {contact.LastName} 
                                </span>   
                                &nbsp; ({contact.EmailAddress}) is already set as the Primary Contact for {contact.EntityName}.
                                <br />
                            </div>;
                        });
                        warnings = [...warnings, ...primaryContactWarnings];

    
                    }
                }

                if (IsPrimarySubscriptionContact) {
                    const portalUserIsPrimarySubscriptionContact = contactsOfEntities.some((entity) => entity.PortalUserID === PortalUserID && entity.PrimarySubscriptionContact);
                    const otherPrimarySubscriptionContacts = contactsOfEntities.filter((entity) => entity.PortalUserID !== PortalUserID && entity.PrimarySubscriptionContact);
                    if (!portalUserIsPrimarySubscriptionContact && otherPrimarySubscriptionContacts.length) {
                        const primarySubscriptionContactWarnings = otherPrimarySubscriptionContacts.map((contact, index: number) => {
                            return <div key={`psc-${index}`}>
                            <span className="font-weight-bold red">
                                {contact.FirstName} {contact.LastName} 
                                </span>   
                                &nbsp; ({contact.EmailAddress}) is already set as the Primary Subscription Contact for {contact.EntityName}.
                                <br />
                            </div>;
                        });

                        warnings = [...warnings, ...primarySubscriptionContactWarnings];
                    }
                }
                // early return on the first submission attempt with warnings
                if (warnings.length) {
                    setWarningMessages(warnings);
                    return ;
                }
            }

            // if ReviewCardPrimaryContact is selected and there are some entities associated but no warning has been shown yet
            if(payload.IsReviewCardPrimaryContact && payload.EntityIDs.length && !primaryUserWarning){
                const getPrimaryUserCountPayload: IGetReviewCardPrimaryUsers ={
                    EntityIDs: payload.EntityIDs.toString(),
                    PortalIDNotPresent: payload.PortalUserID?? undefined
                }; 
                const primaryContactAlreadySet = await getReviewCardPrimaryUserCount(getPrimaryUserCountPayload);
                if (primaryContactAlreadySet.confirm) { 
                
                    setPropertiesAlreadyHavePrimaryUser(primaryContactAlreadySet.entitiesToHaveContactReplaced),
                    setPrimaryUserWarning(true);
                    return;
                }
            }
            const response = isExistingUser
                ? await updateUser(payload)
                : await createUser(payload);

            if (response.errors?.length) { 
                throw new Error(response.errors[0]);
            }

            const responseUser: IPortalUser = response.data;

            if (!responseUser) {
                throw new Error();
            }

            if (!isExistingUser) {
                await sendActivationEmail(responseUser.UserID);

                const addAnotherUser = document.getElementById('addAnotherUser') as HTMLInputElement;
                
                if (addAnotherUser?.checked) {
                    showToast('User added successfully!', ToastType.Success);

                    addAnotherUser.checked = false;
                    
                    setFormData({
                        ...defaultFormData,
                        SessionTypeID: isOrgSession ? SessionType.Org : SessionType.Property
                    });
                    
                    return;
                }
            } else {

                const editedUserID = responseUser.UserID;
                const sessionUserID = _.get(sessionState, 'Session.UserID');

                if (editedUserID === sessionUserID) {
                    updateSessionData();
                }
            }

            return navigate('/users');   
        } catch (error) {
            const err = error as any;
            const errMsg = err.customError ?? 'An error occurred saving the user.';

            showToast(errMsg, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const validateFormData = (): boolean => {
        const {
            EmailAddress,
            SessionTypeID,
            Properties,
        } = formData;

        const validationErrorText: string[] = [];

        if (!validateEmailAddress(EmailAddress)) {
            validationErrorText.push('A valid Email Address must be entered.');
        }

        if (!SessionTypeID) {
            validationErrorText.push('You must select what sort of record(s) you would like to link to.');
        }

        if (isOrgSession && SessionTypeID === SessionType.Property && !Properties?.length) {
            validationErrorText.push('Please select at least one Community.');
        }

        if (validationErrorText.length > 0) {
            showToast(validationErrorText.join(' '), ToastType.Error);

            return false;
        }

        return true;
    };

    const handleSendPasswordResetEmail = async (e: FormEvent) => {
        e.preventDefault();

        if (!isExistingUser) {
            return;
        }

        setIsLoading(true);

        try {
            await sendPasswordResetEmail(formData.EmailAddress);

            showToast(`A Password Reset email has been sent to ${formData.EmailAddress}`, ToastType.Success);
        } catch (err) {
            showToast(`Error sending Password Reset email to ${formData.EmailAddress}`, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteUser = async (e: FormEvent, confirmed: boolean, cancelled = false) => {
        e.preventDefault();

        const { PortalUserID } = formData;

        if (!isExistingUser || !PortalUserID) {
            return;
        }

        if (!confirmed) {
            setConfirmDelete(true);
            return;
        } else if (cancelled) {
            setConfirmDelete(false);
            return;
        }

        setIsLoading(true);

        try {
            await deletePortalUser(PortalUserID);

            showToast('User Deleted', ToastType.Success);
        } catch (err) {
            showToast('Error Deleting User', ToastType.Error);
        } finally {
            setIsLoading(false);
        }

       return navigate('/users'); 
    };

    const handleSendActivationEmail = async (e: FormEvent) => {
        e.preventDefault();

        if (!isExistingUser || formData.HasActivated) {
            return;
        }

        setIsLoading(true);

        try {
            await sendActivationEmail(formData.UserID);
            
            showToast(`An Activation email has been sent to ${formData.EmailAddress}`, ToastType.Success);
        } catch (err) {
            showToast(`Error sending Activation email to ${formData.EmailAddress}`, ToastType.Error);
        }
    };

    const handleSubmitButtonText =()=>{
        let buttonText = 'Submit';
        switch(isExistingUser) {
            case true:
                buttonText = 'Update';
            break;
          }
          switch(primaryUserWarning) {
            case true:
                buttonText = `Proceed with ${buttonText}`;
            break;
          }
        return buttonText;
    };

    const handleDeselectPrimaryUserwarning = () =>{
 
        const renderedOutput =  
          <div>
          <span className="font-weight-bold red">
              Please deselect 'Review Card Order Primary Contact' if no property is associated with this User
              </span>
              <br />
           </div>;
  
         return <div>
                     {renderedOutput}
         </div>;
      };
    
    const handlePrimaryUserwarning = () =>{

       const renderedOutput =  propertiesAlreadyHavePrimaryUser.map(portalUserObject =>
         <div>
         <span className="font-weight-bold red">
             {portalUserObject.UserFirstName} {portalUserObject.UserLastName} 
             </span>   
             &nbsp; ({portalUserObject.EmailAddress}) is already set as the Review Card Order Primary Contact for {portalUserObject.PropertyName}
             <br />
          </div>
        );
        return <div>
             {renderedOutput}
             If you want to replace them as the 'Review Card Order Primary Contact' please proceed, if not please uncheck 'Review Card Order Primary Contact' above
         </div>;
     };

    return (
        <>
            { isLoading ? <LoadingSpinner /> : null }

            {
                (isUserEmailExists && !isExistingUser) ? 
                    <div className="widget widget-fullwidth">
                        <div className="row widget-body">
                            <div className="col-12">
                                <div className="card">
                                    <Toast></Toast>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-4">
                                                    <>
                                                    <EmailExistsForm handleEmailExistsCheck={handleEmailExists}  />
                                                    { doShowUserEmailExistsHtML ? renderExistingUserEmailHTML() : null }
                                                    </>
                                                </div> 
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                : null
            } 
            {
                isExistingUser || (!isUserEmailExists && !isExistingUser) ?
                    <div className="widget widget-fullwidth">
                        <div className="row widget-body">
                            <div className="col-12">
                                <div className="card">
                                <Toast></Toast>
                                    <form onSubmit={saveUser}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label htmlFor="email">Email</label>
                                                        <input
                                                            type="email"
                                                            id="email"
                                                            max="120"
                                                            value={formData.EmailAddress || ''}
                                                            className="form-control"
                                                            onChange={(e) => updateFormData('EmailAddress', e.target.value)}
                                                            disabled={true}
                                                            required
                                                        />
                                                    </div>


                                                    <div className="mb-4">
                                                        <label htmlFor="firstname">First Name</label>
                                                        <input
                                                            type="text"
                                                            id="firstname"
                                                            value={formData.FirstName || ''}
                                                            className="form-control"
                                                            max="120"
                                                            onChange={(e) => updateFormData('FirstName', e.target.value)}
                                                            disabled={!canManageUsers}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="mb-4">
                                                        <label htmlFor="lastname">Last Name</label>
                                                        <input
                                                            type="text"
                                                            id="lastname"
                                                            value={formData.LastName || ''}
                                                            className="form-control"
                                                            max="120"
                                                            onChange={(e) => updateFormData('LastName', e.target.value)}
                                                            disabled={!canManageUsers}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="mb-4">
                                                        <label htmlFor="jobtitle">Job Title</label>
                                                        <input
                                                            type="text"
                                                            id="jobtitle"
                                                            value={formData.JobTitle || ''}
                                                            className="form-control"
                                                            max="255"
                                                            onChange={(e) => updateFormData('JobTitle', e.target.value)}
                                                            disabled={!canManageUsers}
                                                            required
                                                        />
                                                    </div>

                                                    
                                                    <div className="mb-4">
                                                        Permissions
                                                        <div className="row">
                                                            <div className="col-4 mt-3">
                                                                <label htmlFor="CanManageUsers">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.CanManageUsers}
                                                                        id="CanManageUsers"
                                                                        onChange={(e) => updateFormData('CanManageUsers', e.target.checked)}
                                                                        disabled={!canManageUsers}
                                                                    /> <small>Can Manage Users</small>
                                                                    <span></span>
                                                                </label> 
                                                            </div>
                                                            <div className="col-4 mt-3">
                                                                <label htmlFor="CanReplyToReview">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.CanReplyToReview}
                                                                        id="CanReplyToReview"
                                                                        onChange={(e) => updateFormData('CanReplyToReview', e.target.checked)}
                                                                        disabled={!canManageUsers}
                                                                    /> <small>Can Reply To Reviews</small>
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                            <div className="col-4 mt-3">
                                                                <label htmlFor="CanReceiveNotifications">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.CanReceiveNotifications}
                                                                        id="CanReceiveNotifications"
                                                                        onChange={(e) => updateFormData('CanReceiveNotifications', e.target.checked)}
                                                                        disabled={!canManageUsers}
                                                                    /> <small>Review Notifications</small>
                                                                    <span></span>
                                                                </label> 
                                                            </div>
                                                             <div className="col-4 mt-3">
                                                                    <label htmlFor="IsReviewCardPrimaryContact">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={formData.IsReviewCardPrimaryContact}
                                                                            id="IsReviewCardPrimaryContact"
                                                                            onChange={(e) => updateFormData('IsReviewCardPrimaryContact', e.target.checked)}
                                                                            disabled={!canManageUsers}
                                                                        /> <small>Review Card Order Primary Contact</small>
                                                                        <span></span>
                                                                    </label> 
                                                            </div>
                                                            <div className="col-4 mt-3">
                                                                <label htmlFor="IsPrimaryContact">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.IsPrimaryContact}
                                                                        id="IsPrimaryContact"
                                                                        onChange={(e) => updateFormData('IsPrimaryContact', e.target.checked)}
                                                                        disabled={!canManageUsers}
                                                                    /> <small>Primary Contact</small>
                                                                    <span></span>
                                                                </label> 
                                                            </div>
                                                            <div className="col-4 mt-3">
                                                                <label htmlFor="IsPrimarySubscriptionContact">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.IsPrimarySubscriptionContact}
                                                                        id="IsPrimarySubscriptionContact"
                                                                        onChange={(e) => updateFormData('IsPrimarySubscriptionContact', e.target.checked)}
                                                                        disabled={!canManageUsers}
                                                                    /> <small>Primary Subscription Contact</small>
                                                                    <span></span>
                                                                </label> 
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    {
                                                        isOrgSession ?
                                                            <>
                                                                <div className="pt-4 mb-4">
                                                                    <div className="mb-3">  
                                                                        Link this user to
                                                                    </div>                                        
                                                                    <label className="kt-radio kt-radio--brand me-5">
                                                                        <input
                                                                            type="radio"
                                                                            name="EntityLink"
                                                                            checked={formData.SessionTypeID === SessionType.Org}
                                                                            onChange={() => updateFormData('SessionTypeID', SessionType.Org)}
                                                                            disabled={!canManageUsers}
                                                                        />
                                                                            &nbsp;<small><strong>{ sessionState.Org?.Name }</strong> (can access all its Communities)</small>
                                                                        <span></span>
                                                                    </label>

                                                                    <label className="kt-radio kt-radio--brand">
                                                                        <input
                                                                            type="radio"
                                                                            name="EntityLink"
                                                                            checked={formData.SessionTypeID === SessionType.Property}
                                                                            onChange={() => updateFormData('SessionTypeID', SessionType.Property)}
                                                                            disabled={!canManageUsers}
                                                                        />
                                                                            &nbsp;<small>A selection of <strong>{ sessionState.Org?.Name }</strong> Communities</small>
                                                                        <span></span>
                                                                    </label>
                                                                </div>

                                                                {
                                                                    formData.SessionTypeID === SessionType.Property ?
                                                                        <div className="mb-4">
                                                                            <Select
                                                                                id="Properties"
                                                                                onChange={(e) => updateFormData('Properties', e)}
                                                                                options={propertyOptions}
                                                                                isMulti
                                                                                closeMenuOnSelect={false}
                                                                                name="Properties"
                                                                                className="basic-multi-select"
                                                                                classNamePrefix="select"
                                                                                value={formData.Properties}
                                                                                isDisabled={!canManageUsers}
                                                                            />
                                                                        </div> : null
                                                                }
                                                            </> : null
                                                    }
                                                </div>
                                            </div>
                                            {primaryUserWarning? 
                                                handlePrimaryUserwarning()
                                                : null
                                            }
                                            {deselectPrimaryUserWarning?
                                                handleDeselectPrimaryUserwarning()
                                                : null
                                            }
                                            { 
                                                warningMessages.length ? <>
                                                    { warningMessages }
                                                    If you want to add this user as a contact too, please proceed. Otherwise deselect the relevant options. 
                                                </>
                                                : null
                                            }
                                        </div>
                                        <div className="card-footer">     
                                            <div>
                                                {
                                                    canManageUsers ?
                                                        <>
                                                            {
                                                                !confirmDelete ?
                                                                    <button type="submit" className="btn btn-primary me-5">
                                                                        { handleSubmitButtonText() }
                                                                    </button> : null  
                                                            }
                                                            {
                                                                !isExistingUser && !confirmDelete ?
                                                                    <label htmlFor="addAnotherUser">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="addAnotherUser"
                                                                        /> <small>Add another user after clicking submit</small>
                                                                        <span></span>
                                                                    </label> : null
                                                            }
                                                            {
                                                                isExistingUser && !confirmDelete && formData.HasActivated?
                                                                    <button
                                                                        className="btn btn-primary ms-5 me-3"
                                                                        onClick={handleSendPasswordResetEmail}>
                                                                        Send Password Reset Email
                                                                    </button>
                                                                    : null
                                                            }
                                                            {
                                                                isExistingUser ? confirmDelete ?
                                                                    <>
                                                                    <button
                                                                        className="btn btn-danger ms-5 me-3"
                                                                        onClick={ 
                                                                            (e: any) => { 
                                                                                handleDeleteUser(e, true);
                                                                        }}
                                                                    >
                                                                        Confirm Delete User
                                                                    </button> 
                                                                     <button
                                                                     className="btn btn-primary ms-5 me-3"
                                                                     onClick={ 
                                                                         (e: any) => { 
                                                                            handleDeleteUser(e, true, true);
                                                                     }}
                                                                    >
                                                                        Cancel Delete User
                                                                    </button> 
                                                                 </>
                                                                    : 
                                                                    <button
                                                                        className="btn btn-outline-primary btn-outline ms-5 me-3"
                                                                        onClick={ 
                                                                            (e: any) => { 
                                                                                handleDeleteUser(e, false);
                                                                        }}
                                                                    >
                                                                        Delete User
                                                                    </button> : null
                                                            }
                                                            {
                                                                isExistingUser && validateEmailAddress(formData.EmailAddress) && !formData.HasActivated ?
                                                                    <button
                                                                        className="btn btn btn-primary"
                                                                        onClick={handleSendActivationEmail}
                                                                    >
                                                                        Send Activation Email
                                                                    </button> : null
                                                            }
                                                        </>
                                                    : <strong>You do not currently have permission to manage users</strong>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>   
                : null
            }    
        </>
        
    );
};

export default EditUser;