import React, { FC, ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ConnectForm } from '../../../components/common/form/form';
import ReCAPTCHA from 'react-google-recaptcha';

interface IProps {
    name?: string;
}

const FormRecaptchaInput: FC<IProps> = ({ name = 'reCaptcha' }) => {
    return (
        <ConnectForm>
            {({ register, formState, setValue, resetField }: UseFormReturn) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute top-100">
                        {fieldError?.message}
                    </small>
                );
                return (
                    <div className="mt-4 d-flex justify-content-end position-relative">
                        <ReCAPTCHA
                            {...register(name)}
                            sitekey="6Lcbpe0UAAAAAHsJsXEcaUjIKs6JSeDdGa3lCgL9"
                            onChange={(value: string) => {
                                resetField(name);
                                setValue(name, value);
                            }}
                        />
                        {fieldError && errorMessage}
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormRecaptchaInput };
