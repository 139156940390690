import React from 'react';
import PageCommunitySelect from '../../components/common/page_community_select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';
import { useSessionState } from '../../hooks/use_session_state';
import { PageType } from '../../interfaces/page';

interface IProps {
    label?: string;
    selNHID: number | null;
    handleSelect: (memberID: number | null) => void;
    disabled?: boolean;
    pageType?: PageType;
}

const PageCommunitySelectWrapper = ({
    selNHID, 
    label, 
    handleSelect, 
    disabled,
    pageType
}: IProps) => {
    const sessionState = useSessionState();

    const handleSetAllFacilities = (e: any) => {
        handleSelect(null);
    };

    const renderMultiHeader = () => {
        return (
            <div className="d-flex flex-column flex-sm-row align-items-center gap-3">
                {
                selNHID && pageType !== PageType.Dashboard ? <a 
                    href='#'
                    onClick={(e) => handleSetAllFacilities(e)}
                    className='text-nowrap'
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="fe-2"/> View all Facilities
                </a>: null
                }

                <div className="select-community mt-0">
                    {
                        label ?
                            <label htmlFor="SessionSelect">{label}</label>
                            :
                            null
                    }
                    <PageCommunitySelect
                        handleSelect={handleSelect}
                        selNHID={selNHID}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1 ? renderMultiHeader() : null;
};

export default PageCommunitySelectWrapper;
