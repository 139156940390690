import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberCost,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    ShortTermRatePackagesAvailable: yupNullableStringToBool,
    ShortTermRatesAllInclusive: yupNullableStringToBool,
    ShortTermSemiPrivateCostPerDay: yupNullableStringToNumberCost,
    ShortTermPrivateCostPerDay: yupNullableStringToNumberCost,
    ShortTermSharedCostPerDay: yupNullableStringToNumberCost,
});

export default schema;
