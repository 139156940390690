import React from 'react';
import { Ellipsis } from 'react-css-spinners';

interface IProps {
    className?: string;
    showInPlace?: boolean;
    message: string
}

export const LoadingMessage = (props: IProps) => {
    return (
        <div className='loading-msg'> 
            {/*<div className={`${props.showInPlace ? '' : 'centre-fixed'} ${props.className?? ''}`}>*/}
                <span>{props.message}</span>
                <Ellipsis size={80} />
            {/*</div>*/}
        </div>
    );
};
