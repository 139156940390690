import React, { FC, useState } from 'react';
import { LoadingSpinner } from '../../../loading_spinner';

interface Props {
    onClearImage: () => void;
    src: string;
    isLoading: boolean;
}

const SelectedPhotoPreview: FC<Props> = ({ onClearImage, src, isLoading }) => {
    const [mouseIsOverImage, setMouseIsOverImage] = useState(false);
    const showClearImageButton = mouseIsOverImage && !isLoading;

    const clearImageButton = (
        <button
            onClick={onClearImage}
            className="position-absolute end-0 top-0  bg-danger d-flex justify-content-center align-items-center rounded-circle"
            style={{
                all: 'unset',
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                transform: 'translate(7px, -7px)'
            }}
        >
            <i className="fa fa-close fs-5 text-white" />
        </button>
    );

    return (
        <div
            style={{ width: '160px', height: '160px' }}
            className="position-relative border rounded"
            onMouseEnter={() => setMouseIsOverImage(true)}
            onMouseLeave={() => setMouseIsOverImage(false)}
        >
            {showClearImageButton && clearImageButton}
            <div className="rounded overflow-hidden h-100 w-100">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <img
                        src={src}
                        className="object-fit-cover d-block w-100 h-100"
                    />
                )}
            </div>
        </div>
    );
};

export { SelectedPhotoPreview };
