import React from 'react';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { FormInput } from '../../../../../../components/common/form_input';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { yesNoOptions } from '../../../helpers';
import { SectionHeading } from '../../components/section_heading';

const RoomTypeTab = () => {
    const inline = true;

    return (
        <div className="mt-4">
            <SectionHeading title="Room Types" />
            <div className="ps-2">
                <FormRadioGroup
                    groupLabel="Shared"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RoomTypeShared"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Private Room"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RoomTypePrivateRoom"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Semi Private"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RoomTypeSemiPrivate"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Private Suite"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RoomTypePrivateSuite"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormInput
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inlineLabelWidth="large"
                    label="Any other room types?"
                    maxWidth="300px"
                    name="RoomTypeOther"
                />
            </div>
        </div>
    );
};

export { RoomTypeTab };
