import React from 'react';
// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import Select from 'react-select';

// import { getAwardTrackerAwards } from '../../api/award';
// import * as csvEportAPI from '../../api/csv_export';
// import { LoadingSpinner } from '../../components/common/loading_spinner';
// import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
// import AwardsTrackerAggregateTable from '../../components/tables/awards_tracker_aggregate_table';
// import AwardsTrackerTable from '../../components/tables/awards_tracker_table';
// import { AwardKeyName, AwardType, IAwardOption } from '../../interfaces/award';
// import { ISelectOption } from '../../interfaces/form';
// import { ISessionState, SessionType as EnumSessionType } from '../../interfaces/session';
// import { formatFileNameForCsvExport } from '../../utils/file';
// import { awardPriorityOrder, renderOrderedAwardButtons } from '../../components/awards/awards_common';

// interface IProps {
//     memberIDs: number[];
//     groupID: number;
// }

// const isContenderOptions: ISelectOption[] = [
//     { label: 'Yes & No', value: null },
//     { label: 'Yes', value: 1 },
//     { label: 'No', value: 0 }
// ];

// const AwardsTracker = ({ memberIDs, groupID }: IProps) => {
    const AwardsTracker = () => {
        return (<></>); 

    // const [ isFilterLoading, setFilterIsLoading ] = useState<boolean>(true);
    // const [ awards, setAwards] = useState<IAwardOption[]>(); 
    // const [ selectedAward, setSelectedAward ] = useState<IAwardOption>(); 
    // const [ isContender, setIsContender ] = useState<number | null>(null); 

    // const  location = useLocation();
  
    // const { Session, Group, Members }: ISessionState = useSelector(
    //     ({ SessionState }: any) => SessionState
    // );
    
    // const { SessionType } = Session;

    // useEffect(() => {        
    //     if (!groupID && !memberIDs) return;

    //     setFilterIsLoading(true);

    //     if (isSingleMember) setIsContender(null);

    //     const fetchAwards = async (): Promise<void> => {    
    //         try {
    //             const awardType = isSingleMember ? AwardType.Member : null;
    //             const awards: IAwardOption[] = await getAwardTrackerAwards(awardType);

    //             if (!awards.length) {
    //                 throw new Error('No awards data returned');
    //             }

    //             setAwards(awards); 
    //             setValidInitialAward(awards);
    //         } catch (e) {
    //             console.error(e);
    //         } finally {
    //             setFilterIsLoading(false);
    //         }
    //     };

    //    fetchAwards();
    // }, [ memberIDs, groupID ]);

    // const setValidInitialAward = (awards: IAwardOption[]) => {
    //     const { initialAwardKeyName } = location?.state as any || {};

    //     let priorityAward: IAwardOption;

    //     if (awardPriorityOrder.length) {
    //         priorityAward = awards.find((a: IAwardOption) => a.KeyName === awardPriorityOrder[0]);
    //     }

    //     const defaultAward = priorityAward || awards[0];
        
    //     if (
    //         !initialAwardKeyName ||
    //         (initialAwardKeyName === AwardKeyName.BestOfSeniorLivingProvider && isSingleMember)
    //     ) {
    //         setSelectedAward(defaultAward);
    //         return;
    //     }

    //     const award = awards.find((a: IAwardOption) => a.KeyName === initialAwardKeyName);

    //     if (award) {
    //         setSelectedAward(award);
    //         return;
    //     }

    //     setSelectedAward(defaultAward);
    // };

    // const renderAwardTitle = ({ Title, Year }: IAwardOption): string => `${Title} (${Year})`;

    // const isSingleMember = (() => memberIDs?.length === 1)();

    // const handleSelectedAwardChange = (awardYearID: number) => {
    //     const award = awards.find((a: IAwardOption) => a.AwardYearID === awardYearID);
    //     setSelectedAward(award);
    // };

    // const renderAwardButtons = () => {
    //     const renderAwardButton = (award: IAwardOption) => {
    //         return (
    //             <div 
    //                 className="btn btn-primary me-2 me-sm-4 mb-2" 
    //                 key={`award-btn-${award.KeyName}`}
    //                 onClick={() => handleSelectedAwardChange(award.AwardYearID)}
    //             >
    //                 { award.Title } { award.Year }
    //             </div>
    //         );
    //     };

    //     return (
    //         <div className="row scroll-x me-0">
    //             <div className="col-12 mb-4 d-flex flex-xl-wrap">
    //                 { renderOrderedAwardButtons(awards, renderAwardButton, isSingleMember) }
    //             </div>
    //         </div> 
    //     );
    // };

    // const isContenderSelectedOption = ((): ISelectOption => {
    //     return isContenderOptions?.find(({ value }: ISelectOption) => value === isContender);
    // })();

    // const renderTableToolsRow = () => {
    //     const showIsContenderSelect = !isSingleMember && selectedAward.AwardTypeTag !== AwardType.Group;

    //     return (
    //         <div className="row">
    //             {
    //                 showIsContenderSelect ? 
    //                     <div className="d-flex col-md-12 col-lg-6"> 
    //                         <label htmlFor="IsContenderSelect" className="text-nowrap fw-bold d-flex flex-column justify-content-center align-items-center pe-4">Winning Criteria Met</label>
    //                         <Select
    //                             id="IsContenderSelect"
    //                             options={isContenderOptions}
    //                             name="IsContenderSelect"
    //                             className="basic-multi-select"
    //                             classNamePrefix="select"
    //                             onChange={({ value }: ISelectOption) => setIsContender(value)}
    //                             value={isContenderSelectedOption}
    //                             isDisabled={isFilterLoading}
    //                             isSearchable={false}
    //                         />
    //                     </div> : null
    //             }
    //             <div className={`col-sm-12 ${showIsContenderSelect ? 'col-lg-6 mt-lg-0' : ''} mt-sm-4`}> 
    //                 <div className="text-end">
    //                     <CsvExport 
    //                         startExportFunction={startExport}
    //                         modalTitleSuffix={'Upcoming Awards Tracker'}
    //                         label="Export as CSV"
    //                         displayType={DisplayType.Button}
    //                         fileName={currentFileName}
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    // const renderWebText = (text: string, index: number) => {
    //     return ( 
    //         <div className="pb-3" key={`renderWebText-${index}`}>
    //             <span className="fa fa-check"></span>&nbsp;&nbsp;{ text }
    //         </div>
    //     );
    // };
    
    // const renderAwardImage = () => {
    //     const { Title, Year, KeyName } = selectedAward;

    //     const noImageKeyNames = [
    //         AwardKeyName.BestOfSeniorLivingAllStar,
    //         AwardKeyName.BestOfSeniorLivingProvider        
    //     ];

    //     if (noImageKeyNames.includes(KeyName)) {
    //         return <></>;
    //     }
    //         return   (
    //             <div className="col-12 col-sm-4 col-md-3 col-xl-2 pe-sm-0"> 
    //                 <div className="text-center mb-3 mb-sm-0">
    //                     <img 
    //                         src={`/images/awards/${Year}/${KeyName}.png`}
    //                         alt={`${Title} (${Year})`} 
    //                         style={{ width: '140px' }} 
    //                     />
    //                 </div>
    //             </div>
    //     );
    // };

    // const startExport = async (): Promise<string> => {
    //     return csvEportAPI.startExport('/award/tracker/export', {  
    //         AwardYearID: selectedAward.AwardYearID,
    //         IsContender: isContender
    //     });
    // };

    // const currentFileName = ((): string => {
    //     if (!selectedAward) {
    //       return '';  
    //     }
        
    //     const { Title, Year } = selectedAward;
    //     const name = isSingleMember 
    //         ? Members[0].Name 
    //         : SessionType === EnumSessionType.SessionTypeGroup 
    //             ? Group.Name 
    //             : 'multiple_members';

    //     const fileName = `${name}_award_tracker_${Title}_${Year}`;

    //     return formatFileNameForCsvExport(fileName);
    // })();

    // const renderFilters = () => {
    //     if (isFilterLoading) {
    //        return <LoadingSpinner  show={true}/>; 
    //     } 

    //     return (
    //         <div className="widget mb-2">
    //             <div className="row widget-body">
    //                 <div className="col-12">
    //                     <div className="mt-4 mt-lg-0">
    //                         { renderAwardButtons() }
    //                     </div>  
    //                     <div className="card">
    //                         <div className="card-body">
    //                             <div className="row">
    //                                 { renderAwardImage() }
    //                                 <div className="col-12 col-sm-8 col-md-9 col-xl-10 mt-sm-3 mt-md-4">
    //                                     <h4 className="mb-3">
    //                                         { renderAwardTitle(selectedAward) }
    //                                     </h4>
    //                                     <div>
    //                                         { selectedAward.WebText.map((t: string, index: number) => renderWebText(t, index)) }
    //                                     </div>
    //                                     {
    //                                         selectedAward.KeyName === AwardKeyName.BestOfActivities ?
    //                                             <div className="pb-3 text-danger" key="renderWebText-activities-specific">
    //                                                 <span className="fa fa-check"></span>&nbsp;&nbsp;<span className="fw-bold">This Award is now closed. Winners will be announced shortly</span>
    //                                             </div> : null
    //                                     }
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    // const renderAggregateDataTable = () => {
    //     if (isSingleMember || selectedAward.AwardTypeTag === AwardType.Group) return null;
        
    //     return (
    //         <div className="widget widget-fullwidth mb-2">
    //             <div className="row widget-body">
    //                 <div className="col-12">
    //                     <div className="card">
    //                         <div className="card-body p-0 py-4">
    //                             <div className="award_tracker_dt sticky_dt table_dt">
    //                                 <AwardsTrackerAggregateTable 
    //                                     award={selectedAward}
    //                                     isContender={isContender}
    //                                 />
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    // const isMultiRow = ((): boolean => {
    //     return !isSingleMember && !(selectedAward?.AwardTypeTag === AwardType.Group);
    // })();

    // const renderDataTable = () => {
    //     return (
    //         <div className="widget widget-fullwidth">
    //             <div className="row widget-body">
    //                 <div className="col-12">
    //                     <div className="card">
    //                         <div className="card-body p-0 py-4">
    //                             <div className="px-3 pb-4">
    //                                 { renderTableToolsRow() }
    //                             </div> 
    //                             <div className="award_tracker_dt sticky_dt table_dt">
    //                                 <AwardsTrackerTable 
    //                                     award={selectedAward}
    //                                     isContender={isContender}
    //                                     pagination={isMultiRow}
    //                                     sortable={!isSingleMember}
    //                                 />
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    // if (isFilterLoading) return null;

    // return (
    //     <>
    //         { renderFilters() }
    //         { renderAggregateDataTable() }
    //         { renderDataTable() }
    //     </>
    // );
};

export default AwardsTracker;