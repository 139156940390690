import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import MultiOrgPropertyPhotos from '../../components/assets/multi_org_property_photos';
import IndividualPropertyPhotos from '../../components/assets/individual_property_photos';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../interfaces/session';
import { IPortalPropertyPhotoAsset } from '../../interfaces/asset';
import { useSelector } from 'react-redux';
import * as assetApi from '../../api/assets';
import { ProfileScoreType, INhPortalScoreItem } from '../../interfaces/property';
import ProfileCompletenessPhotoHeader from '../../components/profile_completeness/profile_completeness_photo_header';
import { buildProfileCompleteMapForNHIDs } from '../../utils/profile_completeness';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { ITag } from '../../interfaces/tag';
import { IMessageType } from '../../interfaces/common';
import { 
    NHAssetType,
    TagType
} from '../../interfaces/asset';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}


const PropertyPhotos = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [busyLoadingPhotos, setBusyLoadingPhotos] = useState<boolean>(true);
    const [selectedNHID, setSelectedNHID] = useState<number>(defaultNHID);
    const [propertyPhotos, setPropertyPhotos] = useState<any[]>([]);
    const [profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);
    const [hasNonPublishedPhotosFilter, setHasNonPublishedPhotosFilter] = useState<boolean>(false);
    const [tagFilter, setTagFilter] = useState<any[]>([]);
    const [photoTags, setPhotoTags] = useState<ITag[]>([]);
    const [photoUploadMessages, setPhotoUploadMessages] = useState<IMessageType[]>([]);
    const [showPhotoModal, setShowPhotoModal] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelectedNHID(selNHID);
        setTagFilter([]);    
    });

    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const { SessionType: sessionType } = Session;
   
    const loadPropertyPhotos = async () => {
        if (!selectedNHID) {
            setPropertyPhotos(null);
            setBusyLoadingPhotos(true);
            try {
                const propertyPhotos = sessionType === SessionType.SessionTypeOrg
                    ? await getOrgPropertiesPhotos()
                    : await getPropertiesPhotos();

                setPropertyPhotos(propertyPhotos);
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingPhotos(false);
            }
        } else {
            setBusyLoadingPhotos(false);
        }
    };

    useEffect(() => {
        const singleNHID = nhIDs && nhIDs.length === 1 ? nhIDs[0] : null;
        if (singleNHID) {
            setSelectedNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if(photoTags.length === 0) {
            fetchPhotoTags();
        }

        if (!selectedNHID) {
            loadPropertyPhotos();
        }

        setBusyLoadingPhotos(true);
        (async () => {
            try {
                if (selectedNHID) {
                    const propertyPhotos = await getPropertyPhotos([selectedNHID]);
                    if (propertyPhotos) {
                        setPropertyPhotos(propertyPhotos);
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingPhotos(false);
            }
        })();
    }, [selectedNHID]);

    useEffect(() => {
        loadPropertyPhotos();
    }, [profileCompletenessFilterValue, hasNonPublishedPhotosFilter]);

    const getOrgPropertiesPhotos = async (): Promise<IPortalPropertyPhotoAsset[]> => {
        if (!orgNHID) {
            console.error('getOrgPropertiesPhotos: Invalid orgNHID');
            return null;
        }

        const filters = new Map<string, any>();
        if (hasNonPublishedPhotosFilter) {
            filters.set('HasNonPublishedPhotos', hasNonPublishedPhotosFilter);
        }

        const photoData = await assetApi.getOrgPropertiesPhotos(orgNHID, filters);
        return filterByProfileCompleteness(photoData);
    };

    const filterByProfileCompleteness = (photoData: IPortalPropertyPhotoAsset[]) => {

        if (profileCompletenessFilterValue === null) {
            return photoData;
        }

        const profileMap = buildProfileCompleteMapForNHIDs(profileCompletenessState);

        return photoData.filter((item: IPortalPropertyPhotoAsset) => {
            const profileCompleteItem = profileMap.get(item.NHID);
            return profileCompleteItem?.PhotoComplete === profileCompletenessFilterValue;
        });
    };

    const getPropertiesPhotos = async (): Promise<IPortalPropertyPhotoAsset[]> => {
        
        const filters = new Map<string, any>();
        if (hasNonPublishedPhotosFilter) {
            filters.set('HasNonPublishedPhotos', hasNonPublishedPhotosFilter);
        }

        const photoData = await assetApi.getPropertiesPhotos(nhIDs, filters);
        return filterByProfileCompleteness(photoData);
    };

    const getPropertyPhotos= async ( nhID: number[] ): Promise<IPortalPropertyPhotoAsset[]> => {
        let photoData;
        if(nhID[0] === orgNHID) {
            photoData = await getOrgPropertiesPhotos();
        } else {
            photoData = await assetApi.getPropertiesPhotos(nhID);
        }
        return filterByProfileCompleteness(photoData);
    };

    const fetchPhotoTags  = async () : Promise<void>  => {
        const data: ITag[] = await assetApi.getTags({
            AssetTypeID: NHAssetType.Photos, 
            WebsiteID: 1, 
            IsTagPublishPortal: true, 
            TagTypeID: TagType.SingleTag
        });
            
        if(data)      {
            setPhotoTags(data);
        }
    };
    
    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Photos for'}
                handleSelect={handleSelect}
                selNHID={selectedNHID}
            />

            <div className="widget" data-widget-height="auto">
                <div className="widget-body">
                    <ProfileCompletenessPhotoHeader
                        selNHID={selectedNHID}
                        nhIDs={nhIDs}
                        orgNHID={orgNHID}
                        handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                        handlePhotoNotPublishedFilter={setHasNonPublishedPhotosFilter}
                        nonPublished={hasNonPublishedPhotosFilter}
                        profileCompletenessFilterValue={profileCompletenessFilterValue}
                        profileScoreType={ProfileScoreType.Photo}
                    />
                </div>
            </div>

            {
                nhIDs?.length !== 1 && !selectedNHID && propertyPhotos ?
                    <MultiOrgPropertyPhotos
                        handleSelectedNHID={setSelectedNHID}
                        propertyPhotos={propertyPhotos}
                        handleMultiPropertiesTagFilter={setTagFilter}
                        multiPropertiesTagFilterValue={tagFilter}
                        busyLoadingPhotos={busyLoadingPhotos}
                        setShowPhotoModal={setShowPhotoModal}
                    />
            :
                null
            }
               
            {
                (nhIDs?.length === 1 || selectedNHID) && propertyPhotos ?
                    <div className="widget mt-0">
                        <div className="widget-body">
                            <IndividualPropertyPhotos
                                selectedNHID={selectedNHID}
                                nhIDs={nhIDs}
                                propertyPhotos={propertyPhotos}
                                setPropertyPhotos={setPropertyPhotos}
                                photoTags={photoTags}
                                busyLoadingPhotos={busyLoadingPhotos}
                                setBusyLoadingPhotos={setBusyLoadingPhotos}
                                photoUploadMessages={photoUploadMessages}
                                setPhotoUploadMessages={setPhotoUploadMessages}
                                handleReloadPropertyPhotos={getPropertyPhotos}
                                showPhotoModal={showPhotoModal}
                                setShowPhotoModal={setShowPhotoModal}
                            />
                        </div>
                    </div>
                :
                    null
            }
        </>
    );
};

export default PropertyPhotos;