import React from 'react';

interface IProps {
    title: string;
}

const PageTitle = ({ title }: IProps) => {
    return (
        <h2>{title}</h2>
    );
};

export default PageTitle;
