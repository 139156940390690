import React from 'react';

interface IProps {
    websiteUrl: string;
}

export const PremiumLink = ({ websiteUrl }: IProps) => {
    return (
        <a className="small" href={`${websiteUrl}/subscribe/`} target="_blank" rel="noreferrer">
            View all Premium benefits / Subscribe
        </a>
    );
}; 

export default PremiumLink;