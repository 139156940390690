import React, { useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import LeadsBreakdownTable from '../../components/leads/lead_insights_breakdown_table';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';

const LeadsBreakdown = () => {
    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const [ selNHID, setSelNHID ] = useState<number>(null);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const getPropertyIDs = () => {
        if (!sessionState.Properties?.length) {
            return null;
        }

        return sessionState.Properties.map((item) => item.NHID);
    };

    return (
        <>
            <PageCommunitySelectWrapper
                label="All Leads for"
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            <div className="mt-4">
                <LeadsBreakdownTable
                    orgNHID={sessionState.Session.SessionType === 'org' ? sessionState.Org.NHID : null}
                    nhids={sessionState.Session.SessionType === 'property' ? getPropertyIDs() : null}
                    selNHID={selNHID}
                />
            </div>
        </>
    );
};

export default LeadsBreakdown;
