import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    InsuranceTypes: yup.array().of(yup.object().shape({
        PropertyInsuranceTypeID: yup.number().required(),
        IsActive: yupNullableStringToBool
    })).transform((data) => {
        return data.filter(({IsActive}: any) => IsActive !== null).map(({PropertyInsuranceTypeID, IsActive}: any) => ({
            PropertyInsuranceTypeID,
            IsActive
        }));
    })
});

export default schema;
