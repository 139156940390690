import React, { useRef } from 'react';
import RatingStars from './rating_stars';
import { useIntersection} from '../../hooks/use_intersection';
import { intersectionObserverAvailable } from '../../utils/common';

interface IProps {
    keyProp: string | number;
    value : number;
}

const VisibleRatingStars = ({keyProp, value}: IProps) => {

    const ref = useRef();
    const inViewport = intersectionObserverAvailable() ? useIntersection(ref) : true; 

    return (
        <div ref={ref} className="d-inline-flex">
            {
                inViewport ?
                <RatingStars
                    keyProp={keyProp}
                    value={value}
                /> : null
            }
        </div>
    );
};

export default VisibleRatingStars;

