import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import RatingStars from '../../components/common/rating_stars';
import { IReply  } from '../../interfaces/reply';
import { LoadingSpinner } from '../common/loading_spinner';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

interface IProps {
    reply: any;
    hasReply: boolean;
    handleClose(): void;
    handleDeleteReply(reviewID: number): Promise<void> | null;
    handleCreateOrUpdateReply(reviewID: number, replyData: IReply ): Promise<void>;
    cancelText: string;
    saveText: string[];
}

interface FormInputs {
  ReplierFirstName: string;
  ReplierLastName: string;
  ReplierPosition: string;
  ReplierEmail: string;
  ReplyContent: string;
}

const ReplyModal = ({
    reply, 
    hasReply, 
    handleClose, 
    handleDeleteReply,
    handleCreateOrUpdateReply,
    cancelText,
    saveText
}: IProps) => {

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const userFirstName = get(sessionState, 'User.FirstName', '');
    const userLastName = get(sessionState, 'User.LastName', '');
    const userJobTitle = get(sessionState, 'User.JobTitle', '');
    const userEmail = get(sessionState, 'Session.EmailAddress', '');

    const defaultReplierFirstName = reply.ReplyID ? reply.ReplierFirstName || '' : userFirstName;
    const defaultReplierLastName = reply.ReplyID ? reply.ReplierLastName || ''  : userLastName;
    const defaultReplierPosition = reply.ReplyID ? reply.ReplierPosition || ''  : userJobTitle;
    const defaultReplierEmail = reply.ReplyID ? reply.ReplierEmail || '' : userEmail;

    const [ busy, setBusy ] = useState<boolean>(false);
    const [ confirmDelete, setConfirmDelete ] = useState<boolean>(false);

    const { register, formState: { errors }, handleSubmit } = useForm<FormInputs>();

    const onSubmit = async (data: any) => {
        setBusy(true);

        const { 
            ReplierEmail, 
            ReplierFirstName, 
            ReplierLastName, 
            ReplierPosition, 
            ReplyContent
        } = data;

        const replyData: IReply = {
            ReplierEmail,
            ReplierFirstName,
            ReplierLastName,
            ReplierPosition,
            ReplyContent
        };

        if (reply.ReplyID) {
            replyData.ReplyID = reply.ReplyID;
        }

        await handleCreateOrUpdateReply(reply.ReviewID, replyData);
        setBusy(false);
    };

    const handleDelete = async () => {

        if (!handleDeleteReply) {
            return;
        }

        setBusy(true);
        await handleDeleteReply(reply.ReviewID);
        setBusy(false);
        setConfirmDelete(false);
    };

    const renderForm = () => {

        if (busy) return (
            <Modal.Body>
                <div className="py-4">
                    <LoadingSpinner show={true} showInPlace={true} />
                </div>
            </Modal.Body>
        );

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="position-relative"> 
                                <label htmlFor="ReplierFirstName" className="mb-1">
                                    <strong>Replier First Name*</strong>&nbsp; 
                                    <span className="small">(published)</span>
                                </label>
                                <input
                                    {
                                        ...register('ReplierFirstName', {
                                            required: 'Please enter a Replier First Name'
                                        })
                                    }
                                    type="text"
                                    className={`form-control ${errors.ReplierFirstName ? 'is-invalid' : ''}`}
                                    id="ReplierFirstName"
                                    defaultValue={defaultReplierFirstName}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ReplierFirstName"
                                    render={({ message }: any) => <span className="form-error">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="position-relative"> 
                                <label htmlFor="ReplierLastName" className="mb-1">
                                    <strong>Replier Last Name*</strong>&nbsp; 
                                    <span className="small">(published)</span>
                                </label>
                                <input
                                    {
                                        ...register('ReplierLastName', {
                                            required: 'Please enter a Replier Last Name'
                                        })
                                    }
                                    type="text"
                                    className={`form-control ${errors.ReplierLastName ? 'is-invalid' : ''}`}
                                    id="ReplierLastName"
                                    defaultValue={defaultReplierLastName}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ReplierLastName"
                                    render={({ message }: any) => <span className="form-error">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="position-relative">   
                                <label htmlFor="ReplierPosition" className="mb-1">
                                    <strong>Replier Job Title*</strong>&nbsp;  
                                    <span className="small">(published)</span>
                                </label>
                                <input
                                    {
                                        ...register('ReplierPosition', {
                                            required: 'Please enter a Replier Job Title'
                                        })
                                    }
                                    type="text"
                                    className={`form-control ${errors.ReplierPosition ? 'is-invalid' : ''}`}
                                    id="ReplierPosition"
                                    defaultValue={defaultReplierPosition}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ReplierPosition"
                                    render={({ message }) => <span className="form-error">{message}</span>}
                                />
                            </div>
                            <span className="small">eg. Marketing Director</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="position-relative"> 
                                <label htmlFor="ReplierEmail" className="mb-1">
                                    <strong>Replier Email*</strong>&nbsp;  
                                    <span className="small">(not published)</span>
                                </label>
                                <input
                                    {
                                        ...register('ReplierEmail', {
                                            required: 'Please enter a Replier Email Address'
                                        })
                                    }
                                    type="email"
                                    className={`form-control ${errors.ReplierEmail ? 'is-invalid' : ''}`}
                                    id="ReplierEmail"
                                    defaultValue={defaultReplierEmail}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ReplierEmail"
                                    render={({ message }: any) => <span className="form-error">{message}</span>}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="position-relative">   
                                <label htmlFor="ReplyContent" className="mb-1">
                                    <strong>Reply Text*</strong>&nbsp; 
                                    <span className="small">(published - on website alongside the review)</span>
                                </label>
                                <textarea
                                    {
                                        ...register('ReplyContent', {
                                            required: 'Please enter some Reply Text'
                                        })
                                    }
                                    id="ReplyContent"
                                    cols={30}
                                    rows={5}
                                    className={`form-control reply-modal-textarea ${errors.ReplyContent  ? 'is-invalid' : ''}`}
                                    defaultValue={reply.ReplyContent || ''}
                                ></textarea>
                                
                                <ErrorMessage
                                    errors={errors}
                                    name="ReplyContent"
                                    render={({ message }) => <span className="form-error">{message}</span>}
                                />
                            </div>
                            <span className="small">You will be notified when your Reply has been Verified</span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col-12 d-flex flex-column-reverse flex-md-row">

                        {
                            hasReply && !confirmDelete ?
                                <button
                                    type="button"
                                    className="btn btn-sm m-auto mt-4 mb-2 m-md-0 me-md-auto"
                                    onClick={() => setConfirmDelete(true)}
                                >
                                    Delete this reply
                                </button>
                            : <></>
                        }
                        {
                            hasReply && confirmDelete && handleDeleteReply ?
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger m-auto mt-4 mb-2 m-md-0 me-md-auto"
                                    onClick={() => handleDelete()}
                                >
                                    Confirm Delete
                                </button>
                            : <></>
                        }
                            <div className="m-auto m-md-0 ms-md-auto d-flex flex-row">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary btn-outline me-2 me-md-4"
                                    onClick={() => handleClose()}
                                >
                                    Close &amp; <br className="d-md-none"/>Do Not {cancelText}
                                </button>
                                <button type="submit" className="btn btn-sm btn-primary">
                                    {saveText[0]} <br className="d-md-none"/>{saveText[1]} 
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </form>
        );
    };

    return (
        <Modal
            show={true}
            centered
            size="xl"
            dialogClassName="modal-sticky-footer"
        >
            <Modal.Header>
                <Modal.Title className="reply-modal-title">
                    <p className="reply-modal-title mb-3">
                        <strong>Reply to Review ID: {reply.ReviewID} { reply.ReplierFirstName ? `by ${reply.ReplierFirstName}` : '' } { reply.ReplierLastName } for { reply.PropertyName }</strong>
                    </p>
                    <div className="m-0">
                        { 
                            reply.OverallRating ? 
                                <span className="me-3 mb-2 d-inline-flex align-items-center">
                                    <strong className="me-1">Overall Rating:</strong> 
                                    <RatingStars
                                        keyProp={reply.ReviewID}
                                        value={reply.OverallRating}
                                    />
                                </span> : <></> 
                        }
                    </div>
                    <p className="m-0">
                        { reply.ExperienceTypeName ? <span className="me-3 d-inline-block mb-2"><strong>Reviewer Experience Type:</strong> {reply.ExperienceTypeName}</span> : <></> }
                        { reply.ConnectionName ? <span className="d-inline-block mb-2"><strong>Relationship to the resident or client:</strong> {reply.ConnectionName}</span> : <></> }
                    </p>
                    <p className="m-0 reply-modal-review">
                        <strong>Review:</strong> { reply.ReviewContent }
                    </p>
                </Modal.Title>
            </Modal.Header>

            { renderForm() }

        </Modal>
    );
};

export default ReplyModal;
