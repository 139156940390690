import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import DataTable from '../../components/common/data_table';
import ReplyModal from '../../components/reviews/reply_modal';
import ReviewTableExpandableRows from '../../components/reviews/review_table_expandable_rows';
import ReviewListReplyButton from '../../components/reviews/review_list_reply_button';
import { LoadingMessage } from '../../components/common/loading_message';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import NoReviewData from '../../components/reviews/no_review_data';
import { ISessionState } from '../../interfaces/session';
import { IPortalReplyItem, IReply } from '../../interfaces/reply';
import { isDefined} from '../../utils/common';
import {
    createFilters,
    buildColumns,
    renderFilters,
    fetchReviewData,
    getReviewDataParams,
    buildLoadingMessage,
    createOrUpdateReply
} from './reviews_list_common';

import {
    deleteReply,
    getReviewScheme
} from '../../api/review';

import * as csvExportAPI from '../../api/csv_export';
import { scrollToTop } from '../../utils/common';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import { PublishedReviewsFilterGroup } from '../../interfaces/review';
import { debounce, get } from 'lodash';
import { ReviewPublicationStatusType } from '../../interfaces/review';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IReviewsListProps {
    nhIDs: number[];
    orgNHID: number;
}

const defaultSortOrder = 'DESC';
const defaultSortField = 'LastPublishedDate';
const defaultPageLimit = 100;
const defaultPageOffset = 0;

const ReviewsListPublished = ({ nhIDs, orgNHID }: IReviewsListProps) => {
    const navigate = useNavigate();

    const { reviewID: reviewIDParam } = useParams();

    if (reviewIDParam && isNaN(Number(reviewIDParam))) {
        navigate('/reviews');
    }

    const location = useLocation();
   
    const locationfilters = get(location, 'state.filters', null);
    const locationfilterGroup = get(location, 'state.filterGroup', null);

    const [ busy, setBusy ] = useState<boolean>(false);
    const [ activeReply, setActiveReply ] = useState<any>(null);
    const [ hasReply, setHasReply ] = useState<boolean>(false);
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const [ filterMatrix ] = useState(createFilters());
    const [ filterOpen, setFiltersOpen] = useState<boolean>(false);
    const [ reviews, setReviews ] = useState<any[]>([]);
    const [ totalReviewCount, setTotalReviewCount ] = useState<number>(0);
    const [ totalFilteredReviewCount, setTotalFilteredReviewCount ] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(defaultPageOffset);
    const [ pageLimit, setPageLimit ] = useState<number>(defaultPageLimit);
    const [ sortOrder, setSortOrder ] = useState<string>(defaultSortOrder);
    const [ sortField, setSortField ] = useState<string>(defaultSortField);
    const [ reviewScheme, setReviewScheme ] = useState<any>(null);

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { 
        Org: sessionStateOrg, 
        Properties: sessionStateProperties 
    } = sessionState;

    const canReplyToReview = get(sessionState, 'Session.Perms.CanReplyToReview', false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        applyFilter('NHID', selNHID);
    });

    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);

        if (refreshData) {
            getReviewDataDebounced();
        }
    };
        
    const applyReviewScoreFilters = async (refreshData = true) => {

        let curReviewSchemeData: any = null;

        if (!reviewScheme) {
            const reviewSchemeData = await getReviewScheme();
            setReviewScheme(reviewSchemeData);
            curReviewSchemeData = reviewSchemeData;
        } else {
            curReviewSchemeData = reviewScheme;
        }

        const experienceOptions = (curReviewSchemeData?.ExperienceTypes || [])
            .filter((item: any) => item.KeyName !== 'tour')
            .map((item: any) => ({
                label: item.Name,
                value: item.ExperienceTypeID
            }));

        const dateString = moment().subtract(2, 'year').format('YYYY-MM-DD');
        const dateSubmittedFrom = new Date(dateString);

        applyFilter('DateSubmittedFrom', dateSubmittedFrom, false);
        applyFilter('ReviewExperience', experienceOptions, refreshData);
    };

    const getReviewDataDebounced = useCallback(debounce(() => getReviewData(0, pageLimit, sortField, sortOrder), 500), []);

    const columns: any = buildColumns('published');

    if (canReplyToReview) {
        columns.push({
            name: '',
            width: '240px',
            cell: (row: any) => {
                return (
                    <ReviewListReplyButton
                        data={row}
                        setActiveReply={setActiveReply}
                        setHasReply={setHasReply}
                    />
                );
            }
        });
    }

    const getReviewDataWithDefaultParams = () => getReviewData(
        defaultPageOffset, 
        defaultPageLimit, 
        defaultSortField, 
        defaultSortOrder
    );

    const getReviewData = async (
        pageOffset: number,
        pageLimit: number,
        sortField: string,
        sortOrder: string
    ) => {

        setBusy(true);

        const params: any = getReviewDataParams(
            filterMatrix.getRequestParams(),
            pageOffset,
            pageLimit,
            sortField,
            sortOrder,
            ReviewPublicationStatusType.Published,
            false
        );

        const [ tableData, totalReviews, totalFilteredRows]: 
            [any[], number, number] = await fetchReviewData(params);
        
        if (reviewIDParam && !tableData.length) {
            filterMatrix.reset();
            getReviewData(0, pageLimit, sortField, sortOrder);
            navigate('/reviews');
        }

        setReviews(tableData);
        setTotalReviewCount(totalReviews);
        setTotalFilteredReviewCount(totalFilteredRows);
        setBusy(false); 
    };

    useEffect(() => {
        const reviewIDParamNum = Number(reviewIDParam);

        if (reviewIDParamNum && !isNaN(reviewIDParamNum)) {
            applyFilter('ReviewID', reviewIDParamNum, true);
        }
    }, []);

    useEffect(() => {
        applyFilter('NHID', null, false);
    }, [ sessionStateOrg, sessionStateProperties]);

    useEffect(() => {
        const { DatePublishedFrom, HasReply, NHID } = locationfilters || {};

        if (isDefined(NHID)) {
            applyFilter('NHID', NHID, false);
        } 

        if (
            locationfilterGroup && 
            locationfilterGroup === PublishedReviewsFilterGroup.ReviewScoreReviews
        ) {
            applyReviewScoreFilters();
        } 

        // TODO Maybe reset location state after processing ? Doesn't 
        // work well in strict mode as everything gets mounted twice
        //navigate('.', { state: {}, replace: true });

        if (isDefined(DatePublishedFrom) || isDefined(HasReply)) {
            if (isDefined(DatePublishedFrom)) {
                const datePublishedFrom = new Date(DatePublishedFrom);
                applyFilter('DatePublishedFrom', datePublishedFrom, false);
            }

            if (isDefined(HasReply)) {
                applyFilter('HasReply', HasReply ? 'true' : 'false', false);
            }
        }

        setResetPaginationToggle(!resetPaginationToggle);
        setPageOffset(defaultPageOffset);
        getReviewDataWithDefaultParams();
    }, [JSON.stringify(nhIDs), orgNHID]);

    const startExport = async (): Promise<string> => {

        const params: any = getReviewDataParams(
            filterMatrix.getRequestParams(),
            pageOffset,
            pageLimit,
            sortField,
            sortOrder,
            ReviewPublicationStatusType.Published,
            true
        );

        return csvExportAPI.startExport('/review/export', params);
    };
    
    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset((pageNumber - 1) * pageLimit);
            getReviewData(newPageOffset, pageLimit, sortField, sortOrder);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset(defaultPageOffset);
            setPageLimit(newPerPage);
            getReviewData(defaultPageOffset, newPerPage, sortField, sortOrder);
            scrollToTop();
        }
    };

    const onSortChange = (column: any, sortOrderParam: any) => {
        if (column?.sortField && sortOrder) {
            sortOrderParam = sortOrderParam.toUpperCase();
            setSortField(column.sortField);
            setSortOrder(sortOrderParam);
            getReviewData(pageOffset, pageLimit, column.sortField, sortOrderParam);
            scrollToTop();
        }
    };
  
    const handleDeleteReply = async (reviewID: number) => {
        await deleteReply(reviewID);

        updateReplyInReviewList(reviewID, null);
        setActiveReply(null);
    };

    const handleCreateOrUpdateReply = async (reviewID: number, replyData: IReply) => {

        const savedReply = await createOrUpdateReply(reviewID, replyData);
        updateReplyInReviewList(reviewID, savedReply);
        setActiveReply(null);
    };

    const updateReplyInReviewList = (reviewID: number, replyData: IPortalReplyItem | null) => {

        const review = reviews.find((review: any) => review.ReviewID === reviewID);
        if (review) {
            review.Reply = replyData;

            const mutatedReviews: any[] = reviews.map((review: any) => {
                const reviewCopy = { ...review };
                reviewCopy.uuid = uuidv4();
                return reviewCopy;
            });

            setReviews(mutatedReviews);
        } else {
            console.error(`reviewID: ${reviewID} not found`);
        }
    };

    return (
        <>
            <div id='reviews_list_top'>
                <div className="widget widget-table" data-widget-header="1" data-widget-footer="0">
                    <div className="widget-header">
                        <PageCommunitySelectWrapper
                            label={'Published Reviews for'}
                            handleSelect={handleSelect}
                            selNHID={filterMatrix.getRequestParams().NHID}
                        />
                    </div>
                    <div className="widget-body">
                        <div className="card">
                            <div className="card-body">
                                { 
                                    renderFilters(
                                        filterMatrix,
                                        applyFilter,
                                        getReviewData,
                                        true,
                                        totalReviewCount,
                                        totalFilteredReviewCount,
                                        busy,
                                        pageOffset,
                                        pageLimit,
                                        startExport,
                                        filterOpen,
                                        setFiltersOpen,
                                        applyReviewScoreFilters
                                    ) 
                                }

                                <div className="reviews_dt sticky_dt table_dt expander_dt overflow_dt">

                                    {  busy ? <LoadingMessage  message={buildLoadingMessage(filterMatrix)} /> : null }      
                                    
                                    <DataTable
                                        columns={columns}
                                        data={reviews}
                                        expandableRows={true}
                                        expandableRowsComponent={ReviewTableExpandableRows}
                                        expandableRowsComponentProps={{published: true, setActiveReply, setHasReply}} 
                                        pagination={true}
                                        paginationServer={true}
                                        paginationTotalRows={totalFilteredReviewCount}
                                        onChangePage={onPageChange}
                                        onChangeRowsPerPage={onLimitChange}
                                        onSort={onSortChange}
                                        paginationResetDefaultPage={resetPaginationToggle}
                                        fixedHeader={false}
                                        noDataComponent={<NoReviewData loading={busy} />}
                                        defaultExpanded={true}
                                        defaultExpandedIDProp={'ReviewID'}
                                    /> 
                                    {
                                        activeReply ?
                                        <ReplyModal
                                            reply={activeReply}
                                            hasReply={hasReply}
                                            handleClose={() => setActiveReply(null)}
                                            handleDeleteReply={handleDeleteReply}
                                            handleCreateOrUpdateReply={handleCreateOrUpdateReply}
                                            cancelText={'Save'}
                                            saveText={['Save', 'Changes']}
                                        />
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReviewsListPublished;
