import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import PageTitle from '../../components/common/page_title';
import { ISessionProperty, ISessionState } from '../../interfaces/session';
import { LoadingSpinner } from '../../components/common/loading_spinner';

interface IProps {
    Component: any;
    ComponentProps?: any;
    PageTitleText: string;
    ParentMenuText?: string;
    ParentMenuLink?: string;
    HeaderShow: boolean
    HeaderTitle?: string;
    HeaderText?: string;
    SupportArticleButtonShow?: boolean;
}

const NonMenuPageTemplate = ({
    Component,
    ComponentProps,
    PageTitleText,
    ParentMenuText,
    ParentMenuLink,
    HeaderShow,
    HeaderTitle,
    HeaderText,
    SupportArticleButtonShow
}: IProps) => {  
    const { Session, Org, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const renderBreadcrumbs = () => {    
        return (
            <ul className="breadcrumb">
                {
                    <>
                        <li className="breadcrumb-item">
                            <NavLink to={'/'}>Home&nbsp;</NavLink>/
                        </li>
                        { 
                            ParentMenuLink && ParentMenuText ? 
                                <li className="breadcrumb-item">
                                    <NavLink to={ParentMenuLink}>{ParentMenuText}&nbsp;</NavLink>/
                                </li> : null
                        }
                        <li className="breadcrumb-item">
                            { PageTitleText }
                        </li>
                    </> 
                }
            </ul>
        );
    };
    
    const renderSupportLink = () => {
        return (
            <a 
                onClick={() => window.frames[0].document.getElementById('doc360-button').click()}
                className="fw-bold support-link"
            >
                Learn more in the Support Center
            </a>
        );
    };

    const renderMeta = () => {
        const title = `${PageTitleText} | NursingHomes.com Portal `;

        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ title }</title>
                <meta name="description" content={ title } />
            </Helmet>
        );
    };

    const renderComponent = (): any => {

        if (!Session) {
            return null;
        }

        let nhIDs: number[] | null;
        let orgNHID: number | null;

        if (Org) {
            orgNHID = Org.NHID;
            nhIDs = null;
        } else {
            if (Properties) {
                orgNHID = null;
                nhIDs = Properties.map((item: ISessionProperty) => item.NHID);
            }
        }

        const props = { 
            ...(ComponentProps || {}),
            nhIDs,
            orgNHID
        };

        return (
            <Component {...props}/>
        );
    };

    const renderComponentWithHeader = () => {
        return (
            <>
                { renderBreadcrumbs() }

                <div className="kt-portlet">
                    <div className="kt-portlet__head-title white-bg">
                        <div className="kt-portlet__head-title d-flex flex-column ">
                            <PageTitle title={HeaderTitle}/>
                            <p>
                                { HeaderText || null }
                                { SupportArticleButtonShow ? renderSupportLink() : null }
                            </p>
                        </div>
                    </div>
                    <div className="kt-portlet__body">
                        { renderComponent() }
                    </div>
                </div> 
            </>
        );
    };

    return (
        <>
            <div id='page_template_top'>
                { !Session ? <LoadingSpinner show={true}/> : null }
                { renderMeta() }
                { HeaderShow ? renderComponentWithHeader() : renderComponent() } 
            </div>
        </>
    );
};

export default NonMenuPageTemplate;
 