import { useMutation } from '@tanstack/react-query';
import { checkAccount, emailLogin } from '../../../modules/login';


type AccountSuccess = {
    isActionRequired: boolean
};

interface UseLoginMutations {
    onCheckAccountSuccess: (data: AccountSuccess) => void;
    onLoginSuccess: (data: any) => void;
}

const useLoginMutations = ({
    onCheckAccountSuccess,
    onLoginSuccess
}: UseLoginMutations) => {
    // Check account mutation uses the email to determine if there is an existing, active account
    const {
        mutate: checkAccountMutation,
        isPending: isCheckingAccount,
        isError: isCheckAccountError,
        error: checkAccountError,
        reset: resetCheckAccountForm
    } = useMutation({
        mutationFn: async (email: string) => checkAccount(email),
        onSuccess: onCheckAccountSuccess,
        onError: (error) => console.log(error)
    });

    const {
        mutate: loginMutation,
        isPending: isLoggingIn,
        error: loginError,
        isError: isLoginError,
        reset: resetLoginForm
    } = useMutation({
        mutationFn: async ({ email, password }: any) =>
            emailLogin(email, password),
        onSuccess: onLoginSuccess
    });

    return {
        login: loginMutation,
        checkAccount: checkAccountMutation,
        isLoading: isCheckingAccount || isLoggingIn,
        isError: isCheckAccountError || isLoginError,
        error: checkAccountError || loginError,
        reset: () => {
            resetCheckAccountForm();
            resetLoginForm();
        }
    };
};

export { useLoginMutations };
