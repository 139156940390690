import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTestUsers } from '../../../api/login';
import { FormSelect } from '../../../components/common/form_select';
import _ from 'lodash';


interface IProps {
    name: string;
}
const DevelopmentAccountLoginSelect: FC<IProps> = ({name}) => {
    const { data, isLoading } = useQuery({
        queryFn: getTestUsers,
        queryKey: ['portal', 'login', 'testusers']
    });

    const users = _.get(data, 'data.Users', []);

    const options =
        users.map((user: any) => ({
            label: `${user.FirstName} ${user.LastName}`,
            value: user.EmailAddress
        })) || [];

    return (
        <FormSelect
            name={name}
            isLoading={isLoading}
            options={options}
            label="Records for testing"
            inline={false}
        />
    );
};

export { DevelopmentAccountLoginSelect };
