import React, { FC } from 'react';

interface IProps {
    startTime: string;
    endTime: string;
}

const TourTimesColumnItem: FC<IProps> = ({ startTime, endTime }) => {
    if (!startTime || !endTime) {
        return null;
    }
    return (
        <>
            {startTime}
            <br />
            to
            <br />
            {endTime}
        </>
    );
};

export { TourTimesColumnItem };
