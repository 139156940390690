import { useQuery } from '@tanstack/react-query';

import * as tourAPI from '../../../../api/tours';

const useGetTourTypes = () => {
    const query = useQuery({
        queryFn: tourAPI.getTourTypes,
        queryKey: ['tour', 'tourtypes']
    });

    const { data: tourTypes = [] } = query;

    const tourTypeMap = tourTypes.reduce((acc: any, tourType: any) => {
        acc[tourType.TourTypeID] = tourType.TourTypeName;
        return acc;
    }, {});

    return {
        ...query,
        data: tourTypes.map(val => ({...val, TourTypeID: val.TourTypeID ? String(val.TourTypeID) : null})),
        tourTypeMap
    };
};

export { useGetTourTypes };
