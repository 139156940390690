import React, {useState, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import * as assetApi from '../../api/assets';
import Swal, {SweetAlertResult} from 'sweetalert2';
import PhotoCaptionModal from '../../components/assets/photo_caption_update_modal';
import PhotoTagModal from '../../components/assets/photo_tag_update_modal';
import DisplayAsset from '../../components/assets/display_asset';
import cx from 'classnames';
import PhotoUploadModal from './photo_upload_modal';
import { 
    NHAssetType,
    IUpdateSequencePayLoad,
    IPortalPropertyPhotoAsset,
    IPortalPropertyTagObj,
    IPortalPropertyPhotoObj,
    IPortalPropertyTagModalData
} from '../../interfaces/asset';
import { ITag } from '../../interfaces/tag';
import { IMessageType } from '../../interfaces/common';
import { updateProfileCompleteness } from '../../utils/profile_completeness';
import { TagKeyNameIcon } from '../tag_keyname_icon';
interface IProps {
    selectedNHID: number | null;
    nhIDs: number[];
    propertyPhotos: IPortalPropertyPhotoAsset[];
    setPropertyPhotos: (photos: IPortalPropertyPhotoAsset[]) => void;
    photoTags: ITag[];
    busyLoadingPhotos: boolean;
    setBusyLoadingPhotos: (busyState: boolean) => void;
    photoUploadMessages: IMessageType[];
    setPhotoUploadMessages: (messages: IMessageType[]) => void;
    handleReloadPropertyPhotos: any;
    showPhotoModal: boolean;
    setShowPhotoModal: (showPhotoModal: boolean) => void;
}

const IndividualPropertyPhotos = ({
    selectedNHID,
    nhIDs,
    propertyPhotos,
    setPropertyPhotos,
    photoTags,
    busyLoadingPhotos,
    setBusyLoadingPhotos,
    photoUploadMessages,
    setPhotoUploadMessages,
    handleReloadPropertyPhotos,
    showPhotoModal,
    setShowPhotoModal
}: IProps) => {

    const [ photoCaptionModal, setPhotoCaptionModal ] = useState<IPortalPropertyPhotoObj | null>(null);
    const [ tagUpdateModal, setTagUpdateModal ] = useState<IPortalPropertyTagModalData | null>(null);
    const [ publishWebsitePhotosCount, setPublishWebsitePhotosCount ] = useState<number>(0);
    const [ publishWebsiteTagsCount, setPublishWebsiteTagsCount ] = useState<number>(0);

    useEffect(() => {
       
        if( (propertyPhotos || []).length > 0 && (propertyPhotos[0].Tag || []).length > 0 ) {
            const totalPublishPhotosCount = propertyPhotos[0].Tag.reduce((photoCount: number, tag:IPortalPropertyTagObj) => tag.IsTagPublishWebsite ? photoCount + tag.Photo.length : photoCount, 0 );
            setPublishWebsitePhotosCount(totalPublishPhotosCount);
            const totalPublishTagsCount = propertyPhotos[0].Tag.reduce((tagCount: number, tag:IPortalPropertyTagObj) => tag.IsTagPublishWebsite ? ++tagCount : tagCount, 0 );
            setPublishWebsiteTagsCount(totalPublishTagsCount);
        }
        
    }, [propertyPhotos]);
   
    const handleOnDragEnd = async (result: DropResult) =>  {
        const { destination, source} = result;
        
        if (!destination) {
            return;
        }
    
        if(
            destination.droppableId === source.droppableId && 
            destination.index === source.index
        ) {
            return;
        }
    
        if( destination.droppableId === source.droppableId ) {
            setBusyLoadingPhotos(true);
            try {
                const nhID = nhIDs?.length === 1 ? nhIDs[0] : selectedNHID;
                const currentPropertyPhotos = propertyPhotos.filter((propertyPhoto:IPortalPropertyPhotoAsset) => propertyPhoto.NHID === nhID);
                const currentPropertyPhotoObject: IPortalPropertyPhotoAsset = currentPropertyPhotos.length > 0 ? currentPropertyPhotos[0] : null;
                const tagMap = new Map<number, IPortalPropertyPhotoObj[]>;
                currentPropertyPhotoObject?.Tag.forEach((tagItem: IPortalPropertyTagObj) => {
                    tagMap.set(tagItem.TagID, tagItem.Photo);
                });
                const photoDragTagID = parseInt(source.droppableId.split('-')[1], 10);
                const photoArrayOfDragTag = tagMap.get(photoDragTagID);
                const [reorderedItem] = photoArrayOfDragTag.splice(result.source.index, 1);
                photoArrayOfDragTag.splice(result.destination.index, 0, reorderedItem);
                tagMap.set(photoDragTagID, photoArrayOfDragTag);
                const tagArray = Array.from(tagMap.values()).flat(1);
                const assetIDs = tagArray.map((item: IPortalPropertyPhotoObj) => {
                    return item.AssetID;
                });
                
                const params: IUpdateSequencePayLoad = {
                    AssetIDs: assetIDs,
                    NHID: nhID
                };
                const isSuccess = await assetApi.updatePropertyPhotosSequence(params);
                if (!isSuccess) {
                    throw new Error('Failed to update order of the photo');
                }  
            }catch (e) {
                Swal.fire({
                    title: 'Failed to update order of the photo',
                    text: 'Unknown error',
                    icon: 'error',
                });
            } finally {
                setPhotoUploadMessages([]);
                setBusyLoadingPhotos(false);
            }
        }
    };

    const handleTagUpdate = async(sourceTag: IPortalPropertyTagModalData, destinationTag: IPortalPropertyTagModalData) => {
        setTagUpdateModal(null);
        if (sourceTag.TagID !== destinationTag.TagID) {
            setBusyLoadingPhotos(true);
            try {
                const isSuccess = await assetApi.updateAssetTag(
                    {
                        NHID: propertyPhotos[0].NHID,
                        AssetID: sourceTag.AssetID, 
                        TagIDs: destinationTag.TagID.toString()
                    }
                );
                if (!isSuccess) {
                    throw new Error('Failed to update tag for the photo');
                }  
                const updatedPropertyPhotos: IPortalPropertyPhotoAsset[] = propertyPhotos.map((obj: IPortalPropertyPhotoAsset) => {
                    if(obj.NHID === selectedNHID) {
                        const photoObj:IPortalPropertyPhotoObj = obj.Photo.find((currentPhoto:IPortalPropertyPhotoObj) => currentPhoto.AssetID === sourceTag.AssetID && currentPhoto.TagID === sourceTag.TagID);
                        photoObj.TagID = destinationTag.TagID;
                        obj.Photo = obj.Photo.map((currentPhoto:IPortalPropertyPhotoObj) => {
                            if(currentPhoto.AssetID === sourceTag.AssetID && currentPhoto.TagID === sourceTag.TagID ) {
                                return photoObj;
                            }
                            return currentPhoto;
                        });
                        //if the destination tag not exists in the propert photos tag array
                        const isDestinationTagExists = obj.Tag.find((currentTag:IPortalPropertyTagObj )=> currentTag.TagID === destinationTag.TagID );
                        if(!isDestinationTagExists) {
                            const tagObj:IPortalPropertyTagObj  = {
                                AssetTagID: 0,
                                TagID: destinationTag.TagID, 
                                TagName: destinationTag.TagName, 
                                TagSequence: destinationTag.TagSequence,
                                IsTagPublishWebsite: destinationTag.IsTagPublishWebsite,
                                IsTagPublishPortal: destinationTag.IsTagPublishPortal,
                                IsTagPublishCMS: destinationTag.IsTagPublishCMS,
                                Photo: [photoObj]
                            };
                            let newTagIndex = obj.Tag.findIndex((tagItem) => destinationTag.TagSequence < tagItem.TagSequence);
                            newTagIndex = newTagIndex !==  -1 ? newTagIndex : destinationTag.TagSequence;
                            obj.Tag = [...obj.Tag.slice(0, newTagIndex), tagObj, ...obj.Tag.slice(newTagIndex)];
                        }
                        obj.Tag = obj.Tag.filter((currentTag:IPortalPropertyTagObj) => {
                            //if the source tag only contain one asset which we are moving to other tag than remove the source tag completely
                            if(currentTag.TagID === sourceTag.TagID) {
                                if(currentTag.Photo.length > 1) {
                                    currentTag.Photo = currentTag.Photo.filter((currentPhoto:IPortalPropertyPhotoObj)=>currentPhoto.AssetID !== sourceTag.AssetID);
                                    return currentTag;
                                } 
                            } else if(currentTag.TagID === destinationTag.TagID && isDestinationTagExists) {
                                currentTag.Photo.push(photoObj);
                                return currentTag;
                            } else {
                                return currentTag;
                            }
                        });
                        return obj;
                    }
                    return obj;
                });
    
                setPropertyPhotos(updatedPropertyPhotos);         
                updateProfileCompleteness();
            } catch (err) {
                Swal.fire({
                    title: 'Failed to update tag for the photo',
                    text: 'Unknown error',
                    icon: 'error'
                });
            } finally {
                setPhotoUploadMessages([]);
                setBusyLoadingPhotos(false);
            }
        }
    };

    const handlePhotoCaptionUpdate = async(assetID: number, tagID:number, photoCaption: string) => {
        setPhotoCaptionModal(null);
        setBusyLoadingPhotos(true);
        try {
            const returnAssetID = await assetApi.updatePhotoTitle({
                NHID: propertyPhotos[0].NHID,
                AssetID: assetID,
                Title: photoCaption ? photoCaption : ''
            });
            
            if(!returnAssetID) {
                throw new Error('Failed to update caption for the photo');
            }
            
            const updatedPropertyPhotos = propertyPhotos.map((obj: IPortalPropertyPhotoAsset) => {
                if(obj.NHID === selectedNHID) {
                    obj.Photo = obj.Photo.map((currentPhoto:IPortalPropertyPhotoObj) => {
                        if(assetID === currentPhoto.AssetID) {
                            currentPhoto.Title = photoCaption;
                        }
                        return currentPhoto;
                    });
                    obj.Tag = obj.Tag.filter((currentTag:IPortalPropertyTagObj) => {
                        if(tagID !== currentTag.TagID) {
                            return currentTag;
                        } 
                        currentTag.Photo = currentTag.Photo.map((currentTagPhoto: IPortalPropertyPhotoObj) => {
                            if(assetID === currentTagPhoto.AssetID) {
                                currentTagPhoto.Title = photoCaption;
                            }
                            return currentTagPhoto;
                        });
                        return currentTag;
                    });
                    return obj;
                }
                return obj;
            });

            setPropertyPhotos(updatedPropertyPhotos);
            updateProfileCompleteness();
            
        } catch (err) {
            Swal.fire({
                title: 'Failed to update caption for the photo',
                text: 'Unknown error',
                icon: 'error'
            });
        } finally {
            setPhotoUploadMessages([]);
            setBusyLoadingPhotos(false);
        }
    };

    const handleDeletePhoto = (assetID: number, tagID:number) => {
        Swal.fire({
            title: 'Are you sure you want to delete this photo ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0abb87',
            cancelButtonColor: '#fd397a',
            confirmButtonText: 'Yes'
        }).then(async (result: SweetAlertResult) => {
            if (result.value) {
                setBusyLoadingPhotos(true);
                try {
                    const deletedPhotoID:number = await assetApi.deletePropertyPhoto(propertyPhotos[0].NHID, assetID);

                    if (!deletedPhotoID) {
                        throw new Error('Failed to delete photo');
                    }

                    const updatedPropertyPhotos = propertyPhotos.filter((obj: IPortalPropertyPhotoAsset) => {
                        if(obj.NHID === selectedNHID) {
                            obj.Photo = obj.Photo.filter((currentPhoto:IPortalPropertyPhotoObj) => assetID !== currentPhoto.AssetID);
                            obj.Tag = obj.Tag.filter((currentTag:IPortalPropertyTagObj) => {
                                if(tagID !== currentTag.TagID) {
                                    return currentTag;
                                } 
                                currentTag.Photo = currentTag.Photo.filter((currentTagPhoto: IPortalPropertyPhotoObj) => assetID !== currentTagPhoto.AssetID);
                                return currentTag;
                            });
                            return obj;
                        }
                    });

                    setPropertyPhotos(updatedPropertyPhotos);
                    updateProfileCompleteness();
                } catch (err) {
                    Swal.fire({
                        title: 'Failed to delete photo',
                        text: 'Unknown error',
                        icon: 'error'
                    });
                } finally {
                    setPhotoUploadMessages([]);
                    setBusyLoadingPhotos(false);
                }
            }
        });
    };

    const handleOpenModal = async(
        nhID: number, 
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        setShowPhotoModal(true);
    };

    const renderIndividualPropertyPhoto = () => {
        const property = propertyPhotos[0];
        return (
            <>
                 {
                    property ?
                    <> 
                        <div className="mt-3 d-flex justify-content-between align-items-center">
                            <h4 className="m-0">
                                {property.Property.Name} has {publishWebsitePhotosCount} photos uploaded using {publishWebsiteTagsCount} tags
                            </h4>
                            <button 
                                key='show-property-photos-upload-form-${property.Property.NHID}'
                                className="btn btn-primary text-nowrap"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenModal(property.Property.NHID, e);
                                }}
                            >
                                    Add Photos
                            </button>
                        </div>
                        <div className="mt-1 align-items-center">
                            {
                                photoUploadMessages.map((photoUploadMessage: IMessageType) => {
                                    return (
                                        <span key={`message-${photoUploadMessage.type}`} className={cx('msg', photoUploadMessage.type === 'info' ? 'msg-info' : 'msg-error mt-1')}>
                                            {photoUploadMessage.message}
                                        </span>
                                    );
                                })
                            }
                        </div>
                        {
                            property.Tag.map((tag:IPortalPropertyTagObj) => {
                                return (
                                    tag.Photo.length > 0 ?
                                        <div key={tag.TagName} className="card" data-card="Media Scroller">
                                            <div className="card-header">
                                                <h4 className={cx('badge', { red: !tag.IsTagPublishWebsite })}>
                                                    <TagKeyNameIcon tagKeyName={tag.TagKeyName}/>{tag.TagName} <span>{tag.Photo.length}</span>
                                                </h4>
                                                {
                                                    !tag.IsTagPublishWebsite ?
                                                        <span className="msg msg-info">
                                                            These photos will not show on the website
                                                        </span>
                                                    :
                                                        ''
                                                }
                                                <small>
                                                    Drag and drop photos to re-order them within each tag
                                                </small>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-info">
                                                    <DragDropContext onDragEnd={handleOnDragEnd}>    
                                                        <Droppable droppableId={`tag-${tag.TagID}`} type="square" direction="horizontal">
                                                            {(provided) => (                                           
                                                                <div id={`sortable-${tag.TagID}`} className="sortable" {...provided.droppableProps} ref={provided.innerRef}>    
                                                                {
                                                                    tag.Photo.map((photo:IPortalPropertyPhotoObj, index:number) => {
                                                                    return (
                                                                        <Draggable key={photo.AssetID} draggableId={`drId-${photo.AssetID}`} index={index}>
                                                                            {(provided) => (
                                                                        
                                                                        <div className="media-edit media-photo" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                                            <DisplayAsset
                                                                                TransformedAssetUrl={photo.TransformedAssetUrl}
                                                                                FileName={photo.FileName}
                                                                                AssetType={NHAssetType.Photos} 
                                                                            />
                                                                            <div>
                                                                                <span>{photo.Title}</span>
                                                                                <ul>
                                                                                    <li key={`change-tag-${photo.AssetID}`}>
                                                                                    {
                                                                                        tag.IsTagPublishWebsite ?
                                                                                        <a  href=""
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                setTagUpdateModal({
                                                                                                    TagID: tag.TagID,
                                                                                                    TagName: tag.TagName,
                                                                                                    TagSequence: tag.TagSequence,
                                                                                                    IsTagPublishWebsite: tag.IsTagPublishWebsite,
                                                                                                    IsTagPublishPortal: tag.IsTagPublishWebsite,
                                                                                                    IsTagPublishCMS:tag.IsTagPublishCMS,
                                                                                                    AssetID: photo.AssetID
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            Change Tag
                                                                                        </a>
                                                                                        :
                                                                                            ' '
                                                                                    }
                                                                                    </li>
                                                                                    <li key={`edit-caption-${photo.AssetID}`}>
                                                                                        <a href=""
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                setPhotoCaptionModal(photo);
                                                                                            }}
                                                                                        >
                                                                                            Edit Caption
                                                                                        </a>
                                                                                    </li>
                                                                                    <li key={`delete-${photo.AssetID}`}>
                                                                                        <a href=""
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                handleDeletePhoto(photo.AssetID, tag.TagID);
                                                                                            }}
                                                                                        >
                                                                                            Delete
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        )}
                                                                        </Draggable>
                                                                    );
                                                                    })
                                                                }
                                                                {provided.placeholder}    
                                                            </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        ''
                                );
                            })
                        }
                        {
                            photoCaptionModal ?
                            <PhotoCaptionModal
                                photoDetail={photoCaptionModal}
                                handleClose={() => setPhotoCaptionModal(null)}
                                handlePhotoCaptionUpdate={handlePhotoCaptionUpdate}
                            />
                            : null
                        }
                        {
                            tagUpdateModal ?
                            <PhotoTagModal
                                photoTags={photoTags}
                                sourceTag={tagUpdateModal}
                                handleClose={() => setTagUpdateModal(null)}
                                handleTagUpdate={handleTagUpdate}
                            />
                            : null
                        }
                        {
                            showPhotoModal ?
                                <PhotoUploadModal
                                    handleClose={() => setShowPhotoModal(false)}
                                    handleReloadPropertyPhotos={handleReloadPropertyPhotos}
                                    selNHID={selectedNHID}
                                    propertyPhotos={propertyPhotos}
                                    setPropertyPhotos={setPropertyPhotos}
                                    busyLoadingAsset={busyLoadingPhotos}
                                    setBusyLoadingAsset={setBusyLoadingPhotos}
                                    photoTags={photoTags}
                                    setPhotoUploadMessages={setPhotoUploadMessages}
                                />
                            : 
                                null
                        }

                    </>
                    : ''
                }
            </>
        );
    };

    return (
        <>
            <LoadingSpinner show={busyLoadingPhotos} />
            { renderIndividualPropertyPhoto() }
        </>
    );
};

export default IndividualPropertyPhotos;