import { useSessionState } from './use_session_state';
import { useSessionPropertiesMap } from './useSessionPropertiesMap';

const usePremiumStatus = (nhID: number) => {
    const { Org } = useSessionState();
    const propertyDictionary = useSessionPropertiesMap();
    const isPremium = Org?.IsPremium || propertyDictionary?.[nhID]?.IsPremium;
    return { isPremium };
};

export { usePremiumStatus };
