import longTermCareCostTabSchema from './tabs/sections/long_term_care_cost_tab/long_term_care_cost_tab.validation';
import shortTermCareCostTabSchema from './tabs/sections/short_term_care_cost_tab/short_term_care_cost_tab.validation';
import respiteCareCostTabSchema from './tabs/sections/respite_care_cost_tab/respite_care_cost_tab.validation';
import paymentTypesTabSchema from './tabs/sections/payment_types_tab/payment_types_tab.validation';
import roomTypeTabSchema from './tabs/sections/room_type_tab/room_type_tab.validation';
import pediatricResidentsTabSchema from './tabs/sections/pediatric_residents_tab/pediatric_residents_tab.validation';
import bedAllocationTabSchema from './tabs/sections/bed_allocation_tab/bed_allocation_tab.validation';
import careOfferedTabSchema from './tabs/sections/care_offered_tab/care_offered_tab.validation';
import insuranceProvidersAcceptedTabSchema from './tabs/sections/insurace_providers_accepted_tab/insurance_providers_accepted_tab.validation';


export default longTermCareCostTabSchema
    .concat(shortTermCareCostTabSchema)
    .concat(respiteCareCostTabSchema)
    .concat(paymentTypesTabSchema)
    .concat(roomTypeTabSchema)
    .concat(pediatricResidentsTabSchema)
    .concat(bedAllocationTabSchema)
    .concat(careOfferedTabSchema)
    .concat(insuranceProvidersAcceptedTabSchema);

