import React from 'react';
import { NHAssetType} from '../../interfaces/asset';

interface IProps {
    TransformedAssetUrl: string;
    FileName:string;
    AssetType: number;
}

const DisplayAsset = ({
    TransformedAssetUrl,
    FileName,
    AssetType
}: IProps) => {
   
    const renderDisplayAsset = () => {
        return (
            <>
                {
                    AssetType === NHAssetType.Videos ?
                        TransformedAssetUrl.includes('staging') ?
                            <video width="250" height="150" controls src={TransformedAssetUrl}>
                                Your browser does not support the video tag.
                            </video>
                        :
                            <video width="250" height="150" controls src={`https://www.nursinghomes.com/videos/${FileName}.mp4`}>
                                Your browser does not support the video tag.
                            </video>
                           
                    :

                    AssetType === NHAssetType.Photos ?
                        TransformedAssetUrl.includes('staging') ?
                            <img src={TransformedAssetUrl} />
                        :
                            <img src={`https://www.nursinghomes.com/photos/gallery-all/${FileName}.webp`} />
                    :

                    AssetType === NHAssetType.Logos ?
                        TransformedAssetUrl.includes('staging') ?
                            <img src={TransformedAssetUrl} />
                        :
                            <img src={`https://www.nursinghomes.com/logos/large/${FileName}.webp`} />
                    :
                       
                    AssetType === NHAssetType.CardLogos ?
                        TransformedAssetUrl.includes('staging') ?
                            <img src={TransformedAssetUrl} />
                        :
                            <img src={`https://www.nursinghomes.com/logos/logo-review-card-online/${FileName}.webp`} />
                    :
                        ''
                }
            </>
        );
    };

    return renderDisplayAsset();
};

export default DisplayAsset;