import React, { FC, forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { ConnectForm } from '../form/form';

type CheckBoxOption = {
    label: string;
    value: number;
};

interface GroupProps {
    groupLabel?: string;
    name: string;
    options?: CheckBoxOption[];
    inline?: boolean;
    inlineOptions?: boolean;
    labelStyle?: 'normal' | 'bold';
    inlineLabelWidth?: 'small' | 'large' | 'auto';
}

interface CheckBoxProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    value: number;
    label: string;
    hasError?: boolean;
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const inlineLabelWidths = {
    small: 150,
    large: 250,
    auto: 0
};

const FormCheckBoxGroup: FC<GroupProps> = (props) => {
    const {
        name,
        options,
        groupLabel,
        inline,
        labelStyle = 'normal',
        inlineOptions = inline,
        inlineLabelWidth = 'small'
    } = props;

    return (
        <ConnectForm>
            {({
                formState,
                register,
                setValue,
                watch,
                isRequired
            }: UseFormReturn & FieldRequired) => {
                const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>
                ) => {
                    let currentValue = watch(name);
                    const changedValue = Number(e.target.value);
                    if (currentValue.includes(changedValue)) {
                        currentValue = currentValue.filter(function (
                            item: number
                        ) {
                            return item !== changedValue;
                        });
                    } else {
                        currentValue.push(changedValue || null);
                    }

                    setValue(name, currentValue, { shouldDirty: true });
                };

                const fieldError: { message?: string } =
                    formState?.errors?.[name];
                const errorMessage: ReactElement = (
                    <small className="text-danger">{fieldError?.message}</small>
                );

                const groupWrapperClassName = cx('d-flex flex-wrap', {
                    'gap-2 flex-row': inlineOptions,
                    'gap-2 flex-column': !inlineOptions
                });

                const labelClassName = cx('mb-1 fs-md-5', {
                    'fw-bold': labelStyle === 'bold'
                });

                const wrapperClassName = cx('mt-3 d-flex', {
                    'flex-sm-row flex-column': inline,
                    'flex-sm-column': !inline
                });

                return (
                    <>
                        <div className={wrapperClassName}>
                            {groupLabel && (
                                <div
                                    className={labelClassName}
                                    style={{
                                        minWidth: inline
                                            ? inlineLabelWidth === 'auto'
                                                ? ''
                                                : `${inlineLabelWidths[inlineLabelWidth]}px`
                                            : '100%',
                                        maxWidth: '100%'
                                    }}
                                >
                                    {groupLabel}
                                    {isRequired(name) && '*'}
                                </div>
                            )}
                            <div>
                                <div className={groupWrapperClassName}>
                                    {options.map((option) => {
                                        return (
                                            <CheckboxWithRefs
                                                value={option.value}
                                                checked={watch(name).includes(
                                                    option.value
                                                )}
                                                label={option.label}
                                                key={`${name}_${option.value}`}
                                                className="m-0"
                                                hasError={!!fieldError?.message}
                                                {...register(name)}
                                                onChange={handleChange}
                                            />
                                        );
                                    })}
                                </div>
                                {fieldError && errorMessage}
                            </div>
                        </div>
                    </>
                );
            }}
        </ConnectForm>
    );
};

// ref declared to silence console warnings, but left unused. TODO - find better solution.
const CheckBox: ForwardRefRenderFunction<any, CheckBoxProps> = (props, ref) => {
    const { id, label: labelText, className, value, hasError, ...rest } = props;
    const inputId: string = id || rest.name + labelText + '-' + value;
    const inputClassName: string = cx('form-check-input', className, { 'border border-danger': hasError});
    return (
        <label
            htmlFor={inputId}
            className={cx('mb-3 me-5 mb-lg-0 d-flex align-items-center gap-3')}
        >
            {labelText}
            <input
                type="checkbox"
                id={inputId}
                className={inputClassName}
                value={value}
                {...rest}
            />
            <span />
        </label>
    );
};

const CheckboxWithRefs = forwardRef(CheckBox);

export { FormCheckBoxGroup };
