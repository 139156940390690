import { 
    loginByEmail, 
    loginByToken, 
    loginBySessionToken,
    fetchLoginTokens, 
    checkAccountByEmail
} from '../api/login';

import { updateSessionData } from '../utils/session'; 
import { updateProfileCompleteness } from '../utils/profile_completeness'; 

import _ from 'lodash';

export const getJwtToken = () => {
    return sessionStorage.getItem('jwt');
};

export const deleteJwtToken = () => {
    return sessionStorage.removeItem('jwt');
};

export  const deleteRefreshJwtToken = () => {
    return sessionStorage.removeItem('refreshToken');
};

export const setJwtToken = (token: string) => {
    sessionStorage.setItem('jwt', token);
};

export const getRefreshToken = () => {
    return sessionStorage.getItem('refreshToken');
};

export const setRefreshToken = (token: string) => {
    sessionStorage.setItem('refreshToken', token);
};

export const fetchNewTokens = async (): Promise<[string, string]> => {

    const refreshToken = getRefreshToken();
    const resp = await fetchLoginTokens(refreshToken);

    const { AccessToken, RefreshToken } = resp || {};

    return [AccessToken, RefreshToken];
};

export const login = async (jwt: string, refreshJwt: string) => {

    setJwtToken(jwt);
    setRefreshToken(refreshJwt);
    updateSessionData();
    updateProfileCompleteness();
};

export const emailLogin = async (email: string, password: string) => {

    const resp = await loginByEmail(email, password);
    const { Jwt, RefreshJwt } = _.get(resp, 'data');
    login(Jwt, RefreshJwt);
};

export const checkAccount = async (email: string): Promise<{ isActionRequired: boolean }> => {
    const resp = await checkAccountByEmail(email);
    const result: { isActionRequired: boolean } = _.get(resp, 'data');

    return result;
};

export const tokenLogin = async (token: string) => {

    const resp = await loginByToken(token);
    const { Jwt, RefreshJwt } = _.get(resp, 'data');
    login(Jwt, RefreshJwt);
};

export const sessionTokenLogin = async (token: string) => {

    const resp = await loginBySessionToken(token);
    const { Jwt, RefreshJwt } = _.get(resp, 'data');
    login(Jwt, RefreshJwt);
};


export const logout = async () => {

    deleteJwtToken();                                
    deleteRefreshJwtToken();       

    // TODO One of these!
    window.sessionStorage.clear();
    sessionStorage.clear();
};

export const clearSession = () => {
    sessionStorage.clear();
};
