import React, { useState, useEffect, FC } from 'react';
import {
    IPropertyContactDetails,
    ContactDetailsHTMLType,
    ContactDetailsHeaders,
    ContactDetailsPlaceholders
} from '../../../interfaces/property';
import { updatePropertyContactDetails } from '../../../api/property';
import { showToast, ToastType } from '../../../utils/toast';
import {
    getObjectDifferenceWhenSameParams,
    isObjectEmpty
} from '../../../utils/common';
import { updateContactObjectSchema } from './schema/contact_details_schema';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import { Modal } from '../../../components/common/modal';
import { Button } from '../../../components/common/button';
import { websiteJSXIcon } from './website_link_icon';

interface IProps {
    data: IPropertyContactDetails;
    updateModalActive: boolean;
    handleCloseModal?: any;
    handleUpdateData?: any;
}

const UpdatePropertyContactDetailsForm: FC<IProps> = ({
    data,
    updateModalActive,
    handleCloseModal,
    handleUpdateData
}: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [contactData, setContactData] =
        useState<IPropertyContactDetails>(null);
    const [newContactDetails, setNewContactDetails] =
        useState<IPropertyContactDetails>(null);
    const [changedTick, setChangedTick] = useState<number>(0);
    const [rowValidationError, SetRowValidationError] = useState<any[]>([]);
    const [tick, setchangeTick] = useState<number>(0);

    useEffect(() => {
        setContactData(data);
        setNewContactDetails(data);
        setchangeTick(tick + 1);
    }, [data]);

    const renderLoading = () => <LoadingSpinner show={true} />;

    const handleUpdateField = (key: string, value: string) => {
        const nhObject = JSON.parse(JSON.stringify(newContactDetails));
        const validValue = value?.trim() !== '' ? value : null;
        (nhObject as any)[key] = validValue;
        if (rowValidationError.length) {
            const errorObjectIndex = rowValidationError.reduce(
                (r, v, i) => r.concat(v.Key === key ? i : []),
                []
            );
            if (errorObjectIndex.length) {
                const cleanedErrorArray = rowValidationError.filter(function (
                    value,
                    index
                ) {
                    return errorObjectIndex.indexOf(index) === -1;
                });
                SetRowValidationError(cleanedErrorArray);
            }
        }
        setNewContactDetails(nhObject);
        setChangedTick(changedTick + 1);
    };

    const handlePropertyUpdate = async () => {
        const differences = getObjectDifferenceWhenSameParams(
            contactData,
            newContactDetails
        );

        if (!isObjectEmpty(differences)) {
            if (
                (!newContactDetails.GeneralPhoneNumber &&
                    newContactDetails.CarePhoneNumber) ||
                (!newContactDetails.GeneralPhoneNumber &&
                    newContactDetails.JobPhoneNumber)
            ) {
                const errorPayload = [
                    {
                        Key: 'GeneralPhoneNumber',
                        Message:
                            'The Default Phone Number cannot be blank whilst others are populated'
                    }
                ];
                const newErrorArray = rowValidationError.concat(errorPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }

            const jobEmail =
                newContactDetails.JobEmail ||
                newContactDetails.JobEmail2 ||
                newContactDetails.JobEmail3 ||
                null;
            const careEmail =
                newContactDetails.CareEmail ||
                newContactDetails.CareEmail2 ||
                newContactDetails.CareEmail3 ||
                null;
            const generalEmail =
                newContactDetails.GeneralEmail ||
                newContactDetails.GeneralEmail2 ||
                newContactDetails.GeneralEmail3 ||
                null;
            if (
                (!generalEmail && jobEmail) ||
                (!generalEmail && careEmail)
            ) {
                const errorPayload = [
                    {
                        Key: 'GeneralEmail',
                        Message:
                            'The default email cannot be blank whilst other non-default emails are populated'
                    }
                ];
                const newErrorArray = rowValidationError.concat(errorPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }
            try {
                await updateContactObjectSchema.validate(differences, {
                    abortEarly: false
                });
            } catch (err: any) {
                const errorCopy: any = err.inner;
                const errorPayload = errorCopy.map((e: any) => {
                    const errorInnerCopy: any = {};
                    errorInnerCopy.Key = e.path;
                    errorInnerCopy.Message = e.message;
                    return errorInnerCopy;
                });
                const newErrorArray = rowValidationError.concat(errorPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }
            setBusy(true);
            try {
                await updatePropertyContactDetails(
                    contactData.NHID,
                    differences
                );
                showToast(
                    'Facility contact details saved successfully. Please allow up to 60 minutes for the changes to show on the website.',
                    ToastType.Success
                );
                SetRowValidationError([]);
            } catch (e) {
                showToast(
                    'An error occurred saving the updated contact details.',
                    ToastType.Error
                );
                console.log(e);
            } finally {
                setBusy(false);
                updateModalActive ? handleCloseModal() : handleUpdateData();
            }
        }
    };

    const renderRowErrorMessage = (key: string) => {
        const errorObjectArray = rowValidationError.filter(function (e) {
            return e.Key === key;
        });

        if (errorObjectArray.length) {
            return (
                <>
                    <small className="text-danger">
                        {`${errorObjectArray[0].Message} `}
                    </small>
                </>
            );
        }
    };

    // const checkRowError = () => {
    //     if (rowValidationError.length) {
    //         return true;
    //     }
    //     return false;
    // };

    const checkDefifferences = () => {
        if (contactData && newContactDetails) {
            const differences = getObjectDifferenceWhenSameParams(
                contactData,
                newContactDetails
            );

            if (!isObjectEmpty(differences)) {
                return false;
            }
            return true;
        }
        return false;
    };

    const renderUpdateButton = () => {
        return !updateModalActive ? (
            <button
                disabled={busy || checkDefifferences()}
                className="btn btn-primary btn-fixed-bottom shadow"
                type="button"
                onClick={() => handlePropertyUpdate()}
            >
                Update
            </button>
        ) : (
            <div>
                <Button
                    type="button"
                    className="btn btn-md btn-primary text-nowrap"
                    disabled={busy || checkDefifferences()}
                    onClick={() => handlePropertyUpdate()}
                >
                    Update
                </Button>
            </div>
        );
    };

    const renderFormField = (
        htmlId: ContactDetailsHTMLType,
        key: string,
        placeHolder: string,
        label?: string
    ) => {
        let formInputFieldClass = '';
        const id = `contact-details-${key}`;

        const errorObjectArray = rowValidationError.filter(function (e) {
            return e.Key === key;
        });

        let errorFormat = '';
        let labelHeader = (
            <>{label ? <label className="text-nowrap">{label}</label> : <br/>}</>
        );
        if (errorObjectArray.length) {
            errorFormat = 'border border-danger';
        }
        switch (htmlId) {
            case ContactDetailsHTMLType.Website:
                formInputFieldClass = `form-control input-place-holder w-90 ${errorFormat}`;
                labelHeader = (
                    <>
                        {label ? (
                            <label className="text-nowrap">{label}</label>
                        ) : null}
                    </>
                );
                break;
            case ContactDetailsHTMLType.PhoneNumber:
                formInputFieldClass = `form-control input-place-holder w-100 ${errorFormat}`;
                break;
            default:
                formInputFieldClass = `form-control input-place-holder w-100 ${errorFormat}`;
                break;
        }

        const value = newContactDetails
            ? (newContactDetails as any)[key]
            : null;
        return (
            <>
                <div>
                    {labelHeader}
                    <input
                        type="text"
                        id={id}
                        className={formInputFieldClass}
                        placeholder={placeHolder}
                        value={value}
                        onChange={e => handleUpdateField(key, e.target.value)}
                    />
                    {htmlId === ContactDetailsHTMLType.Website ? 
                    <>
                    &nbsp;
                    {contactData? websiteJSXIcon(contactData.GeneralWebsite): null}
                    </>: null}
                    {errorObjectArray.length ? (
                        <>{renderRowErrorMessage(key)} </>
                    ) : null}
                </div>
            </>
        );
    };

    const renderCompleteUpdateForm = () => {
        return (
            <>
                {!updateModalActive && contactData && contactData.Name ? (
                    <div className="widget" data-widget-height="auto">
                        <div className="widget-body">
                            <div
                                className="card"
                                data-card="Media Scroller Filter"
                            >
                                <div className="card-header">
                                    <h4>
                                        {contactData.Name}
                                        <a
                                            className="small text-link text-nowrap"
                                            href="#"
                                            target="_blank"
                                        >
                                            view on website
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div
                    className="widget"
                    data-widget-modal={`${
                        updateModalActive ? 'true' : 'false'
                    }`}
                >
                    <div className="widget-body">
                        <div className="card" data-card="Form" data-columns="3">
                            <div className="card-header card-header-bullets">
                                <h4>Phone</h4>
                                <ul>
                                    <li>
                                        Phone calls can be channeled to
                                        different numbers, depending on the
                                        enquiry type
                                    </li>
                                    <li>
                                        Only enter 'Care' or 'Job' numbers, if
                                        using different numbers from the
                                        'Default Number'
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="card-info">
                                    <div>
                                        {renderFormField(
                                            ContactDetailsHTMLType.PhoneNumber,
                                            'GeneralPhoneNumber',
                                            ContactDetailsPlaceholders.PhoneNumber,
                                            ContactDetailsHeaders.GeneralPhoneNumber
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.PhoneNumber,
                                            'CarePhoneNumber',
                                            ContactDetailsPlaceholders.PhoneNumber,
                                            ContactDetailsHeaders.Care
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.PhoneNumber,
                                            'JobPhoneNumber',
                                            ContactDetailsPlaceholders.PhoneNumber,
                                            ContactDetailsHeaders.Job
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" data-card="Form" data-columns="3">
                            <div className="card-header card-header-bullets">
                                <h4>Email</h4>
                                <ul>
                                    <li>
                                        Email enquiries can be channeled to
                                        different email addresses, depending on
                                        the enquiry type
                                    </li>
                                    <li>
                                        Only enter 'Care Inquiry' or 'Job Inquiry' emails, 
                                        if using a different email address from the 'Default Emails'
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="card-info">
                                    <div>
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'GeneralEmail',
                                            ContactDetailsPlaceholders.GeneralEmail1,
                                            ContactDetailsHeaders.GeneralEmails
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'GeneralEmail2',
                                            ContactDetailsPlaceholders.GeneralEmail2
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'GeneralEmail3',
                                            ContactDetailsPlaceholders.GeneralEmail3
                                        )}

                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'CareEmail',
                                            ContactDetailsPlaceholders.CareEmail1,
                                            ContactDetailsHeaders.CareExtended
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'CareEmail2',
                                            ContactDetailsPlaceholders.CareEmail2
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'CareEmail3',
                                            ContactDetailsPlaceholders.CareEmail3
                                        )}

                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'JobEmail',
                                            ContactDetailsPlaceholders.JobEmail1,
                                            ContactDetailsHeaders.Job
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'JobEmail2',
                                            ContactDetailsPlaceholders.JobEmail2
                                        )}
                                        {renderFormField(
                                            ContactDetailsHTMLType.Email,
                                            'JobEmail3',
                                            ContactDetailsPlaceholders.JobEmail3
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" data-card="Form" data-columns="1">
                            <div className="card-header">
                                <h4>Website</h4>
                            </div>
                            <div className="card-body">
                                <div className="card-info">
                                    <div>
                                        {renderFormField(
                                            ContactDetailsHTMLType.Website,
                                            'GeneralWebsite',
                                            ContactDetailsPlaceholders.Website
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!updateModalActive ? renderUpdateButton() : null}
                </div>
                {busy ? renderLoading() : null}
            </>
        );
    };

    const modalTitle = `Update ${contactData ? contactData.Name : null}`;

    return (
        <>
            {!updateModalActive ? (
                renderCompleteUpdateForm()
            ) : (
                <>
                    <Modal
                        isOpen={true}
                        onClose={handleCloseModal}
                        title={modalTitle}
                        showFooter={true}
                        size="xl"
                        footerActions={renderUpdateButton()}
                    >
                        {renderCompleteUpdateForm()}
                    </Modal>
                </>
            )}
        </>
    );
};

export { UpdatePropertyContactDetailsForm };
