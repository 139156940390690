import React, { useEffect, useState } from 'react';

import { mapProfileDatatoDisplay } from '../../utils/profile_completeness';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ISessionState } from '../../interfaces/session';
import { ISessionProperty } from '../../interfaces/session';

import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    ProfileScoreType
} from '../../interfaces/property';

interface IProps {
    NHID: number;
    profileScoreType: ProfileScoreType;
}

const ProfileCompletenessSingle = ({ NHID, profileScoreType }: IProps) => {

    const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);
    const [ websiteURL, setwebsiteURL ] = useState<string>(null);
    const [ sessionProperty, setSessionProperty ] = useState<ISessionProperty>(null);

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    useEffect(() => {        

        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, NHID);

        const scoreItem = scoreArray.find((item: IScoreDisplayItem) => 
            item.KeyName === profileScoreType);

        setScoreItem(scoreItem);

        const sessionProperty = (sessionState?.Properties || []).find((item: ISessionProperty) => item.NHID === NHID);
        if (sessionProperty) {

            const { WebsiteURL, StateSlug, CitySlug, Slug } = sessionProperty;
            setwebsiteURL(`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`);
            setSessionProperty(sessionProperty);
        } else {
            setwebsiteURL(null);
            setSessionProperty(null);
        }

    }, [NHID, profileCompletenessState, profileScoreType]);

    const profileComplete = 
        scoreItem?.FacilityCount === 1 &&
        scoreItem?.AchievedScoreCount === 1;

    const fieldDescription = scoreItem?.Description || '';
    const label = scoreItem?.Label || '';

    return (
        <div className="card" data-card="Media Scroller Filter">
            {
                sessionProperty ?
                <div className="card-header">
                    <h4>
                        {sessionProperty.Name}
                        <a
                            className="small text-link text-nowrap"
                            href={websiteURL}
                            target="_blank"
                        >
                            view on website
                        </a>
                    </h4> 
                </div>: null
            }
            { label &&
                <div className="card-body">
                    <div>
                        <FontAwesomeIcon icon={profileComplete ? faCheck : faXmark} className={`fe-2 ${profileComplete ? 'text-success' :  'text-danger'} fs-2`}/> 
                        <strong>Profile {profileComplete ? '' : 'NOT'} Complete for {label}</strong><small>({fieldDescription})</small>
                    </div>
                </div>
            }
        </div>
    );
};

export default ProfileCompletenessSingle;
