import React from 'react';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import DisplayAsset from '../../components/assets/display_asset';
import { 
    NHAssetType,
    IPortalPropertyPhotoAsset,
    IPortalPropertyPhotoObj,
    IPortalPropertyTagObj
} from '../../interfaces/asset';
import cx from 'classnames';
import ProfileCompletenessText from '../../components/profile_completeness/profile_completeness_text';
import { ProfileScoreType } from '../../interfaces/property';
import { TagKeyNameIcon } from '../tag_keyname_icon';

interface tagFilter {
    NHID: number;
    TagID: number
}

interface IProps {
    handleSelectedNHID: (NHID: number | null) => void;
    propertyPhotos: IPortalPropertyPhotoAsset[];
    handleMultiPropertiesTagFilter?:(multiPropertiesTagFilter: tagFilter[]) => void;
    multiPropertiesTagFilterValue: tagFilter[];
    busyLoadingPhotos: boolean;
    setShowPhotoModal: (showPhotoModal: boolean) => void;
}

const MultiOrgPropertyPhotos = ({
    handleSelectedNHID,
    propertyPhotos,
    handleMultiPropertiesTagFilter,
    multiPropertiesTagFilterValue,
    busyLoadingPhotos,
    setShowPhotoModal
}: IProps) => {
    
    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const handleTagFilter = (selNHID: number, tagID: number) => {
        let multiPropertiesTagFilter: tagFilter[] = [];
        if(tagID && !multiPropertiesTagFilterValue?.find(el => el.NHID === selNHID)) {
            multiPropertiesTagFilterValue.forEach(e => multiPropertiesTagFilter.push(e));
            multiPropertiesTagFilter.push({
                NHID: selNHID,
                TagID: tagID
            });
        } else {
            multiPropertiesTagFilter = multiPropertiesTagFilterValue.filter( (propertyTagFilter:tagFilter) => {
                if( propertyTagFilter.NHID === selNHID && tagID) {
                    propertyTagFilter.NHID = selNHID;
                    propertyTagFilter.TagID = tagID;
                    return {
                        propertyTagFilter
                    };
                } else if( propertyTagFilter.NHID === selNHID && !tagID) {
                    return null;
                } else {
                    return propertyTagFilter;
                }
            });
        }
        handleMultiPropertiesTagFilter(multiPropertiesTagFilter);
    };

    const renderMultiPropertiesPhotos = () => {
        return (
            <>
            <div className="widget">
                <div className="widget-body">
                {
                    propertyPhotos?.map((property) => {
                        const { WebsiteURL, StateSlug, CitySlug, Slug } = property.Property;

                        return (
                            <div key={property.Property.Name} className="card mb-5" data-card="Media Scroller">
                                <div className="card-header">
                                    <span>
                                        <h4>
                                            {property.Property.Name}
                                                <small className="ms-2">{property.Property.Address}</small>&nbsp;
                                                <a
                                                    className="ms-2 small text-link text-nowrap"
                                                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                                                    target="_blank" 
                                                >
                                                    view on website
                                                </a>    
                                        </h4>
                                        <ProfileCompletenessText
                                            NHID={property.Property.NHID}
                                            profileScoreType={ProfileScoreType.Photo}
                                        />
                                    </span>
                                    <div className="card-toolbar">
                                        <button 
                                            key='show-property-photos-upload-form-${property.Property.NHID}'
                                            className="btn btn-primary text-nowrap"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleSelectedNHID(property.Property.NHID);
                                                setShowPhotoModal(true);
                                                window.scrollTo({ top: 0, behavior: 'auto' });
                                            }}
                                        >
                                            Add Photos
                                        </button>
                                        { property.Photo.length ?
                                            <button 
                                                key='show-property-photos${property.Property.NHID}'
                                                className="btn btn-primary text-nowrap"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleSelectedNHID(property.Property.NHID);
                                                    window.scrollTo({ top: 0, behavior: 'auto' });
                                                }}
                                            >
                                                Update Photos
                                            </button>
                                        :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                    { property.Photo.length ?
                                        <>
                                        <div className="card-info-box">
                                            <ul>
                                                <li
                                                    key={`show-property-photos-${property.Property.NHID}-tag-all`}
                                                    className={
                                                        cx({selected: multiPropertiesTagFilterValue.length === 0 ||!multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID)})
                                                    }                                                       
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleTagFilter(property.Property.NHID, null);
                                                    }}
                                                >
                                                    All Photos <span>{property.Photo.length}</span>
                                                </li>
                                                {
                                                    property.Tag.map((tag:IPortalPropertyTagObj) => {
                                                        return (
                                                            <li 
                                                                key={`show-property-photos-${property.Property.NHID}-tag-${tag.TagID}`}
                                                                className={cx({selected: multiPropertiesTagFilterValue.length > 0 && multiPropertiesTagFilterValue.some(el => el.NHID === property.Property.NHID && el.TagID === tag.TagID),  red: !tag.IsTagPublishWebsite })
                                                                } 
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleTagFilter(property.Property.NHID, tag.TagID);
                                                                }}
                                                            >
                                                                <TagKeyNameIcon tagKeyName={tag.TagKeyName}/>{tag.TagName} <span>{tag.Photo.length}</span>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="card-info">
                                            <div>
                                            {
                                                multiPropertiesTagFilterValue.length === 0 ||  !multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID) ?
                                                    property.Photo.map((photo:IPortalPropertyPhotoObj) => {
                                                        return (
                                                            <div key={`${photo.TagName}-${photo.AssetID}`} className="media-photo">
                                                                <span>
                                                                    {photo.TagName}
                                                                </span>
                                                                <DisplayAsset
                                                                    TransformedAssetUrl={photo.TransformedAssetUrl}
                                                                    FileName={photo.FileName}
                                                                    AssetType={NHAssetType.Photos} 
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                :
                                                multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID ) ?
                                                    property.Photo.map((photo:IPortalPropertyPhotoObj) => {
                                                        return (
                                                            multiPropertiesTagFilterValue.find(el => el.NHID === property.Property.NHID && el.TagID === photo.TagID ) ?
                                                                <div key={photo.AssetID} className="media-photo">
                                                                    <span>
                                                                        {photo.TagName}
                                                                    </span>
                                                                    <DisplayAsset
                                                                        TransformedAssetUrl={photo.TransformedAssetUrl}
                                                                        FileName={photo.FileName}
                                                                        AssetType={NHAssetType.Photos} 
                                                                    />
                                                                </div>
                                                            :
                                                                ''
                                                        );
                                                    })
                                                            
                                                :
                                                    ''
                                            }
                                            </div>
                                        </div>
                                        </>
                                    :
                                        <div className="card-info no-scroll">
                                            <div>
                                                <span className="msg msg-error">
                                                    No photo uploaded
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
            </>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1 
        ? 
            <>
                <LoadingSpinner show={busyLoadingPhotos} /> 
                { renderMultiPropertiesPhotos() }
            </>
        : null;
};

export default MultiOrgPropertyPhotos;