import {
    ITourDefaultValues,
    ITourType
} from '../pages/property_details/property_tour_availability/types';
import { secureHttp } from '../utils/request';
import { get } from 'lodash';

type TourDetailsListResponse = {
    rows: ITourDefaultValues[];
    totalRowCount: number;
};

const getTourDetailsList = async (
    params?: any
): Promise<TourDetailsListResponse> => {
    try {
        const resp = await secureHttp.get('/tour/details', { params });
        const rows = get(resp, 'data.data.rows', []);
        const totalRowCount = get(resp, 'data.data.totalRowCount', 0);

        return {
            rows,
            totalRowCount
        };
    } catch (err) {
        console.error(err);
        return null;
    }
};

const getTourTypes = async (): Promise<ITourType[]> => {
    try {
        const resp = await secureHttp.get('/tour/tourtypes');
        const data = get(resp, 'data.data', {});

        return data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

const updateTourDetails = async (nhID: number, payload: any): Promise<ITourType[]> => {
    try {
        const resp = await secureHttp.patch(`/tour/${nhID}`, payload);
        const data = get(resp, 'data.data', {});

        return data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export { getTourDetailsList, getTourTypes, updateTourDetails };
