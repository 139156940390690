type Option = typeof options[number];
type Value = typeof valueMap[keyof typeof valueMap];

const options = [
    { label: 'All', value: 'all' },
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' }
] as const;

const valueMap = {
    'yes': true,
    'no': false,
    'all': null as null
} as const;

const booleanOrNullToString = (value: Value) => {
    if (typeof value !== 'boolean') {
        return 'all';
    }
    return value ? 'yes' : 'no';
};

const getInitialOption = (value: Value) => {
    const initialOptionValue = booleanOrNullToString(value);
    return options.find((option: Option) => option.value === initialOptionValue);
};

export { options, valueMap, getInitialOption, type Option, type Value, booleanOrNullToString };