import React, { FC, useState } from 'react';
import { useSessionState } from '../../../../../hooks/use_session_state';
import { Modal } from '../../../../../components/common/modal';
import { PropertyTeamForm } from '../property_team_form';
import { Button } from '../../../../../components/common/button';
import { MeetTheTeamMemberType } from '../../types';
import { useAddTeamMemberMutation } from '../../hooks/useAddTeamMemberMutation';

interface Props {
    propertyId: number;
    isInSinglePropertyContext?: boolean;
}

const AddTeamMemberModal: FC<Props> = ({
    propertyId,
    isInSinglePropertyContext = false
}) => {
    const addTeamMemberMutation = useAddTeamMemberMutation({
        includeIdInQueryKey: isInSinglePropertyContext,
        propertyId
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);

    const sessionState = useSessionState();

    const formId = 'add-team-member';

    const actionButtons = (
        <Button type="submit" form={formId}>
            Add Team Member
        </Button>
    );

    const handleSubmit = async (values: MeetTheTeamMemberType) => {
        addTeamMemberMutation(values);
        toggleModal();
    };

    const defaultValues: MeetTheTeamMemberType = {
        description: '',
        firstName: '',
        lastName: '',
        position: '',
        propertyId,
        profileImageAssetId: null,
        sequence: 0,
        yearStarted: null,
        publishWebsite: 'yes'
    };

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onClose={toggleModal}
                title="Add Team Member"
                footerActions={actionButtons}
                size='xl'
            >
                <PropertyTeamForm
                    onSubmit={handleSubmit}
                    id={formId}
                    properties={sessionState.Properties}
                    defaultValues={defaultValues}
                />
            </Modal>
            <Button size="small" onClick={toggleModal}>
                Add Team Member
            </Button>
        </>
    );
};

export { AddTeamMemberModal };
