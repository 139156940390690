import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISessionProperty, ISessionState } from '../../interfaces/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSquareArrowUpRight
} from '@fortawesome/pro-light-svg-icons';

const HeaderSessionText = () => {

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { Session } = sessionState || {};
    const { SessionID, SessionType } = Session || {};


    const [headerName, setHeaderName] = useState<string>('');
    const [headerText, setHeaderText] = useState<string>('');
    const [headerURL, setHeaderURL] = useState<string>('');

    useEffect(() => {

        const { Org, Properties } = sessionState;

        const websiteURL = Properties?.length ? Properties[0].WebsiteURL : '';

        if (Org) {
            setHeaderName(Org.Name);
            setHeaderText('');
            setHeaderURL(`${websiteURL}/provider/${Org.Slug}`);
        } else if ((Properties || []).length > 1) {
            setHeaderName(Properties[0].OrgName);
            setHeaderText('A selection of facilities from');
            setHeaderURL(`${websiteURL}/provider/${Properties[0].OrgSlug}`);
        } else if ((Properties || []).length === 1) {
            setHeaderName(Properties[0].Name);
            setHeaderText('');
            setHeaderURL(`${websiteURL}/${Properties[0].StateSlug}/${Properties[0].CitySlug}/${Properties[0].Slug}/`);
        } else {
            setHeaderText('');
            setHeaderURL('');
        }

    }, [SessionID, SessionType ]);

    const renderSubscriptionLink = () => {
        const { Org, Properties } = sessionState || {};

        if (!Properties?.[0]?.WebsiteURL) {
            return;
        }

        const showAsPremium = Org ? Org.IsPremium : Properties.every((property: ISessionProperty) => property.IsPremium);

        return (
            <>
                <div>
                    <span>Current Subscription:</span> 
                    <a href={`${Properties[0].WebsiteURL}/subscribe/`} target='_blank' rel="noreferrer">{ showAsPremium ? 'Premium' : 'Basic'}</a>
                </div>
            </>
        );
    };

    return (
        <>
            {
                headerText ?
                <span className="small">
                    { headerText }
                </span>: null
            }
            <h4>
                { headerName }
            </h4>
            <div>
                {
                    headerURL ?
                    <a href={headerURL} target="_blank" rel="noreferrer" title="Visit on NursingHomes.com">
                        <FontAwesomeIcon icon={faSquareArrowUpRight} /><span>View on nursinghomes.com</span>
                    </a>: null
                }
                { renderSubscriptionLink() }
            </div>
        </>
    );
};

export default HeaderSessionText;
