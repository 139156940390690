import React, { CSSProperties, FC } from 'react';

interface Props {
    minHeight?: CSSProperties['height'];
}

const LoadingSpinner: FC<Props> = ({ minHeight = '100%' }) => {
    return (
        <div style={{ height: minHeight, position: 'relative' }}>
            <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                <div className="spinner-border" role="status" />
            </div>
        </div>
    );
};

export { LoadingSpinner };
