import React, { FC } from 'react';

import cx from 'classnames';

type Option = {label: string; value: any};

interface IProps {
    onChange: (selectedValue: any) => void;
    value: Option;
    label?: string;
    options: Option[]
}

const ButtonGroup: FC<IProps> = ({ options, onChange, value: currentOption, label }) => {

    const items = options.map((option: Option, index: number) => {
        return (
            <button
                key={index}
                onClick={() => onChange(option)}
                className={cx({ active: currentOption.value === option.value })}
            >
                {option.label}
            </button>
        );
    });

    return (
        <div className="mx-3 d-flex position-relative align-items-sm-center gap-4 flex-column flex-sm-row">
            {label && <label className="fs-5">{label}</label>}
            <div className="custom-btn-group">{items}</div>
        </div>
    );
};

export { ButtonGroup };