import React, { FC, useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import { AllPropertiesMembersView } from './all_properties_members_view';
import { SinglePropertyMembersView } from './single_property_members_view';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { ProfileScoreType } from '../../../interfaces/property';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';

interface Props {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyTeam: FC<Props> = ({ nhIDs, orgNHID }) => {
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [profileCompletenessFilterValue, setProfileCompletenessFilterValue] =
        useState<boolean | null>(null);

    const scrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
        if (nhIDs?.length === 1) {
            setSelNHID(nhIDs[0]);
            scrollToTop();
        }
    }, []);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
        scrollToTop();
    });

    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Team Members for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            <div className="widget" data-widget-height="auto">
                <div className="widget-body"> 
                    <ProfileCompletenessHeader
                        selNHID={selNHID}
                        nhIDs={nhIDs}
                        orgNHID={orgNHID}
                        profileScoreType={ProfileScoreType.MeetTheTeam}
                        handleProfileCompletenessFilter={
                            setProfileCompletenessFilterValue
                        }
                        profileCompletenessFilterValue={
                            profileCompletenessFilterValue
                        }
                        title="for Meet the Team"
                    />
                </div>
            </div>

            <div className="widget">
                <div className="widget-body">
                    {selNHID ? (
                        <SinglePropertyMembersView nhID={selNHID} />
                    ) : (
                        <AllPropertiesMembersView
                            onSelectProperty={handleSelect}
                            profileCompletenessFilterValue={
                                profileCompletenessFilterValue
                            }
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export { PropertyTeam };
