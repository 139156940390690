import { MeetTheTeamMemberType } from './types';

const moveArrayItem = <T>(array: T[], fromIndex: number, toIndex: number) => {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    if (!(startIndex >= 0 && startIndex < array.length)) {
        return array;
    }

    const [item] = array.splice(fromIndex, 1);
    const clonedArray = [...array];
    clonedArray.splice(endIndex, 0, item);
    return clonedArray;
};

const createNewSequenceOrder = (
    oldOrder: MeetTheTeamMemberType[],
    sourceIndex: number,
    destinationIndex: number
) =>
    moveArrayItem(oldOrder, sourceIndex, destinationIndex).map(
        (item: MeetTheTeamMemberType, index) => ({
            ...item,
            sequence: index + 1
        })
    );

export { moveArrayItem, createNewSequenceOrder };
