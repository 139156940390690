import React, { useState, useEffect } from 'react';
import PageCommunitySelect from '../../components/common/page_community_select';
import { getSingleProperty  } from '../../utils/common';
import { ISessionState, SessionType as EnumSessionType } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import axios from 'axios';

import {
    renderQRCodePosterLink,
    renderQRCodeLink,
    qrCodeImageURL,
    qrCodePosterPdfURL,
    renderCSVLink
} from './review_score_certs_common';
import { useLocation } from 'react-router-dom';
import PremiumLink from '../../components/common/premium_sign_up_link';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const showAdditional = false;

const QRCodePoster =({ nhIDs, orgNHID }: IProps) => {
    const { Session, Properties, Org }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType } = Session;

    const location = useLocation();
    const state = location?.state as any;

    const initNHID = state?.NHID || nhIDs?.length === 1 ? nhIDs[0] : null;
    const initProperty = initNHID ? getSingleProperty(SessionType, initNHID, Properties) : null;  
    
    const [selNHID, setSelNHID] = useState<number>(initNHID);
    const [qrCodeSize, setQrCodeSize] = useState<number>(300);
    const [qrCodeImgFormat, setQrCodeImgFormat] = useState<'SVG' | 'PNG'>('PNG');
    const [qrCodeExtraParams, setQrCodeExtraParams] = useState<string>('');
    const [qrCodePosterExtraParams, /*setQrCodePosterExtraParams*/] = useState<string>('');
    const [qrCodeImgURL, setQrCodeImgURL] = useState<string>('');
    const [qrCodePosterURL, setQrCodePosterURL] = useState<string>('');
    const [qrCodePosterDownloading, setQRCodePosterDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [property, setProperty] = useState<any>(initProperty);

    useEffect(() => {
        createQrCodeImageURL();
        createQrCodePosterPdfURL();
    });

    useEffect(() => {
        createQrCodePosterPdfURL();
    }, [qrCodePosterExtraParams]);

    useEffect(() => {
        createQrCodeImageURL();
    }, [qrCodeExtraParams, qrCodeImgFormat, qrCodeSize]);

    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const singlePropertyName = singleProperty ? singleProperty.Name : '';

    const qrcodePosterFilename = () => {

        const prefix = 'qrcodeposter';

        if (singleProperty) {
            return `${prefix}_${singlePropertyName}_${singleProperty.NHID}.pdf`;
        } else if (SessionType === EnumSessionType.SessionTypeOrg) {
            return `${prefix}_${Org.Name}_${Org.NHID}.zip`;
        } else {
            return 'qrcodeposter_multiple_members.zip';
        }
    };

    // const renderSessionText = () => {
    
    //     if (singlePropertyName) {
    //         return (
    //             <span>for <strong>{singlePropertyName}</strong></span>
    //         );
    //     } else if (SessionType === EnumSessionType.SessionTypeOrg) {
    //         return (
    //             <span>for <strong>all {Org.Name} Communities</strong></span>
    //         );
    //     } else {
    //         return (
    //             <span>for <strong>all {nhIDs.length} Communities</strong></span>
    //         );
    //     }
    // };
    

    const createQrCodeImageURL = () => {

        const selNHIDs = selNHID ? [selNHID] : nhIDs;

        const url = qrCodeImageURL(
            selNHIDs, 
            orgNHID, 
            qrCodeSize, 
            qrCodeImgFormat, 
            qrCodeExtraParams
        );

        setQrCodeImgURL(url);
    };

    const createQrCodePosterPdfURL = () => {

        const selNHIDs = selNHID ? [selNHID] : nhIDs;

        const url = qrCodePosterPdfURL(
            selNHIDs, 
            orgNHID, 
            qrCodePosterExtraParams
        );

        setQrCodePosterURL(url);
    };

    const updateQrImageSize = (val: string) => {

        const newSize = parseInt(val, 10);
        if (!isNaN(newSize)) {
            setQrCodeSize(newSize);
        }
    };

    const updateQrImageFormat = (val: 'SVG' | 'PNG') => {
        setQrCodeImgFormat(val);
    };

    const updateQrImageExtraParams = (val: string) => {
        setQrCodeExtraParams(val);
    };

    // const updateQrPosterExtraParams = (val: string) => {
    //     setQrCodePosterExtraParams(val);
    // };

    const handleSelect = (NHID: number | null) => {
        if (NHID) {
            const property = getSingleProperty(SessionType, NHID, Properties);        
            
            if (property) {
                setProperty(property);
                setSelNHID(NHID);
            }
        } else {
            setProperty(null);
            setSelNHID(null);
        }
    };

    const handleQRCodePosterDownload = async (url: string) => {

        if (qrCodePosterDownloading) {
            return;
        }
        
        try {
            setIsLoading(true);
            setQRCodePosterDownloading(true);
            const resp = await axios.get(url,{
                responseType: 'blob',
            });

            const data = _.get(resp, 'data');
            saveAs(data, qrcodePosterFilename());
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
            setQRCodePosterDownloading(false);
        }
    };

    const handleQRCodeDownload = async (url: string) => {
        await axios.get(url);
    };

    if (!(nhIDs || []).length && !orgNHID) {
        return null;
    }

    return (

        <>
            { isLoading ? <LoadingSpinner show={true}/> : null }
            <div className="widget">
                <div className="widget-body">
                    <div className="card" data-card="Generate a QR Code Poster">
                        <div className="card-header">
                            <h4>
                                Generate a QR Code Poster
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="card-info-box">
                                <h5>
                                    Where do I use my QR Code Poster
                                </h5>
                                <p>
                                    We would recommend displaying your QR Code Poster in your reception area(s) so visiting family & friends can easily access them to leave a review.
                                </p>
                                <div className="d-flex">
                                    <p>
                                        <img src="/images/widget/qr.jpg" width="100" height="100" alt="QR" />
                                    </p> 
                                    <p className="p-2">
                                        Please note we can also supply just the QR Code images if you require them to display on your own marketing material, please email <a href="mailto:reviews@nursinghomes.com">reviews@nursinghomes.com</a> detailing your requirement.
                                    </p>
                                </div>
                            </div>
                            <div className="card-info">
                                {
                                    orgNHID || nhIDs?.length > 1 ?
                                    <span>
                                        <label>
                                            <strong>
                                                Select a facility:
                                            </strong>
                                        </label>
                                        <PageCommunitySelect handleSelect={handleSelect} selNHID={selNHID}/>
                                    </span>
                                    : null
                                }
                                { 
                                    property ?
                                        property.IsPremium
                                        ?
                                            renderQRCodePosterLink(
                                                nhIDs,
                                                qrCodePosterURL,
                                                singleProperty, 
                                                SessionType, 
                                                Org,
                                                handleQRCodePosterDownload,
                                                qrCodePosterDownloading
                                            ) 
                                        : 
                                            <div className="text-center mt-5">
                                                <p className="fw-bold">QR Code Posters are only available for Premium Subscribers</p>
                                                <p><PremiumLink websiteUrl={property.WebsiteURL} /></p>
                                            </div>
                                    : null
                                }
                                { property ? null : renderCSVLink(nhIDs, orgNHID) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { 
                showAdditional ? renderQRCodeLink(
                    nhIDs,
                    orgNHID,
                    qrCodeSize,
                    updateQrImageSize,
                    qrCodeImgFormat,
                    updateQrImageFormat,
                    qrCodeExtraParams,
                    updateQrImageExtraParams,
                    qrCodeImgURL,
                    handleQRCodeDownload
                ) : null
            }
        </>
    );
};

export default QRCodePoster;