import React from 'react';
import { Modal } from 'react-bootstrap';
import RatingStars from '../common/rating_stars';

interface IProps {
    review: any;
    handleClose(): void;
    handlePublishReview(reviewID: number): Promise<void>;
    cancelText: string;
    saveText: string[];
}

const PublishModal = ({
    review, 
    handleClose, 
    handlePublishReview,
    cancelText,
    saveText
}: IProps) => {

    console.log(review);

       return (
        <Modal
            show={true}
            centered
            size="xl"
            dialogClassName="modal-sticky-footer"
        >
            <Modal.Header>
                <Modal.Title className="reply-modal-title">
                    <p className="reply-modal-title mb-3">
                        <strong>Publish Review ID: {review.ReviewID} for { review.Name }</strong>
                    </p>
                    <div className="m-0">
                        { 
                            review.OverallRating ? 
                                <span className="me-3 mb-2 d-inline-flex align-items-center">
                                    <strong className="me-1">Overall Rating:</strong> 
                                    <RatingStars
                                        keyProp={review.ReviewID}
                                        value={review.OverallRating}
                                    />
                                </span> : <></> 
                        }
                    </div>
                    <p className="m-0">
                        { review.ExperienceTypeName ? <span className="me-3 d-inline-block mb-2"><strong>Reviewer Experience Type:</strong> {review.ExperienceTypeName}</span> : <></> }
                        { review.ConnectionName ? <span className="d-inline-block mb-2"><strong>Relationship to the resident or client:</strong> {review.ConnectionName}</span> : <></> }
                    </p>
                    <p className="m-0 reply-modal-review">
                        <strong>Review:</strong> { review.ReviewContent }
                    </p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <div className="row w-100">
                    <div className="col-12 d-flex flex-column-reverse flex-md-row">
                        <div className="m-auto m-md-0 ms-md-auto d-flex flex-row">
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-primary btn-outline me-2 me-md-4"
                                onClick={() => handleClose()}
                            >
                                Close &amp; <br className="d-md-none"/>Do Not {cancelText}
                            </button>
                            <button type="submit" className="btn btn-sm btn-primary"
                                onClick={() => handlePublishReview(review.ReviewID)}
                            >
                                {saveText[0]} <br className="d-md-none"/>{saveText[1]} 
                                
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default PublishModal;
