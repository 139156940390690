import { useSelector } from 'react-redux';
import { ISessionProperty, ISessionState } from '../interfaces/session';

export const formatAddress = (
    propertyData: any, 
    addressKeys?: string[]
): string => {
    const data: any = { ...propertyData };

    const keys = addressKeys || ['Address1', 'Address2', 'CityName', 'StateCode', 'ZipCode'];

    const address =  keys.reduce((acc: string, key: string, index: number) => acc + 
        (data[key] as any ? data[key] + `${index < keys.length-1 ? ', ' : ''}` : ''), '');

    return address;
};

export const getSessionPropertyMap = (): Map<number, ISessionProperty> => {
    const { Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const map = new Map<number, ISessionProperty>();
    (Properties || []).forEach((property: ISessionProperty) => map.set(property.NHID, property));

    return map;
};