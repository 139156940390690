import { secureHttp } from '../utils/request';
import { 
    INHSignatureParams,
    INHUploadSignature,
    IAssetPayLoad,
    IUpdateSequencePayLoad,
    IUpdateTitlePayLoad,
    IAssetTagPayLoad
} from '../interfaces/asset';
import { ITag } from '../interfaces/tag';

export const getOrgPropertiesPhotos = async (orgID: number, filters: Map<string, any>)
    : Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/photos/org/${orgID}`, {  
            params: { 
                ...(filters ? Object.fromEntries(filters) : {}) 
            } 
        });
        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getPropertiesPhotos = async (nhIDs: number[], filters?: Map<string, any>): Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/photos/property?NHIDs=${nhIDs.join()}`, { 
            params: { 
                ...(filters ? Object.fromEntries(filters) : {}) 
            } 
        });
        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getOrgPropertiesVideos = async (orgID: number, filters: Map<string, any>)
    : Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/videos/org/${orgID}`, {  
            params: { 
                ...(filters ? Object.fromEntries(filters) : {}) 
            } 
        });
        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getPropertiesVideos = async (nhIDs: number[], filters?: Map<string, any>): Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/videos/property?NHIDs=${nhIDs.join()}`, { 
            params: { 
                ...(filters ? Object.fromEntries(filters) : {}) 
            } 
        });
        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getOrgPropertiesLogos = async (orgID: number, filters: Map<string, any>)
    : Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/logos/org/${orgID}`, {  
            params: { 
                ...(filters ? Object.fromEntries(filters) : {}) 
            } 
        });
        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getPropertiesLogos = async (nhIDs: number[], filters?: Map<string, any>): Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/logos/property?NHIDs=${nhIDs.join()}`, { 
            params: { 
                ...(filters ? Object.fromEntries(filters) : {}) 
            } 
        });
        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getOrgPropertiesBrochures = async (orgID: number) : Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/brochures/org/${orgID}`);

        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getPropertiesBrochures = async (nhIDs: number[]): Promise<any> => {
    try {
        const { data } = await secureHttp.get(`/asset/brochures/property?NHIDs=${nhIDs.join()}`);

        return data?.data ? data.data : null;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const generateUploadSignature = async (params: INHSignatureParams): Promise<INHUploadSignature> => {
    const { data } = await secureHttp.post('/asset/generateuploadsignature', params);
    return data?.data ? data.data : null;
};

export const createAsset = async (params: IAssetPayLoad): Promise<number> => {
    const { data } = await secureHttp.post('/asset/createasset', params);
    return data?.data?.AssetID ? data.data.AssetID : 0;
};

export const deletePropertyLogo = async (nHID: number): Promise<number> => {
    const {data} = await secureHttp.delete(`/asset/logo/${nHID}`);
    return data?.data?.AssetID ? data.data.AssetID : null;
};

export const deletePropertyVideo = async (nHID: number): Promise<number> => {
    const {data} = await secureHttp.delete(`/asset/video/${nHID}`);
    return data?.data?.AssetID ? data.data.AssetID : null;
};

export const deletePropertyPhoto = async (nHID: number, assetID: number): Promise<number> => {
    const {data} = await secureHttp.delete(`/asset/photo/${nHID}/assetid/${assetID}`);
    return data?.data?.AssetID ? data.data.AssetID : null;
};

export const updatePropertyPhotosSequence = async (params: IUpdateSequencePayLoad): Promise<boolean> => {
    const {data} = await secureHttp.post('/asset/photo/updatesequence', params);
    return !!data?.data?.isSuccess;
};

export const updatePhotoTitle = async (params: IUpdateTitlePayLoad): Promise<number> => {
    const {data} = await secureHttp.post('/asset/photo/updatetitle', params);
    return data?.data?.AssetID ? data.data.AssetID : null;
};

export const deletePropertyCardLogo = async (nHID: number): Promise<number> => {
    const {data} = await secureHttp.delete(`/asset/cardlogo/${nHID}`);
    return data?.data?.AssetID ? data.data.AssetID : null;
};

export const deletePropertyBrochure = async (nHID: number): Promise<number> => {
    const {data} = await secureHttp.delete(`/asset/brochures/${nHID}`);
    return data?.data?.AssetID ? data.data.AssetID : null;
};

export const getTags= async(params: any): Promise<ITag[]> => {
    const {data} = await secureHttp.get('/asset/tag', { params });
    return data?.data ? data.data : null;
};

export const updateAssetTag = async (params: IAssetTagPayLoad): Promise<boolean> => {
    const {data} = await secureHttp.post('/asset/tag', params );
    return data?.data?.isSuccess ? data.data.isSuccess : false;
};