import axios, { AxiosInstance } from 'axios';
import { API_HEADER_APP_CONTEXT, BASE_API_URL } from '../config/keys';
import { 
    getJwtToken,
    setJwtToken,
    setRefreshToken,
    fetchNewTokens,
    logout
} from '../modules/login';

import { debugLog } from '../modules/debug';

class BaseRequest {
    protected httpService: AxiosInstance;

    constructor() {
        this.httpService = axios.create({
            baseURL: BASE_API_URL
        });

        this.httpService.interceptors.response.use(
            (response: any) => {
                return Promise.resolve(response);
            },
            async (error: any) => {
                if (error.response) {
                    const errors = (error.response.data || {}).errors || [];
                    error.response.customError = errors.length ? errors[0] : 'An error occurred';
                    return Promise.reject(error.response);
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }

    get(path: string, params?: any) {
        return this.httpService.get(path, params);
    }

    patch(path: string, payload?: any) {
        return this.httpService.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload || {}
        });
    }

    put(path: string, payload?: any) {
        return this.httpService.request({
            method: 'PUT',
            url: path,
            responseType: 'json',
            data: payload || {}
        });
    }

    post(path: string, payload?: any) {
        return this.httpService.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload || {}
        });
    }

    delete(path: string, payload?: any) {
        return this.httpService.request({
            method: 'DELETE',
            url: path,
            responseType: 'json',
            data: payload || {}
        });
    }

    async all(requests: any) {
        return axios.all(requests);
    }
}

class SecureRequest extends BaseRequest {

    isRefreshing: boolean;
    failedRequests: any[];

    constructor() {
        super();
        this.isRefreshing = false;
        this.failedRequests = [];

        this.httpService.interceptors.request.use((config: any): any => {
            const jwt = getJwtToken();
            
            config.headers.common['Authorization'] = `Bearer ${jwt}`;
            config.headers.common['x-app-context'] = API_HEADER_APP_CONTEXT;
            
            return config;
        });

        this.httpService.interceptors.response.use(null, async (error: any) => {

            const { config, status } = error;
            const originalRequest = config;

            if (status === 498) {

                debugLog(`status 498 for URL ${originalRequest.url}`);

                if (!this.isRefreshing) {
                    
                    let accessToken: string;
                    let refreshToken: string;

                    this.isRefreshing = true;

                    try {
                        [accessToken, refreshToken] = await fetchNewTokens();  

                        setJwtToken(accessToken);
                        setRefreshToken(refreshToken);

                        debugLog(`New tokens: ${accessToken} : ${refreshToken}`);
                    } catch(err: any) {
                        debugLog(`Failed to create new tokens: ${err.message || 'Unknown Error'}`);
                        logout();
                        window.location.reload();
                    } finally {
                        this.isRefreshing = false;
                        this.failedRequests.map(cb => cb(accessToken));
                    }
                }

                const originalRequestCb = new Promise((resolve, reject) => {
                    this.failedRequests.push((accessToken: string) => {

                        if (!originalRequest.retry) {
                            originalRequest.retry = true;
                            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                            debugLog(`Replaying request: ${originalRequest.url}`);
                            resolve(this.httpService(originalRequest));
                        } else {
                            debugLog(`Request already replayed: ${originalRequest.url}`);
                            reject(`Request already replayed: ${originalRequest.url}`);
                        }
                    });
                });
                
                return originalRequestCb;
            } else {

                //TODO Can't remember why we should log out for a non 498 ?? Should show an error box.
                // Non 498 so logout
                //debugLog(`Status: ${status} Non 498 error so logging out`);

                //logout();
                //window.location.reload();
            }

            return Promise.reject(error);
        });
    }
}

export const http = new BaseRequest();
export const secureHttp = new SecureRequest();
