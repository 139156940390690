import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IMenuItem, IMenuState, IMenuStateTotals } from '../../interfaces/menu';
import { ISessionState } from '../../interfaces/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { getLinkTotal } from '../../utils/menu';
import { isDefined } from '../../utils/common';
import { get } from 'lodash';
import { findMenuItem, findPageByID } from './common';


interface IProps {
    menuPath?: string;
}

const BreadcrumbMenu = ({ menuPath }: IProps) => {
    const { Menu }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const menuTotals: IMenuStateTotals = useSelector(
        ({ MenuTotalsState }: any) => MenuTotalsState
    );
    
    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const currentPage: IMenuItem = findMenuItem(Menu, menuPath, location.pathname);
    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);

    if (!currentPage || (currentPage.IsTestFeature && !canViewTestFeatures)) {
        return;
    }

    const renderBreadcrumbs = () => {    
        const parentPage = findPageByID(Menu, currentPage.ParentPageID);
        const pages = parentPage?.Children || [currentPage];

        return (
            <ul className="breadcrumb d-none d-lg-flex">
                {
                    <>
                        {
                            parentPage ?
                                <li className="breadcrumb-item">
                                    <span className="fw-bold">{ parentPage.MenuText }</span> <FontAwesomeIcon icon={faChevronRight} />
                                </li> : null
                        }
                        {
                            pages.map((page: IMenuItem) => {
                                const linkTotal = getLinkTotal(menuTotals, page.Slug);
                                const menuText = <>{  page.MenuText } { isDefined(linkTotal) ? `(${linkTotal})` : null }</>;

                                if (currentPage.PageID === page.PageID) {
                                    return (
                                        <li className="breadcrumb-item current" key={`breadcrumb-page-${page.PageID}`}>
                                            <NavLink to={ page.Route }>{ menuText }</NavLink>
                                        </li> 
                                    );
                                }
                                return (
                                    <li className="breadcrumb-item" key={`breadcrumb-page-${page.PageID}`}>
                                        <NavLink to={ page.Route }>{ menuText }</NavLink>
                                    </li>
                                );
                            })
                        }
                    </> 
                }
            </ul>
        );
    };


    return (
        <>
            { renderBreadcrumbs() }
        </>
    );
};

export default BreadcrumbMenu;
 