import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IPortalPropertyTagModalData } from '../../interfaces/asset';
import { ITag } from '../../interfaces/tag';
import Swal from 'sweetalert2';

interface IProps {
    photoTags: ITag[];
    sourceTag: IPortalPropertyTagModalData;
    handleClose(): void;
    handleTagUpdate(sourceTag: IPortalPropertyTagModalData, destinationTag: IPortalPropertyTagModalData): void;
}

const PhotoTagUpdateModal = ({
    photoTags,
    sourceTag, 
    handleClose, 
    handleTagUpdate   
}: IProps) => {

    const defaultTag: IPortalPropertyTagModalData = sourceTag ? sourceTag : null;
    const [selectedTag, setSelectedTag] = useState<IPortalPropertyTagModalData>(defaultTag);
  
    const handleSelectedTag = (tag: IPortalPropertyTagModalData)  => {
        setSelectedTag(tag);
    };

    const handleOnSubmit = () => {
          
        if( !selectedTag?.TagID ) {
            Swal.fire({
                icon: 'error',
                title: 'Update Photo Tag',
                text: 'Please select a Relevant Tag'
            });
        } else {
            handleTagUpdate(sourceTag, selectedTag);
        }
    };

    const renderForm = () => {
        return (
            <form onSubmit={handleOnSubmit}>
                <Modal.Body>
                <div className="widget mt-0">
                    <div className="widget-body">
                        <div className="card" data-card="Media Upload">
                            <div className="card-body">
                                <div className="card-info">
                                    <ul>
                                        <li>
                                            <header>
                                                <h4>
                                                    Select a Relevant Tag*
                                                </h4>
                                            </header>
                                            <div>
                                                <ul>
                                                    {
                                                        photoTags.map((tag:ITag) => {
                                                            return (
                                                                tag.IsTagPublishWebsite === true ?
                                                                <li 
                                                                    key={`tag-${tag.TagID}`}
                                                                    className={
                                                                        selectedTag.TagID === tag.TagID ? 
                                                                            'selected' 
                                                                        : 
                                                                            ''
                                                                    } 
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleSelectedTag({
                                                                            TagID: tag.TagID,
                                                                            TagName: tag.TagName,
                                                                            TagSequence: tag.TagSequence,
                                                                            IsTagPublishWebsite: tag.IsTagPublishWebsite,
                                                                            IsTagPublishPortal: tag.IsTagPublishWebsite,
                                                                            IsTagPublishCMS:tag.IsTagPublishCMS,
                                                                            AssetID: 0
                                                                        });
                                                                    }}
                                                                >
                                                                    {tag.TagName}
                                                                </li>
                                                                :
                                                                ''
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col-12 d-flex flex-column-reverse flex-md-row">
                            <div className="m-auto m-md-0 ms-md-auto d-flex flex-row">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary btn-outline me-2 me-md-4"
                                    onClick={() => handleClose()}
                                >
                                    Close
                                </button>
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update Tag
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </form>
        );
    };

    return (
        <Modal
            show={true}
            centered
            size="xl"
            dialogClassName="modal-sticky-footer"
        >
            <Modal.Header>
                <Modal.Title className="reply-modal-title">
                    <p className="reply-modal-title mb-3">
                        <strong>Update Photo Tag for Photo ID: {sourceTag.AssetID} </strong>
                    </p>
                </Modal.Title>
            </Modal.Header>
            { renderForm() }
        </Modal>
    );
};

export default PhotoTagUpdateModal;
