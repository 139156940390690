import React from 'react';
//import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/app.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/common/scroll_to_top';

const root = ReactDOM.createRoot(document.getElementById('root'));
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
root.render(
    // <StrictMode>
    <Router>
        <QueryClientProvider client={queryClient}>
            <ScrollToTop />
            <App />
        </QueryClientProvider>
    </Router>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
