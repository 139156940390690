import { useMemo } from 'react';
import { useSessionState } from './use_session_state';
import { ISessionProperty } from '../interfaces/session';

type PropertyDictionary = Record<string, ISessionProperty>;
type Hook = ({includeOrg}?: { includeOrg: boolean }) => PropertyDictionary;

const useSessionPropertiesMap: Hook = (options = { includeOrg: false }) => {
    const { Properties = [], Org } = useSessionState();

    const properties = useMemo(
        () =>
            Properties.reduce((acc: any, curr: any) => {
                acc[String(curr.NHID)] = curr;
                return acc;
            }, {}),
        [Properties]
    );

    if (options.includeOrg) {
        properties[Org?.NHID] = Org;
    }

    return properties;
};

export { useSessionPropertiesMap };
