import React from 'react';
import { longFormatDateTime } from '../../../utils/date';
import { FacilityNameColumnItem } from './components/facillity_name_column_item';
import { ITourAvailabilityTableColumn, ITourDefaultValues } from './types';
import { mapToDaysOfWeek } from './helpers';
import { TourTimesColumnItem } from './components/tour_times_column_item';
import { EditTourAvailabilityModal } from './components/edit_tour_availability_modal';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const addMarginWhenIsOrganization = {
    when: (row: ITourDefaultValues) => row.IsOrganization,
    style: {
        position: 'static',
        marginTop: '55px',
        marginBottom: '10px'
    }
};

const addPaddingWhenIsOrganization = {
    when: (row: ITourDefaultValues) => row.IsOrganization,
    style: {
        paddingTop: '40px',
        paddingBottom: '30px'
    }
};

const addTransparencyWhenHasNoTourData = {
    when: (row: ITourDefaultValues) => row.InheritedAvailabilityDetailsFromOrg,
    style: {
        opacity: '50%'
    }
};

const conditionalCellStyles = [
    addTransparencyWhenHasNoTourData,
    addMarginWhenIsOrganization
];

interface IProps {
    moveToSingleFacilityView: (nhID: number) => void;
}

const getColumns = ({
    moveToSingleFacilityView
}: IProps): ITourAvailabilityTableColumn[] => {
    return [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            width: '250px',
            conditionalCellStyles: [addPaddingWhenIsOrganization],
            cell: (row) => {
                const handleNameClicked = () =>
                    moveToSingleFacilityView(row.NHID);
                return (
                    <FacilityNameColumnItem
                        {...row}
                        onNameClicked={handleNameClicked}
                    />
                );
            }
        },
        {
            id: 'ProfileComplete',
            name: <>Profile Complete for Tour Availability</>,
            sortable: true,
            sortField: 'ProfileComplete',
            width: '140px',
            allowOverflow: true,
            cell: (row) => {
                if (row.IsOrganization) {
                    return null;
                }
                return (
                    <FontAwesomeIcon
                        icon={row.ProfileComplete ? faCheck : faXmark}
                        className={`fe-2 fs-2 ${
                            row.ProfileComplete ? 'text-success' : 'text-danger'
                        }`}
                    />
                );
            }
        },
        {
            id: 'TourTypeName',
            name: 'Tour Type',
            sortField: 'TourTypeName',
            cell: (row) => row.TourTypeName,
            conditionalCellStyles,
            sortable: true
        },
        {
            id: 'OnsiteTourDays',
            width: '130px',
            name: 'Onsite Tour Days',
            cell: (row) => {
                return (
                    <div>
                        {mapToDaysOfWeek(row.PhysicalTourDays).map(
                            (day: string) => {
                                return <div key={day}>{day}</div>;
                            }
                        )}
                    </div>
                );
            },
            conditionalCellStyles
        },
        {
            id: 'OnsiteTourTimes',
            name: 'Onsite Tour Times',
            width: '100px',
            cell: ({ PhysicalTourStartTime, PhysicalTourEndTime }) => (
                <TourTimesColumnItem
                    startTime={PhysicalTourStartTime}
                    endTime={PhysicalTourEndTime}
                />
            ),
            conditionalCellStyles
        },
        {
            id: 'RemoteTourDays',
            name: 'Remote Tour Days',
            width: '130px',
            cell: (row) => {
                return (
                    <div>
                        {mapToDaysOfWeek(row.VirtualTourDays).map(
                            (day: string) => {
                                return <div>{day}</div>;
                            }
                        )}
                    </div>
                );
            },
            conditionalCellStyles
        },
        {
            id: 'RemoteTourTimes',
            name: 'Remote Tour Times',
            width: '100px',
            cell: ({ VirtualTourStartTime, VirtualTourEndTime }) => (
                <TourTimesColumnItem
                    startTime={VirtualTourStartTime}
                    endTime={VirtualTourEndTime}
                />
            ),
            conditionalCellStyles
        },
        {
            id: 'UpdatedAt',
            name: 'Updated Date',
            sortField: 'UpdatedAt',
            width: '125px',
            cell: (row) => longFormatDateTime(row.UpdatedAt),
            conditionalCellStyles,
            sortable: true
        },
        {
            id: 'UpdatedBy',
            name: 'Updated By',
            sortField: 'UpdatedBy',
            cell: (row) => row.UpdatedBy,
            conditionalCellStyles,
            sortable: true
        },
        {
            id: 'Actions',
            name: '',
            width: '180px',
            cell: (row) => {
                return <EditTourAvailabilityModal {...row} />;
            }
        }
    ];
};

export { getColumns };
