import moment, { Moment } from 'moment';
import momenttz from 'moment-timezone';
const timezone = 'America/New_York';

export const formatDateMMMDDYYYY = (
    date: Date | string | null,
    startOf: moment.unitOfTime.StartOf = 'day',
    format = 'MMM DD YYYY'
): string => {
    if (!date) return null;
    if (!(date instanceof Date)) date = new Date(date);

    return moment({ 
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate() 
    }).startOf(startOf)
        .format(format);
};

export const longFormatDate = (date: string | Moment): string => {
    return date ? moment(date).format('MMMM Do YYYY') : '';
};

const nonBreakingSpace = '\xa0';
export const longFormatDateTime = (date: string | Moment): string => {
    return date ? moment(date).format(`MMMM Do YYYY h:mm${nonBreakingSpace}a`) : '';
};

export const shortFormatTime = (date: string): string => {
    return date ? moment(date).format('h:mm a') : '';
};

export const formatFromDateForAPIRequestUTC = (date: Date | string, startOf: moment.unitOfTime.StartOf = 'day'): string => {

    momenttz.tz.setDefault(timezone);

    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return momenttz({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }).startOf(startOf).utc().format();
};

export const formatToDateForAPIRequestUTC = (date: Date | string, endOf: momenttz.unitOfTime.StartOf = 'day'): string => {

    momenttz.tz.setDefault(timezone);

    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return momenttz({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }).endOf(endOf).utc().format();
};

export const getClientFormattedDate = (dateStr: string): string => {
    const clientTz = moment.tz.guess();
    return moment(dateStr).tz(clientTz).format('MMMM  Do YYYY');
};

export const getClientFormattedDateShort = (dateStr: string): string => {
    const clientTz = moment.tz.guess();
    return moment(dateStr).tz(clientTz).format('MMM  Do YYYY');
};

export const getAppDate = () => {
    momenttz.tz.setDefault(timezone);
    return momenttz();
};
