import { secureHttp } from '../utils/request';
import {
    IPropertyLiveDescription,
    IPropertyLiveDescriptionParams,
    IPropertyContactDetailsParams,
    IPropertyContactDetails,
    IPropertyUpdateContactDetailsParams,
    IPropertyBulkDetailsPayload,
    INhPortalScoreItem,
    IPropertyCoreDetailNHID,
    IPropertyCoreDetailPayload,
    IPropertyContactNoValueDetails
} from '../interfaces/property';

export const getPropertyLiveDescriptions = async (
    params: IPropertyLiveDescriptionParams
): Promise<IPropertyLiveDescription[]> => {
    try {
        const {
            data: { data }
        } = await secureHttp.get('/property/descriptions', { params });

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }

        return null;
    }
};

export const getPropertyContactDetails = async (
    params: IPropertyContactDetailsParams
): Promise<[IPropertyContactDetails[], number]> => {
    try {
        const {
            data: { data, totalRows }
        } = await secureHttp.get('/property/contactdetails', { params });

        return [data, totalRows];
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }

        return [[], 0];
    }
};

export const getPropertyContactDetailsFilterDetails = async (
    params: IPropertyContactDetailsParams
): Promise<IPropertyContactNoValueDetails> => {
    try {
        const {
            data: { data }
        } = await secureHttp.get('/property/contactdetailsfiltercounts', { params });

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
        return {};
    }
};

export const updatePropertyContactDetails = async (
    nhID: number,
    payload: IPropertyUpdateContactDetailsParams
): Promise<void> => {
    try {
        await secureHttp.patch(`/property/contactdetails/${nhID}`, payload);
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const updateBulkPropertyContactDetails = async (
    payload: IPropertyBulkDetailsPayload
): Promise<void> => {
    try {
        await secureHttp.patch('/property/bulkcontactdetails', payload);
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getPropertyProfileCompleteness = async (): Promise<INhPortalScoreItem> => {
    try {
        const { data: { data } } = await secureHttp.get('/property/profilecompleteness');
        return data;
    } catch (e: any) {
        console.error(e);
        return null;
    }
};

export const getPropertyCoreDetails = async (): Promise<IPropertyCoreDetailNHID[]> => {
    try {
        const { data: { data } } = await secureHttp.get('/property/core/details');
        return data;
    } catch (e: any) {
        console.error(e);
        return [];
    }
};

export const createPropertyCoreDetailData = async (
    nhID: number,
    payload: IPropertyCoreDetailPayload
): Promise<any> => {
    await secureHttp.post(`/property/core/details/${nhID}`, payload);
};

export const updatePropertyCoreDetailData = async (
    nhID: number,
    payload: IPropertyCoreDetailPayload
): Promise<any> => {
    await secureHttp.patch(`/property/core/details/${nhID}`, payload);
};



