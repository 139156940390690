import { IMenuState } from '../../interfaces/menu';

interface IAction {
    type: string,
    payload?: any
}

const DEFAULT_STATE: IMenuState = {
    Version: 0,
    Menu: []
};

export default (state = DEFAULT_STATE, action: IAction) => {
    switch (action.type) {
        case 'SET_MENU':
            state = {
                ...state,
                ...action.payload
            };
            break;
    }

    return state;
};
