/* eslint-disable */
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { faBars, faXmarkCircle, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as menuApi from '../../api/menu';
import { useInterval } from '../../hooks/use_interval';
import { IMenuItem, IMenuState, IMenuStateTotals } from '../../interfaces/menu';
import { ISessionState } from '../../interfaces/session';
import { logout } from '../../modules/login';
import { setMenu } from '../../store/actions/menu_actions';
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import { setMenuTotals } from '../../store/actions/menu_totals_actions';
import { isDefined } from '../../utils/common';
import { getLinkTotal } from '../../utils/menu';

const menuVersionDelay = 60000;
const menuTotalsDelay = 60000;

interface IProps {
    pathName: string;
}

const Sidebar = ({ pathName }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const isOrgMulti = Session?.SessionType === 'org' || Properties?.length > 1;

    const { Menu, Version }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const menuTotals: IMenuStateTotals = useSelector(
        ({ MenuTotalsState }: any) => MenuTotalsState
    );

    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);

    const [ activeHeaderItem, setActiveHeaderItem ] = useState<IMenuItem>(null);
    const [ activePageItem, setActivePageItem ] = useState<IMenuItem>(null);
    const [ addAnimation, setAddAnimation ] = useState<boolean>(false);
    const [ showNav, setShowNav ] = useState<boolean>(false);
    const [ menuRefresh, setMenuRefresh ] = useState<number>(1);

    const getMenuTotals  = async () => {
        const menuTotals = await menuApi.getMenuTotals();

        if (menuTotals) {
            dispatch(setMenuTotals(menuTotals));
        } 
    };

    useInterval(async () => {
        if (!Menu) {
           return null; 
        }

        const version = await menuApi.getMenuVersion();

        if (version) {
            dispatch(setMenu(version));
        } 

    }, menuVersionDelay);
      
    useEffect(() => {
        const fetchMenu = async () => {
            const menu = await menuApi.getMenu();

            if (menu) {
                dispatch(setMenu(menu));
            }
        }; 
        
        fetchMenu();
    }, [ Version ]);
    
    useEffect(() => {
        if (addAnimation) {
            setTimeout(() => setAddAnimation(false), 500);
        }
     }, [ addAnimation ]);
 
    useInterval(async () => {
        getMenuTotals();
    }, menuTotalsDelay);

    useEffect(() => {
        getMenuTotals();
     }, []);
     
    useEffect(() => {

        const activePageItem = (() => {
            let activeItem: IMenuItem = null;

            
            const findActivePageItem = (menuItems: IMenuItem[]): IMenuItem => {
                const curPathName = location.pathname;
 
                for (let idx = 0; idx < menuItems.length; idx ++) {
                    const menuItem = menuItems[idx];

                    if (menuItem.Route === curPathName) {
                        activeItem = menuItem;
                    }

                    if (!activeItem && menuItem.Children) {
                        findActivePageItem(menuItem.Children);
                    }
    
                    if (activeItem) {
                        break;
                    }
                }

                return activeItem;
            };
  
            return findActivePageItem(Menu);
        })();

  
        if (activePageItem) {
            setActivePageItem(activePageItem);
            setMenuRefresh(menuRefresh + 1);

            if (showNav) {
                setShowNav(false);
            }
        }
    }, [pathName, Menu]);

    useEffect(() => {
        if (activePageItem) {
            const activeHeaderItem = (() => {
                let activeHeader: IMenuItem = null;
                
                const findActiveHeaderItem = (menuItems: IMenuItem[], headerItem?: IMenuItem): IMenuItem => {
                    for (let idx = 0; idx < menuItems.length; idx ++) {
                        const menuItem = menuItems[idx];
    
                        if (menuItem.Children) {
                            const child = menuItem.Children.find(
                                (child: IMenuItem) => child.PageID === activePageItem?.PageID
                            );

                            if (child) {
                                activeHeader = headerItem || menuItem;
                            } else {
                                findActiveHeaderItem(menuItem.Children, menuItem);
                            }

                            if (activeHeader) {
                                break;
                            }
                        }
                    }
    
                    return activeHeader;
                };
        
                return findActiveHeaderItem(Menu);
            })();

            setActiveHeaderItem(activeHeaderItem);
        }
    }, [activePageItem]);

    const handleLinkClick = () => setShowNav(!showNav);

    const renderMenu = () => {
        const menu =  Menu?.map((item: IMenuItem) => {
            if (!isOrgMulti && item.IsOrgMulti) {
                return null;
            }

            if (item.IsHeader) {             
                return (
                    <li 
                        className={item.PageID === activeHeaderItem?.PageID ? 'active' : ''}
                        key={`menu-item-${item.Slug}`}
                    >
                        { renderMenuItem(item) }
                        { renderChildMenu(item) }
                    </li>
                );
            }

            return (
                <li 
                    role="presentation" 
                    key={`menu-item-${item.Slug}`}
                    className={item.PageID === activePageItem?.PageID ? 'active' : ''}
                >
                    { renderMenuItem(item) }
                </li>
            );
        });

        return (
            <ul role="presentation" key={`root-menu-${menuRefresh}`}>
               { menu }
            </ul>
        );
    };

    const renderChildMenu = (item: IMenuItem): any => {
        if (!item.Children) {
            return null;
        }

        const childMenuItems = item.Children.map((item: IMenuItem) => {
            if (!isOrgMulti && item.IsOrgMulti) {
                return null;
            }

            if (item.IsHeader) {
                return (
                    <li role="presentation" key={`submenu-item-${item.Slug}`}>
                        { renderMenuItem(item) }
                        { renderChildMenu(item) }
                    </li>
                );
            }  

            return renderChildMenuItem(item);
        });

        return (
            <ul role="presentation" key={`submenu-${item.Slug}`}>
                { childMenuItems }
            </ul>
        );
    };

    const renderLink = (item: IMenuItem) => {
        const { MenuText, Route, IsExternal, Slug } = item;

        if (IsExternal) {
            return (
                <a href={Route} target="_blank" rel="noreferrer">
                    { MenuText }
                </a>
            );
        }

        const linkTotal = getLinkTotal(menuTotals, Slug);

        return (
            <NavLink 
                className = {() => (item.PageID === activePageItem?.PageID  ? 'active' : '')}
                to={Route}
                onClick={() => setActivePageItem(item)}
            >
                <span>{ MenuText } { isDefined(linkTotal) ? `(${linkTotal})` : null}</span>
            </NavLink>
        );
    };

    const renderHeading = (item: IMenuItem) => {
        return <span>{ item.MenuText }<FontAwesomeIcon icon={faChevronRight} /></span>;
    };

    const renderMenuItem = (item: IMenuItem) => {
        if (!canViewTestFeatures && item.IsTestFeature) {
            return null;
        }

        return item.Route ? renderLink(item) : renderHeading(item);
    };

    const renderChildMenuItem = (item: IMenuItem) => {
        if (!canViewTestFeatures && item.IsTestFeature) {
            return null;
        }

        return (
            <li className={item.PageID === activePageItem?.PageID ? 'active' : ''} role="presentation" key={`${item.Slug}-submenu-item`}>
                { renderLink(item) }
            </li>
        );
    };

    return (
        <div className="app-menu d-flex order-lg-2 nav">
            <div className="d-lg-none d-flex border-top border-bottom w-100">
                <div className="d-flex align-items-center d-lg-none ms-2 pe-2 border-end" title="Show sidebar menu"  onClick={handleLinkClick}>
                    <div className="btn-icon btn-active-color-primary px-3" id="kt_app_sidebar_mobile_toggle">
                        <FontAwesomeIcon icon={faBars} color="#000" className="h-20px" />
                    </div>
                </div>
                <a className="logo px-2 d-flex align-items-end text-secondary" href="/">
                    <img className="h-50px py-3" src="/logo.svg" alt="Portal" />
                    <small>Portal</small>
                </a>
                <div className="d-flex ms-auto align-items-center d-lg-none px-4 border-start" title="User">
                    <a 
                        href='#'
                        onClick={() => {
                            logout();      
                            navigate(0);      
                        }}
                    >
                        <FontAwesomeIcon icon={faRightFromBracket} className="h-20px" />
                    </a>
                </div>
            </div>

            <nav className={`global-nav ${showNav ? 'open' : ''}`}>
                <button role="button" onClick={handleLinkClick}>
                    <FontAwesomeIcon icon={faXmarkCircle} />
                </button>
                { renderMenu() }
            </nav>
        </div>
    );
};

export default Sidebar;
