import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { yesNoOptions } from '../../../helpers';
import { SectionHeading } from '../../components/section_heading';

const InsuranceProvidersAcceptedTab = () => {
    const { fields } = useFieldArray({
        name: 'InsuranceTypes'
    });

    return (
        <div className="mt-4">
            <SectionHeading
                title="Insurance Providers Accepted"
            />
            <div className="ps-2">
                {fields?.map((field: any, index: number) => (
                    <div key={field.key}>
                        <FormRadioGroup
                            inline={true}
                            name={`InsuranceTypes.[${index}].IsActive`}
                            options={yesNoOptions}
                            groupLabel={field.Name}
                            inlineLabelWidth="large"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export { InsuranceProvidersAcceptedTab };
