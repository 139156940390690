
import { 
    IServiceTotals,
    IPropertyServiceExtras,
    IPropertyService,
    IUpdatePropertyServicesPayload,
    IPropertyServiceTotalParams,
    IPropertyServiceCustomExtra
} from '../interfaces/service';
import { secureHttp } from '../utils/request';

export const getPropertyServiceExtras = async (nhID: number): Promise<IPropertyServiceExtras[]> => {

    try {
        const { data: { data } } = await secureHttp.get(`/service/extras/${nhID}`);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }

        return null;
    }
};

export const getPropertyServiceCustomExtras = async (nhID: number): Promise<IPropertyServiceCustomExtra[]> => {

    try {
        const { data: { data } } = await secureHttp.get(`/service/extras/custom/${nhID}`);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }

        return null;
    }
};

export const getPropertyServices = async (nhID: number): Promise<IPropertyService[]> => {

    try {
        const { data: { data } } = await secureHttp.get(`/service/resource/${nhID}`);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }

        return null;
    }
};

export const getPropertyServiceTotals = async (
    params: IPropertyServiceTotalParams
): Promise<[IServiceTotals[], number]> => {
    try {
        const { data: { 
            data, 
            totalFilteredRows 
        } } = await secureHttp.get('/service/totals', { params });

        return [ data, totalFilteredRows ];
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }

        return null;
    }
};

export const updatePropertyServices = async (
    nhID: number,
    payload: IUpdatePropertyServicesPayload
): Promise<void> => {
    try {
        await secureHttp.patch(`/service/${nhID}`, payload);
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};