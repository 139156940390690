import React  from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight, faArrowDown, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

// interface IProps {
//     memberIDs: number[];
//     groupID: number;
// }

// const WebsiteWidget = ({ memberIDs, groupID }: IProps) => {
    const WebsiteWidget = () => {
        return (<></>);  
    // return (<>
    
    //     <div className="widget widget-fullwidth">
    //         <div className="row widget-body">
    //             <div className="col-12">
    //                 <div className="card">
    //                     <div className="card-header pb-4 border-bottom d-flex flex-column flex-lg-row justify-content-between">
    //                         <h3 className="card-title">Customize Vertical Review Widget</h3>
    //                         <div className="customize-widget-tabs mt-3 mt-lg-0">
    //                             <button className="btn btn-primary me-2">Vertical Widget</button>
    //                             <button className="btn btn-secondary">Horizontal Widget</button>
    //                         </div>
    //                     </div>
    //                     <div className="card-body p-0">
    //                         <div className="customize-widget customize-widget-vertical">
    //                             <div className="customize-widget-form">
    //                                 <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                     <label htmlFor="" className="col-sm-3 col-form-label">Setting</label>
    //                                     <div className="col-sm-9">
    //                                         <select className="form-control" id="">
    //                                             <option>1</option>
    //                                             <option>2</option>
    //                                         </select>
    //                                     </div>
    //                                 </div>
    //                                 <fieldset className="row mb-4 pb-4 border-bottom">
    //                                     <legend className="col-form-label col-sm-3 pt-0">Review Content</legend>
    //                                     <div className="col-sm-9">
    //                                         <div className="form-check">
    //                                             <input className="form-check-input" type="radio" name="" id="" value="" checked />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Review Snippet
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="radio" name="" id="" value="" />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Full Review
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="radio" name="" id="" value="" />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Full Review and Reply
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="radio" name="" id="" value="" />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 No Reviews
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-4">
    //                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Show Review Score
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Show Average Rating Stars for each Review
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="checkbox" id="" />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Show &quot;Write A Review&quot; Link
    //                                             </label>
    //                                         </div>
    //                                         <div className="form-check mt-2 disabled">
    //                                             <input className="form-check-input" type="checkbox" id="" disabled />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Show &quot;Write A Review&quot; Button
    //                                             </label>
    //                                         </div>
    //                                     </div>
    //                                 </fieldset>
    //                                 <div className="row align-items-center mb-4">
    //                                     <label htmlFor="" className="col-sm-3 col-form-label">Widget Width</label>
    //                                     <div className="col-sm-3">
    //                                         <div className="input-group">
    //                                             <input type="text" className="form-control" />
    //                                             <span className="input-group-text">px</span>
    //                                         </div>
    //                                     </div>
    //                                     <div className="col-sm-3 mt-3 mt-sm-0">
    //                                         <div className="form-check">
    //                                             <input className="form-check-input" type="checkbox" id="" />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Auto
    //                                             </label>
    //                                         </div>
    //                                     </div>
    //                                     <div className="form-text mt-2 col-sm-9 offset-sm-3">
    //                                         Enter min width of 240px / max width 1020px, or tick &apos;auto&apos; for the width to adapt (i.e. responsive) to whatever the widget container size is.
    //                                     </div>
    //                                 </div>
    //                                 <div className="row pt-2">
    //                                     <div className="col-12">
    //                                         <button className="btn btn-sm btn-secondary small"><FontAwesomeIcon icon={faArrowDown} className="me-2" /> More Customization Options</button>
    //                                         <div className="customize-widget-form-reveal">
    //                                             <div className="mt-4 py-4 px-4 rounded border">
    //                                                 <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                                     <label htmlFor="" className="col-sm-9 col-form-label">Show Reviews with a minimum Overall Rating of</label>
    //                                                     <div className="col-sm-3">
    //                                                         <select className="form-control" id="">
    //                                                             <option>0</option>
    //                                                             <option>1</option>
    //                                                             <option>2</option>
    //                                                             <option>3</option>
    //                                                             <option>4</option>
    //                                                             <option>5</option>
    //                                                         </select>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-4 pb-4 border-bottom">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show all Ratings for each Review
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                                     <label htmlFor="" className="col-sm-9 col-form-label">Text Size</label>
    //                                                     <div className="col-sm-3">
    //                                                         <select className="form-control" id="">
    //                                                             <option>Default</option>
    //                                                             <option>Large</option>
    //                                                             <option>Extra Large</option>
    //                                                             <option>Extra Extra Large</option>
    //                                                         </select>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                                     <label htmlFor="" className="col-sm-9 col-form-label">Number of Recent Reviews</label>
    //                                                     <div className="col-sm-3">
    //                                                         <select className="form-control" id="">
    //                                                             <option>1</option>
    //                                                             <option>2</option>
    //                                                             <option>3</option>
    //                                                             <option>4</option>
    //                                                             <option>5</option>
    //                                                             <option>6</option>
    //                                                             <option>7</option>
    //                                                             <option>8</option>
    //                                                             <option>9</option>
    //                                                             <option>10</option>
    //                                                         </select>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-4 pb-4 border-bottom">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show Pagination Links To Show Additional Pages
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="form-text col-12">
    //                                                         Maximum total of 50 Reviews shown on all pages.
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                             Show carehome.co.uk Logo
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show Strap-Line Below carehome.co.uk Logo
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show Setting Name headers and carehome.co.uk copywrite footer
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Add a &apos;nofollow&apos; tag to links
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-4 pb-4 border-bottom">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Include Border
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row align-items-center mt-3">
    //                                                     <label htmlFor="" className="col-sm-9 col-form-label">Background Color</label>
    //                                                     <div className="col-sm-3">
    //                                                         <select className="form-control" id="">
    //                                                             <option>Faded</option>
    //                                                             <option>White</option>
    //                                                         </select>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className="customize-widget-preview">
    //                                 <div className="sticky-xl-top">
    //                                     <h4 className="mt-1">Vertical Preview</h4>
    //                                     <div>Widget</div>
    //                                     <span className="customize-widget-code d-block border-top pt-4">
    //                                         <p>
    //                                             To view the widget code please agree to the <a href="#">aplaceformom.com Terms & Conditions</a> regarding widget usage.
    //                                         </p>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 I agree
    //                                             </label>
    //                                         </div>
    //                                         <div className="customize-widget-snippet mt-4">
    //                                             <p>
    //                                                 <strong>
    //                                                     Please copy the below code and embed in your website to activate the widget:
    //                                                 </strong>
    //                                             </p>
    //                                             <code className="mt-2">
    //                                                 snippet code goes here
    //                                             </code>
    //                                         </div>
    //                                     </span>
    //                                 </div>  
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="card-footer border-top d-flex">
    //                         <FontAwesomeIcon size='lg' color='#4568DC' icon={faCircleInfo} className="me-2" />
    //                         <p className="m-0 text-start form-text small">
    //                             If you are unsure how to embed this widget on your website please either send a link to this page to your web developers, or create your custom widget below and email your web developer the code.
    //                         </p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    
    //     <div className="widget widget-fullwidth">
    //         <div className="row widget-body">
    //             <div className="col-12">
    //                 <div className="card">
    //                     <div className="card-header pb-4 border-bottom d-flex flex-column flex-lg-row justify-content-between">
    //                         <h3 className="card-title">Customize Horizontal Review Widget</h3>
    //                         <div className="customize-widget-tabs mt-3 mt-lg-0">
    //                             <button className="btn btn-secondary me-2">Vertical Widget</button>
    //                             <button className="btn btn-primary">Horizontal Widget</button>
    //                         </div>
    //                     </div>
    //                     <div className="card-body p-0">
    //                         <div className="customize-widget customize-widget-horizontal">
    //                         <div className="customize-widget-form">
    //                         <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                     <label htmlFor="" className="col-sm-3 col-form-label">Setting</label>
    //                                     <div className="col-sm-9">
    //                                         <select className="form-control" id="">
    //                                             <option>1</option>
    //                                             <option>2</option>
    //                                         </select>
    //                                     </div>
    //                                 </div>
    //                                 <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                     <label htmlFor="" className="col-sm-3 col-form-label">Max Number of Recent Reviews</label>
    //                                     <div className="col-sm-9">
    //                                         <select className="form-control" id="">
    //                                             <option>50</option>
    //                                             <option>25</option>
    //                                             <option>10</option>
    //                                             <option>5</option>
    //                                         </select>
    //                                     </div>
    //                                 </div>
    //                                 <div className="row mb-4">
    //                                     <div className="col-12">
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 Show &quot;Write A Review&quot; Link
    //                                             </label>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="row pt-2">
    //                                     <div className="col-12">
    //                                         <button className="btn btn-sm btn-secondary small"><FontAwesomeIcon icon={faArrowDown} className="me-2" /> More Customization Options</button>
    //                                         <div className="customize-widget-form-reveal">
    //                                             <div className="mt-4 py-4 px-4 rounded border">
    //                                                 <div className="row align-items-center mb-4 pb-4 border-bottom">
    //                                                     <label htmlFor="" className="col-sm-9 col-form-label">Show Reviews with a minimum Overall Rating of</label>
    //                                                     <div className="col-sm-3">
    //                                                         <select className="form-control" id="">
    //                                                             <option>0</option>
    //                                                             <option>1</option>
    //                                                             <option>2</option>
    //                                                             <option>3</option>
    //                                                             <option>4</option>
    //                                                             <option>5</option>
    //                                                         </select>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="radio" id="" checked />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show carehome.co.uk Logo &amp; Review Score
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="radio" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Review Score only
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3 pb-4 border-bottom">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="radio" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Logo only
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show Overall Rating Stars for each Review
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row mb-3">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Show shaded background behind each Review
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="row">
    //                                                     <div className="col-12">
    //                                                         <div className="form-check mt-2">
    //                                                             <input className="form-check-input" type="checkbox" id="" />
    //                                                             <label className="form-check-label" htmlFor="">
    //                                                                 Add a &quot;nofollow&quot; tag to links
    //                                                             </label>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className="customize-widget-preview">
    //                                 <div>
    //                                     <h4 className="mt-1">Horizontal Preview</h4>
    //                                     <div>Widget</div>
    //                                     <span className="customize-widget-code d-block border-top pt-4">
    //                                         <p>
    //                                             To view the widget code please agree to the <a href="#">aplaceformom.com Terms & Conditions</a> regarding widget usage.
    //                                         </p>
    //                                         <div className="form-check mt-2">
    //                                             <input className="form-check-input" type="checkbox" id="" checked />
    //                                             <label className="form-check-label" htmlFor="">
    //                                                 I agree
    //                                             </label>
    //                                         </div>
    //                                         <div className="customize-widget-snippet mt-4">
    //                                             <p>
    //                                                 <strong>
    //                                                     Please copy the below code and embed in your website to activate the widget:
    //                                                 </strong>
    //                                             </p>
    //                                             <code className="mt-2">
    //                                                 snippet code goes here
    //                                             </code>
    //                                         </div>
    //                                     </span>
    //                                 </div>  
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="card-footer border-top d-flex">
    //                         <FontAwesomeIcon size='lg' color='#4568DC' icon={faCircleInfo} className="me-2" />
    //                         <p className="m-0 text-start form-text small">
    //                             If you are unsure how to embed this widget on your website please either send a link to this page to your web developers, or create your custom widget below and email your web developer the code.
    //                         </p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    
    
    
    
    
    
    // </>);
};

export default WebsiteWidget;