import { sortBy } from 'lodash';
import { ITourDefaultValues, ITourFormData, TourType } from './types';
import Swal from 'sweetalert2';
import { showToast, ToastType } from '../../../utils/toast';

type WeekdayMap = typeof weekDays;

const weekDays = {
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
    1: 'Sunday'
} as const;


const mapToDaysOfWeek = (daysString: string) => {
    const days =
        daysString
            ?.split(',')
            .sort()
            .map((digit) => {
                const dayIndex = Number(digit) as keyof WeekdayMap;
                return weekDays[dayIndex];
            }) || [];

    return days;
};


const daysOptions = (() => {
    return Object.entries(weekDays).map(([weekDayNumber, dayText]) => ({
        label: dayText,
        value: Number(weekDayNumber)
    }));
})();

const timeOptions = (() => {
    const timeDiff = 16;
    const Hour = 60 * 60 * 1000;
    const startTimestamp = Date.parse('1970-1-1 06:00:00');
    const timeObject = [];
    const timeLabelConfig: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };
    const timeValueConfig: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric'
    };

    for (let hours = 0; hours <= timeDiff; hours++) {
        const date = new Date(startTimestamp + hours * Hour);
        timeObject.push({
            label: date
                .toLocaleTimeString('en-GB', timeLabelConfig)
                .toUpperCase(),
            value: date.toLocaleTimeString('en-GB', timeValueConfig)
        });
    }
    return timeObject;
})();

const prepareDataForForm = (data: ITourDefaultValues): ITourFormData => {
    return {
        ...data,
        TourTypeID: data.TourTypeID ? String(data.TourTypeID) as TourType : null,
        PhysicalTourDays: data?.PhysicalTourDays?.split(',').map(Number) || [],
        VirtualTourDays: data?.VirtualTourDays?.split(',').map(Number) || []
    };
};

const prepareFormValuesForSubmission = (nhID: number, formValues: ITourFormData): ITourDefaultValues => {
    const {
        IsVerified = false,
        IsOrganization,
        PhysicalDescription,
        PhysicalTourDays,
        PhysicalTourEndTime,
        PhysicalTourStartTime,
        TourAvailabilityID,
        TourTypeID,
        VirtualDescription,
        VirtualTourDays,
        VirtualTourEndTime,
        VirtualTourStartTime,
    } = formValues;

    const tourPayLoad: any = {
        IsVerified,
        IsOrganization,
        NHID: nhID,
        PhysicalDescription: null,
        PhysicalTourDays: [],
        PhysicalTourEndTime: null,
        PhysicalTourStartTime: null,
        TourAvailabilityID,
        TourTypeID,
        VirtualDescription: null,
        VirtualTourDays: [],
        VirtualTourEndTime: null,
        VirtualTourStartTime: null,
    };

    switch (formValues.TourTypeID) {
        case TourType.Both_onsite_remote:
            return {
                ...tourPayLoad,
                PhysicalDescription,
                PhysicalTourDays,
                PhysicalTourEndTime,
                PhysicalTourStartTime,
                VirtualDescription,
                VirtualTourDays,
                VirtualTourEndTime,
                VirtualTourStartTime,
            };
        case TourType.Onsite:
            return {
                ...tourPayLoad,
                PhysicalDescription,
                PhysicalTourDays,
                PhysicalTourEndTime,
                PhysicalTourStartTime,
            };
        case TourType.Remote:
            return {
                ...tourPayLoad,
                VirtualDescription,
                VirtualTourDays,
                VirtualTourEndTime,
                VirtualTourStartTime,
            };
        default:
            return {
                ...tourPayLoad
            };
    }
};

const moveOrgToTopRow = (rows: ITourDefaultValues[]) => {
    return sortBy(rows, ({ IsOrganization }) => IsOrganization ? 0 : 1);
};

const failedToSaveTourError = () => Swal.fire({
    title: 'Failed to save tour updates',
    text: 'Please try again',
    icon: 'error'
});

const displaySuccessToast = () => {
    showToast('Care Offered details successfully updated. Please allow up to 60 minutes for the changes to show on the website.', ToastType.Success);
};

export {
    daysOptions,
    displaySuccessToast,
    failedToSaveTourError,
    mapToDaysOfWeek,
    moveOrgToTopRow,
    prepareDataForForm,
    prepareFormValuesForSubmission,
    timeOptions,
    weekDays
};
