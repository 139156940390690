export enum FilterType {
    Group = 'group',
    Member = 'member',
    User = 'user',
    String = 'string',
    Number = 'number',
    Showing = 'showing',
    YesNo = 'yesno',
    DateFromUTC = 'datefromutc',
    DateToUTC = 'datetoutc',
    LabelValue = 'labelvalue',
    ToEmail = 'toemail',
    FromEmail = 'fromemail',
    FromEmailName = 'fromemailname',
    SentEmailStatus = 'sentemailstatus',
    Rating = 'rating',
    LeadID = 'leadid',
    Range = 'range',
    StringArray = 'stringArray',
    Boolean = 'boolean'
}

export interface IFilterSet {
    Key: string;
    Type: FilterType;
    FilterFormLabel: string;
    FilterPropName: string;
    Value?: any;
    PropFormatter?: any;
    TextFormatter?: any;
    FormFieldFormatter?: any;
    Options?: any;
    DefaultValue?: any;
    Hidden?: boolean
}

export class FilterSet implements IFilterSet {
    public Key: string;
    public Type: FilterType;
    public FilterFormLabel: string;
    public FilterPropName: string;
    public FormFieldFormatter: any;
    public Options: any;
    public PropFormatter: any;
    public TextFormatter: any;
    public Value: any;
    public DefaultValue: unknown;

    /**
     * @param {string} key - A unique name that identifies the filter within the filter collection
     * @param {FilterType} type - The type of filter
     * @param {string} filterFormLabel - The descriptor of the filter. This is what's shown by the ActiveFilters component when showing the filters
     * that are currently in effect
     * @param {string} filterPropName - The name given to the URL query param for the filter value
     * @param defaultValue - The value part of the default of the filter. To re-iterate, this is the VALUE (not the entire struct) of the default
     * filter option
     */
    constructor(key: string, type: FilterType, filterFormLabel: string, filterPropName: string, defaultValue?: any) {
        this.Key = key;
        this.Type = type;
        this.FilterFormLabel = filterFormLabel;
        this.FilterPropName = filterPropName;
        this.DefaultValue = defaultValue;

        if (defaultValue !== undefined) {
            this.Value = defaultValue;
        }
    }
}

export interface INotEnteredOption {
    showNotEnteredOption: boolean;
    notEnteredLabel?: string;
}
