import React, { FC } from 'react';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { NoResultsPlaceholder } from '../../components';
import { DraggableMemberCard } from './draggable_member_card';

interface Props {
    teamMembers: any[];
    onDragEnd: OnDragEndResponder;
    propertyId: number;
    disabled: boolean;
}

const DroppableMemberCardArea: FC<Props> = ({
    disabled = false,
    teamMembers,
    onDragEnd,
    propertyId
}) => {
    const draggableCards = teamMembers?.map(
        (member: MeetTheTeamMemberType, index: number) => (
            <DraggableMemberCard key={member.id} index={index} {...member} />
        )
    );

    const dropArea = (
        <Droppable
            isDropDisabled={disabled}
            droppableId={`meet-the-team-${propertyId}`}
            direction="horizontal"
        >
            {(provided) => (
                <div className="px-4 pb-4">
                    <div
                        className="bg-white card d-flex align-items-start flex-row py-3 overflow-x-auto"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {draggableCards}
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {teamMembers?.length ? dropArea : <NoResultsPlaceholder />}
        </DragDropContext>
    );
};

export { DroppableMemberCardArea };
