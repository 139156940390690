import React, { CSSProperties, FC } from 'react';

interface Props {
    imgUrl?: string;
    style?: CSSProperties;
    alt?: string;
}
const ProfileImage: FC<Props> = ({ imgUrl, style, alt }) => {
    return (
        <div style={style}>
            <img
                alt={alt}
                className="object-fit-cover d-block w-100 h-100"
                src={imgUrl || '/images/profile-image-placeholder.png'}
            />
        </div>
    );
};

export { ProfileImage };
