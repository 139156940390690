import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearSession } from '../../modules/login';
import { ENV } from '../../config/keys';
import _ from 'lodash';
import { NursingHomesCard } from './components/nursing_homes_card';
import { Form } from '../../components/common/form';
import { FormInput } from '../../components/common/form_input';
import { checkAccountSchema, loginSchema } from './login_form.validation';
import { DevelopmentAccountLoginSelect } from './components/development_account_login_select';
import { useLoginMutations } from './hooks/useLoginMutations';
import { ErrorMessage as CustomErrorMessage } from './components/error_message';
import { FormRecaptchaInput } from './components/form_recaptcha_input';
import { FormActions } from './components/form_actions';

type FormState = 'CHECK_ACCOUNT' | 'LOGIN' | 'PENDING_ACCOUNT_ACTIVATION';

const LoginForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state: any = location.state;

    const from = _.get(state, 'from', null);
    const redirectToPath = from && from !== '/login' ? from : '/';

    const [formState, setFormState] = useState<FormState>('CHECK_ACCOUNT');
    const email = useRef<string>('');

    const {
        login,
        checkAccount,
        error,
        isError: isServerError,
        isLoading,
        reset
    } = useLoginMutations({
        onLoginSuccess: () => {
            navigate(redirectToPath);
        },
        onCheckAccountSuccess: (data) => {
            if (data?.isActionRequired) {
                setFormState('PENDING_ACCOUNT_ACTIVATION');
            } else {
                setFormState('LOGIN');
            }
        }
    });

    useEffect(() => {
        clearSession();
    }, []);

    useEffect(() => {
        if (isServerError) {
            reset();
        }
    }, [formState]);

    const resetServerError = () => {
        if (isServerError) {
            reset();
        }
    };

    const loginWithDevAccount = (email: string) => {
        login({
            email,
            password: 'password'
        });
    };

    const handleCheckAccount = async (values: any) => {
        if (values.devAccountEmail) {
            loginWithDevAccount(values.devAccountEmail);
        } else {
            email.current = values.email;
            await checkAccount(values.email);
        }
    };

    const checkAccountDefaultValues: any = {
        email: email.current,
        devAccountEmail: null
    };

    const checkAccountActiveForm = (
        <Form
            key="check-account-step"
            onSubmit={handleCheckAccount}
            validationSchema={checkAccountSchema}
            defaultValues={checkAccountDefaultValues}
            onChange={resetServerError}
        >
            {ENV !== 'production' && (
                <DevelopmentAccountLoginSelect name="devAccountEmail" />
            )}
            <FormInput
                name="email"
                autoComplete="username"
                label="Email"
                inline={false}
                autoFocus={true}
            />
            {isServerError && (
                <CustomErrorMessage message="Please enter a valid email." />
            )}
            <FormActions isLoading={isLoading} buttonText="Continue" />
        </Form>
    );

    const handleLogin = async (values: any) => {
        if (values.devAccountEmail) {
            loginWithDevAccount(values.devAccountEmail);
        } else {
            await login(values);
        }
    };

    const loginDefaultValues: any = {
        email: email.current,
        devAccountEmail: null,
        password: '',
        reCaptcha: ''
    };

    const editEmailButton = (
        <button
            style={{ all: 'unset' }}
            type="button"
            className="text-primary cursor-pointer"
            onClick={() => setFormState('CHECK_ACCOUNT')}
        >
            Edit
        </button>
    );

    const loginForm = (
        <Form
            onChange={resetServerError}
            key="login-step"
            onSubmit={handleLogin}
            validationSchema={loginSchema}
            defaultValues={loginDefaultValues}
        >
            {ENV !== 'production' && (
                <DevelopmentAccountLoginSelect name="devAccountEmail" />
            )}
            <FormInput
                name="email"
                label="Email"
                autoComplete="username"
                inline={false}
                disabled={true}
                inputAction={editEmailButton}
                showErrorStyle={isServerError}
            />
            <FormInput
                autoFocus={true}
                autoComplete="current-password"
                name="password"
                label="Password"
                type="password"
                inline={false}
                showErrorStyle={isServerError}
            />
            {isServerError && (
                <CustomErrorMessage
                    message={_.get(error, 'data.data.UserMsg')}
                />
            )}
            <FormRecaptchaInput name="reCaptcha" />
            <FormActions isLoading={isLoading} />
        </Form>
    );

    const inactiveAccountInstruction = (
        <div className="card-body">
            <p>
                Your account has not yet been activated, a new account
                activation link has just been emailed to{' '}
                <span className="fw-bold">{email.current}</span>.{' '}
            </p>
            <p className='mt-2'>
                Please check your inbox or spam folder, and click on the link in
                the email to activate your account.
            </p>
        </div>
    );

    const content = {
        LOGIN: loginForm,
        PENDING_ACCOUNT_ACTIVATION: inactiveAccountInstruction,
        CHECK_ACCOUNT: checkAccountActiveForm
    };

    return <NursingHomesCard>{content[formState]}</NursingHomesCard>;
};

export default LoginForm;
