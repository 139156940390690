import React, { FC } from 'react';

const NoResultsPlaceholder: FC = () => {
    return (
        <div className="card-body">
            <span className="msg msg-error">
                No team members uploaded
            </span>
        </div>
    );
};

export { NoResultsPlaceholder };
