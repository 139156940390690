import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import { sendPasswordResetEmail } from '../../api/login';
import { LoadingSpinner } from '../../components/common/loading_spinner';

interface FormInputs {
    EmailAddress: string;
    ReCaptcha: string;
}

const PasswordResetEmail = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<FormInputs>();

    const onSubmit = async (data: any) => {
        const { EmailAddress } = data;

        setLoading(true);

        try {
            await sendPasswordResetEmail(EmailAddress);
            setLoading(false);
            navigate('/passwordresetconfirm', { state: { EmailAddress } });
        } catch(err) {
            console.log('sendPasswordResetEmail failed');
            console.log(err);
            navigate('/login');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        { loading ? <LoadingSpinner show={true}/> : null }
        <div className="container-fluid public public__login">
            <div className="row pt-md-4">
                <div className="col-12">
                    <div className="widget mt-5">
                        <div className="widget-body">
                            <div className="card">
                                <div className="card-header border-bottom">
                                    <a href="/" className="d-flex flex-row align-items-end text-secondary">
                                        <img src="/logo.svg" height="30px" className="logo-large" alt="Portal" />
                                        <span className="d-block ms-3">Portal</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <form className="kt-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="kt-portlet__body p-0">
                                            <p className="mb-3">Please enter your email address to reset your password for your NursingHomes.com Portal.</p>
                                            <div className="form-group mb-3">
                                                <label htmlFor="email" className="mb-2">Email Address</label>
                                                <input
                                                    {
                                                        ...register('EmailAddress', {
                                                            required: 'Please enter your Email Address'
                                                        })
                                                    }
                                                    type="email"
                                                    id="email"
                                                    className={`form-control ${errors.EmailAddress ? 'is-invalid' : ''}`}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="EmailAddress"
                                                    render={({ message }: any) => <span className="form-error">{message}</span>}
                                                />
                                            </div>
                                            {/* <div className="form-group">
                                                <ReCAPTCHA
                                                    {
                                                        ...register('ReCaptcha', {
                                                            required: 'Please complete the reCAPTCHA'
                                                        })
                                                    }
                                                    sitekey="6Lcbpe0UAAAAAHsJsXEcaUjIKs6JSeDdGa3lCgL9"
                                                    onChange={(value: string) => setValue('ReCaptcha', value, { shouldValidate: true })}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="ReCaptcha"
                                                    render={({ message }: any) => <span className="form-error">{message}</span>}
                                                />
                                            </div> */}
                                            <div className="kt-portlet__foot mt-4">
                                                <div className="kt-form__actions">
                                                    <div className="row align-items-center">
                                                        <div className="col-12 col-sm-6">
                                                            <button type="submit" className="btn btn-primary">
                                                                Reset Password
                                                            </button>
                                                        </div>
                                                        <div className="kt-form__actions mt-3 mt-md-0 text-sm-end col-12 col-sm-6">
                                                            <Link to={{
                                                                pathname: '/login'
                                                            }}> Return to login
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default PasswordResetEmail;