import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import { 
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailPayload,
    IPropertyInsuranceTypesPayloadItem,
} from '../../../interfaces/property';
import * as nhAPI from '../../../api/property';
import { updateProfileCompleteness } from '../../../utils/profile_completeness';
import { Toast, ToastType, showToast } from '../../../utils/toast';
import coreDetailsFormSchema from './property_core_details_form.validation';
import * as formCommon from './property_core_detail_cost_funding_form common';

interface ICoreDetailFormProps {
    nhID: number
}

const PropertyCoreDetailsForm = ({ nhID }: ICoreDetailFormProps) => {
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ isEdit, setIsEdit] = useState<boolean>(false);
    const [ ,setInsuranceTypes ] = useState<IPropertyCoreDetailInsuranceType[]>([]);

    const onSubmit = async (e: any) => {
        const { ...coreDetails } = e;

        const insuranceTypes: IPropertyInsuranceTypesPayloadItem[] = [];
        Object.keys(coreDetails).forEach((key: string) => {
            if (key.startsWith('InsuranceType')) {
                const propertyInsuranceTypeID = Number(key.split('_')[1]);
                const isActive = coreDetails[key];

                if (isActive) {
                    insuranceTypes.push({
                        PropertyInsuranceTypeID: propertyInsuranceTypeID,
                        IsActive: isActive === '1' ? true : false
                    });                    
                }

                delete coreDetails[key];
            }
        });

        delete coreDetails['LastUpdated'];
        delete coreDetails['CoreDetailsLastUpdated'];
        delete coreDetails['CostFundingLastUpdated'];

        const payload: IPropertyCoreDetailPayload = {
            ...coreDetails,
            InsuranceTypes: insuranceTypes.length ? insuranceTypes : null
        };

        setIsLoading(true);

        try {
            await (nhAPI as any)[`${isEdit ? 'update' : 'create'}PropertyCoreDetailData`](nhID, payload);
            showToast(`Care Offered details successfully ${isEdit ? 'updated' : 'created'}. Please allow up to 60 minutes for the changes to show on the website.`, ToastType.Success);

            initForm();
                
            window.scrollTo(0, 0);
        } catch(err) {
            showToast('An error occurred saving Core Details.', ToastType.Error);
        } finally {
            if (!isEdit) {
                setIsEdit(true);
            }
            
            setIsLoading(false);
            updateProfileCompleteness();
        }
    };

    const getFormDefaults = async (): Promise<any> => {

        setIsLoading(true); 
        const [formDefaults, insuranceTypes] = await formCommon.getFormDefaults(nhID);

        if (insuranceTypes) {
            setInsuranceTypes(insuranceTypes); 
        }

        setIsLoading(false);

        if (formDefaults.NHID) {
            setIsEdit(true);

        }

        return formDefaults;
    };

    const { 
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        getValues,
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(coreDetailsFormSchema), 
        defaultValues:  async () => await getFormDefaults(),
    });
    
    const initForm = async () => {
        setIsEdit(false);
        const defaults = await getFormDefaults();
        reset({...defaults});
    };

    useEffect(() => {
       initForm();
    }, [ nhID ]);

    const watchMinAgeRequirement  = watch('MinAgeRequirement');
    const watchPediatricMaxAgeRequirement = watch('PediatricMaxAgeRequirement');
    const watchRespiteMinStayRequirement = watch('RespiteMinStayRequirement');
    const watchAdmitPediatricResidents = watch('AdmitPediatricResidents');
    const watchAdmitPediatricResidentsSelected = watchAdmitPediatricResidents === '1';

    useEffect(() => {
        if (watchMinAgeRequirement !== '1') {
            setValue('MinAge', null);
            trigger('MinAge');
        }

        if (watchPediatricMaxAgeRequirement !== '1') {
            setValue('PediatricMaxAge', null);
            trigger('PediatricMaxAge');
        }

        if (watchRespiteMinStayRequirement !== '1') {
            setValue('RespiteMinStay', null);
            trigger('RespiteMinStay');
        }
    }, [watchMinAgeRequirement, watchPediatricMaxAgeRequirement, watchRespiteMinStayRequirement]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            
            {isLoading ? <LoadingSpinner /> : null}

            <Toast></Toast>

            <div>

                <div className="widget">
                    <div className="widget-body">
                        <div className="card mb-9 mb-md-0" data-card="Form" data-columns="1">
                            <div className="card-header">
                                <h4>Care Offered</h4>
                            </div>
                            <div className="card-body my-4">
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Long term care', Key: 'LongTermCare', IncludedInProfileCompleteness: true },
                                        { Label: 'Short term care', Key: 'ShortTermCare', IncludedInProfileCompleteness: true },
                                        { Label: 'Respite care', Key: 'RespiteCare', IncludedInProfileCompleteness: true }
                                    ], false)
                                }

                                { formCommon.renderOptionalDuration(register, errors, setValue, watch, { Label: 'Is there a minimum age requirement?', Key: 'MinAge', DurationUnit: 'years', IncludedInProfileCompleteness: true }, false) }

                                {
                                formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Facility Has Memory Care Unit', Key: 'FacilityHasMemoryCareUnit', IncludedInProfileCompleteness: true },
                                        { Label: 'Facility Has Behavioural Health Unit', Key: 'FacilityHasBehaviouralHealthUnit', IncludedInProfileCompleteness: true }
                                    ], false)
                                }

                                { formCommon.renderText(register, errors, [{ Label: 'Any other speciality/niche services?', Key: 'OtherFacilityTypes' }], true)}
                                { formCommon.renderText(register, errors, [ { Label: 'Which hospitals is the facility affiliated with?', Key: 'AffiliatedHospitals' } ], true) }
                                { formCommon.renderPercent(register, errors, [{ Label: 'Average % of short-term residents per month', Key: 'AvgShortTermResidentsPc', IncludedInProfileCompleteness: true }], false) }
                                { formCommon.renderDuration(register, errors, { Label: 'Average short-term stay duration', Key: 'AvgShortStayDuration', DurationUnit: 'days' }, false) }
                                { formCommon.renderPercent(register, errors, [{ Label: 'Average % of long-term residents per month', Key: 'AvgLongTermResidentsPc', IncludedInProfileCompleteness: true }], false) }
                                { formCommon.renderDuration(register, errors, { Label: 'Average long-term stay duration', Key: 'AvgLongTermStayDuration', DurationUnit: 'months' }, false) }
                            </div>
                        </div>
                        <div className="card"> 
                            <div className="card-header">
                                <h4>Pediatric Residents</h4>
                            </div>
                            <div className="card-body my-4">
                                { formCommon.renderRadioGroup(register, setValue, [ { Label: 'Do you admit pediatric residents?', Key: 'AdmitPediatricResidents', IncludedInProfileCompleteness: true } ], false) }

                                { 
                                    formCommon.renderPercent(register, errors, [{ 
                                        Label: 'Average % of short-term pediatric residents per month', 
                                        Key: 'PediatricAvgShortTermResidentsPc',
                                        IncludedInProfileCompleteness: watchAdmitPediatricResidentsSelected 
                                    }], !watchAdmitPediatricResidentsSelected) }

                                { formCommon.renderDuration(register, errors, { Label: 'Average short-term pediatric stay duration', Key: 'PediatricAvgShortStayDuration', DurationUnit: 'days' }, !watchAdmitPediatricResidentsSelected) }

                                { 
                                    formCommon.renderPercent(register, errors, [{ 
                                        Label: 'Average % of long-term pediatric residents per month', 
                                        Key: 'PediatricAvgLongTermResidentsPc', 
                                        IncludedInProfileCompleteness: watchAdmitPediatricResidentsSelected 
                                    }], !watchAdmitPediatricResidentsSelected) }

                                { formCommon.renderDuration(register, errors, { Label: 'Average long-term pediatric stay duration', Key: 'PediatricAvgLongTermStayDuration', DurationUnit: 'months' }, !watchAdmitPediatricResidentsSelected) }
                                { formCommon.renderOptionalDuration(register, errors, setValue, watch, { Label: 'Is there a maximum age requirement for pediatric residents?', Key: 'PediatricMaxAge', DurationUnit: 'years' }, false) }

                            </div>
                        </div>        

                        <section id="roomtype">
                            <div className="card"> 
                                <div className="card-header">
                                    <h4>Room Type</h4>
                                </div>
                                <div className="card-body my-4">
                                    <div className="row mb-4">
                                        <div className="">
                                        {                  
                                            formCommon.renderRadioGroup(register, setValue, [
                                                { Label: 'Shared', Key: 'RoomTypeShared', IncludedInProfileCompleteness: true },
                                                { Label: 'Private Room', Key: 'RoomTypePrivateRoom', IncludedInProfileCompleteness: true },
                                                { Label: 'Semi Private', Key: 'RoomTypeSemiPrivate', IncludedInProfileCompleteness: true },
                                                { Label: 'Private Suite', Key: 'RoomTypePrivateSuite', IncludedInProfileCompleteness: true },
                                            ], false)
                                        }
                                        </div>
                                    </div>
                                    { formCommon.renderText(register, errors, [{ Label: 'Any other room types?', Key: 'RoomTypeOther' } ]) }
                                </div>
                            </div>     
                        </section>   
                    </div> 
                </div> 
            </div> 
            <div className="d-flex justify-content-center sticky-bottom my-4 pb-4">
                <button 
                    type="submit" 
                    className="btn btn-primary shadow"
                    disabled={!isDirty || isLoading}
                >
                    Update
                </button>
            </div>
        </form>
    );
};

export default PropertyCoreDetailsForm;
