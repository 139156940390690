import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { SectionHeading } from '../../components/section_heading';

const BedAllocationTab = () => {
    const inline = true;

    return (
        <div className="mt-4">
            <SectionHeading title="Bed Allocation" />
            <div className="ps-2">
                <FormRadioGroup
                    inline={inline}
                    name="BedsAllDualLicenced"
                    options={yesNoOptions}
                    groupLabel="All beds dual-licenced for either Medicare or Medicaid?"
                    showResetButton={true}
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inlineLabelWidth="large"
                />
                <FormInput
                    inline={inline}
                    name="BedsMedicaidCount"
                    label="Medicaid bed count"
                    type="number"
                    maxWidth='150px'
                    allowedPattern={onlyNumeric}
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inlineLabelWidth="large"
                />
                <FormInput
                    inline={inline}
                    name="BedsMedicareCount"
                    label="Medicare bed count"
                    type="number"
                    maxWidth='150px'
                    allowedPattern={onlyNumeric}
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inlineLabelWidth="large"
                />
            </div>
        </div>
    );
};

export { BedAllocationTab };
