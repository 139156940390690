import React, { useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { LeadsWidget } from '../../components/dashboard/leads_widget';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

const Leads = () => {
    const [ selNHID, setSelNHID ] = useState<number>(null);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    return (
        <>
            <PageCommunitySelectWrapper
                label="All Leads for"
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            <div className="widget" data-widget="Leads" data-widget-header="0" data-widget-footer="0" data-widget-columns="1">
                <div className="widget-body">
                    <LeadsWidget
                        selNHID={selNHID}
                        showTable={true}
                    />
                </div>
            </div>
        </>
    );
};

export default Leads;
