import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useCommunitySelectHistory = (cbFn: any) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.onbeforeunload = () => {
            navigate(location.pathname, { state: { selNHID: null } });
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, []);


    useEffect(() => {
        const { state } = location;
        cbFn(state?.selNHID || null);

    }, [location]);

    return (NHID: number | null) => {
        navigate(location.pathname, { state: { selNHID: NHID } });
    };
};