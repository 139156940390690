import React from 'react';
import { COMMIT_ID } from '../../config/keys';

function Commit() {

    return (
        <div>
          { COMMIT_ID }
        </div>
    );
  }

export default Commit;