import React, { FC } from 'react';
import { getPropertyTeamMembers } from '../../../../api/meet_the_team';
import { useQuery } from '@tanstack/react-query';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { DroppableMemberCardArea } from './member_cards';
import { useUpdateTeamSequenceOrderMutation } from '../hooks/useUpdateTeamSequenceOrderMutation';

import { LoadingSpinner } from '../components/loading_spinner';
import { columnStyles } from './style';
import { AddTeamMemberModal } from '../components';

const SinglePropertyMembersView: FC<{ nhID: number }> = ({ nhID }) => {
    const queryKey = ['meet-the-team', 'property', nhID];

    const { data = [], isLoading } = useQuery({
        enabled: !!nhID, // don't make the request until nhID is available
        queryKey,
        staleTime: 5000,
        refetchOnWindowFocus: false,
        queryFn: () => getPropertyTeamMembers(nhID)
    });

    const updateTeamSequenceOrderMutation = useUpdateTeamSequenceOrderMutation(
        nhID,
        queryKey
    );

    const handleDragEnd: OnDragEndResponder = async ({
        destination,
        source
    }) => {
        // eslint-disable-next-line
        if (destination?.index == null) return;

        updateTeamSequenceOrderMutation({
            sequence: destination.index + 1, // we want to start sequence count at 1
            memberId: data[source.index].id
        });
    };

    const header = (
        <>
            <div className="card-info-box">
                <div style={columnStyles[0]}>Order</div>
                <div style={columnStyles[1]}>Photo</div>
                <div style={columnStyles[2]}>Full Name</div>
                <div style={columnStyles[3]}>Job title</div>
                <div style={columnStyles[4]}>Year Started</div>
                <div style={columnStyles[5]}>Description</div>
                <div style={columnStyles[6]}>Publish to Website</div>
                <div style={columnStyles[7]}>Actions</div>
            </div>
        </>
    );

    return (
        <div className="card" data-card="Meet the Team">
            <div className="card-header">
                <small>
                    To re-order a Team Member drag-and-drop the entire row to the desired position.
                </small>
                <div className="card-toolbar">
                    <AddTeamMemberModal
                        propertyId={nhID}
                        isInSinglePropertyContext={true}
                    />                        
                </div>
            </div>
            <div className="card-body">
                <div>
                    {header}
                    {isLoading ? (
                        <LoadingSpinner minHeight="200px" />
                    ) : (
                        <DroppableMemberCardArea
                            onDragEnd={handleDragEnd}
                            teamMembers={data}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export { SinglePropertyMembersView };
