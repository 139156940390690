import React, { FC, ReactElement, ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { Button } from '../button';
import cx from 'classnames';

interface Props {
    children?: ReactNode;
    footerActions?: ReactElement;
    isOpen: boolean;
    minHeight?: string;
    onClose: () => void;
    showCloseButtonInHeader?: boolean;
    showFooter?: boolean;
    size?: 'sm' | 'lg' | 'xl';
    tabHeaders?: ReactElement;
    title: ReactNode;
}

const Modal: FC<Props> = (props) => {
    const {
        children,
        footerActions,
        isOpen,
        minHeight = 0,
        onClose,
        showCloseButtonInHeader = true,
        showFooter = true,
        size = 'lg',
        tabHeaders,
        title,
    } = props;

    const closeModalButton: ReactElement = (
        <Button styleType="outline" onClick={onClose}>
            Close
        </Button>
    );

    const headerCloseModalButton: ReactElement = (
        <button style={{ all: 'unset', cursor: 'pointer' }} onClick={onClose}>
            <i className="fa fa-close fs-4 p-2 ms-3" />
        </button>
    );

    const headerBorderStyle: React.CSSProperties = tabHeaders
        ? { borderBottom: 'none' }
        : {};

    const modalContentClassName = cx('custom-modal-content', {
        // if there are tabs - keep the modal content full height so the tabs 
        // don't move about due to different heights of the content
        'vh-100': tabHeaders
    });

    return (
        <BootstrapModal
            show={isOpen}
            onHide={onClose}
            centered
            size={size}
            style={{ overflow: 'hidden' }}
            contentClassName={modalContentClassName}
            
        >
            <BootstrapModal.Header style={headerBorderStyle}>
                <BootstrapModal.Title className='w-100'>{title}</BootstrapModal.Title>
                {showCloseButtonInHeader && headerCloseModalButton}
            </BootstrapModal.Header>
            {tabHeaders}
            <BootstrapModal.Body className="overflow-auto position-relative" id="modal-body" style={{ minHeight }}>
                {children}
            </BootstrapModal.Body>
            {showFooter && (
                <BootstrapModal.Footer>
                    {closeModalButton}
                    {footerActions}
                </BootstrapModal.Footer>
            )}
        </BootstrapModal>
    );
};

export { Modal };
