import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {TableColumn } from 'react-data-table-component';
import DataTable from '../common/data_table';
import { IPropertyServiceTotalParams, IServiceTotals } from '../../interfaces/service';
import { getPropertyServiceTotals } from '../../api/service';
import AppContext from '../../context/app_context';
import { LoadingSpinner } from '../common/loading_spinner';
import { formatAddress } from '../../utils/property';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

import { generalSort } from '../../utils/common';
import { ServicesAndAmenitiesModal } from '../../pages/property_details/property_services_and_amenities/services_and_amenities_modal';
import { useQuery } from '@tanstack/react-query';
import { longFormatDate } from '../../utils/date';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    handleSelect: (nhID: number) => void; 
    profileCompletenessFilterValue: boolean | null;
}

const PropertyServiceTotalsTable = ({ 
    handleSelect, 
    // selNHID, 
    // nhIDs, 
    // orgNHID,
    profileCompletenessFilterValue
}: IProps) => {
    const [sortOrder, setSortOrder] = useState<{column: string, direction: 'ASC' | 'DESC'}>({column: '', direction: 'ASC'});

    // Not sure what the following code was for, didn't seem to be doing anything so I commented it out.
    // const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);

    // const profileCompletenessState: INhPortalScoreItem = useSelector(
    //     ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    // );

    // useEffect(() => {
        
    //     const scoreItem = mapProfileDatatoDisplay(profileCompletenessState, selNHID)
    //         .find((item: IScoreDisplayItem) => item.KeyName === ProfileScoreType.FacilityBaseData);
    //     setScoreItem(scoreItem);

    // }, [ orgNHID, nhIDs, selNHID ]);

    const appContext: any = useContext(AppContext);

    const params: IPropertyServiceTotalParams = {
        ResourceProfileCompleted: profileCompletenessFilterValue
    };

    const { data: propertyServiceTotals, isLoading } = useQuery({
        queryFn: () => getPropertyServiceTotals(params),
        queryKey: ['portal', 'service', 'totals', profileCompletenessFilterValue]
    });

    const sortedTotals = propertyServiceTotals?.[0].sort(generalSort(sortOrder.column, sortOrder.direction)) || [];

    


    const columns = [{
        name: 'Facility Name',
        cell: (row: IServiceTotals) => formatFacility(row),
        sortable: true,
        sortField: 'PropertyName',
        minWidth: appContext.isMobile ? '146px' : '400px',
        id: 'PropertyName'
    }, {
        name: <>Profile <br/>Complete <br/>for Services & Amenities</>,
        cell: (row: IServiceTotals) => formatProfileCompleteness(row),
        sortable: true,
        sortField: 'ProfileCompleteResourceCount',
        width: '150px',
        id: 'ProfileCompleteResourceCount'
    }, {
        name: <>Misc <br/>Services</>,
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'ServiceExtra'),
        sortable: true,
        sortField: 'ServiceExtraCount',
        minWidth: '130px',
        id: 'ServiceExtraCount'
    }, {
        name: 'Health Care Services',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'HealthCareServices'),
        sortable: true,
        sortField: 'HealthCareServicesCount',
        minWidth: '130px',
        id: 'HealthCareServicesCount'
    }, {
        name: 'Activities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'Activities'),
        sortable: true,
        sortField: 'ActivitiesCount',
        minWidth: '130px',
        id: 'ActivitiesCount'
    }, {
        name: 'Community Amenities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'CommunityAmenities'),
        sortable: true,
        sortField: 'CommunityAmenitiesCount',
        minWidth: '130px',
        id: 'CommunityAmenitiesCount'
    }, {
        name: 'Dining',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'Dining'),
        sortable: true,
        sortField: 'DiningCount',
        minWidth: '130px',
        id: 'DiningCount'
    }, {
        name: <>Room <br/>Amenities</>,
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'RoomAmenities'),
        sortable: true,
        sortField: 'RoomAmenitiesCount',
        minWidth: '130px',
        id: 'RoomAmenitiesCount'
    },
    {
        name: 'Last Updated',
        sortable: true,
        sortField: 'LastUpdated',
        width: '120px',
        id: 'LastUpdated',
        cell: (row: IServiceTotals) => <div className='fs-6 mt-4'>{row.LastUpdated ? longFormatDate(row.LastUpdated): 'No updates made'}</div>,
    },
    {
        name: '',
        sortable: false,
        cell: (row: IServiceTotals) => <div className='w-100'>
            <ServicesAndAmenitiesModal row={row} />
        </div>,
        width: '120px',
        id: 'Actions'
    }];

    const formatProfileCompleteness = (row: IServiceTotals) => {
        const complete = row.ProfileCompleteResourceCount === row.ProfileCompleteResourceTotal;

        return (
            <>
                <div className="my-3">
                    <div className="d-flex flex-column align-items-center justify-content-center just fw-bold text-nowrap w-100">
                        {
                            <div>
                                <FontAwesomeIcon 
                                    icon={complete ? faCheck : faXmark} 
                                    className={`${complete ? 'text-success' : 'text-danger'} fs-3`}
                                /> 
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    };

    const formatServiceTotal = (row: IServiceTotals, key: string, showProfileComplete = false) => {

        const complete = (row as any)[`${key}Count`] === (row as any)[`${key}Total`];

        return (
            <>
                <div className="my-3">
                    <div className="d-flex flex-column align-items-center justify-content-center just fw-bold text-nowrap w-100">
                        {
                            showProfileComplete && <div>
                                <FontAwesomeIcon 
                                    icon={complete ? faCheck : faXmark} 
                                    className={`${complete ? 'text-success' : 'text-danger'} fs-3`}
                                /> 
                            </div>
                        }
                        <div>
                            { (row as any)[`${key}Count`] } / { (row as any)[`${key}Total`] }
                        </div> 
                    </div>
                    <small className="text-nowrap">completed</small>
                </div>
            </>
        );
    };

    const formatFacility = (row: IServiceTotals) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

          return (
            <div className="py-2">
                <Link 
                    className="fw-bolder" 
                    to={'/amenities'}
                    onClick={(e) => handleLinkClick(row.NHID, e)}
                >
                    { row.PropertyName }
                </Link>      
                <br />
                <small>{ formatAddress(row) }</small>&nbsp;
                <a
                    className="small text-link text-link-blue text-nowrap"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>    
            </div>
        );
    };

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
    };

    const handleTableSortChange = (column: TableColumn<any>, sortOrder: string) => {
        setSortOrder({ 
            column: column.sortField, direction: sortOrder === 'asc' 
                ? 'ASC'
                : 'DESC' 
        });
    };

    return (
        <>
            { 
                isLoading 
                    ? <LoadingSpinner show={true}/> 
                    : (
                        <div className={'reviewscore_dt sticky_dt table_dt expander_dt'}>
                            <DataTable
                                columns={columns}
                                data={sortedTotals}
                                sortServer={true}
                                fixedHeader={false}
                                onSort={handleTableSortChange}
                            />
                        </div>
                    )
            }
        </>
    );
};

export default PropertyServiceTotalsTable;