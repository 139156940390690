import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './protected_route';
import PublicRoute from './public_route';
import Dashboard from '../pages/dashboard/dashboard';
import ReviewsListPublished from '../pages/reviews/reviews_list_published';
import ReviewsListPending from '../pages/reviews/reviews_list_pending';
import InviteToReview from '../pages/invite_to_review/invite_to_review';
import ReviewCards from '../pages/review_cards/review_cards';
import ReviewScoreCert from '../pages/review_score_certs/review_score_cert';
import QRCodePoster from '../pages/review_score_certs/qr_code_poster';
import ReviewsAndReplies from '../pages/reviews_and_replies/reviews_and_replies';
import ReviewRatings from '../pages/review_ratings/review_ratings';
// import ReviewScoreInsights from '../pages/review_score_insights/review_score_insights';
// import Facility from '../pages/facility/facility';
import ReviewWordCloud from '../pages/review_word_cloud/review_word_cloud';
// import ReviewsAnalysis from '../pages/reviews_analysis/reviews_analysis';
import Users from '../pages/users/users';
// import Help from '../pages/support/help';
import Login from '../pages/login/login';
import TokenLogin from '../pages/login/token_login';
import SessionTokenLogin from '../pages/login/session_token_login';
import PageTemplate from '../pages/page_template/page_template';
import PasswordReset from '../pages/password_reset/password_reset';
import PasswordResetEmail from '../pages/password_reset/password_reset_email';
import PasswordResetConfirm from '../pages/password_reset/password_reset_confirm';
import EditUser from '../pages/users/user_edit';
import NonMenuPageTemplate from '../pages/non_menu_page_template/non_menu_page_template';
import Commit from '../pages/commit/commit';
import ReviewScore from '../pages/review_score/review_score';
import WebsiteWidget from '../pages/website_widget/website_widget';
// import ReviewScoreComparison from '../pages/public/review_score_comparison';
import RequestAccess from '../pages/public/request_access';
import ReviewScoreSimulator from '../pages/review_score_simulator/review_score_simulator';
import AwardsTracker from '../pages/awards_tracker/awards_tracker';
import Awards from '../pages/awards/awards';
import ReviewListPublishedBanner from '../components/reviews/review_list_published_banner';
import PropertyPhotos from '../pages/property_media/property_photos';
import PropertyVideos from '../pages/property_media/property_videos';
import PropertyLogos from '../pages/property_media/property_logos';
import PropertyBrochures from '../pages/property_media/property_brochures';
import PropertyCoreDetails from '../pages/property_details/property_core_details';
import PropertyAmenities from '../pages/property_details/property_services_and_amenities/property_amenities';
import PropertyContacts from '../pages/property_details/property_contact_details/property_contacts';
import PropertyTourAvailability from '../pages/property_details/property_tour_availability';
import PropertyTeam from '../pages/property_details/property_team';
import Leads from '../pages/leads/leads';
import PropertyDescriptions from '../pages/property_details/property_descriptions';
import EmailLeads from '../pages/leads/email_leads';
import LeadsBreakdown from '../pages/leads/leads_breakdown';
import { ProfileScoreType } from '../interfaces/property';

const Router = () => {
    return (
        <Routes>
            <Route
                path="/login"
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
            />
            <Route
                path="/token-login"
                element={
                    <PublicRoute>
                        <TokenLogin />
                    </PublicRoute>
                }
            />
            <Route
                path="/sessiontoken-login"
                element={
                    <PublicRoute>
                        <SessionTokenLogin />
                    </PublicRoute>
                }
            />
            <Route
                path="/activate/:token"
                element={
                    <PublicRoute>
                        <PasswordReset mode={'activate'} />
                    </PublicRoute>
                }
            />
            <Route
                path="/passwordreset/:token"
                element={
                    <PublicRoute>
                        <PasswordReset mode={'password_reset'} />
                    </PublicRoute>
                }
            />

            <Route
                path="/passwordresetemail"
                element={
                    <PublicRoute>
                        <PasswordResetEmail />
                    </PublicRoute>
                }
            />

            <Route
                path="/passwordresetconfirm"
                element={
                    <PublicRoute>
                        <PasswordResetConfirm />
                    </PublicRoute>
                }
            />
            {/* {['/compare', '/compare/community/:memberID', '/compare/provider/:groupID']
                .map((path: string, i: number) => (
                    <Route 
                        key={`compare-${i}`} 
                        path={path}
                        element={
                            <PublicRoute>
                                <ReviewScoreComparison />
                            </PublicRoute>
                        }
                    />
                )
            )*/}
            <Route
                index
                element={
                    <ProtectedRoute>
                        <PageTemplate component={Dashboard} />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/leadslog"
                element={
                    <ProtectedRoute>
                        <PageTemplate
                            component={EmailLeads}
                            menuPath="/leadslog"
                        />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/leads"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={Leads} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/leadbreakdown"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={LeadsBreakdown} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/core-details"
                element={
                    <ProtectedRoute>
                        <PageTemplate 
                            component={PropertyCoreDetails} 
                            componentProps={{ profileScoreType: ProfileScoreType.FacilityBaseData}} 
                            automaticallyAddPageTitle={false}
                        />
                    </ProtectedRoute>
                }
            ></Route>
             <Route
                path="/cost-funding"
                element={
                    <ProtectedRoute>
                        <PageTemplate 
                            component={PropertyCoreDetails}
                            componentProps={{ profileScoreType: ProfileScoreType.CostFunding}} 
                            automaticallyAddPageTitle={false}
                        />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/amenities"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyAmenities} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/descriptions"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyDescriptions} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/contacts"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyContacts} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/team"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyTeam} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/photos"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyPhotos} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/videos"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyVideos} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/logos"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyLogos} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/brochures"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyBrochures} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/touravailability"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={PropertyTourAvailability} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/reviews/reviewid/:reviewID"
                element={
                    <ProtectedRoute>
                        <PageTemplate
                            component={ReviewsListPublished}
                            menuPath="/reviews/reviewid/:reviewID"
                        />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/reviews"
                element={
                    <ProtectedRoute>
                        <PageTemplate
                            component={ReviewsListPublished}
                            bannerComponent={ReviewListPublishedBanner}
                        />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/pendingreviews"
                element={
                    <ProtectedRoute>
                        <PageTemplate
                            component={ReviewsListPending}
                            bannerComponent={ReviewListPublishedBanner}
                        />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/published-reviews"
                element={<Navigate to="/reviews" replace />}
            />

            <Route
                path="/review-cards"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewCards} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/invite-to-review"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={InviteToReview} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/qr-code-posters"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={QRCodePoster} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/review-score-certificate"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewScoreCert} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/website-widget"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={WebsiteWidget} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/reviews-replies"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewsAndReplies} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/review-ratings"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewRatings} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/review-word-cloud"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewWordCloud} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/review-score"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewScore} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/review-score-simulator"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={ReviewScoreSimulator} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/awards"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={Awards} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/awards-tracker"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={AwardsTracker} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/users"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={Users} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/users/add"
                element={
                    <ProtectedRoute>
                        <PageTemplate component={EditUser} />
                    </ProtectedRoute>
                }
            ></Route>

            <Route
                path="/users/edit"
                element={
                    <ProtectedRoute>
                        <NonMenuPageTemplate
                            Component={EditUser}
                            PageTitleText="Edit User"
                            ParentMenuText="List Users"
                            ParentMenuLink="/users"
                            HeaderShow={true}
                            HeaderTitle="Edit Users"
                            HeaderText=""
                            SupportArticleButtonShow={false}
                        />
                    </ProtectedRoute>
                }
            ></Route>

            {/* <Route
                path="/help-centre"
                element={
                <ProtectedRoute>
                    <PageTemplate component={Help}/>
                </ProtectedRoute>}
            >
            </Route>
            */}

            <Route
                path="/commit"
                element={
                    <PublicRoute>
                        <Commit />
                    </PublicRoute>
                }
            />

            <Route
                path="/requestaccess"
                element={
                    <PublicRoute>
                        <RequestAccess />
                    </PublicRoute>
                }
            />
        </Routes>
    );
};

export default Router;
