import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const PasswordResetConfirm = () => {

    const  location = useLocation();
    const state: any = location?.state;
    const { EmailAddress } = state || {};

    if (!EmailAddress) {
        console.log('EmailAddress is not defined');
        return;
    }

    return (
        <>
         <div className="container-fluid public public__login">
            <div className="row">
                <div className="col-12">
                    <div className="widget mt-3 mt-md-5">
                        <div className="widget-body">
                            <div className="card">
                                <div className="card-header border-bottom">
                                    <a href="/" className="d-flex flex-row align-items-end text-secondary">
                                        <img src="/logo.svg" height="30px" className="logo-large" alt="Portal" />
                                        <span className="d-block ms-3">Portal</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <p className="mb-6">
                                        If <strong>{EmailAddress}</strong> has a registered account you will receive an email shortly with instructions on how to reset your password.
                                    </p>
                                    <p>
                                        If you do not receive an email within 5 minutes (and you have checked your junk folder), you likely do not have an existing account. To create an account please <a href="/requestaccess">request access here</a>
                                    </p>
                                    <div className="row align-items-center mt-6">
                                            <Link to={{
                                                pathname: '/login'
                                            }}> Return to login
                                            </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default PasswordResetConfirm;