import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import RootReducer from './reducers';

const persistConfig = {
    key: 'nh_portal_store',
    storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
    persistedReducer,
    composeWithDevToolsDevelopmentOnly()
);
const persistor = persistStore(store);

export { store, persistor };
