import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MeetTheTeamMemberType } from '../types';
import { prepareCreateTeamMemberPayload } from '../components/property_team_form/helpers';
import { createPropertyTeamMember } from '../../../../api/meet_the_team';
import { createQueryKey } from './helpers';
import { updateProfileCompleteness } from '../../../../utils/profile_completeness';

interface Props {
    includeIdInQueryKey: boolean;
    propertyId: number;
}

const useAddTeamMemberMutation = ({
    includeIdInQueryKey,
    propertyId
}: Props) => {
    const queryClient = useQueryClient();
    const queryKey = createQueryKey(propertyId, includeIdInQueryKey);

    const { mutate } = useMutation({
        mutationFn: async (values: MeetTheTeamMemberType) => {
            const { propertyId, ...otherFields } = values;
            const payload = prepareCreateTeamMemberPayload(otherFields);

            const newTeamMaber = await createPropertyTeamMember(propertyId, payload);
            if (newTeamMaber) {
                updateProfileCompleteness();
            }
            return newTeamMaber;
        },
        onMutate: async (values: MeetTheTeamMemberType) => {
            // prevent any query updates while we perform optimistic update
            await queryClient.cancelQueries({ queryKey });

            // copy the current data as it exists in the react-query store
            const previous: MeetTheTeamMemberType[] =
                queryClient.getQueryData(queryKey);

            // Optimistic update
            queryClient.setQueryData(
                queryKey,
                (oldData: MeetTheTeamMemberType[]) => {
                    const opt = {
                        ...values,
                        publishWebsite: values.publishWebsite === 'yes',
                        id: -1,
                        sequence: (oldData?.length || 0) + 1
                    };

                    return [...(previous || []), opt];
                }
            );

            // this gets added to the context in case we need to rollback
            return { previous };
        },
        onError: (err, _, context) => {
            console.log(err);
            // set the data in the store back to how it was before the optimistic update
            queryClient.setQueryData(queryKey, context.previous);
        },
        onSettled: () => {
            // we need to invalidate the queries used in both the single and multiple property views
            queryClient.invalidateQueries({
                queryKey: createQueryKey(propertyId)
            });
        }
    });

    return mutate;
};

export { useAddTeamMemberMutation };
