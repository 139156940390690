import React, { FC } from 'react';
import {
    faBed,
    faPeopleRoof,
    faUtensils,
    faDumbbell,
    faChurch,
    faScissors,
    faCircleEllipsis,
    faHouseTree,
    faTrees,
    faBellConcierge,
    faUserDoctor,
    faStethoscope,
    faTv,
    faStandardDefinition,
    faCameraSlash
} from '@fortawesome/pro-light-svg-icons';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { TagKeyName } from '../../interfaces/tag';
interface Props {
    tagKeyName: TagKeyName;
    className?: string;
}

const TagKeyNameIcon: FC<Props> = ({ tagKeyName, className }) => {
    const tagKeynameToIconMapper: Record<
        TagKeyName,
        FontAwesomeIconProps['icon'] | null
    > = {
        'exterior-of-building': faHouseTree,
        'resident-room': faBed,
        'common-area': faPeopleRoof,
        'dining-area': faUtensils,
        'outdoor-space-garden': faTrees,
        'gym-therapy-area': faDumbbell,
        'chapel-worship-area': faChurch,
        'barber-salon': faScissors,
        'other': faCircleEllipsis,
        'healthcare-area': faStethoscope,
        'recreational-activity-area': faTv,
        'reception-area': faBellConcierge,
        'team-member-resident': faUserDoctor,
        'too-low-resolution': faStandardDefinition,
        'non-compliant': faCameraSlash
    };

    const isRecognisedKeyName =
        typeof tagKeyName === 'string' && tagKeyName in tagKeynameToIconMapper;

    // Although typed, the keyname comes from the db, so we want to alert devs of any unexpected values
    if (!isRecognisedKeyName) {
        console.log('Unrecognised TagKeyName was used.');
        return null;
    }

    const iconName = tagKeynameToIconMapper[tagKeyName];

    return iconName ? (
        <FontAwesomeIcon icon={iconName} className={className} />
    ) : null;
};

export { TagKeyNameIcon };
