import React, { ReactNode, useState } from 'react';

import {
    daysOptions,
    prepareFormValuesForSubmission,
    timeOptions
} from '../helpers';
import cx from 'classnames';
import { ITourFormData, TourType } from '../types';
import { Form } from '../../../../components/common/form';
import { FormSelect } from '../../../../components/common/form_select';
import { FormTextArea } from '../../../../components/common/form_text_area';
import { FormCheckBoxGroup } from '../../../../components/common/form_checkbox_group';
import { schema } from './book_a_tour_form.validation';
import { FormTourTypeSelect } from '../components/form_tour_type_select';
import { UseFormReturn } from 'react-hook-form';

interface Props {
    defaultFormValues: ITourFormData;
    handleSubmit: (payload: any) => void;
    id?: string;
    actionButton?: ReactNode | ((formMethods: UseFormReturn<ITourFormData>) => ReactNode)
}

const inline = true;

const physicalTourDescription = (
    <div className="fst-normal mb-1 lh-base">
        <p className='mb-1'>
            <strong>Please include</strong> typical length of the tour, maximum
            number of participants, and any other information you think may help (eg
            arrive 10 minutes before, brief details about the tour / what to expect
            etc)
        </p>
        <p>
            <strong>Please Do Not include</strong> Phone Numbers / Email Addresses /
            Website Addresses, or non-informative text such as &apos;please contact
            us for more information&apos; - these will all be edited out.
        </p>
    </div>
);

const virtualTourDescription = (
    <div className="fst-normal mb-1 lh-base">
        <p className='mb-1'>
            <strong>Please include</strong> typical length of the tour and what
            Application(s) are used (eg Zoom / Teams / Facetime / WhatsApp etc), any
            other information you think may help (eg brief details about the tour /
            what to expect etc) <br />
        </p>
        <p>
            <strong>Please Do Not include</strong> Phone Numbers / Email Addresses /
            Website Addresses, or non-informative text such as &apos;please contact
            us for more information&apos; - these will all be edited out.
        </p>
    </div>
);

function BookATourForm(props: Props) {
    const { actionButton, defaultFormValues } = props;
    const { NHID: nhID } = defaultFormValues;
    const [wasFormClicked, setWasFormClicked] = useState(false);

    const handleSubmit = (formValues: ITourFormData) => {
        props.handleSubmit(prepareFormValuesForSubmission(nhID, formValues));
    };

    const { InheritedAvailabilityDetailsFromOrg, TourTypeID } = props.defaultFormValues;
    const parentOrgHasTourDetails = !!TourTypeID;

    const inheritedInfo = (
        <div className="group-record bg-primary-subtle d-flex flex-column flex-sm-row w-100 p-5 mt-10 align-items-center mb-4">
            <span className="me-4 mb-4 mb-sm-0">
                <i className="fa fa-solid fa-info" aria-hidden="true"></i>
            </span>
            The tour availability details here have been inherited from the
            parent Provider. You can make changes as normal, to diverge from the
            details set at the Provider level.
        </div>
    );

    return (
        <div>
            {InheritedAvailabilityDetailsFromOrg && parentOrgHasTourDetails && !wasFormClicked && inheritedInfo}
            <Form
                onSubmit={handleSubmit}
                validationSchema={schema}
                id={props.id || 'tourForm'}
                defaultValues={props.defaultFormValues}
            >
                {(formVals: UseFormReturn<ITourFormData>) => {
                    const tourType = formVals.watch('TourTypeID');
                    return (
                        <div
                            onClick={() => setWasFormClicked(true)}
                            className={cx({
                                'opacity-50':
                                    InheritedAvailabilityDetailsFromOrg && !wasFormClicked
                            })}
                        >
                            <FormTourTypeSelect asRadioGroup={true} />
                            {tourType && tourType !== TourType.No_tours ? (
                                <div>
                                    <div>
                                        <div className="bg-primary-subtle d-flex flex-column flex-sm-row p-4 my-5 align-items-center mx-0">
                                            <span className="me-4 mb-4 mb-sm-0">
                                                <i
                                                    className="fa fa-solid fa-info"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                            <div className="d-flex flex-column pe-0 pe-sm-10">
                                                <span>
                                                    The Days &amp; Time Ranges
                                                    selected below will dictate
                                                    what Days and Time can be
                                                    selected on the tour booking
                                                    form on your profile page.
                                                    These are guideline Day and
                                                    Time ranges, and you will be
                                                    required to confirm
                                                    availability directly with
                                                    the contact who filled in
                                                    the booking form (the form
                                                    submission will be emailed
                                                    to you).
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {[
                                TourType.Both_onsite_remote,
                                TourType.Onsite
                            ].includes(tourType) ? (
                                <div>
                                    <div className="bg-light py-3 ps-2">
                                        <h3>
                                            Onsite Tours - Tours conducted at
                                            the Facility
                                        </h3>
                                    </div>
                                    <div className="card-body mb-4">
                                        <FormCheckBoxGroup
                                            groupLabel="Available Days"
                                            name="PhysicalTourDays"
                                            options={daysOptions}
                                            inline={true}
                                            inlineLabelWidth="large"
                                        />
                                        <div className="d-flex flex-column flex-sm-row align-items-md-center">
                                            <label
                                                style={{ width: '250px' }}
                                                htmlFor="PhysicalTourStartTime"
                                                className="mt-3 fs-md-5"
                                            >
                                                Available Time Range*
                                            </label>
                                            <div className="flex-column flex-sm-row d-flex gap-sm-5 h-100 align-items-md-center">
                                                <FormSelect
                                                    name="PhysicalTourStartTime"
                                                    id="PhysicalTourStartTime"
                                                    label="From:"
                                                    options={timeOptions}
                                                    inline={inline}
                                                    inlineLabelWidth="auto"
                                                    maxMenuHeight={150}
                                                />

                                                <FormSelect
                                                    name="PhysicalTourEndTime"
                                                    label="To:"
                                                    options={timeOptions}
                                                    inline={true}
                                                    inlineLabelWidth="auto"
                                                    maxMenuHeight={150}
                                                />
                                            </div>
                                        </div>
                                        <FormTextArea
                                            rows={6}
                                            name="PhysicalDescription"
                                            inline={false}
                                            label="Onsite Tours Info"
                                            description={physicalTourDescription}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {[
                                TourType.Remote,
                                TourType.Both_onsite_remote
                            ].includes(tourType) ? (
                                <div>
                                    <div className="bg-light py-3 ps-2">
                                        <h3>
                                            Remote Tours - Tours conducted via
                                            Zoom/WhatsApp etc
                                        </h3>
                                    </div>
                                    <div className="card-body mb-4">
                                        <FormCheckBoxGroup
                                            groupLabel="Available Days"
                                            name="VirtualTourDays"
                                            options={daysOptions}
                                            inline={true}
                                            inlineLabelWidth="large"
                                        />
                                        <div className="d-flex flex-column flex-sm-row align-items-md-center">
                                            <label
                                                style={{ width: '250px' }}
                                                htmlFor="VirtualTourStartTime"
                                                className="mt-3 fs-md-5"
                                            >
                                                Available Time Range*
                                            </label>
                                            <div className="flex-column flex-sm-row d-flex gap-sm-5 h-100 align-items-md-center">
                                                <FormSelect
                                                    name="VirtualTourStartTime"
                                                    id="VirtualTourStartTime"
                                                    label="From:"
                                                    options={timeOptions}
                                                    inline={inline}
                                                    inlineLabelWidth="auto"
                                                    maxMenuHeight={150}
                                                />

                                                <FormSelect
                                                    name="VirtualTourEndTime"
                                                    label="To:"
                                                    options={timeOptions}
                                                    inline={true}
                                                    inlineLabelWidth="auto"
                                                    maxMenuHeight={150}
                                                />
                                            </div>
                                        </div>
                                        <FormTextArea
                                            rows={6}
                                            name="VirtualDescription"
                                            inline={false}
                                            label="Remote Tours Info"
                                            description={virtualTourDescription}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            { typeof actionButton === 'function' ? actionButton(formVals) : actionButton }
                        </div>
                    );
                }}
            </Form>
        </div>
    );
}

export default BookATourForm;
