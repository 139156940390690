import { secureHttp } from '../utils/request';

export const getLeadTotals = async (params: any) => {
    try {
        const { data: { data } } = await secureHttp.get('/lead/total', {params});
        return data;
    } catch (e) {
        return [];
    }
};

export const getPropertyLeads = async (params: any) => {
    try {
        const { data: { data } } = await secureHttp.get('/lead/property', {params});
        return data;
    } catch (e) {
        return [];
    }
};

export const getOrgLeads = async (params: any) => {
    try {
        const { data: { data } } = await secureHttp.get('/lead/org', {params});
        return data;
    } catch (e) {
        return [];
    }
};

export const getLeadsBreakdown = async (params: any = {}) => {
    try {
        const { data: { data } } = await secureHttp.get('/lead/breakdown', {params});
        return data;
    } catch (e) {
        return [];
    }
};
