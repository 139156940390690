
import React, { useEffect, useState, useContext } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useLocation } from 'react-router-dom';

import { get } from 'lodash';

import { getReviewScore } from '../../api/review';
import ActiveFilters from '../../components/common/active_filters';
import DataTable, { DataTableSortOrder, INITIAL_ROWS_PER_PAGE } from '../../components/common/data_table';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import ReviewScoreTableExpandableRows from '../../components/reviews/review_score_table_expandable_rows';
import TableLoading from '../../components/tables/table_loading';
import { FilterType } from '../../interfaces/filters';
import { IPropertyReviewScore, IReviewScoreFilterState } from '../../interfaces/review';
import FilterMatrix from '../../modules/filter_matrix';
import { scrollToTop } from '../../utils/common';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import { formatFileNameForCsvExport } from '../../utils/file';
import ReviewScoreDisplayFormatted from '../../components/common/review_score_display_formatted';
import ReviewScoreFullFormatted from '../../components/common/review_score_full_formatted';
import AppContext from '../../context/app_context';

import * as csvEportAPI from '../../api/csv_export';
import { getClientFormattedDateShort } from '../../utils/date';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const ReviewScore = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const filters: IReviewScoreFilterState = get(location, 'state.filters', {});

    const [ firstLoad, setFirstLoad] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ reviewScores, setReviewScores] = useState<IPropertyReviewScore[]>();
    const [ totalRows, setTotalRows] = useState<number>(0);
    const [ totalFilteredRows, setTotalFilteredRows] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(0);
    const [ pageLimit, setPageLimit ] = useState<number>(INITIAL_ROWS_PER_PAGE);
    const [ tableSortColumn, setTableSortColumn] = useState<string>(); 
    const [ tableSortOrder, setTableSortOrder] = useState<DataTableSortOrder>();
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

    const [ filterMatrix ] = useState(new FilterMatrix([
        {
            Key: 'ReviewScoreMin',
            FilterFormLabel: 'Review Score Min',
            Type: FilterType.Number,
            FilterPropName: 'ReviewScoreMin'
        },
        {
            Key: 'ReviewScoreMax',
            FilterFormLabel: 'Review Score Max',
            Type: FilterType.Number,
            FilterPropName: 'ReviewScoreMax'
        },
        {
            Key: 'NoReviewScore',
            FilterFormLabel: 'No Review Score',
            Type: FilterType.Boolean,
            FilterPropName: 'NoReviewScore'
        },
        {
            Key: 'BelowReviewScorePositiveReviewCountThreshold',
            FilterFormLabel: 'Less than required number of Positive Reviews',
            Type: FilterType.Boolean,
            FilterPropName: 'BelowReviewScorePositiveReviewCountThreshold'
        },
        { 
            Key: 'NHID', 
            FilterFormLabel: 'NHID' , 
            Type: FilterType.Number, 
            FilterPropName: 'NHID', 
            Hidden: true
        },

    ]));
    
    const isSinglePropertySession = nhIDs?.length === 1;
    const avgRowClass = (orgNHID || nhIDs?.length > 1) && totalFilteredRows > 0 ? 'reviewscore_dt_show_avg_row' : ''; 

    const isSinglePropertySelected = () => !!filterMatrix.getFormFieldValue('NHID') || isSinglePropertySession;
    const showActiveFilters = () => !isSinglePropertySelected() && firstLoad;

    const getRequestParams = () => {
        const params = filterMatrix.getRequestParams();

        if (params.NHID) {
            params.NHIDs = [params.NHID];
            delete params.NHID;
        }

        return params;
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const params = {
                ...getRequestParams(),
                Offset: pageOffset,
                Limit: pageLimit,
                SortField: tableSortColumn,
                SortOrder: tableSortOrder
            };

            if (params.NHID) {
                params.NHIDs = [params.NHID];
                delete params.NHID;
            }

            const { reviewScores, totalRows, totalFilteredRows } = await getReviewScore(params);
            
            if (reviewScores) {
                const hideAvgRowForResults = isSinglePropertySession || totalFilteredRows === 0;

                if (hideAvgRowForResults) {
                    reviewScores.shift(); 
                }

                const expandFirstDataRow = isSinglePropertySession || totalFilteredRows === 1;

                const withUpdatedRows = reviewScores.map((rs: IPropertyReviewScore, index: number) => {
                    const disabled = rs.isAvgRow || !rs.ReviewScoreDisplay;
                    const expanded = expandFirstDataRow 
                        ? isSinglePropertySession || index === 1
                        : false;

                    return { ...rs, disabled, expanded};
                });

                setReviewScores(withUpdatedRows);
                setTotalRows(totalRows);
                setTotalFilteredRows(totalFilteredRows);

                if (!firstLoad) {
                    setFirstLoad(true);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {        
        if (!orgNHID && !nhIDs) {
           return; 
        }

        const  { 
            NoReviewScore,
            BelowReviewScorePositiveReviewCountThreshold,
            NHID
        } = filters;

        if (NHID) {
            applyFilter('NHID', NHID, false);
        }

        if (NoReviewScore) {
            applyFilter('NoReviewScore', true, false);
        }

        if (BelowReviewScorePositiveReviewCountThreshold) {
            applyFilter('BelowReviewScorePositiveReviewCountThreshold', true, false);
        }
    }, []);


    useEffect(() => {     
        setResetPaginationToggle(!resetPaginationToggle);
        fetchData();
    }, [  orgNHID, nhIDs, pageOffset, tableSortColumn, tableSortOrder ]);
    
    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);

        if (refreshData) {
            fetchData();
        }
    };

    const formatFacility = (data: IPropertyReviewScore) => {
        if (data.isAvgRow) {
            return <small className="py-4"><strong>Averages from all {data.OrgPropertyCount} {data.OrgName} Communities</strong></small>;
        }

        const { Name, Address } = data;

        // const reviewFilterState: IPublishedReviewsFilterState = {  
        //     filters: { NHID },
        //     filterGroup: PublishedReviewsFilterGroup.ReviewScoreReviews
        // };
        
        return (
            <div className="py-2">
                <strong>{ Name }</strong> <br /><small>{ Address }</small>
                {/* <small className="name-column-links">
                    <Link to={'/reviews'} state={reviewFilterState}>
                        View&nbsp;all&nbsp;Reviews&nbsp;used&nbsp;in&nbsp;the&nbsp;Score
                    </Link>             
                    <br />
                    <Link to="/review-score-certificate" state={{ NHID }}>
                        Download Review&nbsp;Score&nbsp;Certificate
                    </Link>
                    <br />
                    <Link to="/invite-to-review" state={{ NHID }}>
                        Invite&nbsp;to&nbsp;Review
                    </Link>&nbsp;|&nbsp;
                    <Link to="/review-cards" state={{ NHID }}>
                        Order&nbsp;Review&nbsp;Cards
                    </Link>
                </small> */}
            </div>
        );
    };

    const appContext: any = useContext(AppContext);

    const columns = [
        {
            name: 'Facility Name',
            selector: (row: IPropertyReviewScore) => row.Name,
            cell: (row: IPropertyReviewScore) => formatFacility(row),
            sortable: true,
            sortField: 'Name',
            minWidth: appContext.isMobile ? '146px' : '290px',
            id: 'name'
        },
        {
            name: 'Review Score',
            selector: (row: IPropertyReviewScore) => row.ReviewScoreDisplay,
            cell: (row: IPropertyReviewScore) => formatReviewScore(row),
            sortable: true,
            sortField: 'ReviewScoreDisplay',
            minWidth: '160px',
            id: 'score-display'
        },
        {
            name: <div className="font-weight-normal"># Reviews in the last 24 Months</div>,
            selector: (row: IPropertyReviewScore) => <>{row.ReviewScoreTotalReviewCount}</>,
            sortable: true,
            sortField: 'ReviewScoreTotalReviewCount',
            minWidth: '90px',
            id: 'review-count'
        },
        {
            name: <div className="font-weight-normal">Average Overall Rating of all reviews in the last 24 months <strong>(up to 5 points available)</strong></div>,
            selector: (row: IPropertyReviewScore) => row.ReviewScoreQualityScore,
            cell: (row: IPropertyReviewScore) => <><strong>{row.ReviewScoreQualityScore}</strong></>,
            sortable: true,
            sortField: 'ReviewScoreQualityScore',
            minWidth: '119px',
            id: 'average-rating'
        },
        {
            name: <div className="font-weight-normal"># Positive Reviews (Overall Rating = 4 or 5) in  the last 24 months</div>,
            selector: (row: IPropertyReviewScore) => <>{row.ReviewScorePositiveReviewCount}</>,
            sortable: true,
            sortField: 'ReviewScorePositiveReviewCount',
            minWidth: '105px',
            id: 'positive-reviews'
        },
        {
            name: <div className="font-weight-normal"># Positive Reviews required in the last 24 months for max points</div>,
            selector: (row: IPropertyReviewScore) => <>{row.isAvgRow ? '' : `${row.ReviewScorePositiveReviewCountThreshold}+`}</>,
            sortable: false,
            sortField: 'ReviewScorePositiveReviewCountThreshold',
            minWidth: '100px',
            id: 'positive-reviews-threshold'
        },
        {
            name: <div className="font-weight-normal">Points from Positive Reviews in the last 24 months <strong>(up to 5 points available)</strong></div>,
            selector: (row: IPropertyReviewScore) => row.ReviewScoreQuantityScore,
            cell: (row: IPropertyReviewScore) => <><strong>{row.ReviewScoreQuantityScore}</strong></>,
            sortable: true,
            sortField: 'ReviewScoreQuantityScore',
            minWidth: '105px',
            id: 'points'
        },
        {
            name: <div className="font-weight-normal">Date of Most Recent Review</div>,
            selector: (row: IPropertyReviewScore) => row.MostRecentReviewDate ? getClientFormattedDateShort(row.MostRecentReviewDate) : null,
            sortable: true,
            sortField: 'MostRecentReviewDate',
            minWidth: '105px',
            id: 'date-recent-review'
        },
    ];

    const formatReviewScore = ({ ReviewScoreDisplay, ReviewScoreFull }: IPropertyReviewScore) => {
        return (
            <div className="text-center fw-bold">
                <ReviewScoreDisplayFormatted value={ReviewScoreDisplay} classNames="mb-2"/>
                <ReviewScoreFullFormatted value={ReviewScoreFull} />
            </div>
        );
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        applyFilter('NHID', selNHID);
    });

    const applyMinMax = (filter: string, val: string) => {
        const numVal = val ? Number(val) : null;

        if (filter === 'ReviewScoreMin' && numVal) {
            const maxValue = Number(filterMatrix.getFormFieldValue('ReviewScoreMax')) || 10;

            if (numVal > maxValue) {
                applyFilter('ReviewScoreMin', maxValue); 
                return;
            }
    
            if (numVal < 0) {
                applyFilter(filter, 0); 
                return;
            }
        }
        if (filter === 'ReviewScoreMax' && numVal) {
            const minValue = Number(filterMatrix.getFormFieldValue('ReviewScoreMin')) || 0;
            if (minValue > numVal) {
                applyFilter('ReviewScoreMax', minValue);
                return;
            }

            if (numVal > 10) {
                applyFilter(filter, 10); 
                return;
            }
        }

        applyFilter(filter, numVal); 
    };

    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset(newPageOffset);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number, pageNumber: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset((pageNumber - 1) * pageLimit);
            setPageLimit(newPerPage);
            scrollToTop();
        }
    };

    const onSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
        if (column?.sortField && sortOrder) {
            setTableSortColumn(column.sortField);
            setTableSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);            
        }
    };

    const startExport = async (): Promise<string> => {
        return csvEportAPI.startExport('/review/reviewscore/export', getRequestParams());
    };

    const resetFilters = () => {
        filterMatrix.reset();
        fetchData();
    };

    const selectedNHID = filterMatrix.getRequestParams().NHID;

    return (
        <>
            { isLoading ? <LoadingSpinner show={true} /> : null }
            
            <PageCommunitySelectWrapper
                label={'All Review Scores for'}
                handleSelect={handleSelect}
                selNHID={selectedNHID}
            />      
   
            <div className="widget widget-fullwidth">
                <div className="widget-body">
                    <div className="card">
                        <div className="card-body p-0">
                            {
                            !isSinglePropertySelected() ? 
                                <>
                                    <div className="border-bottom p-4 d-flex flex-column flex-xxl-row align-items-xxl-center">
                                        <div className="d-flex flex-wrap mb-1 mb-xxl-0">
                                            <label htmlFor="NoReviewScore" className="me-4 mb-2 mb-xxl-0 text-nowrap d-flex align-items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={filterMatrix.getFormFieldValue('NoReviewScore')}
                                                    id="NoReviewScore"
                                                    onChange={(e) => applyFilter('NoReviewScore', e.target.checked || null)}
                                                /><span className="ms-2">No Review Score</span>
                                                <span></span>
                                            </label>
                                            <label htmlFor="BelowReviewScorePositiveReviewCountThreshold" className="me-4 mb-2 mb-xxl-0 text-nowrap d-flex align-items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={filterMatrix.getFormFieldValue('BelowReviewScorePositiveReviewCountThreshold')}
                                                    id="BelowReviewScorePositiveReviewCountThreshold"
                                                    onChange={(e) => applyFilter('BelowReviewScorePositiveReviewCountThreshold', e.target.checked || null)}
                                                /><span className="ms-2">Less than required number of Positive Reviews</span>
                                                <span></span>
                                            </label>  
                                        </div>
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center ms-xxl-4">
                                            <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-4 text-nowrap">
                                                <label className="me-2" htmlFor="ReviewScoreMin">Min Review Score</label>
                                                <input
                                                    type="number"
                                                    id="ReviewScoreMin"
                                                    className="form-control"
                                                    onChange={(e) => applyMinMax('ReviewScoreMin', e.target.value)}
                                                    value={filterMatrix.getFormFieldValue('ReviewScoreMin')}
                                                    min={0} 
                                                    max={10}
                                                    step="0.1"
                                                    style={{ width: '75px' }}                                                
                                                />    
                                            </div>
                                            <div className="d-flex align-items-center me-sm-5 text-nowrap">
                                                <label className="me-2" htmlFor="ReviewScoreMax">Max Review Score</label>
                                                <input
                                                    type="number"
                                                    id="ReviewScoreMax"
                                                    className="form-control"
                                                    onChange={(e) => applyMinMax('ReviewScoreMax', e.target.value)}
                                                    value={filterMatrix.getFormFieldValue('ReviewScoreMax')}
                                                    min={filterMatrix.getFormFieldValue('ReviewScoreMin')} 
                                                    max={10}
                                                    step="0.1"
                                                    style={{ width: '75px' }} 
                                                />   
                                            </div> 
                                        </div>
                                        <div className="ms-auto">
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={resetFilters}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                            }

                            <div className="table-info p-4">
                                <div className="row align-items-center">
                                    {
                                        showActiveFilters() ? 
                                            <div className="col-lg-8 mb-4 mb-lg-0">
                                                <ActiveFilters 
                                                    filterMatrices={[filterMatrix]} 
                                                    isLoading={isLoading}
                                                    pageOffset={pageOffset}
                                                    pageLimit={pageLimit}
                                                    singularNoun={'Community'}
                                                    pluralNoun={'Communities'}
                                                    totalFilteredSize={totalFilteredRows}
                                                    totalSize={totalRows}
                                                    showTotals={true}
                                                    applyFilter={applyFilter}
                                                />
                                            </div> : null
                                    }

                                    <div className={`col-lg-${showActiveFilters() ? '4' : '12'} text-end`}>
                                        <CsvExport 
                                            startExportFunction={startExport}
                                            modalTitleSuffix={'Review Score'}
                                            label="Export as CSV"
                                            displayType={DisplayType.Button}
                                            fileName={`${formatFileNameForCsvExport('review_score')}`}
                                        />                                   
                                    </div>   
                                </div>                                         
                            </div>

                            <div className={`reviewscore_dt sticky_dt table_dt expander_dt ${avgRowClass}`}>
                                <DataTable
                                    columns={columns}
                                    data={reviewScores}
                                    pagination={true}
                                    paginationServer={true}
                                    paginationTotalRows={totalFilteredRows}
                                    onChangePage={onPageChange}
                                    onChangeRowsPerPage={onLimitChange}
                                    sortServer={true}
                                    onSort={onSortChange}
                                    fixedHeader={false}
                                    expandableRows
                                    expandableRowExpanded={(row: any) => row.expanded}
                                    expandableRowDisabled={(row: any) => row.disabled}
                                    expandableRowsComponent={ReviewScoreTableExpandableRows}
                                    expandableIcon={{
                                        collapsed: <>View Full Breakdown</>,
                                        expanded: <>Hide Full Breakdown</>
                                    }}
                                    noDataComponent={
                                        <TableLoading 
                                            loading={isLoading} 
                                            loadingMessage="Loading Properties..." 
                                            noDataMessage="No Properties found" 
                                        />
                                    }
                                />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReviewScore;