import React, { useState, useEffect } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import DataTable from '../../../components/common/data_table';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import {
    IPropertyContactDetails,
    IPropertyContactDetailsParams,
    IPropertyContactNoValueDetails,
    ContactDetailsHeaders
} from '../../../interfaces/property';
import {
    getPropertyContactDetails,
    getPropertyContactDetailsFilterDetails
} from '../../../api/property';
import { Toast } from '../../../utils/toast';
import { DataTableSortOrder } from '../../../components/common/data_table';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { formatAddress } from '../../../utils/property';
import { websiteJSXIcon } from './website_link_icon';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { BulkPropertyContactDetailsUpdateForm } from './bulk_property_contact_details_form';
import { UpdatePropertyContactDetailsForm } from './update_property_contact_details_form';
import { UpdatePropertyDetailModal } from './modal/update_property_contact_details_modal';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyContacts = ({ nhIDs, orgNHID }: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [selNHID, setSelNHID] = useState<number[]>([]);
    const [contactDetails, setContactDetails] = useState<
        IPropertyContactDetails[]
    >([]);
    const [activeContactDetails, setActiveContactDetails] =
        useState<IPropertyContactDetails>(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
    const [tableOffset, setTableOffset] = useState<number>(0);
    const [tableLimit, setTableLimit] = useState<number>(100);
    const [tableSortColumn, setTableSortColumn] = useState<string>('Name');
    const [tableSortOrder, setTableSortOrder] = useState<any>('DESC');
    const [tableTotalRows, setTableTotalRows] = useState<number>(0);
    const [changedTick, setChangedTick] = useState<number>(0);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>({
        HasNoPhone: false,
        HasNoWebsite: false,
        HasNoEmail: false
    });
    const [filterCounts, setFilterCounts] = useState<any>({
        HasNoPhone: 0,
        HasNoWebsite: 0,
        HasNoEmail: 0
    });

    useEffect(() => {
        fetchPropertyContactDetails();
    }, [
        tableOffset,
        tableLimit,
        tableSortColumn,
        tableSortOrder,
        selNHID,
        selectedFilterOptions
    ]);

    const fetchPropertyContactDetails = async () => {
        const params: IPropertyContactDetailsParams = {
            Offset: tableOffset,
            Limit: tableLimit,
            SortField: tableSortColumn,
            SortOrder: tableSortOrder,
            NHIDs: selNHID.length ? selNHID : nhIDs,
            OrgNHID: orgNHID,
            ...selectedFilterOptions
        };
        setBusy(true);

        const [[propertyContactDetails, totalRows], noValueData]: [
            [IPropertyContactDetails[], number],
            IPropertyContactNoValueDetails
        ] = await Promise.all([
            getPropertyContactDetails(params),
            getPropertyContactDetailsFilterDetails(params)
        ]);

        if (totalRows) {
            setTableTotalRows(totalRows);
        }
        if (noValueData) {
            setFilterCounts({
                HasNoPhone: noValueData.NoPhoneCount ?? 0,
                HasNoWebsite: noValueData.NoWebsiteCount ?? 0,
                HasNoEmail: noValueData.NoEmailCount ?? 0
            });
        }

        setBusy(false);
        setChangedTick(changedTick + 1);
        setContactDetails(propertyContactDetails);
    };

    const handleTablePageChange = async (pageNumber: number) => {
        setTableOffset((pageNumber - 1) * tableLimit);
    };

    const handleTableLimitChange = async (
        newPerPage: number,
        pageNumber: number
    ) => {
        setTableOffset((pageNumber - 1) * newPerPage);
        setTableLimit(newPerPage);
    };

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setTableSortColumn(column.sortField);
        setTableSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const handleOpenUpdateRowModal = async (row: IPropertyContactDetails) => {
        setActiveContactDetails(row);
        setIsUpdateModalOpen(true);
    };

    const handleCloseUpdateRowModal = async (row: IPropertyContactDetails) => {
        setActiveContactDetails(null);
        setIsUpdateModalOpen(false);
        fetchPropertyContactDetails();
    };

    const columns = [
        {
            name: 'Facility Name',
            selector: (row: IPropertyContactDetails) => row.Name,
            sort: true,
            maxWidth: '300px',
            sortable: true,
            sortField: 'Name',
            cell: (row: IPropertyContactDetails) => formatFacility(row)
        },
        {
            name: 'Phone',
            selector: (row: IPropertyContactDetails) => row.GeneralPhoneNumber,
            sort: true,
            width: '200px',
            sortable: true,
            sortField: 'GeneralPhoneNumber',
            cell: (row: IPropertyContactDetails) => formatPhoneNumbers(row)
        },
        {
            name: 'Email',
            sortable: true,
            sortField: 'Email',
            width: '300px',
            selector: (row: IPropertyContactDetails) => row.GeneralEmail,
            cell: (row: IPropertyContactDetails) => formatEmailAddresses(row)
        },
        {
            name: 'Website',
            sortable: true,
            sortField: 'Website',
            minWidth: '300px',
            selector: (row: IPropertyContactDetails) => row.GeneralWebsite,
            cell: (row: IPropertyContactDetails) => (
                <>
                    <div className="column w-100">
                        <div className="d-flex flex-row flex-sm-row align-items-md-center w-100">
                            <p
                                title={
                                    (ContactDetailsHeaders as any)[
                                        'GeneralWebsite'
                                    ]
                                }
                            >
                                {row.GeneralWebsite || 'No website'}
                            </p>
                            &nbsp;
                            {websiteJSXIcon(row.GeneralWebsite)}
                        </div>
                    </div>
                </>
            )
        },
        {
            name: '',
            maxWidth: '125px',
            cell: (row: IPropertyContactDetails) =>
                row ? renderUpdateButton(row) : null
        }
    ];

    const renderUpdateButton = (row: IPropertyContactDetails) => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-primary text-nowrap"
                disabled={busy}
                onClick={() => handleOpenUpdateRowModal(row)}
            >
                Update
            </button>
        );
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        if (selNHID) {
            handleClearFilters();
            setSelNHID([selNHID]);
        } else {
            setSelNHID([]);
        }
    });

    const formatFacility = (row: IPropertyContactDetails) => {
        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="py-2">
                <b>{row.Name}</b>
                <br />
                <small>{formatAddress(row)}</small>
                &nbsp;
                <a
                    target="_blank"
                    className="small text-link text-link-blue text-nowrap"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                >
                    view on website
                </a>
            </div>
        );
    };

    const formatPhoneNumbers = (row: IPropertyContactDetails) => {
        const { GeneralPhoneNumber, CarePhoneNumber, JobPhoneNumber } = row;
        const NoPhoneNumber = 'no number';

        return (
            <div className="w-70">
                <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                    <div className="flex-row-auto flex-sm-row align-items-md-center w-100">
                        <b>{ContactDetailsHeaders.GeneralPhoneNumber}</b>
                        <p
                            title={
                                (ContactDetailsHeaders as any)[
                                    'GeneralPhoneNumber'
                                ]
                            }
                        >
                            {GeneralPhoneNumber || NoPhoneNumber}
                        </p>
                        <br />
                        <b>{ContactDetailsHeaders.Care}</b>
                        <p
                            title={
                                (ContactDetailsHeaders as any)[
                                    'CarePhoneNumber'
                                ]
                            }
                        >
                            {CarePhoneNumber || NoPhoneNumber}
                        </p>
                        <br />
                        <b>{ContactDetailsHeaders.Job}</b>
                        <p
                            title={
                                (ContactDetailsHeaders as any)['JobPhoneNumber']
                            }
                        >
                            {JobPhoneNumber || NoPhoneNumber}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const emailDataTableObject = (
        dataArray: string[],
        row: IPropertyContactDetails
    ) => {
        const noEmails = 'no email addresses';
        const emaiData: any[] = [];
        dataArray.forEach(key => {
            if ((row as any)[key]) {
                emaiData.push((row as any)[key]);
            }
        });

        if (!emaiData.length) {
            return (
                <>
                    <br />
                    {noEmails}
                    <br />
                </>
            );
        }

        return dataArray.map(key => {
            const inputId = `dt-${key}`;
            return (
                <>
                    <p id={inputId} title={(ContactDetailsHeaders as any)[key]}>
                        {(row as any)[key] || ''}
                    </p>
                </>
            );
        });
    };

    const formatEmailAddresses = (row: IPropertyContactDetails) => {
        const generalEmailKeys = [
            'GeneralEmail',
            'GeneralEmail2',
            'GeneralEmail3'
        ];
        const careEmailKeys = ['CareEmail', 'CareEmail2', 'CareEmail3'];
        const jobEmailKeys = ['JobEmail', 'JobEmail2', 'JobEmail3'];

        const generalEmailObject = emailDataTableObject(generalEmailKeys, row);
        const careEmailObject = emailDataTableObject(careEmailKeys, row);
        const jobEmailObject = emailDataTableObject(jobEmailKeys, row);

        return (
            <div className="w-100">
                <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                    <div className="flex-row-auto flex-sm-row align-items-md-center w-100">
                        <b>{ContactDetailsHeaders.GeneralEmails}</b>
                        {generalEmailObject}
                        <br />
                        <b>{ContactDetailsHeaders.Care}</b>
                        {careEmailObject}
                        <br />
                        <b>{ContactDetailsHeaders.Job}</b>
                        {jobEmailObject}
                        <br />
                    </div>
                </div>
            </div>
        );
    };

    const renderLoading = () => <LoadingSpinner show={true} />;

    const handleUpdateFilters = (key: string, value: boolean) => {
        const filterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
        filterOptions[key] = value;
        setSelectedFilterOptions(filterOptions);
        setChangedTick(changedTick + 1);
    };

    const handleClearFilters = () => {
        setSelectedFilterOptions({
            HasNoPhone: false,
            HasNoWebsite: false,
            HasNoEmail: false
        });
        setChangedTick(changedTick + 1);
    };

    const renderCheckBox = (key: string, label: string) => {
        return (
            <div className="d-flex align-items-center">
                <input
                    type="checkbox"
                    className="form-check-input m-0 me-2"
                    name={key}
                    checked={selectedFilterOptions[key]}
                    disabled={!filterCounts[key]}
                    onChange={(e: any) =>
                        handleUpdateFilters(key, e.target.checked)
                    }
                />
                <div
                    onClick={(e: any) => {
                        filterCounts[key]
                            ? handleUpdateFilters(
                                  key,
                                  !selectedFilterOptions[key]
                              )
                            : null;
                    }}
                >
                    {label} (
                    {filterCounts[key] > 0
                        ? filterCounts[key]
                        : 'all completed'}
                    )
                </div>
            </div>
        );
    };

    return (
        <>
            <PageCommunitySelectWrapper
                label={'Phone / Website / Email'}
                handleSelect={handleSelect}
                selNHID={selNHID[0]}
            />
            {busy ? renderLoading() : null}
            <Toast></Toast>

            {(orgNHID && contactDetails.length && !selNHID.length) ||
            (nhIDs && nhIDs.length > 1 && !selNHID.length) ? (
                <BulkPropertyContactDetailsUpdateForm
                    nhIDs={nhIDs}
                    orgNHID={orgNHID}
                    contactData={contactDetails}
                    handleFetchData={fetchPropertyContactDetails}
                />
            ) : null}

            {(orgNHID && contactDetails.length && !selNHID.length) ||
            (nhIDs && nhIDs.length > 1 && !selNHID.length) ? (
                <div
                    className="widget widget-fullwidth dataTable-widget"
                    data-widget-header="1"
                >
                    <div className="widget-header">
                        <h4>All Facilities</h4>
                        <div>
                            <div className="d-inline-flex align-items-center gap-5 small">
                                {renderCheckBox('HasNoPhone', 'Has no Phone')}
                                {renderCheckBox('HasNoEmail', 'Has no Email')}
                                {renderCheckBox(
                                    'HasNoWebsite',
                                    'Has no Website'
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="widget-body">
                        <div className="card mb-9 mb-md-0">
                            <div className="card-body flex-col justify-content-center p-0">
                                <div className="bulk-contact-table sticky_dt">
                                    <DataTable
                                        columns={columns}
                                        key={'NHID'}
                                        data={contactDetails}
                                        paginationTotalRows={tableTotalRows}
                                        pagination={true}
                                        paginationServer={true}
                                        initialRowsPerPage={tableLimit}
                                        sortServer={true}
                                        onChangePage={handleTablePageChange}
                                        onChangeRowsPerPage={
                                            handleTableLimitChange
                                        }
                                        onSort={handleTableSortChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {(!orgNHID &&
                contactDetails.length &&
                contactDetails.length === 1 &&
                nhIDs &&
                nhIDs.length === 1) ||
            (selNHID.length &&
                selNHID.length === 1 &&
                contactDetails.length === 1 &&
                selNHID[0] === contactDetails[0].NHID) ? (
                <div className="card-toolbar">
                    <UpdatePropertyContactDetailsForm
                        data={contactDetails[0]}
                        updateModalActive={isUpdateModalOpen}
                        handleUpdateData={fetchPropertyContactDetails}
                    />
                </div>
            ) : null}
            <UpdatePropertyDetailModal
                propertyDetail={activeContactDetails}
                handleModalClose={handleCloseUpdateRowModal}
                isUpdateModalOpen={isUpdateModalOpen}
            />
        </>
    );
};

export default PropertyContacts;
