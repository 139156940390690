import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableString,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    RoomTypeOther: yupNullableString,
    RoomTypeSemiPrivate: yupNullableStringToBool,
    RoomTypeShared: yupNullableStringToBool,
    RoomTypePrivateSuite: yupNullableStringToBool,
    RoomTypePrivateRoom: yupNullableStringToBool,
});

export default schema;
