import { IMenuState, IMenuStateTotals } from '../interfaces/menu';
import { secureHttp } from '../utils/request';

export const getMenu = async (): Promise<IMenuState> => {    
    try {
        const { data: { data } } = await secureHttp.get('/menu');

        return data;
    } catch (e: any) {
        console.log(`Failed to get menu: ${e.message || 'Unknown Error'}`);
        return null;
    }
};

export const getMenuVersion = async (): Promise<IMenuState> => {    
    try {
        const { data: { data } } = await secureHttp.get('/menu/version');

        return data;
    } catch (e: any) {
        console.log(`Failed to getMenuVersion: ${e.message || 'Unknown Error'}`);
        return null;
    }
};

export const getMenuTotals = async (): Promise<IMenuStateTotals> => {    
    try {
        const { data: { data } } = await secureHttp.get('/menu/totals');

        return data;
    } catch (e: any) {
        console.log(`Failed to getMenuTotals: ${e.message || 'Unknown Error'}`);
        return null;
    }
};


