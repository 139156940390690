import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { IPropertyLiveDescription, IPropertyLiveDescriptionParams } from '../../interfaces/property';
import { getPropertyLiveDescriptions } from '../../api/property';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { formatAddress, getSessionPropertyMap } from '../../utils/property';
import ProfileCompletenessHeader from '../../components/profile_completeness/profile_completeness_header';
import { ProfileScoreType } from '../../interfaces/property';
import ProfileCompletenessText from '../../components/profile_completeness/profile_completeness_text';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyDescriptions = ({ nhIDs, orgNHID }: IProps) => {
    const singlePropertySession = nhIDs?.length === 1;
    const nhID = singlePropertySession ? nhIDs[0] : undefined;

    const propertyMap = getSessionPropertyMap();

    const [ busy, setBusy ] = useState<boolean>();
    const [ hasLiveDescription, setHasLiveDescription ] = useState<boolean | null>(null);
    const [ data, setData ] = useState<IPropertyLiveDescription[]>();
    const [ selNHID, setSelNHID ] = useState<number>(nhID);

    const showForm = !selNHID && (orgNHID || !singlePropertySession);

    useEffect(() => {
        fetchData();
    }, [selNHID, hasLiveDescription]);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const fetchData = async () => {
        setBusy(true);

        const params: IPropertyLiveDescriptionParams = {
            NHID: selNHID,
            HasLiveDescription: showForm ? hasLiveDescription : null
        };

        const data = await getPropertyLiveDescriptions(params);


        if (data) {
            setData(data);
        } 

        setBusy(false);
    };

    const renderDescriptionLink = (nhID: number) => {
        const property = propertyMap.get(nhID);

        if (!property) {
            return null;
        }

        const { WebsiteURL, StateSlug, CitySlug, Slug } = property;

        return (
            <a className="ms-2 small text-link text-nowrap" href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}/`} target="_blank" rel="noreferrer">
                view on website
            </a>
        );
    };

    const renderSection = (data: IPropertyLiveDescription) => {
       const noDescription = 
            <><strong>Currently No Description</strong> - The NursingHomes.com editorial team are writing descriptions for all Premium subscribers</>;

        return (
            <div className="widget" key={`property-description-${data.NHID}`}>
                <div className="widget-body">
                    <div className="card" data-card="Descriptions">
                        <div className="card-header">
                            <span>
                                <h4>
                                    { data.PropertyName }
                                    <small className="ms-2">{ formatAddress(data) }</small>&nbsp;
                                    { data.Description ? renderDescriptionLink(data.NHID) : null } 
                                </h4>   
                                { 
                                    // profile completeness text is only for properties not orgs
                                    data.IsOrg 
                                        ? null 
                                        : <ProfileCompletenessText
                                            NHID={data.NHID}
                                            profileScoreType={ProfileScoreType.Description}
                                        />
                                }
                                                             
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="p_wrap">
                                { data.Description || noDescription }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDescriptions = () => {
        if (!data) {
            return null;
        }

        return (
            <>  
                <div className="widget" data-widget-height="auto">
                    <div className="widget-body">            
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.Description}
                            handleProfileCompletenessFilter={setHasLiveDescription}
                            profileCompletenessFilterValue={hasLiveDescription}
                            title="for Descriptions"
                        />
                    </div>
                </div>
                        
                { data.map((data: IPropertyLiveDescription) => renderSection(data)) }
            </>
        );
    };

    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Descriptions for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            { busy ? <LoadingSpinner show={true}/> : null }

            { renderDescriptions() }
        </>
    );
};

export default PropertyDescriptions;