import React, { useContext } from 'react';
import FilterMatrix from '../../modules/filter_matrix';
import { FilterType } from '../../interfaces/filters';
import ActiveFilters from '../../components/common/active_filters';
import Accordion from 'react-bootstrap/Accordion';
import ReviewFilters from '../../components/reviews/review_list_filters/review_filters';
import { getReviews } from '../../api/review';
import VisibleRatingStars from '../../components/common/visible_rating_stars';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import moment from 'moment';
import { formatFileNameForCsvExport } from '../../utils/file';
import { get } from 'lodash';
import { ReviewPublicationStatusType } from '../../interfaces/review';
import { IPortalReplyItem, IReply } from '../../interfaces/reply';

import {
    createReply,
    updateReply
} from '../../api/review';

import AppContext from '../../context/app_context';

const replystringTextFormatter = (filter: any) => {

    const filterValue = filter.Value === 'true' 
        ? 'Has a Reply (Published or Pending Verification)'
        : 'Has No Reply';

    return `${filter.FilterFormLabel}: ${filterValue}`;
};

export const createFilters = () => {
    return new FilterMatrix([
        { Key: 'DateSubmittedFrom', FilterFormLabel: 'Date Submitted From' , Type: FilterType.DateFromUTC, FilterPropName: 'DateFrom'},
        { Key: 'DateSubmittedTo', FilterFormLabel: 'Date Submitted To' , Type: FilterType.DateToUTC, FilterPropName: 'DateTo'},
        { Key: 'DatePublishedFrom', FilterFormLabel: 'Date Published From' , Type: FilterType.DateFromUTC, FilterPropName: 'LastPublishedDateFrom'},
        { Key: 'DatePublishedTo', FilterFormLabel: 'Date Published To' , Type: FilterType.DateToUTC, FilterPropName: 'LastPublishedDateTo'},
        { Key: 'PublicationStatus', FilterFormLabel: 'Reason not published' , Type: FilterType.LabelValue, FilterPropName: 'PublicationAuxiliaryStatusID'},
        { Key: 'ReviewerConnections', FilterFormLabel: 'Reviewer Connection' , Type: FilterType.LabelValue, FilterPropName: 'ReviewerConnectionIDs'},
        { Key: 'ReviewExperience', FilterFormLabel: 'Review Experience' , Type: FilterType.LabelValue, FilterPropName: 'ExperienceTypeIDs'},
        { Key: 'CareTypes', FilterFormLabel: 'Reviewer Care Type' , Type: FilterType.LabelValue, FilterPropName: 'CareTypeID'},
        { Key: 'ReviewText', FilterFormLabel: 'Review Text' , Type: FilterType.String, FilterPropName: 'ReviewText'},
        { Key: 'ReviewerDisplayName', FilterFormLabel: 'Reviewer Display Name', Type: FilterType.String, FilterPropName: 'ReviewerDisplayName'},
        { Key: 'HasReply', FilterFormLabel: 'Reply Status', Type: FilterType.String, FilterPropName: 'HasReply', Options: { stringTextFormatter: replystringTextFormatter }},

        { Key: 'OverallRating', FilterFormLabel: 'Overall Experience Rating' , Type: FilterType.Range, FilterPropName: 'OverallRatingExpr'},
        { Key: 'CleanlinessRating', FilterFormLabel: 'Cleanliness Rating' , Type: FilterType.Range, FilterPropName: 'CleanlinessRatingExpr'},
        { Key: 'FriendlinessRating', FilterFormLabel: 'Friendliness Rating' , Type: FilterType.Range, FilterPropName: 'FriendlinessRatingExpr'},
        { Key: 'SafetySecurity', FilterFormLabel: 'Safety / Security Rating' , Type: FilterType.Range, FilterPropName: 'SafetySecurityRatingExpr'},
        { Key: 'NursingCareRating', FilterFormLabel: 'Nursing Care Rating' , Type: FilterType.Range, FilterPropName: 'NursingCareRatingExpr'},
        { Key: 'StaffRating', FilterFormLabel: 'Staff Rating' , Type: FilterType.Range, FilterPropName: 'StaffRatingExpr'},
        { Key: 'DiningRating', FilterFormLabel: 'Meals & Dining Rating' , Type: FilterType.Range, FilterPropName: 'DiningRatingExpr'},
        { Key: 'ActivitiesRating', FilterFormLabel: 'Activities Rating' , Type: FilterType.Range, FilterPropName: 'ActivitiesRatingExpr'},
        { Key: 'RehabTherapyRating', FilterFormLabel: 'Rehab / Therapy Rating' , Type: FilterType.Range, FilterPropName: 'RehabTherapyRatingExpr'},
        { Key: 'FacilitiesRating', FilterFormLabel: 'Facilities Rating' , Type: FilterType.Range, FilterPropName: 'FacilitiesRatingExpr'},

        { Key: 'NHID', FilterFormLabel: 'NHID' , Type: FilterType.Number, FilterPropName: 'NHID', Hidden: true},
        { Key: 'ReviewID', FilterFormLabel: 'Review ID' , Type: FilterType.Number, FilterPropName: 'ReviewID'}
    ]);
};

export const getReviewDataParams = (
    requestParams: any,
    pageOffset: number, 
    pageLimit: number,
    sortField: string,
    sortOrder: string,
    publicationStatusType: ReviewPublicationStatusType,
    forExport: boolean
) => {

    return forExport ? requestParams : {
        ...requestParams,         
        PublicationStatusKeyName: publicationStatusType,
        Offset: pageOffset,
        Limit: pageLimit,
        SortOrder: sortOrder || 'DESC',
        SortField: sortField || 'LastPublishedDate'
    };
};

export const createOrUpdateReply = async (reviewID: number, replyData: IReply)
    : Promise<IPortalReplyItem> => {

    const saveFn = replyData.ReplyID ? updateReply : createReply;

    const savedReply = await saveFn(reviewID, replyData);

    const {
        ReplyID,
        ReplierEmail,
        ReplierFirstName,
        ReplierLastName,
        ReplierPosition,
        ReplyContent,
        ReplyStatusReply
    } = savedReply;

    const AuxiliaryPublicationStatusID = get(ReplyStatusReply, 'ReplyAuxiliaryStatusID', null);
    const AuxiliaryStatusKeyName = get(ReplyStatusReply, 'ReplyAuxiliaryStatus.KeyName');
    const AuxiliaryPublicationStatusName  = get(ReplyStatusReply, 'ReplyAuxiliaryStatus.ExternalDescription');

    const reviewReply: IPortalReplyItem = {
        ReplyID,
        ReplierEmail,
        ReplierFirstName,
        ReplierLastName,
        ReplierPosition,
        ReplyContent,
        AuxiliaryPublicationStatusID,
        AuxiliaryStatusKeyName,
        AuxiliaryPublicationStatusName,
    };

    return reviewReply;
};

export const fetchReviewData = async (params: any): Promise<[any[], number, number]> => {

    const data = await getReviews(params);

    const rowData = get(data, 'data', []);
    const totalFilteredRows = get(data, 'totalFilteredRows', 0);
    const totalRows = get(data, 'totalRows', 0);
    const tableData = rowData.map((item: any) => ({
        id: item.ReviewID,
        ...item,
        defaultExpanded: true
    }));

    return [ tableData, totalRows, totalFilteredRows];
};

export const buildColumns = (mode: 'published' | 'pending') => {

    const appContext: any = useContext(AppContext);

    const cols = [
        {
            name: 'Review ID',
            selector: (row: any) => <div>{row.ReviewID} </div>,
            sortable: true,
            sortField: 'ReviewID',
            width: '120px'
        },
        {
            name: 'Facility Name',
            selector: (row: any) => <div>{row.Name} </div>,
            sortable: true,
            sortField: 'PropertyName',
            width: appContext.isMobile ? '180px' : '270px',
            wrap: 1,
            id: 'community'
        },
        {
            name: 'Display Name',
            selector: (row: any) => <div>{row.ReviewerDisplayName}</div>,
            sortable: true,
            sortField: 'ReviewerDisplayName',
            width: '200px',
            wrap: 1,
            id: 'name'
        },
        {
            name: 'Submitted Date',
            cell: (row: any) => <div>
                <span className='label label-dot label-success mr-2'></span>
                <span>
                    { moment(row.CreatedAt).format('MMMM Do YYYY') }
                </span>
            </div>,
            sortable: true,
            sortField: 'CreatedAt',
            width: '230px',
            id: 'submitted'
        },
       
       
    ];

    // cols.push( {
    //     name: `${published ? 'Publication Date' : 'Reason Not Published'}`,
    //     cell: (row: any) => <div>
    //         <span className='label label-dot label-success mr-2'></span>
    //         <span>
    //             { published ? moment(row.LastPublishedDate).format('MMMM Do YYYY h:mm a') : row.PublicationAuxiliaryExternalDescription }
    //         </span>
    //     </div>,
    //     sortable: true,
    //     sortField: 'LastPublishedDate',
    //     width: `${published ? '250px' : '220px'}`,
    //     id: 'published'
    // });

    if (mode === 'published') {
        cols.push( {
            name: 'Publication Date',
            cell: (row: any) => <div>
                <span className='label label-dot label-success mr-2'></span>
                <span>
                    { moment(row.LastPublishedDate).format('MMMM Do YYYY h:mm a') }
                </span>
            </div>,
            sortable: true,
            sortField: 'LastPublishedDate',
            width: '250px',
            id: 'published'
        });
    }

    if (mode === 'pending') {
        cols.push( {
            name: 'Pending Publication Date',
            cell: (row: any) => <div>
                <span className='label label-dot label-success mr-2'></span>
                <span>
                    { moment(row.PendingPublicationDate).utc().format('MMMM Do YYYY') }
                </span>
            </div>,
            sortable: true,
            sortField: 'PendingPublicationDate',
            width: '250px',
            id: 'pending'
        });
    }

    cols.push( {
        name: 'Overall Experience',
        cell: (row: any) => 
            <div>
                <VisibleRatingStars 
                    keyProp={row.ReviewID} 
                    value={row.OverallRating}
                />
            </div>,
        sortable: true,
        sortField: 'OverallRating',
        width: '200px',
        id: 'ratings'
    });

    return cols;
};

export const renderFilters = (
    filterMatrix: FilterMatrix,
    applyFilter: any,
    getReviewData: any,
    published: boolean,
    totalReviewCount: number,
    totalFilteredReviewCount: number,
    busy: boolean,
    pageOffset: number,
    pageLimit: number,
    startExport: any,
    filtersOpen = false,
    setFiltersOpen?: (open: boolean) => void,
    applyReviewScoreFilters?: () => void
) => {

    return (
        <>
            <Accordion className="filters">
                <Accordion.Item className="border-top-0" eventKey="0">
                    <Accordion.Header
                        className="accordion-header"
                        onClick={() => setFiltersOpen(!filtersOpen)}
                    >
                        Filters
                    </Accordion.Header>
                    <Accordion.Body className="accordion-body p-0">
                        <ReviewFilters
                            filterMatrix={filterMatrix}
                            applyFilter={applyFilter}
                            refreshData={getReviewData}
                            published={published}
                            applyReviewScoreFilters={applyReviewScoreFilters}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="table-info p-4 pb-4">
                <div className="row align-items-center">
                    <div className="col-lg-8 mb-4 mb-lg-0">
                        <ActiveFilters 
                            filterMatrices={[filterMatrix]} 
                            totalFilteredSize={totalFilteredReviewCount}
                            totalSize={totalReviewCount}
                            isLoading={busy}
                            pageOffset={pageOffset}
                            pageLimit={pageLimit}
                            singularNoun={'Published Review'}
                            pluralNoun={'Published Reviews'}
                            showTotals={true}
                            applyFilter={applyFilter}
                            showResetLink={true}
                            refreshData={getReviewData}
                        />
                    </div>

                    <div className="col-lg-4 text-end">
                        <CsvExport 
                            startExportFunction={startExport}
                            modalTitleSuffix={'Reviews'}
                            label="Export as CSV"
                            displayType={DisplayType.Button}
                            fileName={`${formatFileNameForCsvExport('review_export')}`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const buildLoadingMessage = (filterMatrix: FilterMatrix) => {

    const numAppliedFilters = filterMatrix.getActiveCount();

    const msg = numAppliedFilters > 0 ? 
        `Applying ${numAppliedFilters} ${numAppliedFilters === 1 ? 'filter' : 'filters'} - see results below`
        : 'Fetching reviews - see results below';

    return msg;
};