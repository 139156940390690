import React from 'react';
import parse from 'html-react-parser';
import DataTable from '../common/data_table';

import { formatDateMMMDDYYYY } from '../../utils/date';
import LeadEmailTableExpandable from './lead_email_table_expandable';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { LoadingSpinner } from '../common/loading_spinner';

type Props = {
    filterMatrix: any;
    data: any[];
    totalRows: number;
    handlePageChange(pageNumber: number): void;
    handleLimitChange(newPerPage: number, pageNumber: number): void;
    handleSortChange(column: TableColumn<any>, sortOrder: SortOrder): void;
    limit: number;
    loading: boolean;
};

export default function LeadEmailTable(props: Props) {
    const columns = [
        {
            name: 'Inquiry ID',
            sortable: true,
            sortField: 'EnquiryID',
            cell: (row: any) => (
                <a href="#" className='fw-bolder d-block'>{row.EnquiryID}</a>
            ),
        },
        {
            name: 'Inquiry Type',
            sortable: true,
            sortField: 'TemplateTypeID',
            cell: (row: any) => (
                <div className="py-4">{row.TemplateTypeName}</div>
            ),
        },
        {
            name: 'Facility',
            minWidth: '300px',
            cell: (row: any) => (
                <div>
                    <strong>{row.PropertyName}</strong>
                    <p>{row.PropertyAddress}</p>
                </div>
          ),
        },
        {
            name: 'Inquirer Name',
            cell: (row: any) => (
                <div>{row.SenderFirstName} {row.SenderLastName}</div>
          ),
        },
        {
            name: 'Inquirier Email',
            cell: (row: any) => (
                <div>{row.SenderEmail}</div>
            ),
        },
        {
            name: 'Inquiry Date',
            cell: (row: any) => (
                <div>{formatDateMMMDDYYYY(row.CreatedAt)}</div>
          ),
        },
        {
            name: '	Inquiry',
            minWidth: '400px',
            cell: (row: any) => {
                if (!row.SenderInquiry) {
                    return null;
                }

                if (row.SenderInquiry.length > 200) {
                    return (
                        <div className="py-4">
                            {parse(row.SenderInquiry.substring(0, 200)) + '...'}
                            <button className="btn btn-link d-block mt-2">Read More</button>
                        </div>
                    );
                }

                return (
                    <div className="py-4">
                        {parse(row.SenderInquiry)}
                    </div>
                );
            }
        }        
    ];

    if (props.loading) {
        return (
            <div className="py-4 w-100 text-center position-relative">
                <LoadingSpinner show={true} showInPlace={true} />
            </div>
        );
    }

    if (!props.data || !props.data.length) {
        return (
            <div className="py-4 w-100 text-center">
                <h5>There are no results for this search.</h5>
            </div>
        );
    }

    return (
        <DataTable
            columns={columns}
            data={props.data}
            expandableRows
            expandableRowsComponent={({ data }: any) => <LeadEmailTableExpandable data={data} />}
            pagination={true}
            paginationServer={true}
            paginationTotalRows={props.totalRows}
            onChangePage={props.handlePageChange}
            onChangeRowsPerPage={props.handleLimitChange}
            onSort={props.handleSortChange}
            sortServer={true}
            initialRowsPerPage={props.limit}
            defaultExpandedIDProp="EnquiryID"
        />
    );
}
