import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageCommunitySelect from '../../components/common/page_community_select';
import { getCommunityMode, getSingleProperty  } from '../../utils/common';
import { ISessionState, PropertyMode } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { saveAs } from 'file-saver';
import _, { get } from 'lodash';
import axios from 'axios';

import {
    renderReviewScoreCertLink,
    reviewScoreCertificatePdfURL,
    reviewScoreCertFilename,
} from './review_score_certs_common';
import ReviewScoreDisplayFormatted from '../../components/common/review_score_display_formatted';
import { getReviewScoreForNHID } from '../../api/review';
import { IReviewScore } from '../../interfaces/review';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const showAdditional = false;

const ReviewScoreCert =({ nhIDs, orgNHID }: IProps) => {
    const [selNHID, setSelNHID] = useState<number>(null);
    const [reviewScoreCertExtraParams, /*setReviewScoreCertExtraParams*/] = useState<string>('');
    const [reviewScoreCertURL, setReviewScoreCertURL] = useState<string>('');
    const [includeReviewScoreBreakdown, /*setIncludeReviewScoreBreakdown*/] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reviewScoreCertDownloading, setReviewScoreCertDownloading] = useState<boolean>(false);
    const [reviewScore, setReviewScore] = useState<number>();

    const location = useLocation();
    const state = location?.state as any;
    const stateNHID = state?.NHID || null;

    useEffect(() => {

        if (orgNHID && !stateNHID) {
            return;
        }

        const nhID = nhIDs?.length === 1 ? nhIDs[0] : stateNHID;

        if (nhID) {   
            setSelNHID(nhID);
        }
    }, [orgNHID, nhIDs]);

    useEffect(() => {
        createReviewScoreCertificatePdfURL();
    });

    useEffect(() => {
        fetchReviewScore();
    });

    useEffect(() => {
        createReviewScoreCertificatePdfURL();
    }, [includeReviewScoreBreakdown, reviewScoreCertExtraParams]);

    const { Session, Org, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType } = Session;

    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const propertyMode = getCommunityMode(orgNHID, nhIDs, selNHID);

    const fetchReviewScore = async () => {
        const firstProperty: any = get(Properties, '[0]');
        const nhID = selNHID 
            ? selNHID 
            : propertyMode === PropertyMode.SingleProperty 
                ? nhIDs[0]
                : firstProperty.OrgNHID;

        if (nhID) {
            const reviewScore: IReviewScore = await getReviewScoreForNHID(nhID);
            setReviewScore(reviewScore.ReviewScoreDisplay);
        } else {
            setReviewScore(null);
        }
    };
    
    const createReviewScoreCertificatePdfURL = () => {

        const selNHIDs = selNHID ? [selNHID] : nhIDs;

        const url = reviewScoreCertificatePdfURL(
            selNHIDs, 
            orgNHID,
            reviewScoreCertExtraParams
        );

        setReviewScoreCertURL(url);
    };

    // const updateReviewScoreCertExtraParams = (val: string) => {
    //     setReviewScoreCertExtraParams(val);
    // };

    // const updateIncludeReviewScoreBreakdown = (val: boolean) => {
    //     setIncludeReviewScoreBreakdown(val);
    // };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const handleReviewScoreCertDownload = async (url: string) => {

        if (reviewScoreCertDownloading) {
            return;
        }
        
        try {
            setIsLoading(true);
            setReviewScoreCertDownloading(true);
            const resp = await axios.get(url,{
                responseType: 'blob',
            });

            const data = _.get(resp, 'data');
            saveAs(data, reviewScoreCertFilename(singleProperty, SessionType, Org));
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
            setReviewScoreCertDownloading(false);
        }
    };

    return (
        <>
            { isLoading ? <LoadingSpinner show={true}/> : null }
            { 
                showAdditional ? renderReviewScoreCertLink(
                    nhIDs,
                    reviewScoreCertURL,
                    singleProperty,
                    SessionType,
                    Org,
                    handleReviewScoreCertDownload,
                    reviewScoreCertDownloading
                ): null
            }
            <div className="widget">
                <div className="widget-body">
                    <div className="card" data-card="Generate a Review Certificate">
                        <div className="card-header">
                            <h4>
                                Generate a Review Certificate
                            </h4>
                        </div>
                        <div className="card-body">
                            <div className="card-info-box">
                                <h5>
                                    Where do I use my Review Score Certificate
                                </h5>
                                <p>
                                    We would recommend always displaying your most recent Review Score Certificate in your reception area(s) so residents, and visiting family & friends can view your most up-to-date score.
                                </p>
                                <div className="d-flex">
                                    <div className="d-flex align-items-center justify-content-center me-2">
                                        <ReviewScoreDisplayFormatted value={reviewScore} showAsNotApplicableOnNull={true}/>
                                    </div> 
                                    <p className="p-2">
                                        We would also recommend sharing your latest Review Score Certificate on your social media and website.
                                    </p>
                                </div>
                            </div>
                            <div className="card-info">
                                {
                                    orgNHID || nhIDs?.length > 1 ?
                                    <span>
                                        <label>
                                            <strong>
                                                Select a facility:
                                            </strong>
                                        </label>
                                        <PageCommunitySelect 
                                            handleSelect={handleSelect} 
                                            selNHID={selNHID}
                                        />
                                    </span>
                                    : null
                                }
                                { 
                                    renderReviewScoreCertLink(
                                        nhIDs,
                                        reviewScoreCertURL,
                                        singleProperty,
                                        SessionType,
                                        Org,
                                        handleReviewScoreCertDownload,
                                        reviewScoreCertDownloading
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReviewScoreCert;