import { ISettingsState } from '../../interfaces/settings';
import { IAction } from '../../interfaces/store';


const DEFAULT_STATE: ISettingsState = {
    SideBarMinimise: false
};

export default (state = DEFAULT_STATE, action: IAction) => {
    switch (action.type) {
        case 'SET_SIDEBAR_MINIMISE':

            state = {
                ...state,
                SideBarMinimise: <boolean>action.payload
            };
            break;
    }

    return state;
};
