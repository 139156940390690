import React, { useEffect, useState } from 'react';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { PropertyMode } from '../../interfaces/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/pro-solid-svg-icons';

import { 
    INhPortalScoreItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { 
    mapProfileExtraDatatoDisplay,
} from '../../utils/profile_completeness';
import { LoadingSpinner } from '../common/loading_spinner';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    propertyMode: PropertyMode,
    widgetSize: 'small' | 'large';
}

export const ProfileCompletnessWidgetOther = ({  
    nhIDs, 
    orgNHID, 
    selNHID, 
}: IProps) => {

    const [ scoreExtra, setScoreExtra ] = useState<IScoreExtraDisplayItem>();

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreExtra = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
        setScoreExtra(scoreExtra);

}, [ orgNHID, nhIDs, selNHID, profileCompletenessState ]);

    if (!scoreExtra) {
        return <LoadingSpinner/>;
    }

    const {
        FacilityCount,
        HasPhoneNumberCount,
        HasEmailAddressCount,
        HasWebsiteCount,
        HasPortalUsersCount,
    } = scoreExtra;

    const scoreExtraArray = [
        [HasPhoneNumberCount, FacilityCount, 'Inquiry Phone Number', '/contacts'],
        [HasEmailAddressCount, FacilityCount, 'Inquiry Email Address(es)', '/contacts'],
        [HasWebsiteCount, FacilityCount, 'Website Address', '/contacts'],
        [HasPortalUsersCount, FacilityCount, 'Covered by a 1+ Portal Users', '/users'],
    ];

    const orgIsSelected = orgNHID && !selNHID;

    const renderWidget = () => {
        return (
            <>
                <div className="card" data-card="Profile Completeness">
                    <div className="card-header">
                        <h4>
                            Contact Details &amp; Portal Users
                        </h4>
                    </div>
                    <div className="card-body flex-col pt-1 pb-1">

                        <table className="table table-row-bordered table-row-gray-300 align-middle gx-0 gy-3 mb-0 transparent">
                            <tbody>
                                {
                                    scoreExtraArray.map((item: any) => 
                                    <>
                                    <tr>
                                        <th>
                                        {
                                            orgIsSelected || FacilityCount === 1 ?
                                            <a className="text-link text-nowrap" href={item[3]}>
                                                <div className={`d-flex align-items-center ${item[0] === FacilityCount ? 'text-success' : 'text-danger'} fw-bold`}>
                                                    <FontAwesomeIcon icon={ item[0] === FacilityCount ? faCheck : faXmark} className="me-2" />
                                                </div> 
                                            </a>: null
                                        }
                                        </th>
                                        <td>
                                            <div className="d-flex">
                                                {
                                                    FacilityCount > 1 ?
                                                        <a className="text-link text-nowrap" href={item[3]}>
                                                            <span className="badge badge-light text-primary fs-5 fw-normal">
                                                                {item[0]}/{item[1]}
                                                            </span>
                                                        </a>: null
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-primary">
                                                <a className="text-link text-nowrap" href={item[3]}>
                                                    <span className="pe-4">{item[2]}</span>
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex-root d-flex justify-content-center text-primary">
                                                <a className="text-link text-nowrap" href={item[3]}>View</a>
                                            </div>
                                        </td>
                                        </tr>
                                    </>
                                    )
                                }
                            </tbody>
                        </table>

                    </div>
                </div>  
            </>
        );    
    };

    return (
        <>
            { renderWidget() }
        </>
    );
};
