import React, { FC, useState } from 'react';
import { DeleteEntityDialog } from '../../../../../components/common/delete_entity_dialog';
import { Button, ButtonProps } from '../../../../../components/common/button';
import { useDeleteTeamMemberMutation } from '../../hooks/useDeleteTeamMemberMutation';

interface Props {
    styleType?: ButtonProps['styleType'];
    className?: string;
    isInSinglePropertyContext?: boolean;
    memberDetails: {
        propertyId: number;
        id?: number;
        firstName: string;
        lastName: string;
    };
}

const DeleteTeamMemberDialogButton: FC<Props> = (props) => {
    const {
        styleType = 'outline',
        memberDetails,
        className,
        isInSinglePropertyContext = false
    } = props;
    const { firstName, lastName, propertyId, id } = memberDetails;

    const mutate = useDeleteTeamMemberMutation({
        propertyId,
        memberId: id,
        includeIdInQueryKey: isInSinglePropertyContext
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleIsOpen = () => setIsDialogOpen((isOpen) => !isOpen);

    const handleDelete = async () => {
        mutate();
    };

    return (
        <>
            <DeleteEntityDialog
                onClose={toggleIsOpen}
                isOpen={isDialogOpen}
                title="Delete member"
                subtitle={`Are you sure you want to remove ${firstName} ${lastName} from this team?`}
                onConfirm={handleDelete}
            />
            <Button
                onClick={toggleIsOpen}
                size="small"
                styleType={styleType}
                className={className}
            >
                Delete
            </Button>
        </>
    );
};

export { DeleteTeamMemberDialogButton };
