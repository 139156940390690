import React, { useState, FormEvent } from 'react';

interface IPortalUserEmailCheckerFormProps {
    handleEmailExistsCheck(emailToCheck: string) : void;
}

function EmailExistsForm(props : IPortalUserEmailCheckerFormProps) {
    const [emailToCheck, setEmailToCheck] = useState('');
  
    const handleEmailToCheckValue = (e: any) => {
        setEmailToCheck(e.target.value);
    };
    
    const handleOnSubmit = (e: FormEvent) => {
        e.preventDefault();
        props.handleEmailExistsCheck(emailToCheck);
    };

    return (
        <>
            <form id="emailChecker" onSubmit={handleOnSubmit}>
                <div className="row mt-3 align-items-end col-md-12">
                    <div className='col-md-10'>  
                        <label htmlFor="emailCheck">Email</label>
                        <input
                            type="email"
                            id="emailToCheck"
                            max="120"
                            value={emailToCheck || ''}
                            className="form-control"
                            onChange={handleEmailToCheckValue}
                            placeholder="Enter email"
                            required
                        /> 
                    </div>
                    <div className='col-md-2'>  
                        <button
                            className="btn btn-primary"
                            type='submit'
                        >
                            Next
                        </button> 
                    </div>
                </div>
            </form>          
        </>
    );
}

export default EmailExistsForm;