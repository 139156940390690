import { secureHttp } from '../utils/request';

export const startExport = async (url: string, params?: unknown): Promise<string> => {
    try {
        const { data: { data } } = await secureHttp.get(`${url}`, {params});
        return data.jobID;
    } catch (e) {
        return '';
    }
};

export const getCsvExportStatus = async (jobID: string) => {
    try {
        const { data: { data } } = await secureHttp.get(`/export/csv/status/${jobID}`);
        return data;
    } catch (e) {
        return {};
    }
};

export const cancelCsvExport = async (jobID: string) => {
    try {
        const { data: { data } } = await secureHttp.get(`/export/csv/cancel/${jobID}`);
        return data;
    } catch (e) {
        return {};
    }
};

export const downloadCsvExport = async (jobID: string) => {
    try {
        const { data: { data } } = await secureHttp.get(`/export/csv/download/${jobID}`);
        return data;
    } catch (e) {
        return {};
    }
};