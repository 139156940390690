import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, useNavigate  } from 'react-router-dom';
import { tokenLogin, logout } from '../../modules/login';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

const TokenLogin = () => { 

    const navigate = useNavigate();

    const [success, setSuccess] = useState<boolean>(false);

    const qp = useLocation().search;
    const token = new URLSearchParams(qp).get('Token');

    if (!token) {
        return <Navigate to={'/login'}/>;
    }

    useEffect(() => {
        if (success) {
            navigate('/');
        }
    }, [success]);

    useEffect(() => {
        const login = async() => {
            try {
                await logout();
                await tokenLogin(token);
                updateProfileCompleteness();

                setSuccess(true);
            } catch(e) {
                navigate('/login');
            } 
        };

        login();
    }, []);

    return (
       <>
            <h4>Logging in...</h4>
        </>
        
    );
};

export default TokenLogin;