import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { getReviewCountTotals } from '../../api/review';
import { IReviewCountTotals } from '../../interfaces/review';
import { formatFromDateForAPIRequestUTC } from '../../utils/date';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    setIsLoading: any;
    isLoading: boolean;
}

export const ReviewsWidget = ({ nhIDs, orgNHID, selNHID, setIsLoading, isLoading }: IProps) => { 
    const [ reviewCounts, setReviewCounts ] = useState<IReviewCountTotals>(null);
    const [ , setDate30DaysAgo ] = useState<Date>();
    const [ date90DaysAgo, setDate90DaysAgo ] = useState<Date>();

    useEffect(() => {        
        setIsLoading(true);

        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);

        const date30DaysAgo = new Date(dateNow);
        date30DaysAgo.setDate(date30DaysAgo.getDate() - 30);
        setDate30DaysAgo(date30DaysAgo);

        const date90DaysAgo = new Date(dateNow);
        date90DaysAgo.setDate(date90DaysAgo.getDate() - 90);
        setDate90DaysAgo(date90DaysAgo);
    }, []);

    useEffect(() => {        
        if (!orgNHID && !nhIDs) {
            return;
        }

        const fetchData = async (): Promise<void> => { 
            
            try {

                const dateNow = new Date();
                dateNow.setHours(0, 0, 0, 0);
                const todayUTC = formatFromDateForAPIRequestUTC(dateNow);
                const data = await getReviewCountTotals(selNHID, todayUTC);
                setReviewCounts(data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

       fetchData();
    }, [ orgNHID, nhIDs, selNHID ]);
    

    const formatNumbers = (val: number) => {
        return (
            <>
                { isLoading ? 
                <>&nbsp;</> : 
                <>{val.toString()}</>
                }
            </>
        );
    };

    const renderNumber = (number: number, pulsePositive = false) => {
        if (pulsePositive && number) {
            return (
                <em className="pulse">
                    { formatNumbers(number) }
                </em>
            );
        }

        return formatNumbers(number);
    };

    const renderButton = (text: string, url: string, count: number, filters?: any) => {
        if (!count) {
            return (
                <span className="btn btn-primary disabled">
                    { text }
                </span>  
            );
        }

        return (
            <NavLink 
                className="btn btn-primary"
                to={url}
                state={{filters}}
            >
                { text }
            </NavLink>  
        );
    };

    const renderWidget = () => {
        let totalPublished = 0;
        let totalPendingPublication = 0;
        let totalPublishNoReplyLast90 = 0;

        if (reviewCounts) {
            
            const {
                TotalPublished,
                TotalPendingPublication,
                TotalPublishNoReplyLast90
            } = reviewCounts;

            totalPublished = TotalPublished || 0;
            totalPendingPublication = TotalPendingPublication || 0;
            totalPublishNoReplyLast90 = TotalPublishNoReplyLast90 || 0;
        }

        return (
            <>
                <div className="card mb-9 mb-md-0">
                    <div className="card-header">
                        <h4>
                            Pending Publication Reviews
                        </h4>
                    </div>
                    <div className="card-body flex-row">
                        <span className="card-stat">
                            { renderNumber(totalPendingPublication, true) }
                        </span>
                        { 
                            renderButton(
                                'View & Publish',
                                '/pendingreviews', 
                                totalPendingPublication, 
                                { NHID: selNHID }
                            ) 
                        }
                    </div>
                </div>  

                <div className="card mb-9 mb-md-0">
                    <div className="card-header">
                        <h4>
                            Reviews Published in the last 90 days with No Reply
                        </h4>
                    </div>
                    <div className="card-body flex-row">
                        <span className="card-stat">
                            { renderNumber(totalPublishNoReplyLast90, true) }
                        </span>
                        { 
                            renderButton(
                                'View & Reply',
                                '/reviews', 
                                totalPublishNoReplyLast90, 
                                {
                                    DatePublishedFrom: date90DaysAgo,
                                    HasReply: false,
                                    NHID: selNHID
                                }
                            ) 
                        }
                    </div>
                </div>  
                
                <div className="card mb-9 mb-md-0">
                    <div className="card-header">
                        <h4>
                            All Published Reviews
                        </h4>
                    </div>
                    <div className="card-body flex-row">
                        <span className="card-stat">
                            { renderNumber(totalPublished) }
                        </span>
                        { 
                            renderButton(
                                'View Reviews',
                                '/reviews', 
                                totalPublished, 
                                { NHID: selNHID }
                            ) 
                        }
                    </div>
                </div>                                    
            </>
        );    
    };

    return (
        <>
            { renderWidget() }
        </>
    );
};
