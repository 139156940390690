import React from 'react';
// import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-datepicker';
// import { useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';
// import * as csvEportAPI from '../../api/csv_export';
// import { getReviewRatings } from '../../api/review';
// import DataTable from '../../components/common/data_table';
// import { LoadingSpinner } from '../../components/common/loading_spinner';
// import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
// import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
// import { IReviewRating, IReviewRatingsFilters } from '../../interfaces/review';
// import { ISessionMember, ISessionState, SessionType as EnumSessionType } from '../../interfaces/session';
// import { formatFileNameForCsvExport } from '../../utils/file';
// import { useLocation } from 'react-router-dom';

// interface IProps {
//     memberIDs: number[];
//     groupID: number;
// }

// const ReviewRatings = ({ memberIDs, groupID }: IProps) => {
    const ReviewRatings = () => {
        return (<></>);  
    // const location = useLocation();
    // const state = location?.state as any;
    // const stateMemberID = state?.MemberID || null;

    // const [ selMemberID, setSelMemberID ] = useState<number>(stateMemberID);
    // const [ isLoading, setIsLoading ] = useState<boolean>();
    // const [ ratings, setRatings ] = useState<IReviewRating[]>();
    // const [ useTotalsRow, setUseTotalsRow ] = useState<boolean>(false);
    // const [ filters, setFilters ] = useState<IReviewRatingsFilters>({
    //     DateFrom: null,
    //     DateTo: null
    // });

    // const { Session, Group, Members }: ISessionState = useSelector(
    //     ({ SessionState }: any) => SessionState
    // );
    
    // const { SessionType } = Session;

    // useEffect(() => {        
    //     if (!groupID && !memberIDs) return;

    //     const fetchWinners = async (): Promise<void> => {    
    //         try {
    //             setIsLoading(true);

    //             const params = {
    //                 ...filters,
    //                 MemberID: selMemberID
    //             };

    //             const ratings: IReviewRating[] = await getReviewRatings(params);

    //             setUseTotalsRow(ratings.length > 1);
    //             setRatings(ratings);
    //         } catch (e) {
    //             console.error(e);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //    fetchWinners();
    // }, [ groupID, memberIDs, filters, selMemberID ]);

    // const applyFilter = (key: string, value: any) => setFilters((prevState: any) => ({
    //     ...prevState,
    //     [key]: value
    // }));

    // const isSingleMember = (() => memberIDs?.length === 1)();

    // const columns = [
    //     {
    //         name: 'Facility Name',
    //         selector: (row: IReviewRating) => row.Name,
    //         cell: (row: IReviewRating) => formatFacility(row),
    //         sortable: true,
    //         grow: 2
    //     },
    //     {
    //         name: <em># of Reviews</em>,
    //         selector: (row: IReviewRating) => row.TotalReviews,
    //         center: true,
    //         sortable: true,
    //         minWidth: '120px'
    //     },
    //     {
    //         name: <>Overall Rating</>,
    //         selector: (row: IReviewRating) => row.AvgOverallValue,
    //         center: true,
    //         sortable: true
    //     },
    //     {
    //         name: <>Cleanliness</>,
    //         selector: (row: IReviewRating) => row.AvgCleanlinessValue,
    //         center: true,
    //         sortable: true,
    //         minWidth: '140px'
    //     },
    //     {
    //         name: <>Friendliness</>,
    //         selector: (row: IReviewRating) => row.AvgFriendlinessValue,
    //         center: true,
    //         sortable: true,
    //         minWidth: '140px'
    //     },
    //     {
    //         name: <>Care Services</>,
    //         selector: (row: IReviewRating) => row.AvgCareServicesValue,
    //         center: true,
    //         sortable: true,
    //         minWidth: '120px'
    //     },
    //     {
    //         name: <>Staff</>,
    //         selector: (row: IReviewRating) => row.AvgStaffValue,
    //         center: true,
    //         sortable: true
    //     },
    //     {
    //         name: <>Meals &amp; Dining</>,
    //         selector: (row: IReviewRating) => row.AvgMealsAndDiningValue,
    //         center: true,
    //         sortable: true
    //     },
    //     {
    //         name: <>Activities</>,
    //         selector: (row: IReviewRating) => row.AvgActivitiesValue,
    //         center: true,
    //         sortable: true
    //     },
    //     {
    //         name: <>Value for Cost</>,
    //         selector: (row: IReviewRating) => row.AvgValueForCostValue,
    //         center: true,
    //         sortable: true
    //     }
    // ];

    
    // const formatFacility = ({ MemberID, Name, Address, TotalsRow }: IReviewRating) => {
    //     if (TotalsRow) {
    //         return (
    //             <div className="pt-4 pb-4">
    //                 <span className="fw-bold">{ Name }</span>
    //             </div>
    //         ); 
    //     }

    //     return (
    //         <div className="pt-4 pb-4">
    //             <strong>{ Name }</strong><br /><small>{ Address }</small><br />
    //             <NavLink
    //                     to={'/reviews'}
    //                     state={{
    //                         filters: {
    //                             MemberID
    //                         }
    //                     }}
    //                 >
    //                     View all Reviews
    //             </NavLink>
    //         </div>
    //     );
    // };

    // const renderDataTable = () => {
    //     if (isLoading) return <LoadingSpinner  show={true}/>;

    //     return (
    //         <DataTable
    //             columns={columns}
    //             data={ratings}
    //             pagination={true}
    //             useTotalsRow={useTotalsRow}
    //             fixedHeader={false}
    //         />
    //     );
    // };

    // const handleSelect = (memberID: number | null) => {
    //     setSelMemberID(memberID);
    // };

    // const startExport = async (): Promise<string> => {
    //     return csvEportAPI.startExport('/review/ratings/export', {
    //         ...filters,
    //         MemberID: selMemberID
    //     });
    // };

    // const selectedMember = ((): ISessionMember => {
    //     return Members.find((m: ISessionMember) => m.MemberID === selMemberID);
    // })();

    // const currentFileName = ((): string => {
    //     const name = selMemberID
    //         ? selectedMember?.Name 
    //         : SessionType === EnumSessionType.SessionTypeGroup  
    //             ? Group?.Name
    //             : isSingleMember ? Members[0]?.Name : 'multiple_members';

    //     const fileName = `${name}_all_ratings`;

    //     return formatFileNameForCsvExport(fileName);
    // })();

    // return (
    //     <>
    //         <PageCommunitySelectWrapper
    //             label={'All Ratings for'}
    //             handleSelect={handleSelect}
    //             selMemberID={selMemberID}
    //         />
    //         <div className="widget widget-fullwidth">
    //             <div className="row widget-body">
    //                 <div className="col-12">
    //                     <div className="card">
    //                         <div className="card-body p-0">
    //                             <div className="table-info p-4 pb-4">
    //                                 <div className="row align-items-end mb-4">
    //                                     <div className="col-12 col-md-4 mb-3 mb-md-0">
    //                                         <label className='mb-2'>Date Submitted From</label>
    //                                         <DatePicker 
    //                                             className='form-control' 
    //                                             dateFormat={'dd MMM yyyy'}
    //                                             selected={filters.DateFrom}
    //                                             placeholderText="Click to select a date"
    //                                             maxDate={filters.DateTo ?? new Date()}
    //                                             onChange={(date: Date) => {
    //                                                 if (date) date.setHours(0, 0, 0, 0);
    //                                                 applyFilter('DateFrom', date);
    //                                             }}
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 mb-3 mb-md-0">
    //                                         <label className='mb-2'>Date Submitted To</label>
    //                                         <DatePicker 
    //                                             className='form-control' 
    //                                             dateFormat={'dd MMM yyyy'}
    //                                             selected={filters.DateTo}
    //                                             placeholderText="Click to select a date"
    //                                             minDate={filters.DateFrom}
    //                                             maxDate={new Date()}
    //                                             onChange={(date: Date) => {
    //                                                 if (date) date.setHours(23, 59, 59, 999);
    //                                                 applyFilter('DateTo', date);
    //                                             }}
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 text-center text-md-end">
    //                                         <CsvExport 
    //                                             startExportFunction={startExport}
    //                                             modalTitleSuffix={'All Ratings'}
    //                                             label="Export as CSV"
    //                                             displayType={DisplayType.Button}
    //                                             fileName={currentFileName}
    //                                         />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className="ratings_dt sticky_dt table_dt">
    //                                 { renderDataTable() }
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
};

export default ReviewRatings;