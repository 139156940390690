import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as tourAPI from '../../../../api/tours';
import { ITourDefaultValues, ITourFormData } from '../types';
import { useSessionState } from '../../../../hooks/use_session_state';
import _ from 'lodash';
import { useGetTourTypes } from './useGetTourTypes';
import { moveOrgToTopRow } from '../helpers';
import { updateProfileCompleteness } from '../../../../utils/profile_completeness';


const useUpdateTourDetails = (nhID: number) => {
    const sessionState = useSessionState();
    const firstName = _.get(sessionState, 'User.FirstName', '');
    const lastName = _.get(sessionState, 'User.LastName', '');

    const { tourTypeMap } = useGetTourTypes();
    const queryClient = useQueryClient();
    const queryKey = ['portal', 'tour', 'details'];

    const mutation = useMutation({
        mutationFn: (values: ITourFormData) =>
            tourAPI.updateTourDetails(nhID, values),
        onMutate: async (values: ITourFormData) => {
            // prevent any query updates while we perform optimistic update
            await queryClient.cancelQueries({ queryKey });

            // copy the current data as it exists in the react-query store
            const previous = queryClient.getQueryData(queryKey);

            // Optimistic update
            queryClient.setQueryData(
                queryKey,
                (oldData: {
                    rows: ITourDefaultValues[];
                    totalRowCount: number;
                }) => {

                    const optimisticData: ITourDefaultValues = {
                        ...values,
                        TourTypeName: tourTypeMap?.[values.TourTypeID] || '...',
                        PhysicalTourDays: values.PhysicalTourDays.join(','),
                        UpdatedAt: new Date().toISOString(),
                        InheritedAvailabilityDetailsFromOrg: false,
                        UpdatedBy: `${firstName} ${lastName}`,
                        VirtualTourDays: values.VirtualTourDays.join(',')
                    };

                    return {
                        rows: moveOrgToTopRow(oldData.rows.map((oldValue) => {
                            // replace element in old array with the updated version
                            if (oldValue.NHID === optimisticData.NHID) {
                                return optimisticData;
                            }
                            // if the updated item was an org, update the items that inherit its details
                            if (optimisticData.IsOrganization && oldValue.InheritedAvailabilityDetailsFromOrg) {
                                return {
                                    ...optimisticData,
                                    InheritedAvailabilityDetailsFromOrg: true,
                                    IsOrganization: false,
                                    NHID: oldValue.NHID
                                };
                            }
                            // otherwise return the item unchanged
                            return oldValue;
                        })),
                        totalRowCount: oldData.totalRowCount
                    };
                }
            );

            // this gets added to the context in case we need to rollback
            return { previous };
        },
        onError: (err, _, context) => {
            console.log(err);
            // set the data in the store back to how it was before the optimistic update
            queryClient.setQueryData(queryKey, context.previous);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey
            });
            updateProfileCompleteness();
        }
    });

    return mutation;
};

export { useUpdateTourDetails };
