import React from 'react';
// import React, { useContext, useEffect, useState } from 'react';
// import { SortOrder, TableColumn } from 'react-data-table-component';
// import Select from 'react-select';
// import { Link } from 'react-router-dom';

// import * as csvEportAPI from '../../api/csv_export';

// import { getReviewScoreSimulator } from '../../api/review';
// import DataTable, {
//     DataTableSortOrder,
//     INITIAL_ROWS_PER_PAGE
// } from '../../components/common/data_table';
// import { LoadingSpinner } from '../../components/common/loading_spinner';
// import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
// import TableLoading from '../../components/tables/table_loading';
// import AppContext from '../../context/app_context';
// import { FilterType } from '../../interfaces/filters';
// import { IReviewScoreSimulatorFilters, IReviewScoreSimulatorRow } from '../../interfaces/review';
// import FilterMatrix from '../../modules/filter_matrix';
// import { scrollToTop } from '../../utils/common';
// import { ISelectOption } from '../../interfaces/form';
// import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
// import { formatFileNameForCsvExport } from '../../utils/file';
// import ReviewScoreDisplayFormatted from '../../components/common/review_score_display_formatted';
// import ReviewScoreFullFormatted from '../../components/common/review_score_full_formatted';

// interface IProps {
//     memberIDs: number[];
//     groupID: number;
// }

// const ReviewScoreSimulator = ({ memberIDs, groupID }: IProps) => {
    const ReviewScoreSimulator = () => {
        return (<></>);  
    // const [ firstLoad, setFirstLoad] = useState<boolean>(true);
    // const [ isLoading, setIsLoading ] = useState<boolean>();
    // const [ reviewScores, setReviewScores] = useState<IReviewScoreSimulatorRow[]>();
    // const [ totalRows, setTotalRows] = useState<number>(0);
    // const [ pageOffset, setPageOffset ] = useState<number>(0);
    // const [ pageLimit, setPageLimit ] = useState<number>(INITIAL_ROWS_PER_PAGE);
    // const [ tableSortColumn, setTableSortColumn] = useState<string>(); 
    // const [ tableSortOrder, setTableSortOrder] = useState<DataTableSortOrder>();
    // const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

    // const [ filterMatrix ] = useState(new FilterMatrix([
    //     {
    //         Key: 'ReviewCount',
    //         FilterFormLabel: 'Review Count',
    //         Type: FilterType.LabelValue,
    //         FilterPropName: 'ReviewCount'
    //     },
    //     {
    //         Key: 'StarRating',
    //         FilterFormLabel: 'Five Star Review(s)',
    //         Type: FilterType.LabelValue,
    //         FilterPropName: 'StarRating'
    //     },
    //     { 
    //         Key: 'MemberID', 
    //         FilterFormLabel: 'MemberID' , 
    //         Type: FilterType.Number, 
    //         FilterPropName: 'MemberID', 
    //         Hidden: true
    //     },
    // ]));

    // const maxReviewCount = 15;
    // const maxStarCount = 5;

    // const getReviewCountOptions = (): ISelectOption[] => { 
    //     const options = [];

    //     for (let i = 1; i <= maxReviewCount; i++) {
    //         options.push({ label: `x${i}`, value: i });
    //     }

    //     return options;
    // };

    // const getStarRatingOptions = (): ISelectOption[] => {
    //     const options = [];
       
    //     for (let i = 1; i <= maxStarCount; i++) { 
    //         options.push({ label: `${i} star`, value: i });
    //     }

    //     return options;
    // };

    // const isSingleMemberSession = memberIDs?.length === 1;
    // const avgRowClass = (groupID || memberIDs?.length > 1) && totalRows > 0 ? 'reviewscoresimulator_dt_show_avg_row' : ''; 

    // const fetchData = async () => {
    //     try {
    //         setIsLoading(true);

    //         if (firstLoad) {
    //             setDefaultFormData();
    //         }

    //         const params: IReviewScoreSimulatorFilters = {
    //             ...filterMatrix.getRequestParams(),
    //             Offset: pageOffset,
    //             Limit: pageLimit,
    //             SortField: tableSortColumn,
    //             SortOrder: tableSortOrder
    //         };

    //         const { reviewScores, totalRows } = await getReviewScoreSimulator(params);

    //         if (reviewScores) {
    //             if (isSingleMemberSession) {
    //                 reviewScores.shift(); 
    //             }

    //             setReviewScores(reviewScores);
    //             setTotalRows(totalRows);

    //             if (firstLoad) {
    //                 setFirstLoad(false);
    //             }
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    // useEffect(() => {     
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     fetchData();
    // }, [  groupID, memberIDs, pageOffset, tableSortColumn, tableSortOrder ]);

    // const applyFilter = (key: string, value: any, refreshData = true) => {
    //     filterMatrix.setValue(key, value);

    //     if (refreshData) {
    //         fetchData();
    //     }
    // };

    // const formatFacility = (data: IReviewScoreSimulatorRow) => {
    //     if (data.IsAvgRow) {
    //         return <small className="py-4"><strong>Averages from all {data.TotalMembers} {data.GroupName} Communities</strong></small>;
    //     }

    //     const { Name, Address } = data;
        
    //     return (
    //         <div className="py-2">
    //             <strong>{ Name }</strong> <br /><small>{ Address }</small>
    //         </div>
    //     );
    // };

    // const formatButtons = ({ MemberID, IsAvgRow }: IReviewScoreSimulatorRow) => {
    //     const state = IsAvgRow ? null : { MemberID };

    //     return (
    //         <div className="py-3">
    //             <small className="name-column-links">            
    //                 <Link className="d-block mb-2" to="/invite-to-review" state={state}>
    //                     <button className="review-replybutton btn btn-sm btn-primary">Invite&nbsp;to&nbsp;Review</button>
    //                 </Link>
    //                 <Link className="d-block" to="/review-cards" state={state}>
    //                     <button className="review-replybutton btn btn-sm btn-primary">Order&nbsp;Review&nbsp;Cards</button>
    //                 </Link>
    //             </small>
    //         </div>
    //     );
    // };

    // const appContext: any = useContext(AppContext);

    // const simulatedStyleObject = {
    //     backgroundColor: 'rgb(230, 235, 237) !important'
    // };

    // const columns = [
    //     {
    //         name: 'Facility Name',
    //         selector: (row: IReviewScoreSimulatorRow) => row.Name,
    //         cell: (row: IReviewScoreSimulatorRow) => formatFacility(row),
    //         sortable: true,
    //         sortField: 'Name',
    //         minWidth: appContext.isMobile ? '146px' : '290px',
    //         id: 'name'
    //     },
    //     {
    //         name: <div className="font-weight-normal"><strong>Current</strong><br />Points from Average<br />Rating (X/5)</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => row.ReviewScoreRatingScore,
    //         sortable: true,
    //         sortField: 'ReviewScoreRatingScore',
    //         minWidth: appContext.isMobile ? '80px' : '120px'
    //     },
    //     {
    //         name: <div className="font-weight-normal"><strong>Current</strong><br />Points from Positive Review Count (X/5)</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => row.ReviewScoreQuantityScore,
    //         sortable: true,
    //         sortField: 'ReviewScoreQuantityScore',
    //         minWidth: appContext.isMobile ? '80px' : '120px'
    //     },
    //     {
    //         name: <div className="font-weight-normal"><strong>Current</strong><br />Review Score (X/10)</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => formatReviewScore(row.ReviewScoreDisplay, row.ReviewScoreFull),
    //         sortable: true,
    //         sortField: 'ReviewScoreFull',
    //         minWidth: appContext.isMobile ? '80px' : '120px'
    //     },        
    //     {
    //         name: <div></div>,
    //         width: appContext.isMobile ? null: '20px',

    //     },
    //     {
    //         name: <div className="font-weight-normal"><strong>Simulated</strong><br />Points from Average<br />Rating (X/5)</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => row.ReviewScoreRatingScoreSimulated,
    //         sortable: true,
    //         sortField: 'ReviewScoreRatingScore',
    //         style: simulatedStyleObject,
    //         minWidth: appContext.isMobile ? '80px' : '120px'
    //     },
    //     {
    //         name: <div className="font-weight-normal"><strong>Simulated</strong><br />Points from Positive Review Count (X/5)</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => row.ReviewScoreQuantityScoreSimulated,
    //         sortable: true,
    //         sortField: 'ReviewScoreQuantityScore',
    //         style: simulatedStyleObject,
    //         minWidth: appContext.isMobile ? '80px' : '120px'
    //     },
    //     {
    //         name: <div className="font-weight-normal"><strong>Simulated</strong><br />Review Score (X/10)</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => formatReviewScore(row.ReviewScoreDisplaySimulated, row.ReviewScoreFullSimulated),
    //         sortable: true,
    //         sortField: 'ReviewScoreFull',
    //         style: simulatedStyleObject,
    //         minWidth: appContext.isMobile ? '80px' : '120px'
    //     },
    //     {
    //         name: <div className="">Generate Reviews</div>,
    //         selector: (row: IReviewScoreSimulatorRow) => formatButtons(row),
    //         minWidth: '230px'
    //     }
    // ];

    // const formatReviewScore = (reviewScoreDisplay: number, reviewScoreFull: number) => {
    //     return (
    //         <div className="text-center fw-bold">
    //             <ReviewScoreDisplayFormatted value={reviewScoreDisplay} classNames="mb-2"/>
    //             <ReviewScoreFullFormatted value={reviewScoreFull} />
    //         </div>
    //     );
    // };

    // const setDefaultFormData = () => {
    //     const reviewCountOption = getReviewCountOptions().find((o: ISelectOption) => o.value === 1);
    //     const starRatingOption = getStarRatingOptions().find((o: ISelectOption) => o.value === maxStarCount);

    //     applyFilter('ReviewCount', [reviewCountOption], false);
    //     applyFilter('StarRating', [starRatingOption], false);
    // };

    // const handleSelect = (memberID: number | null) => {
    //     applyFilter('MemberID', memberID);
    // };

    // const onPageChange = async (pageNumber: number) => {
    //     const newPageOffset = (pageNumber - 1) * pageLimit;

    //     if (newPageOffset !== pageOffset) {
    //         setPageOffset(newPageOffset);
    //         scrollToTop();
    //     }
    // };

    // const onLimitChange = async (newPerPage: number, pageNumber: number) => {
    //     if (newPerPage !== pageLimit) {
    //         setPageOffset((pageNumber - 1) * pageLimit);
    //         setPageLimit(newPerPage);
    //         scrollToTop();
    //     }
    // };

    // const onSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
    //     if (column?.sortField && sortOrder) {
    //         setTableSortColumn(column.sortField);
    //         setTableSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);            
    //     }
    // };

    // const startExport = async (): Promise<string> => {
    //     return csvEportAPI.startExport('/review/reviewscoresimulator/export', filterMatrix.getRequestParams());
    // };

    // const selectedMemberID = filterMatrix.getRequestParams().MemberID;

    // return (
    //     <>
    //         { isLoading ? <LoadingSpinner show={true} /> : null }
            
    //         <PageCommunitySelectWrapper
    //             label={'All Review Scores for'}
    //             handleSelect={handleSelect}
    //             selMemberID={selectedMemberID}
    //         />
   
    //         <div className="widget widget-fullwidth">
    //             <div className="row widget-body">
    //                 <div className="col-12">
    //                     <div className="card">
    //                         <div className="card-body p-0">
    //                             <div className="border-bottom p-4">
    //                                 <h4 className="text-center pb-3 text-blue">Select additional reviews to simulate what your Review Score would change to</h4>
    //                                 <div className="row mb-4 mb-md-0">
    //                                     <div className="col text-center">
    //                                         <div className="d-inline-block">
    //                                             <div className="d-flex align-items-center">
    //                                                 <Select
    //                                                     id="ReviewCount"
    //                                                     options={getReviewCountOptions()}
    //                                                     name="ReviewCount"
    //                                                     className="basic-multi-select w-auto z-index-25"
    //                                                     classNamePrefix="select"
    //                                                     value={filterMatrix.getFormFieldValue('ReviewCount')}
    //                                                     onChange={(option: any) => applyFilter('ReviewCount', [option])}
    //                                                     styles={{
    //                                                         control: (baseStyles) => ({
    //                                                         ...baseStyles,
    //                                                         width: '90px'
    //                                                         }),
    //                                                     }}
    //                                                 /> 
    //                                                 <span className="mx-2">
    //                                                     additional
    //                                                 </span>
    //                                                 <Select
    //                                                     id="StarRating"
    //                                                     options={getStarRatingOptions()}
    //                                                     name="StarRating"
    //                                                     className="basic-multi-select w-auto z-index-25"
    //                                                     classNamePrefix="select"
    //                                                     value={filterMatrix.getFormFieldValue('StarRating')}
    //                                                     onChange={(option: any) => applyFilter('StarRating', [option])}
    //                                                     styles={{
    //                                                         control: (baseStyles) => ({
    //                                                         ...baseStyles,
    //                                                         width: '110px',
    //                                                         }),
    //                                                     }}
    //                                                 />
    //                                                 <span className="ms-2">
    //                                                     review(s)
    //                                                 </span>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="row">
    //                                     <div className="col text-end">
    //                                         <CsvExport 
    //                                             startExportFunction={startExport}
    //                                             modalTitleSuffix={'Review Score'}
    //                                             label="Export as CSV"
    //                                             displayType={DisplayType.Button}
    //                                             fileName={`${formatFileNameForCsvExport('review_score_simulator')}`}
    //                                         />
    //                                     </div>
    //                                 </div>
    //                             </div>

    //                             <div className={`reviewscoresimulator_dt table_dt sticky_dt ${avgRowClass}`}>
    //                                 <DataTable
    //                                     columns={columns}
    //                                     data={reviewScores}
    //                                     pagination={true}
    //                                     paginationServer={true}
    //                                     paginationTotalRows={totalRows}
    //                                     onChangePage={onPageChange}
    //                                     onChangeRowsPerPage={onLimitChange}
    //                                     sortServer={true}
    //                                     onSort={onSortChange}
    //                                     fixedHeader={false}
    //                                     noDataComponent={
    //                                         <TableLoading 
    //                                             loading={isLoading} 
    //                                             loadingMessage="Loading Facilities..." 
    //                                             noDataMessage="No Facilities found" 
    //                                         />
    //                                     }
    //                                 />
    //                             </div> 
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
};

export default ReviewScoreSimulator;