import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IPortalPropertyPhotoObj } from '../../interfaces/asset';
import { useForm } from 'react-hook-form';
import { LoadingSpinner } from '../common/loading_spinner';

interface IProps {
    photoDetail: IPortalPropertyPhotoObj;
    handleClose(): void;
    handlePhotoCaptionUpdate(assetID: number, tagID: number, photoCaption: string): void;
}

interface FormInputs {
    PhotoCaption: string;
}

const PhotoCaptionUpdateModal = ({
    photoDetail, 
    handleClose, 
    handlePhotoCaptionUpdate   
}: IProps) => {

    const defaultPhotoCaption = photoDetail.Title ? photoDetail.Title : '';
    const [ busy, setBusy ] = useState<boolean>(false);
    const { register, formState: { errors }, handleSubmit } = useForm<FormInputs>();

    const onSubmit = async (data: any) => {
        setBusy(true);

        const { 
            PhotoCaption            
        } = data;

        await handlePhotoCaptionUpdate(photoDetail.AssetID, photoDetail.TagID, PhotoCaption);
        setBusy(false);
    };

    const renderForm = () => {

        if (busy) return (
            <Modal.Body>
                <div className="py-4">
                    <LoadingSpinner show={true} showInPlace={true} />
                </div>
            </Modal.Body>
        );

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="position-relative"> 
                                <label htmlFor="PhotoCaption" className="mb-1">
                                    <strong>Photo Caption*</strong>&nbsp; 
                                </label>
                                <input
                                    {
                                        ...register('PhotoCaption', )
                                    }
                                    type="text"
                                    className={`form-control ${errors.PhotoCaption ? 'is-invalid' : ''}`}
                                    id="PhotoCaption"
                                    defaultValue={defaultPhotoCaption}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row w-100">
                        <div className="col-12 d-flex flex-column-reverse flex-md-row">
                            <div className="m-auto m-md-0 ms-md-auto d-flex flex-row">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary btn-outline me-2 me-md-4"
                                    onClick={() => handleClose()}
                                >
                                    Close
                                </button>
                                <button type="submit" className="btn btn-sm btn-primary">
                                    Update Caption
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </form>
        );
    };

    return (
        <Modal
            show={true}
            centered
            size="xl"
            dialogClassName="modal-sticky-footer"
        >
            <Modal.Header>
                <Modal.Title className="reply-modal-title">
                    <p className="reply-modal-title mb-3">
                        <strong>Update Photo Caption For Photo ID: {photoDetail.AssetID}</strong>
                    </p>
                </Modal.Title>
            </Modal.Header>
            { renderForm() }
        </Modal>
    );
};

export default PhotoCaptionUpdateModal;