import React from 'react';

interface IProps {
    loading: boolean;
}

const NoReviewData = ({ loading }: IProps) => {
    return (
        <>
            <div className="row mt-4">
                <p className=" mb-4">
                    { loading ? 'Loading reviews...' : 'No reviews found' }
                </p>
            </div>
        </>
    );
};

export default NoReviewData;
