import React, { useState, useEffect } from 'react';
import * as Rollbar from '@rollbar/react';
import * as ReactRedux from 'react-redux';

import AppContext from './context/app_context';
import { intersectionObserverAvailable } from './utils/common';
import Main from './Main';
import { updateProfileCompleteness } from './utils/profile_completeness'; 

import { 
    isMobile, 
    isTablet, 
    isDesktop, 
    isTiny, 
    isSmall, 
    isMedium, 
    isLarge, 
    isExtraLarge, 
    isExtraExtraLarge 
} from './utils/screen';

import { getJwtToken } from './modules/login';

const rollbarConfig = {
    accessToken: '1987b6c3df704b84907c8b5d63874975',
    environment: 'development',
    captureUncaught: true,
    captureUnhandledRejections: true
};

import { store } from '../src/store';
import { Toast } from './utils/toast';

const App = () => {
    const getWindowDimensions = () => {

        if (typeof window === 'undefined') {
            return { winWidth: 0, winHeight: 0 };
        }

        const { innerWidth: winWidth, innerHeight: winHeight } = window;
        return { winWidth, winHeight };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {

        const token = getJwtToken();
        if (token) {
            updateProfileCompleteness();
        }

        const handleLoad = () => {
          setWindowDimensions(getWindowDimensions());
        };
    
        window.addEventListener('load', handleLoad);
        return () => window.removeEventListener('load', handleLoad);
    }, []);

    if (intersectionObserverAvailable()) {
        console.log('intersectionObserver AVAILABLE');
    } else {
        console.log('intersectionObserver NOT AVAILABLE');
    }

    return (

        <ReactRedux.Provider store={store}>
            <Rollbar.Provider config={rollbarConfig}>
                <AppContext.Provider
                    value={{
                        isMobile: isMobile(windowDimensions.winWidth),
                        isTablet: isTablet(windowDimensions.winWidth),
                        isDesktop: isDesktop(windowDimensions.winWidth),
                        isTiny: isTiny(windowDimensions.winWidth),
                        isSmall: isSmall(windowDimensions.winWidth),
                        isMedium: isMedium(windowDimensions.winWidth),
                        isLarge: isLarge(windowDimensions.winWidth),
                        isExtraLarge: isExtraLarge(windowDimensions.winWidth),
                        isExtraExtraLarge: isExtraExtraLarge(windowDimensions.winWidth)
                    }}
                >
                    <Rollbar.ErrorBoundary>
                        <Toast />
                        <Main/>
                    </Rollbar.ErrorBoundary>
                </AppContext.Provider>
            </Rollbar.Provider>
        </ReactRedux.Provider>
    );
};

export default App;
