import { secureHttp } from '../utils/request';

import { 
    IPortalUser, 
    IPortalUserByEmail, 
    IPortalUserPayload,
    IGetReviewCardPrimaryUsers,
    IGetReviewCardPrimaryUsersData,
    IPortalUserEntityContact
 } from '../interfaces/user';

export const getUsers = async (params: any): Promise<any[]> => {
    try {
        const { data } = await secureHttp.get('/user', { params });

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return [];
    }
};

export const getUser = async (id: any): Promise<IPortalUser> => {
    try {
        const { data: { data } } = await secureHttp.get(`/user/${id}`);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return null;
    }
};

export const getUserByEmailID = async (email: string): Promise<IPortalUserByEmail> => {
    const { data: { data } } = await secureHttp.get(`/user/email/${email}`);
    return data;
};

export const createUser = async (payload: IPortalUserPayload): Promise<any> => {
    const { data } = await secureHttp.post('/user', payload);
    return data;
};

export const updateUser = async (payload: IPortalUserPayload): Promise<any> => {
    const { data } = await secureHttp.patch('/user', payload);
    return data;
};

export const deletePortalUser = async (portalUserID: number): Promise<any> => {
    const { data } = await secureHttp.delete(`/user/${portalUserID}`);
    return data;
};

export const updatePortalUserEntity = async (PortalUserID: number): Promise<void> => {
     await secureHttp.patch(`/user/${PortalUserID}/entity`);
};

export const sendPasswordResetEmail = async (emailAddress: string): Promise<any> => {
    try {
        await secureHttp.get(`/user/email/resetpasswordsecure/${encodeURIComponent(emailAddress)}`);
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const sendActivationEmail = async (userID: number): Promise<any> => {
    try {
        await secureHttp.get(`/user/email/activate/${userID}`);
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getReviewCardPrimaryUserCount = async (params: IGetReviewCardPrimaryUsers): Promise<IGetReviewCardPrimaryUsersData> => {
    const { data: { data } } = await secureHttp.get('/user/reviewcard/primaryusers', { params });
    
    return data;
};

export const getEntityContacts = async (entityIDs: number[]): Promise<IPortalUserEntityContact[]> =>   {
    const entityIDsString = entityIDs.join(',');
    const { data: { data } } = await secureHttp.get('/user/entity/contacts', {params: { entityIDs: entityIDsString }});
    
    return data;
};
