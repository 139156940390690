import React, { useMemo, useState } from 'react';
import { getTourDetailsList } from '../../../api/tours';
import { useQuery } from '@tanstack/react-query';
import DataTable, { DataTableSortOrder } from '../../../components/common/data_table';
import { getColumns } from './property_tour_availability_columns';
import { TableExpandedRowItem } from './components/table_expanded_row_item';

import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { displaySuccessToast, failedToSaveTourError, moveOrgToTopRow, prepareDataForForm } from './helpers';
import BookATourForm from './book_a_tour_form/book_a_tour_form';
import { Button } from '../../../components/common/button';
import { useUpdateTourDetails } from './hooks/useUpdateTourDetails';

import { ProfileScoreType } from '../../../interfaces/property';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { useProfileCompletenessFilter } from '../../../hooks/use_profile_completeness_filter';
import { boolSort, textSort } from '../../../utils/common';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { usePremiumStatus } from '../../../hooks/use_premium_status';
interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyTourAvailability = ({ nhIDs, orgNHID }: IProps) => {
    const isSingleFacilitySession = nhIDs?.length === 1;
    const [selNHID, setSelNHID] = useState<number|null>(isSingleFacilitySession ? nhIDs[0] : null);
    const showTable = nhIDs?.length !== 1 && !selNHID;
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const { isPremium } = usePremiumStatus(selNHID);
    const {
        withProfileCompleteness, 
        handleProfileCompletenessFilterChange, 
        profileCompletenessFilterValue
    } = useProfileCompletenessFilter(ProfileScoreType.TourAvailability);

    const {mutate, isPending } = useUpdateTourDetails(selNHID);

    const sortFn = useMemo(() => {
        switch(sortField) {
            case'Name':
                return textSort;
            case 'ProfileComplete': 
                return boolSort;
            case'TourTypeName':
                return textSort;
            case 'UpdatedAt':
                return textSort;
            case 'UpdatedBy':
                return textSort;
            default:
              console.log(`No sort function declared for column ${sortField}`);
        }
    }, [sortField]);

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const { data = { rows: [], totalRowCount: 0 }, isLoading } = useQuery({
        queryFn: () => getTourDetailsList(),
        queryKey: ['portal', 'tour', 'details']
    });

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        if (!isSingleFacilitySession) {
            setSelNHID(selNHID);
        }
    });

    const selectedPropertyTourAvailabilityDetails = data.rows?.find(
        (item) => item.NHID === selNHID
    ) || data.rows?.[0];

    const formId = 'book-a-tour';

    const filteredRows = withProfileCompleteness(
        moveOrgToTopRow(data.rows)
    ).sort(sortFn(sortField, sortOrder));

    return (
        <>
            <div className='mb-4'>
                <PageCommunitySelectWrapper
                    label={'Tour Availability for'}
                    handleSelect={handleSelect}
                    selNHID={selNHID}
                />
            </div>
            <div className="widget" data-widget-height="auto">
                    <div className="widget-body">                          
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.TourAvailability}
                            handleProfileCompletenessFilter={handleProfileCompletenessFilterChange}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Tour Availability"
                        />
                    </div> 
                </div> 
            {showTable ? (
                <div className={'coredetails_dt sticky_dt table_dt expander_dt overflow_dt'}>
                    <DataTable
                        isLoading={isLoading}
                        columns={getColumns({ moveToSingleFacilityView: setSelNHID })}
                        data={filteredRows}
                        onSort={handleTableSortChange}
                        pagination={true}
                        paginationTotalRows={data?.totalRowCount}
                        expandableRows
                        expandableRowsComponent={TableExpandedRowItem}
                    />
                </div>
            ) : (
                <div className="card mt-4">
                    <div className="card-header align-items-center">
                        <h4 className="m-0">Tour Availability</h4>
                    </div>
                    <div className="card-body">
                        { selectedPropertyTourAvailabilityDetails && (
                            <BookATourForm
                                id={formId}
                                defaultFormValues={prepareDataForForm(selectedPropertyTourAvailabilityDetails)}
                                handleSubmit={(values) => mutate(values, { onError: failedToSaveTourError, onSuccess: displaySuccessToast })}
                                actionButton={
                                    isPremium 
                                        ? ({formState}) => (
                                            <Button 
                                                className='btn-fixed-bottom' 
                                                isLoading={isPending} 
                                                type='submit' 
                                                form={formId} 
                                                disabled={!formState.isDirty}
                                            >
                                                Update
                                            </Button>
                                        ) : (
                                            <div className='btn-fixed-bottom mt-2 msg msg-error'>
                                                Premium Subscription Required
                                            </div>
                                        )
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export { PropertyTourAvailability };
