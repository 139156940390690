import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhoneCircle,
    faEnvelopeCircle
} from '@fortawesome/pro-light-svg-icons';

import {
    faClose
} from '@fortawesome/pro-solid-svg-icons';

interface IProps {
    handleClose(): void;
    show: boolean;
}

const HeaderContactInfo = ({ handleClose, show }: IProps) => {
  return (
        <Modal
            show={show}
            centered
        >
            <Modal.Header>
                <h3>
                    Contact Our Support Team
                </h3>
                <FontAwesomeIcon
                    icon={faClose}
                    className="modal-close"
                    onClick={() => handleClose()}
                    role="button"
                />
            </Modal.Header>
            <Modal.Body>
                <ul className="header-contact-details">
                    {/*<li>
                        <a href="tel:8668921205" className="app-header-support-phone">
                            <FontAwesomeIcon icon={faPhoneCircle} />
                            (866) 892-1205
                        </a>
                    </li>*/}
                    <li>
                        <a href="mailto:support@nursinghomes.com" className="app-header-support-email">
                            <FontAwesomeIcon icon={faEnvelopeCircle} />
                            support@nursinghomes.com
                        </a>
                    </li>                    
                </ul>
            </Modal.Body>
        </Modal>
  );
};

export default HeaderContactInfo;
