import React, { useState, useEffect } from 'react';
import { IPropertyContactDetails } from '../../../../interfaces/property';
import { UpdatePropertyContactDetailsForm } from '../update_property_contact_details_form';

interface IProps {
    propertyDetail: IPropertyContactDetails;
    isUpdateModalOpen: boolean;
    handleModalClose: any;
}

const UpdatePropertyDetailModal = ({
    propertyDetail,
    isUpdateModalOpen,
    handleModalClose
}: IProps) => {
    const [contactData, setContactData] =
        useState<IPropertyContactDetails>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const closeModal = async () => {
        handleModalClose();
    };

    useEffect(() => {
        setContactData(propertyDetail);
        setIsOpen(isUpdateModalOpen);
    }, [propertyDetail, isUpdateModalOpen]);

    if (!isOpen || !propertyDetail) {
        return;
    }
    
    return (
        <>
            <UpdatePropertyContactDetailsForm
                data={contactData}
                updateModalActive={isOpen}
                handleCloseModal={closeModal}
            />
        </>
    );
};

export { UpdatePropertyDetailModal };
