import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberPc,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    AcceptMedicaidPending: yupNullableStringToBool,
    AcceptMedicareAdvantage: yupNullableStringToBool,
    ManagedCareContractsInNetwork: yupNullableStringToBool,
    PrivateInsurancePc: yupNullableStringToNumberPc,
});

export default schema;
