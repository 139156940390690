import React from 'react';
import { LoadingSpinner } from '../common/loading_spinner';
import UploadWidget from '../cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

import { 
    NHAssetType,
    ICloudinaryData,
    IAssetUploadLog,
    IAssetPayLoad,
    IPortalPropertyAsset
 } from '../../interfaces/asset';


interface IProps {
    handleReloadPropertyBrochures: (nhID: number[]) => Promise<IPortalPropertyAsset[]>;
    orgNHID?: number | null;
    nhIDs?: number[];
    selNHID: number | null;
    propertyBrochures: IPortalPropertyAsset[];
    setPropertyBrochures: (propertyBrochures: IPortalPropertyAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    handleClose?: any;
}

const IndividualPropertyLogo = ({
    handleReloadPropertyBrochures,
    orgNHID,
    nhIDs,
    selNHID,
    propertyBrochures,
    setPropertyBrochures,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleClose
}: IProps) => {

    const refreshNHID = orgNHID ? [orgNHID] : nhIDs ? nhIDs : [selNHID];

    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { 
            NHID: selNHID,
            AssetType: NHAssetType.BrochurePdfs,
            ...cloudinaryData
        };
        const assetID = await assetApi.createAsset(assetPayLoad);

        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0],
            PageCount: cloudinaryData.PageCount
        };
    };
    
    const handleShowAssetUploadResult = async(assetUploadLog : IAssetUploadLog[]) => {
        let hasAssetUploaded = false;
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasAssetUploaded = true;
                break;
            }
        }

        if( !hasAssetUploaded ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error occured while uploading PDF Brochure, please try again.'
            });
        } else {
            try {
                setBusyLoadingAsset(true);
                const updatedPropertyBrochures = await handleReloadPropertyBrochures(refreshNHID);
                setPropertyBrochures(updatedPropertyBrochures);    
                handleClose ?. ();     
            } catch (err) {
                Swal.fire({
                    title: 'Failed to fetch the uploaded PDF Brochure',
                    text: 'Unknown error',
                    icon: 'error'
                });
            } finally {
                updateProfileCompleteness();
                setBusyLoadingAsset(false);
            }
        }
    };

    const handleDelete = async() => {
        Swal.fire({
            title: 'Are you sure you want to delete this PDF Brochure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0abb87',
            cancelButtonColor: '#fd397a',
            confirmButtonText: 'Yes'
        }).then(async (result: any) => {
            if (result.value) {
                setBusyLoadingAsset(true);
                try {
                    const deletedAssetID = await assetApi.deletePropertyBrochure(selNHID);
                   
                    if (!deletedAssetID) {
                        throw new Error('Failed to delete PDF Brochure');
                    }
                    const updatedPropertyBrochures = await handleReloadPropertyBrochures(refreshNHID);
                    setPropertyBrochures(updatedPropertyBrochures);
                    handleClose ?. ();
                } catch (err) {
                    Swal.fire({
                        title: 'Failed to delete PDF Brochure',
                        text: 'Unknown error',
                        icon: 'error'
                    });
                } finally {
                    updateProfileCompleteness();
                    setBusyLoadingAsset(false);
                }
            }
        });
    };

    const renderIndividualPropertyBrochure = () => {
        const property = (propertyBrochures || []).length > 0  ? propertyBrochures[0].Property : null;
        const asset = property && (propertyBrochures[0]?.Asset || []).length > 0  ? propertyBrochures[0].Asset[0] : null;

        const pdfThumbnailsLink = [];
        if( asset && asset.TransformedAssetUrl && asset.PageCount) {
            for (let i = 0; i < asset.PageCount; i++) {
                pdfThumbnailsLink.push(
                    <a href={asset.TransformedAssetUrl.replace(/image\/upload\/.*?\/brochure_pdfs/, `pg_${i+1}/brochure_pdfs`)} target='_blank' key={i} rel='noreferrer'>
                        <img src={asset.TransformedAssetUrl.replace(/image\/upload\/.*?\/brochure_pdfs/, `c_scale,w_100,h_100,pg_${i+1}/f_jpg/brochure_pdfs`)} alt={`${property?.Name} PDF Brochure page ${i+1}`} />
                    </a>
                );
            }
        }

        return (
            <>
                <div className="widget mt-0">
                    <div className="widget-body">
                        <div className="media-upload">
                            <div className="card" data-card="Media Scroller">
                                <div className="card-header">
                                    <h4>
                                        PDF Brochure for {property?.Name ?  property.Name : ''}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-info no-scroll">
                                        <div>
                                        {
                                            asset && asset.AssetID > 0 ?
                                                <div key={asset.AssetID} className="media-edit media-pdf">
                                                    <em>
                                                        { pdfThumbnailsLink }
                                                    </em>
                                                    <div>
                                                        <ul>
                                                            <li>
                                                                <a href=""
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleDelete();
                                                                    }}
                                                                >
                                                                    Delete
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            :
                                                <span className="msg msg-error">
                                                    No PDF Brochure uploaded
                                                </span>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-card="Media Upload">
                                <div className="card-header">
                                    <h4>
                                        Upload a New PDF Brochure
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <>
                                        <div className="card-info">
                                            <ul>
                                                <li>
                                                    <div>
                                                        <UploadWidget 
                                                            nHID = {selNHID}
                                                            fileName={property?.Slug}
                                                            assetTypeName = 'Select PDF Brochure to Upload' 
                                                            assetType = {NHAssetType.BrochurePdfs}
                                                            onAssetUpload = {handleAssetUpload}
                                                            showAssetUploadResult = {handleShowAssetUploadResult}
                                                         />
                                                        <p>
                                                            Please note maximum file size 40MB.
                                                        </p>
                                                        <p>
                                                            Large PDF Brochure file can take up to 5 minutes to upload on slower connections.
                                                        </p>
                                                        <p>If in doubt please refresh the page after a couple of minutes.</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-info-box">
                                            <h5>
                                                Uploading Logo - Tips &amp; Terms
                                            </h5>
                                            <p>
                                                Max PDF Brochure upload size 40MB.
                                            </p>
                                            <p>
                                                NursingHomes.com reserve the right to remove any PDF Brochures we deem unsuitable.
                                            </p>
                                            <p>
                                                <strong>By pressing Upload button, I/we agree to the terms & conditions set out on <a href="https://>www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                                            </p>
                                        </div>
                                    </>
                                    { asset && asset?.AssetID > 0 ?
                                        <div className="card-info-box">
                                            <span className="msg msg-error">
                                                Please note any new PDF Brochures uploaded will overwrite your existing PDF Brochure
                                            </span>
                                        </div>
                                    :
                                        ''
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        
    };

    return (
        <>
            <LoadingSpinner show={busyLoadingAsset} /> 
            { renderIndividualPropertyBrochure() }
        </>
    );
};

export default IndividualPropertyLogo;