import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberCost,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    LongTermRatePackagesAvailable: yupNullableStringToBool,
    LongTermRatesAllInclusive: yupNullableStringToBool,
    LongTermSemiPrivateCostPerDay: yupNullableStringToNumberCost,
    LongTermPrivateCostPerDay: yupNullableStringToNumberCost,
    LongTermSharedCostPerDay: yupNullableStringToNumberCost,
});

export default schema;
