import React from 'react';
import { useSelector } from 'react-redux';
import { ISessionState, SessionType } from '../../interfaces/session';
import CommunitySelect from './community_select';

interface IProps {
    selNHID: number | null;
    handleSelect: (memberID: number | null) => void;
    disabled?: boolean;
}

const PageCommunitySelect = ({ selNHID, handleSelect, disabled = false}: IProps) => {
    
    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { Session } = sessionState || {};

    const handleCommunitySelect = async (option: any) => {
        let selNHID = null;

        if (option) {
            const { Type, NHID } = option.value;

            if (Type === 'property') {
                selNHID = NHID;
            }
        }

        handleSelect(selNHID);
    };

    const hideSelect = (Session.SessionType === SessionType.SessionTypeProperty && (sessionState.Properties || []).length <= 1);

    return (
        <>
            <CommunitySelect
                properties={sessionState.Properties}
                org={sessionState.Org}
                selNHID={selNHID}
                handleCommunitySelect={handleCommunitySelect}
                hide={hideSelect}
                disabled={disabled}
            />
        </>
    );
};

export default PageCommunitySelect;
