import React from 'react';
import { MEDIA_CREATOR_BASE_URL } from '../../config/keys';
import { SessionType } from '../../interfaces/session';

export const  reviewScoreCertificatePdfURL = (NHIDs: number[] | null, orgNHID: any | null, 
    reviewScoreCertExtraParams: string = null) => {

    const searchParams = new URLSearchParams(reviewScoreCertExtraParams);
    const extraParams = reviewScoreCertExtraParams ? Object.fromEntries([...searchParams]) : {};
    const params = NHIDs ? { NHID: NHIDs.join() } : { OrgNHID: orgNHID };
    const queryParams = new URLSearchParams({...params, ...extraParams});

    return `${MEDIA_CREATOR_BASE_URL}/reviewscorecertificate?${queryParams.toString()}`;
};

export const  qrCodePosterPdfURL = (NHIDs: number[] | null, orgNHID: any | null, qrCodePosterExtraParams: string) => {

    const searchParams = new URLSearchParams(qrCodePosterExtraParams);
    const extraParams = Object.fromEntries([...searchParams]);
    const params = NHIDs ? { NHID: NHIDs.join() } : { OrgNHID: orgNHID };
    const queryParams = new URLSearchParams({...params, ...extraParams});

    return `${MEDIA_CREATOR_BASE_URL}/reviewqrcodeposter?${queryParams.toString()}`;
};

export const  qrCodeImageURL = (NHIDs: number[] | null, orgNHID: number | null, qrCodeSize: number, 
    qrCodeImgFormat: string, qrCodeExtraParams: string) => {
    
    const size = Math.min(Math.max(qrCodeSize, 100), 2000);

    const params: any = {
        Size: size,
        Format: qrCodeImgFormat,
    };

    if (NHIDs) {
        params.NHID = NHIDs.join();
    }

    if (orgNHID) {
        params.orgNHID = orgNHID;
    }

    const searchParams = new URLSearchParams(qrCodeExtraParams);
    const extraParams = Object.fromEntries([...searchParams]);

    const queryParams = new URLSearchParams({...params, ...extraParams});

    return `${MEDIA_CREATOR_BASE_URL}/reviewqrcode?${queryParams.toString()}`;
};

export const renderReviewScoreCertLink = (
    NHIDs: number[],
    reviewScoreCertURL: string,
    singleProperty: any, 
    sessionType: SessionType, 
    org: any, 
    handleReviewScoreCertDownload: any,
    reviewScoreCertDownloading: boolean,
    showFullText = true
) => {

    return (
        <>
        {
            showFullText ?
                <div>
                    <div>
                        <p>
                            <strong>Either</strong> press&nbsp;
                            <button
                                onClick={() => handleReviewScoreCertDownload(reviewScoreCertURL)}
                                className="btn btn-primary">
                                    { reviewScoreCertDownloading ? 'Creating Review Score Certificate(s)...' : 'Download' }
                            </button>
                            &nbsp;to generate your Review Score Certificate PDF {renderSessionText(singleProperty, sessionType, org, NHIDs)}.
                        </p>                        
                    </div>
                    <div>
                        <p>
                            <strong>Or</strong> use the download link to generate your {'\'Review Us\''} Review Score Certificate PDF {renderSessionText(singleProperty, sessionType, org, NHIDs)}:
                        </p>
                        <div>
                            <input
                                type="text" 
                                className="w-md-75"
                                disabled={true}
                                value={reviewScoreCertURL}
                            />
                            <button 
                                className="btn btn-primary"
                                type="button"
                                onClick={() => navigator.clipboard.writeText(reviewScoreCertURL)}>
                                    Copy
                            </button>
                        </div>
                    </div>
                </div>
                : 
                <>
                    <button
                        onClick={() => handleReviewScoreCertDownload(reviewScoreCertURL)}
                        className="btn btn-primary">
                            { reviewScoreCertDownloading ? 'Creating Review Score Certificate(s)...' : 'Download' }
                    </button>
                </>
                } 
        </>
    );
};

export const renderQRCodePosterLink = (
    NHIDs: number[], 
    qrCodePosterURL: string,
    singleProperty: any, 
    sessionType: SessionType, 
    org: any, 
    handleQRCodePosterDownload: any,
    qrCodePosterDownloading: boolean

) => {

    return (
        <div>
            <div>
                <p>
                    <strong>Either</strong> press&nbsp;
                    <button
                        onClick={() => handleQRCodePosterDownload(qrCodePosterURL)}
                        className="btn btn-primary">
                            { qrCodePosterDownloading ? 'Creating Poster(s)...' : 'Download' }
                    </button>
                    &nbsp;to generate your 'Review us' QR Code Poster PDF {renderSessionText(singleProperty, sessionType, org, NHIDs)}.
                </p>                
            </div>
            <div>
                <p>
                    <strong>Or</strong> use the download link to generate your {'\'Review Us\''} QR Code Poster PDF {renderSessionText(singleProperty, sessionType, org, NHIDs)}:
                </p>
                <div>
                    <input
                        type="text"
                        className="w-md-75"
                        disabled={true}
                        value={qrCodePosterURL}
                    />
                    <button 
                        className="btn btn-primary"
                        type="button"
                        onClick={() => navigator.clipboard.writeText(qrCodePosterURL)}>
                            Copy
                    </button>
                </div>               
            </div>
        </div>
    );
};

export const renderCSVLink = (
    NHIDs: number[], 
    orgNHID: number 
) => {
    const queryParams = NHIDs ? `NHID=${NHIDs.join()}` :
       `OrgNHID=${orgNHID}`;

    const url = `${MEDIA_CREATOR_BASE_URL}/csvexport?${queryParams}`;

    return (
        <a href={url}>
            Alternatively, download a CSV containing the relevant links to generate your {'\''}Review Us{'\''} QR Code Poster PDF
        </a>
    );
};

export const renderQRCodeLink = (
    NHIDs: number[],
    orgNHID: number,
    qrCodeSize: number,
    updateQrImageSize: any,
    qrCodeImgFormat: string,
    updateQrImageFormat: any,
    qrCodeExtraParams: string,
    updateQrImageExtraParams: any,
    qrCodeImgURL: string,
    handleQRCodeDownload: any
) => {

    return (
       
        <div className="kt-portlet">
            <div className="kt-portlet__head align-items-center">
                <div className="kt-portlet__head-title m-0">
                    <h5>QR Code Image</h5>
                </div>
            </div>
            <div className="kt-portlet__body">
                <>
                    <div className="row">
                        <div className="col-sm-2">
                            <label htmlFor="qrcodesize">Size (pixels)</label>
                            <input
                                id='qrcodesize'
                                type="text"
                                className="form-control"
                                value={qrCodeSize}
                                onChange={(e) => updateQrImageSize(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-2">
                            <label htmlFor='qrcodeformat'>Image Format</label>
                            <div className="kt-radio-list kt-radio-list--inline">
                                <label className="kt-radio kt-radio--brand mr-4">
                                    <input 
                                        type="radio"
                                        name='qrcodeformat'
                                        checked={qrCodeImgFormat === 'SVG'}
                                        onChange={() => updateQrImageFormat('SVG')}
                                    />
                                    SVG
                                    <span></span>
                                </label>
                                <label className="kt-radio kt-radio--brand mr-4">
                                    <input 
                                        type="radio"
                                        name='qrcodeformat'
                                        checked={qrCodeImgFormat === 'PNG'}
                                        onChange={() => updateQrImageFormat('PNG')}
                                    />
                                    PNG
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="qrcodeextraparams">Extra Parameters to include in the QR Code Link eg. UTM codes</label>
                            <input
                                id='qrcodeextraparams'
                                type="text"
                                className="form-control"
                                value={qrCodeExtraParams}
                                onChange={(e) => updateQrImageExtraParams(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-12">
                            <button
                                onClick={() => handleQRCodeDownload(qrCodeImgURL)}
                                className="btn btn-primary">
                                    Download
                            </button>
                            { 
                                orgNHID ? 
                                    <div className='row mt-2'>
                                        <div className="col-sm-12">
                                            Zip file of Images of all facilities (on www.nursinghomes.com)
                                        </div> 
                                    </div>: null
                            }
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6">
                            <input
                                type="text"
                                className="form-control"
                                disabled={true}
                                value={qrCodeImgURL}
                            />
                        </div>
                        <div className="col-sm-6 pl-0">
                            <button className="btn btn-primary ml-3"
                                    type="button"
                                    onClick={() => navigator.clipboard.writeText(qrCodeImgURL)}>
                                Copy
                            </button>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
};

export const reviewScoreCertFilename = (
    singleProperty: any, 
    sessionType: SessionType,
    org: any
) => {

    const prefix = 'review_score_cert';

    if (singleProperty) {
        return `${prefix}_${singleProperty.Name}_${singleProperty.NHID}.pdf`;
    } else if (sessionType === SessionType.SessionTypeOrg) {
        return `${prefix}_${org.Name}_${org.NHID}.zip`;
    } else {
        return 'review_score_cert_multiple_properties.zip';
    }
};

export const renderSessionText = (
    singleProperty: any, 
    sessionType: SessionType, 
    org: any, 
    NHIDs: number[]
) => {
    
    if (singleProperty?.Name) {
        return (
            <span>for <strong>{singleProperty.Name}</strong></span>
        );
    } else if (sessionType === SessionType.SessionTypeOrg) {
        return (
            <span>for <strong>all {org.Name} Communities</strong></span>
        );
    } else {
        return (
            <span>for <strong>all {NHIDs.length} Communities</strong></span>
        );
    }
};


