import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTeamSequenceOrder } from '../../../../api/meet_the_team';
import { MeetTheTeamMemberType } from '../types';
import { createNewSequenceOrder } from '../helpers';

const useUpdateTeamSequenceOrderMutation = (nhID: number, queryKey: any[]) => {
    const queryClient = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: (payload: any) => updateTeamSequenceOrder(nhID, payload),
        onMutate: async (newData) => {
            console.log({ newData });
            await queryClient.cancelQueries({ queryKey });
            const previous: MeetTheTeamMemberType[] =
                queryClient.getQueryData(queryKey);

            const targetedProperty = previous.filter(
                (item: MeetTheTeamMemberType) => item.propertyId === nhID
            );

            const otherProperties = previous.filter(
                (item: MeetTheTeamMemberType) => item.propertyId !== nhID
            );

            const recombinedProperties = [
                ...targetedProperty,
                ...otherProperties
            ];

            queryClient.setQueryData(
                queryKey,
                createNewSequenceOrder(
                    recombinedProperties,
                    recombinedProperties.findIndex(
                        (item) => item.id === newData.memberId
                    ),
                    newData.sequence - 1 // convert the sequence count into an array index
                )
            );

            return { previous };
        },
        onError: (err, _, context) => {
            console.log(err);
            queryClient.setQueryData(queryKey, context.previous);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey });
        }
    });

    return mutate;
};

export { useUpdateTeamSequenceOrderMutation };
