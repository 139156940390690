import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { SectionHeading } from '../../components/section_heading';

const PaymentTypesTab = () => {
    const inline = true;

    return (
        <div className="mt-4">
            <SectionHeading title="Payment Types" />
            <div className="ps-2">
                <FormInput
                    allowedPattern={onlyNumeric}
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    label="% of residents/patients who use private insurance to pay for care"
                    maxWidth='150px'
                    name="PrivateInsurancePc"
                    suffix="%"
                    type="number"
                />
                <FormRadioGroup
                    groupLabel="Managed care contracts accepted as in-network?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="ManagedCareContractsInNetwork"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Do you accept Medicare Advantage plans?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="AcceptMedicareAdvantage"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    groupLabel="Do you accept Medicaid pending patients?"
                    info={<ProfileCompletenessTag isVisible={true} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="AcceptMedicaidPending"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </div>
    );
};

export { PaymentTypesTab };
