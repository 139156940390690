import React from 'react';

const RequestAccess = () => { 
   
    return (
        <div className="container-fluid public__requestaccess">
            <div className="row">
                <div className="col-12">
                    <div className="widget mt-3 mt-md-5">
                        <div className="card">
                            <div className="card-header pb-4 border-bottom">
                                <a href="/" className="d-flex flex-row align-items-end text-secondary">
                                    <img src="/logo.svg" height="30px" className="logo-large" alt="Portal" />
                                    <span className="d-block ms-3">Portal</span>
                                </a>
                            </div>
                            <div className="card-body border-bottom">
                            <h2 className="mb-6"> Request access to the NursingHomes.com Portal</h2>

                            <p className="mb-6">If you do not have an existing account on the NursingHomes.com Portal (you can check by sending a&nbsp;
                                <a href="/passwordresetemail">&lsquo;password reset&rsquo; request</a>), and you wish to manage your Reviews on nursinghomes.com please email us with the following information:
                            </p>
                                <ul>
                                    <li>
                                        The Organization or Facility you wish to have access to
                                    </li>
                                    <li>
                                        Whether you wish to receive &lsquo;new review&rsquo; notifications
                                    </li>
                                    <li>
                                        Whether you have permission to add replies to reviews
                                    </li>
                                </ul>
                            <p className="mb-6">
                                    We will then contact you to confirm the above.
                            </p>
                            <p className="mb-6">
                                Please email your request to <a href="mailto:support@nursinghomes.com?subject=NursingHomes.com Portal access request">support@nursinghomes.com</a>
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default RequestAccess;