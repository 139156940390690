import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePropertyTeamMember } from '../../../../api/meet_the_team';
import { MeetTheTeamMemberType } from '../types';
import { createQueryKey } from './helpers';
import { updateProfileCompleteness } from '../../../../utils/profile_completeness';

interface Props {
    includeIdInQueryKey: boolean;
    propertyId: number;
    memberId: number;
}
const useDeleteTeamMemberMutation = (args: Props) => {
    const { propertyId, memberId, includeIdInQueryKey } = args;

    const queryKey = createQueryKey(propertyId, includeIdInQueryKey);
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: async () => {
            await deletePropertyTeamMember(propertyId, memberId);
            updateProfileCompleteness();
        },
        onMutate: async () => {
            // prevent any query updates while we perform optimistic update
            await queryClient.cancelQueries({ queryKey });

            // copy the current data as it exists in the react-query store
            const previous = queryClient.getQueryData(queryKey);

            // Optimistic update
            queryClient.setQueryData(
                queryKey,
                (oldData: MeetTheTeamMemberType[] = []) => {
                    return oldData
                        .filter((user) => user.id !== memberId)
                        .map((user, index) => ({
                            ...user,
                            sequence: index + 1
                        }));
                }
            );

            // this gets added to the context in case we need to rollback
            return { previous };
        },
        onError: (err, _, context) => {
            console.log(err);
            // set the data in the store back to how it was before the optimistic update
            queryClient.setQueryData(queryKey, context.previous);
        },
        onSettled: () => {
            // we need to invalidate the queries used in both the single and multiple property views
            queryClient.invalidateQueries({
                queryKey: createQueryKey(propertyId)
            });
        }
    });

    return mutate;
};

export { useDeleteTeamMemberMutation };
