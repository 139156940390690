import React from 'react';

export interface IReviewScoreDisplayFormattedProps {
    value?: number;
    classNames?: string;
    showAsText?: boolean;
    showAsNotApplicableOnNull?: boolean;
    showAsPlaceholder?: boolean;
}

const ReviewScoreDisplayFormatted = ({    
    value,
    classNames = '',
    showAsText = false,
    showAsNotApplicableOnNull = false,
    showAsPlaceholder = false
}: IReviewScoreDisplayFormattedProps) => {  
    if (isNaN(value)) {
        return <></>;
    }

    if (showAsPlaceholder) {
        return <>X.X</>;
    }

    if (!value) {
        if (showAsNotApplicableOnNull) {
            if (showAsText) {
                return <>N/A</>;
            }

            return <div className={`review-score__score ${classNames}`}>N/A</div>;
        }

        return <></>;
    }

    const displayValue = value < 10 ? value.toFixed(1) : value;
        
    if (showAsText) {
        return <>{ displayValue }</>;
    }

    return (
        <div className={`review-score__score ${classNames}`}>{ displayValue }</div>
    );
};

export default ReviewScoreDisplayFormatted;

