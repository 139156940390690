import React, { FC } from 'react';
import { Button } from '../../../components/common/button';
import { Link } from 'react-router-dom';

interface IProps {
    isLoading: boolean;
    buttonText?: string;
}

const FormActions: FC<IProps> = ({
    isLoading,
    buttonText = 'Submit'
}) => {
    return (
        <div className="d-flex justify-content-between align-items-center mt-10">
            <div className="d-flex flex-column">
                <Link to="/passwordresetemail">Reset Password</Link>
                <Link to="/requestaccess">Request Access</Link>
            </div>
            <Button type="submit" isLoading={isLoading}>
                {buttonText}
            </Button>
        </div>
    );
};

export { FormActions };
