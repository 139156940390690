import * as yup from 'yup';

const onlyValidateWhenNotUsingDevAccount = (validation: any) =>
    yup.string().when('devAccountEmail', {
        is: (property: any) => property === null,
        then: () => validation
    });

const password = onlyValidateWhenNotUsingDevAccount(
    yup.string().required('Please enter a password.')
);

const reCaptcha = onlyValidateWhenNotUsingDevAccount(
    yup.string().required('Please complete the reCAPTCHA.')
);

const email = onlyValidateWhenNotUsingDevAccount(
    yup
        .string()
        .email('Please enter a valid email.')
        .required('Please enter an email address.')
);

const checkAccountSchema = yup.object().shape({
    email
});

const loginSchema = yup.object().shape({
    email,
    password,
    reCaptcha
});

export { checkAccountSchema, loginSchema };
