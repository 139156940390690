export interface IReviewFilters {
    DateCreatedFrom: any;
    DateCreatedTo: any;
    PublishedDateFrom: any;
    PublishedDateTo: any;
    ReviewText: string;
    ReviewerDisplayName: string;
    HasReply: boolean;
    OverallRating: string;
    CleanlinessRating: string;
    FriendlinessRating: string;
    CareServicesRating: string;
    StaffRating: string;
    DiningRating: string;
    ActivitiesRating: string;
    ValueCostRating: string;
    NHID?: number;
}

export interface IReviewCountTotals {
    TotalPublished: number;
    TotalPendingPublication: number;
    TotalNotPublished: number;
    TotalPublishedLast30: number;
    TotalPublishedLast90: number;
    TotalPublishNoReplyLast90: number;
}

export interface IReviewRatingTotal {
    RatingName: string;
    Sequence: number;
    RatingKeyName: number;
    RatingTypeID: number; 
    AverageRating: number;
}

export interface IReviewStatisticTotals {
    TotalNoReviewCardsLast12: number;
    TotalReviewsFromInvite: number;
    TotalWithReviewScore: number;
}

export interface IReviewRatingsFilters {
    MemberID?: number;
    DateFrom?: Date;
    DateTo?: Date;
}

export interface IReviewRating {
    TotalsRow: boolean;
    Name: string;
    Address: string;
    MemberID: number;
    AvgOverallValue: number;
    AvgCleanlinessValue: number;
    AvgFriendlinessValue: number;
    AvgValueForCostValue: number;
    AvgCareServicesValue: number;
    AvgActivitiesValue: number;
    AvgStaffValue: number;
    AvgMealsAndDiningValue: number;
    TotalReviews: number;
}

export interface IPropertyCountTotalsFilters {
    NHID?: number;
    TodaysDateUTC: string;
}

export interface IPropertyCountTotals {
    TotalCount: number;
    TotalNoReviewScore: number;
    TotalReviewScoreDrop: number;
    TotalLessThan15PosReviewsIn24Mnths: number;
}

export class SqlRange {
    constructor(public operation: ExpressionType, public from?: string, public to?: string) {
    }
}

export enum ReviewPublicationStatusType {
    Published = 'published',
    PendingPublication = 'pending-publication'
}

export enum ExpressionType {
    Equal = 'EQ',
    NotEqual = 'NE',
    GreaterThan = 'GT',
    GreaterThanOrEqual = 'GE',
    LessThan = 'LT',
    LessThanOrEqual = 'LE',
    Between = 'BT',
    In = 'IN',
    NotEntered = 'XX'
}

export interface RangeDefault {
    filterKey: string;
    sqlRange: SqlRange;
}

export interface INotEnteredOption {
    showNotEnteredOption: boolean;
    notEnteredLabel?: string;
}

export interface IReviewScoreFilters {
    NHIDs?: number[]
    ReviewScoreMin?: number;
    ReviewScoreMax?: number;
    NoReviewScore?: boolean;
    BelowReviewScorePositiveReviewCountThreshold?: boolean;
}

export interface IPropertyReviewScore {
    NHID: number;
    Name: string;
    OrgName: string;
    OrgPropertyCount: number;
    Address: string;
    ReviewSchemeID: number;
    ReviewScoreFull: number;
    ReviewScoreDisplay: number;
    ReviewScoreQualityScore: number;
    ReviewScoreQuantityScore: number;
    ReviewScoreTotalReviewCount: number;
    ReviewScorePositiveReviewCount: number;
    ReviewScorePositiveReviewCountThreshold?: number;
    MostRecentReviewDate: string;
    isAvgRow: boolean;
}

export interface IReviewScoreSimulatorFilters {
    MemberID?: number;
    ReviewCount?: number;
    StarRating?: number;
}

export interface IReviewScoreSimulatorReviewScoreData {
    ReviewScoreFull: number;
    ReviewScoreDisplay: number;  
    ReviewScoreRatingScore: number;
    ReviewScoreQuantityScore: number;
}
export interface IReviewScoreSimulatorRow extends IReviewScoreSimulatorReviewScoreData {
    MemberID?: number;
    Name?: string;
    Address?: string;
    GroupName?: string;
    ReviewScorePositiveReviewCountThreshold?: number;
    ReviewScoreFullSimulated: number;
    ReviewScoreDisplaySimulated: number;  
    ReviewScoreRatingScoreSimulated: number;
    ReviewScoreQuantityScoreSimulated: number;
    TotalMembers?: number;
    IsAvgRow: boolean;
}

export interface IReviewScoreCounts {
    ReviewScoreRatingValueCount05: number;
    ReviewScoreRatingValueCount10: number;
    ReviewScoreRatingValueCount15: number;
    ReviewScoreRatingValueCount20: number;
    ReviewScoreRatingValueCount25: number;
    ReviewScoreRatingValueCount30: number;
    ReviewScoreRatingValueCount35: number;
    ReviewScoreRatingValueCount40: number;
    ReviewScoreRatingValueCount45: number;
    ReviewScoreRatingValueCount50: number;
}

export interface IReviewScoreMemberView extends IReviewScoreCounts {
    MemberName?: string;
    ReviewScoreFull: number;
    ReviewScoreDisplay: number;
    ReviewScorePositiveReviewCount: number;
    ReviewScoreTotalReviewCount: number;
    ReviewScoreRatingScore: number;
    ReviewScoreQuantityScore: number;
}

export interface IReviewScore {
    ReviewScoreFull: number;
    ReviewScoreDisplay?: number;
}

export interface IReviewScoreFilterState {
    NoReviewScore?: boolean;
    BelowReviewScorePositiveReviewCountThreshold?: boolean;
    NHID?: number;
}


export enum PublishedReviewsFilterGroup { 
    ReviewScoreReviews = 1 
}

export interface IPublishedReviewsFilterState {
    filters?: { 
        NHID?: number 
    };
    filterGroup?: PublishedReviewsFilterGroup
}

export interface IReviewScoreHistoryChartData {
    date: number;
    value: number;
}

export interface IReviewScoreHistoryData {
    ReviewScoreFull: number;
    CreatedAt: string;
}

export interface IReviewScoreHistoryFilters {
    MemberIDs?: number[];
}

export interface IReviewScoreComparison {
    MemberID: number;
    Name: string;
    GroupID: number;
    GroupName: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    RegionCode: string;
    PostCode: string;
    ReviewScoreDisplay: number;
    CareTypesNames: string[];
    DistMiles?: number;   
}

export interface IReviewScoreComparisonResponse { 
    data: IReviewScoreComparison[],
    totalRows: number 
}

export interface IReviewScoreQuantityScore {
    PositiveReviewCount: number;
    QuantityScore: number;
}

export interface IReviewCountTotals {
    TotalPublished: number;
    TotalNotPublished: number;
    TotalPublishedLast30: number;
    TotalPublishedLast90: number;
    TotalPublishNoReplyLast90: number;
}

export interface IReviewScoreBreakdownParams {
    ReviewScoreDisplay: number;
    ReviewScoreFull: number;
    ReviewScoreTotalReviewCount: number;
    ReviewScoreQuantityScore: number;
    ReviewScoreQualityScore: number;
    ReviewScorePositiveReviewCount: number;
    ReviewScorePositiveReviewCountThreshold: number;
}