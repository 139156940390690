import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

type Props = {
    filters: any;
    data: any[];
};

export default function LeadsInsightsChartAll(props: Props) {
    const location = useLocation();
    const path = location.pathname;
    
    const isDashboard = path === '/';

    const chartDiv = 'leads-insights-chart-mix';

    useEffect(() => {
        if (!props.data) {
            return;
        }

        const root = am5.Root.new(chartDiv);
        root.setThemes([am5themes_Animated.new(root)]);
        root._logo.dispose();

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'panX',
            wheelY: 'zoomX',
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        if (!isDashboard) {
            chart.set('scrollbarX', am5.Scrollbar.new(root, {
                orientation: 'horizontal'
            }));
        }

        const now = new Date();

        const data = props.data.map((item: any) => {
            let date = new Date();
            date.setHours(0,0,0,0);
            date.setDate(1);

            let lastColumn = false;
            let lastLine = false;

            switch (props.filters.DataPoint) {
                case 'month':
                    date.setFullYear(item.Year);
                    date.setMonth(item.Month - 1);
                    root.dateFormatter.set('dateFormat', 'MMM, yyyy');
                    lastColumn = date.getMonth() === now.getMonth();
                    lastLine = date.getMonth() === now.getMonth() - 1;
                    break;
                case 'week':
                    date.setFullYear(item.Year);
                    date.setMonth(0);
                    date.setDate(item.Week * 7);
                    root.dateFormatter.set('dateFormat', 'MMM dt, yyyy');
                    break;
                case 'day':
                    date = new Date(item.EventDate);
                    root.dateFormatter.set('dateFormat', 'MMM dt, yyyy');
                    break;
            }

            const columnSettings = lastColumn ? {
                fillOpacity: 0.5
            } : {};

            const strokeSettings = lastLine ? {
                stroke: am5.color('#C3B1E1'),
                fill: am5.color('#C3B1E1')
            } : {
                stroke: am5.color('#C3B1E1'),
                fill: lastColumn ? am5.color('#fff') : am5.color('#C3B1E1')
            };

            return {
                ...item,
                EventDate: date,
                ColumnSettings: columnSettings,
                StrokeSettings: strokeSettings
            };
        }).sort((a, b) => a.EventDate < b.EventDate ? -1 : 1);

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true
        });

        const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: 'EventDate',
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xRenderer.grid.template.setAll({ location: 1 });

        xAxis.data.setAll(data);

        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            }),
            maxPrecision: 0
        }));

        const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            y: isDashboard ? am5.percent(90) : am5.percent(95)
        }));

        const makeSeries = (name: any, fieldName: any, color: string) => {
            const series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                stacked: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: 'EventDate',
                fill: am5.color(color)
            }));

            series.columns.template.setAll({
                tooltipText: '{name}, {categoryX}: {valueY}',
                tooltipY: am5.percent(10),
                templateField: 'ColumnSettings'
            });

            series.data.setAll(data);
            series.appear();

            legend.data.push(series);
        };

        const series2 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: 'Page Views',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'PageViews',
                categoryXField: 'EventDate',
                stroke: am5.color('#C3B1E1')
            })
        );

        series2.strokes.template.setAll({
            strokeWidth: 3,
            templateField: 'StrokeSettings'
        });

        series2.data.setAll(data);

        series2.bullets.push(() => {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    strokeWidth: 3,
                    radius: 5,
                    tooltipText: '{name}, {categoryX}: {valueY}',
                    templateField: 'StrokeSettings'
                })
            });
        });

        legend.data.push(series2);

        makeSeries('Website Referrals', 'WebsiteReferrals', '#006edb');
        makeSeries('Phone Number Views', 'PhoneNumberViews', '#37415c');
        makeSeries('Email Inquiries', 'EmailInquiries', '#e66b00');
        makeSeries('Brochure Inquiries ', 'BrochureInquiries', '#1d8b80');

        series2.appear();
        chart.appear(1000, 100);

        return () => root.dispose();
    }, [props.data]);

    return <div id={chartDiv} style={{height: '440px', width: '100%'}}></div>;
}
