import React from 'react';

const replaceNewlinesWithBreakElements = (text: string) => {
    const sentences = text.split(/\n|\r\n/);

    const formattedSentences = sentences.map((sentence, index) => {
        return (
            <React.Fragment key={index}>
                {sentence}
                <br />
            </React.Fragment>
        );
    });

    return <p>{formattedSentences}</p>;
};

export { replaceNewlinesWithBreakElements };
