import React, { FC, ReactNode } from 'react';
import { NursingHomesLogo } from './nursing_homes_logo';

const NursingHomesCard: FC<{ children: ReactNode }> = ({ children }) => {
    const cardHeading = (
        <a href="/" className="d-flex flex-row align-items-end text-secondary">
            <NursingHomesLogo className="me-3" />
            Portal
        </a>
    );

    return (
        <div className="container-fluid public public__login">
            <div className="row">
                <div className="col-12">
                    <div className="widget mt-3 mt-md-5">
                        <div className="widget-body">
                            <div className="card">
                                <div className="card-header border-bottom">
                                    {cardHeading}
                                </div>
                                <div className="card-body">{children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { NursingHomesCard };
