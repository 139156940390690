import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
    keyProp: string | number;
    value : number;
}

const RatingStars = ({keyProp, value}: IProps) => {

    if(!value) return;

    const aScore: any = value.toString().split('.'); 
    const stars = [];

    for (let i = 0; i < aScore[0]; i++) {
        stars.push(
            <FontAwesomeIcon 
                key={`fullstar-${keyProp}-${i}`}
                icon={faStar} 
                className="me-1" 
                color="#facc15" 
            />
        );
    }

    if (aScore[1]) {
        stars.push(
            <FontAwesomeIcon 
                key={`ahlfstar-${keyProp}`}
                icon={faStarHalf} 
                className="me-1" 
                color="#facc15" 
            />
        );
    }
    
    return (
        <div key={Math.random()} className="d-inline-flex align-items-center">
            { stars }
            <span className="d-block mt-1">
                { value }
            </span>
        </div>
    );
};

export default RatingStars;

