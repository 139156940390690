import { IMenuItem } from '../../interfaces/menu';

export const findMenuItem = (
    menu: IMenuItem[],
    menuPath: string,
    srchPath: string
): IMenuItem => {

    let foundMenuItem: IMenuItem;

    const iter = (menuItem: IMenuItem) => {
        if (menuItem.Route === srchPath || 
            (menuPath && (menuItem.RouteAliases || []).includes(menuPath))) {

            foundMenuItem = menuItem;
            return true;
        }

        return Array.isArray(menuItem.Children) && 
            menuItem.Children.some(iter);
    };

    menu.some(iter);

    return foundMenuItem;
};

export const findPageByID = (menu: IMenuItem[], pageID: number): IMenuItem  => {
    let menuItem: IMenuItem = null;

    const findPage = (menuItems: IMenuItem[]): IMenuItem => {
        for (let idx = 0; idx < menuItems.length; idx ++) {
            const page = menuItems[idx];

            if (page.PageID === pageID) {
                menuItem = page;
            }

            if (!menuItem && page.Children) {
                findPage(page.Children);
            }

            if (menuItem) {
                break;
            }
        }

        return menuItem;
    };

    return findPage(menu);
};