import React, { ReactNode } from 'react';

interface IProps {
    children: ReactNode
}

const PublicRoute = ({
    children
}: IProps) => {
    return (
        <>
           { children }           
        </>
    );
};

export default PublicRoute;
