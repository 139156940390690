import { secureHttp } from '../utils/request';

export const getSessionData = async () => {
    try {
        const {data: {data}} = await secureHttp.get('/auth/session');
        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const sessionTest = async () => {
    try {
        const {data: {data}} = await secureHttp.get('/auth/session/test');
        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};