import React, { Component, MouseEvent } from 'react';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';
import {
    INHSignatureParams,
    INHUploadSignature,
    ICloudinaryData,
    IAssetUploadLog,
    IGetPropertyAsset,
    NHAssetType
} from '../../interfaces/asset';
import { Button } from '../common/button';

interface IUploadWidgetProps {
    nHID?: number;
    fileName?: string;
    assetTypeName?: string;
    assetType: number;
    onAssetUpload: (cloudinaryData: ICloudinaryData) => any;
    showAssetUploadResult: (uploadResult: IAssetUploadLog[]) => void;
    selectedTagID?: number | null;
    setIsUploadLogoTypeSelected?: (isUploadLogoTypeSelected: boolean | null) => void;
}

interface IUploadWidgetState {
    isOpeningWidget: boolean;
    assetUploadLog: IAssetUploadLog[];
}

class UploadWidget extends Component<IUploadWidgetProps, IUploadWidgetState> {
    constructor(props: IUploadWidgetProps) {
        super(props);

        this.state = { 
            isOpeningWidget: false,
            assetUploadLog: []
        };
    }

    private readonly assetTypeUploadProps: any[] = [
        {
            buttonVerb: 'Select Photos to Upload',
            uploadPreset: 'photos',
            maxFiles: 15,
            maxFileSize: 40000000,
            resourceType: 'image'
        }, //40MB
        {
            buttonVerb: 'Select a Website Logo',
            uploadPreset: 'logos',
            maxFiles: 1,
            maxFileSize: 40000000,
            resourceType: 'image'
        }, //40MB
        {
            buttonVerb: 'Select a Video',
            uploadPreset: 'videos',
            maxFiles: 1,
            maxFileSize: 1000000000,
            resourceType: 'video'
        }, //1GB
        {
            buttonVerb: 'Select a Review Card Logo',
            uploadPreset: 'card_logos',
            maxFiles: 1,
            maxFileSize: 40000000,
            resourceType: 'image'
        }, //40MB
        {
            buttonVerb: 'Select a PDF Brochure',
            uploadPreset: 'brochure_pdfs',
            maxFiles: 1,
            maxFileSize: 40000000,
            resourceType: 'image'
        }, //40MB
        {
            buttonVerb: 'Select a Photo',
            uploadPreset: 'meet_team_photos',
            maxFiles: 1,
            maxFileSize: 5000000,
            resourceType: 'image'
        }, //5MB
        {
            buttonVerb: 'Select a Website & Review Card Logo ',
            uploadPreset: 'logos',
            maxFiles: 1,
            maxFileSize: 40000000,
            resourceType: 'image'
        }, //40MB
    ];

    callAssetUploadHandler = async() => {
        if (this.state.assetUploadLog.length > 0) {
           this.props.showAssetUploadResult(this.state.assetUploadLog); 
        } 
    };

    handleUploadAsset = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (
            'assetType' in this.props && !this.props.assetType
        ) {
            this.props.setIsUploadLogoTypeSelected(true);
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select where you want to use this Logo.'
            });
            return false;
        }
        if (
            'selectedTagID' in this.props &&
            (this.props.selectedTagID === null || !this.props.selectedTagID)
        ) {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: 'Please Select a Relevant Tag.<br/><br/>If bulk uploading photos which require different tags, select tag \'Other\' and amend the photo tags post upload.'
            });
            return false;
        }

        this.setState({ isOpeningWidget: true });
        const windowObj = window as any;
        const selectedAssetTypeUploadProps =
            this.assetTypeUploadProps[this.props.assetType ?  this.props.assetType - 1 : 1];
        const assetUploadLog: IAssetUploadLog[] = [];
        const params: INHSignatureParams = {
            UploadPreset: selectedAssetTypeUploadProps.uploadPreset
        };
        if (this.props.fileName) {
            params.FileName = this.props.fileName;
        }
        const generatedSignature: INHUploadSignature =
            await assetApi.generateUploadSignature(params);
        let existingPhotosAssetNumber = 0;
        if (this.props.assetType === NHAssetType.Photos) {
            const propertyPhotosData: IGetPropertyAsset[] =
                await assetApi.getPropertiesPhotos([this.props.nHID]);
            existingPhotosAssetNumber = propertyPhotosData.length;
        }
        if (generatedSignature) {
            let sequenceCounter = 0;
            let pageCount: number = 0;
            const publicIds: string[] = [];
            const assetUploadWidget = windowObj.cloudinary.createUploadWidget(
                {
                    preBatch: (cb: any, data: any) => {
                        data.files.forEach((item: any, i: number) => {
                            const randomString = Math.random()
                                .toString(36)
                                .substring(2, 9);
                            publicIds[i] =
                                this.props.fileName +
                                '_' +
                                randomString +
                                (existingPhotosAssetNumber + i);
                        });
                        cb();
                    },
                    prepareUploadParams: async function (cb: any) {
                        const newParams = [];
                        for (let i = 0; i < publicIds.length; i++) {
                            const uploadParam: any = {};
                            const generatedPhotoSignature =
                                await assetApi.generateUploadSignature({
                                    UploadPreset:
                                        selectedAssetTypeUploadProps.uploadPreset,
                                    FileName: publicIds[i]
                                });
                            uploadParam.public_Id =
                                generatedPhotoSignature.FileName;
                            uploadParam.signature =
                                generatedPhotoSignature.Signature;
                            uploadParam.timestamp =
                                generatedPhotoSignature.SignatureTimestamp;
                            newParams.push(uploadParam);
                        }
                        cb(newParams);
                    },
                    cloudName: generatedSignature.CloudName,
                    apiKey: generatedSignature.ApiKey,
                    uploadPreset: selectedAssetTypeUploadProps.uploadPreset,
                    sources: [
                        'local',
                        'url',
                        'dropbox',
                        'google_photos',
                        'facebook',
                        'instagram'
                    ],
                    multiple:
                        selectedAssetTypeUploadProps.maxFiles > 1
                            ? true
                            : false,
                    maxFiles: selectedAssetTypeUploadProps.maxFiles,
                    maxFileSize: selectedAssetTypeUploadProps.maxFileSize,
                    resourceType: selectedAssetTypeUploadProps.resourceType,
                    showPoweredBy: false,
                    thumbnails: '.thumbnailV2'
                },
                async (error: any, result: any) => {
                    this.setState({ isOpeningWidget: false });
                    if (!error && result?.event === 'success') {
                        try {
                            if (result.info.pages) {
                                pageCount = result.info.pages;
                            }
                            ++sequenceCounter;
                            let transformedAssetUrl = null;
                            if (this.props.assetType === NHAssetType.Photos) {
                                transformedAssetUrl = result.info.secure_url;
                            } else if (
                                this.props.assetType === NHAssetType.Logos
                            ) {
                                transformedAssetUrl =
                                    result.info.secure_url.replace(
                                        /\.[^/.]+$/,
                                        '.png'
                                    );
                            } else {
                                transformedAssetUrl =
                                    result.info.secure_url.replace(
                                        /\.[^/.]+$/,
                                        '.mp4'
                                    );
                                transformedAssetUrl =
                                    result.info.secure_url.replace(
                                        'video/upload',
                                        'video/upload/c_scale,h_360,q_95'
                                    );
                            }
                            const createdAssetDetail =
                                await this.props.onAssetUpload({
                                    AssetUrl: result.info.secure_url,
                                    TransformedAssetUrl: transformedAssetUrl,
                                    IsTransfromationComplete: true,
                                    Sequence:
                                        sequenceCounter +
                                        existingPhotosAssetNumber,
                                    CloudName: generatedSignature.CloudName,
                                    PageCount: pageCount
                                });
                            assetUploadLog.push({
                                Type: 'success',
                                FileName: result.info.secure_url,
                                Message: '',
                                PageCount: pageCount,
                                AssetDetail: createdAssetDetail
                            });
                            this.setState({assetUploadLog: assetUploadLog});
                        } catch (e) {
                            assetUploadLog.push({
                                Type: 'error',
                                FileName: result?.info?.secure_url,
                                Message: 'Failed to upload'
                            });
                        }
                    } else if (error) {
                        assetUploadLog.push({
                            Type: 'error',
                            FileName: '',
                            Message: error.statusText
                        });
                    } else if (!error && result?.event === 'close') {
                        setTimeout(this.callAssetUploadHandler, 2000 );
                    }
                }
            );
            assetUploadWidget.open();
        } else {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `You are not authorized to upload ${this.props.assetTypeName}, Error generating upload signature.`
            });
        }
    };

    render() {
        const selectedAssetTypeUploadProps =
            this.assetTypeUploadProps[this.props.assetType ? this.props.assetType - 1 : 1];
        const isPendingOpening = this.state.isOpeningWidget;

        return Object.hasOwn(window, 'cloudinary') ? (
            <div className="row col-6">
                <div>
                    <Button
                        id="upload_widget"
                        isLoading={isPendingOpening}
                        className="btn btn-outline-primary btn-outline text-nowrap"
                        onClick={this.handleUploadAsset}
                    >
                        {selectedAssetTypeUploadProps.buttonVerb}
                    </Button>
                </div>
                <div className="thumbnailV2" />
            </div>
        ) : (
            <span className="required">
                The upload service is unavailable, please try again in a few
                minutes. If the problem persists please check (or ask your Tech
                department to check) that your firewall is not blocking the
                following domains: "res.cloudinary.com" "api.cloudinary.com"
                "widget.cloudinary.com" otherwise please contact your account
                manager.
            </span>
        );
    }
}

export default UploadWidget;