import React, { FC, ReactNode } from 'react';

interface IProps {
    data: ReactNode[];
}

const ServicesFields: FC<IProps> = ({ data }) => {
    return (
        <>
            {Object.entries(data).map(([subkey, subvalue], index: number) => {
                return (
                    <div className="ps-2 mb-2" key={index}>
                        <span className=" text-muted text-decoration-underline">
                            {subkey}
                        </span>
                        <>{subvalue}</>
                    </div>
                );
            })}
        </>
    );
};

export { ServicesFields };