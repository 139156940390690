import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import { 
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailPayload,
    IPropertyInsuranceTypesPayloadItem,
} from '../../../interfaces/property';
import * as nhAPI from '../../../api/property';
import { updateProfileCompleteness } from '../../../utils/profile_completeness';
import { Toast, ToastType, showToast } from '../../../utils/toast';
import coreDetailsFormSchema from './property_core_details_form.validation';
import * as formCommon from './property_core_detail_cost_funding_form common';
import { useNavigate } from 'react-router-dom';

interface ICoreDetailFormProps {
    nhID: number
}

const PropertyCostFundingForm = ({ nhID }: ICoreDetailFormProps) => {
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ isEdit, setIsEdit] = useState<boolean>(false);
    const [ insuranceTypes, setInsuranceTypes ] = useState<IPropertyCoreDetailInsuranceType[]>([]);

    const navigate = useNavigate();

    const onSubmit = async (e: any) => {
        const { ...coreDetails } = e;

        const insuranceTypes: IPropertyInsuranceTypesPayloadItem[] = [];
        Object.keys(coreDetails).forEach((key: string) => {
            if (key.startsWith('InsuranceType')) {
                const propertyInsuranceTypeID = Number(key.split('_')[1]);
                const isActive = coreDetails[key];

                if (isActive) {
                    insuranceTypes.push({
                        PropertyInsuranceTypeID: propertyInsuranceTypeID,
                        IsActive: isActive === '1' ? true : false
                    });                    
                }

                delete coreDetails[key];
            }
        });

        delete coreDetails['LastUpdated'];
        delete coreDetails['CoreDetailsLastUpdated'];
        delete coreDetails['CostFundingLastUpdated'];

        const payload: IPropertyCoreDetailPayload = {
            ...coreDetails,
            InsuranceTypes: insuranceTypes.length ? insuranceTypes : null
        };

        setIsLoading(true);

        try {
            await (nhAPI as any)[`${isEdit ? 'update' : 'create'}PropertyCoreDetailData`](nhID, payload);
            showToast(`Care Offered details successfully ${isEdit ? 'updated' : 'created'}. Please allow up to 60 minutes for the changes to show on the website.`, ToastType.Success);

            initForm();
                
            window.scrollTo(0, 0);
        } catch(err) {
            showToast('An error occurred saving Core Details.', ToastType.Error);
        } finally {
            if (!isEdit) {
                setIsEdit(true);
            }
            
            setIsLoading(false);
            updateProfileCompleteness();
        }
    };

    const getFormDefaults = async (): Promise<any> => {

        setIsLoading(true); 
        const [formDefaults, insuranceTypes] = await formCommon.getFormDefaults(nhID);

        if (insuranceTypes) {
            setInsuranceTypes(insuranceTypes); 
        }

        setIsLoading(false);

        if (formDefaults.NHID) {
            setIsEdit(true);

        }

        return formDefaults;
    };

    const { 
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        getValues,
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(coreDetailsFormSchema), 
        defaultValues:  async () => await getFormDefaults(),
    });
    
    const initForm = async () => {
        setIsEdit(false);
        const defaults = await getFormDefaults();
        reset({...defaults});
    };

    useEffect(() => {
       initForm();
    }, [ nhID ]);

    const longTermCareSelected = getValues()['LongTermCare'] === '1';
    const shortTermCareSelected = getValues()['ShortTermCare'] === '1';
    const respiteCareSelected = getValues()['RespiteCare'] === '1';

    const minAgeRequirement = getValues()['MinAgeRequirement'] === '1';
    const pediatricMaxAgeRequirement = getValues()['PediatricMaxAgeRequirement'] === '1';
    const respiteMinStayRequirement = getValues()['RespiteMinStayRequirement'] === '1';

    const roomTypeSharedRoom = getValues()['RoomTypeShared'] === '1';
    const roomTypePrivateRoom = getValues()['RoomTypePrivateRoom'] === '1';
    const roomTypeSemiPrivateRoom = getValues()['RoomTypeSemiPrivate'] === '1';
    const roomTypePrivateSuite = getValues()['RoomTypePrivateSuite'] === '1';

    useEffect(() => {
        if (!minAgeRequirement) {
            setValue('MinAge', null);
            trigger('MinAge');
        }

        if (!pediatricMaxAgeRequirement) {
            setValue('PediatricMaxAge', null);
            trigger('PediatricMaxAge');
        }

        if (!respiteMinStayRequirement) {
            setValue('RespiteMinStay', null);
            trigger('RespiteMinStay');
        }
    }, [minAgeRequirement, pediatricMaxAgeRequirement, respiteMinStayRequirement]);


    const setTermData = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        source: string,
        target: string
    ) => {
        e.preventDefault();

        const sourceKeys = [
            'SharedCostPerDay',
            'PrivateCostPerDay',
            'SemiPrivateCostPerDay'
        ];

        const sourceData = getValues(sourceKeys.map((key: string) => `${source}${key}`));

        sourceKeys.forEach((key: string, index: number) => setValue(`${target}${key}`, sourceData[index], {
            shouldDirty: true
        }));
    };

    const roomTypeLink = () => (
        <>
            <span
                className="span-link"
                onClick={() => {
                    navigate('/core-details', { 
                        state: { 
                            targetId: 'roomtype',
                            targetNHID: nhID
                        } 
                    });
                }} 
            >
                room type
            </span>
        </>
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            
            {isLoading ? <LoadingSpinner /> : null}

            <Toast></Toast>

            <div>
                <div className="widget">
                    <div className="widget-body">
                        <div className="card"> 
                            <div className="card-header">
                                <span>
                                    <h4>Long Term Care Cost</h4>
                                    <small>To edit the below inputs &lsquo;<a href={'/core-details'} className="">Long Term Care</a>&rsquo; and the relevant { roomTypeLink() } must be set to &lsquo;Yes&rsquo;.</small>
                                </span>
                            </div>
                            <div className="card-body my-4">
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Shared room', 
                                        Key: 'LongTermSharedCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypeSharedRoom
                                    }], !longTermCareSelected || !roomTypeSharedRoom)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private room', 
                                        Key: 'LongTermPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypePrivateRoom
                                    }], !longTermCareSelected || !roomTypePrivateRoom)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Semi-private room', 
                                        Key: 'LongTermSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypeSemiPrivateRoom
                                    }], !longTermCareSelected  || !roomTypeSemiPrivateRoom)} 
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private Suite', 
                                        Key: 'LongTermPrivateSuiteCostPerDay', 
                                        IncludedInProfileCompleteness: longTermCareSelected && roomTypePrivateSuite
                                    }], !longTermCareSelected  || !roomTypePrivateSuite)} 
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Rates are all-inclusive?', Key: 'LongTermRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'LongTermRatePackagesAvailable' }
                                    ], !(longTermCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                } 
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <span>
                                    <h4>Short Term Care Cost</h4>
                                    <small className="me-2">To edit the below inputs &lsquo;<a href={'/core-details'} className="">Short Term Care</a>&rsquo; and the relevant { roomTypeLink() } must be set to &lsquo;Yes&rsquo;.</small>
                                    <a 
                                        href="#" 
                                        className="small" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'LongTerm', 'ShortTerm')}
                                    >
                                        Use Long Term Care Prices
                                    </a>
                                </span>
                            </div>
                            <div className="card-body my-4">
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Shared room', 
                                        Key: 'ShortTermSharedCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypeSharedRoom
                                    }], !shortTermCareSelected || !roomTypeSharedRoom)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private room', 
                                        Key: 'ShortTermPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypePrivateRoom
                                    }], !shortTermCareSelected||  !roomTypePrivateRoom)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Semi-private room', 
                                        Key: 'ShortTermSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypeSemiPrivateRoom
                                    }], !shortTermCareSelected || !roomTypeSemiPrivateRoom)} 
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private Suite', 
                                        Key: 'ShortTermPrivateSuiteCostPerDay', 
                                        IncludedInProfileCompleteness: shortTermCareSelected && roomTypePrivateSuite
                                    }], !shortTermCareSelected  || !roomTypePrivateSuite)} 
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Rates are all-inclusive?', Key: 'ShortTermRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'ShortTermRatePackagesAvailable' }
                                    ], !(shortTermCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                } 
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <span>
                                    <h4>Respite Care Cost</h4>
                                    <small className="me-2">To edit the below inputs &lsquo;<a href={'/core-details'} className="">Respite Care</a>&rsquo; and the relevant { roomTypeLink() } must be set to &lsquo;Yes&rsquo;.</small>
                                    <a 
                                        href="#" 
                                        className="small" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'LongTerm', 'Respite')}
                                    >
                                        Use Long Term Care Prices
                                    </a>
                                    <small>&nbsp;or&nbsp;</small>
                                    <a 
                                        href="#" 
                                        className="small" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'ShortTerm', 'Respite')}
                                    >
                                        Use Short Term Care Prices
                                    </a>
                                </span>
                            </div>
                            <div className="card-body my-4">
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Shared room', 
                                        Key: 'RespiteSharedCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypeSharedRoom
                                    }], !respiteCareSelected || !roomTypeSharedRoom)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private room', 
                                        Key: 'RespitePrivateCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypePrivateRoom
                                    }], !respiteCareSelected || !roomTypePrivateRoom)}
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Semi-private room', 
                                        Key: 'RespiteSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypeSemiPrivateRoom
                                    }], !respiteCareSelected || !roomTypeSemiPrivateRoom)} 
                                { 
                                    formCommon.renderCostPerDay(register, errors, [{ 
                                        Label: 'Private Suite', 
                                        Key: 'RespitePrivateSuiteCostPerDay', 
                                        IncludedInProfileCompleteness: respiteCareSelected && roomTypePrivateSuite
                                    }], !respiteCareSelected  || !roomTypePrivateSuite)} 
                                { 
                                    formCommon.renderOptionalDuration(register, errors, setValue, watch, { 
                                        Label: 'Minimum stay required?', 
                                        Key: 'RespiteMinStay', 
                                        DurationUnit: 'days' 
                                    },!(respiteCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                }
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Rates are all-inclusive?', Key: 'RespiteRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'RespiteRatePackagesAvailable' }
                                    ], !(respiteCareSelected && (roomTypeSharedRoom || roomTypePrivateRoom || roomTypeSemiPrivateRoom)))
                                } 
                            </div>
                        </div>
                        
                        <div className="card"> 
                            <div className="card-header">
                                <h4>Bed Allocation</h4>
                            </div>
                            <div className="card-body my-4">
                                { formCommon.renderRadioGroup(register, setValue, [{ Label: 'All beds dual-licenced for either Medicare or Medicaid?', Key: 'BedsAllDualLicenced', IncludedInProfileCompleteness: true }], false) } 
                                {
                                    formCommon.renderInteger(register, errors, [
                                        { Label: 'Medicaid bed count', Key: 'BedsMedicaidCount', MinIntegerValue: 0, MaxIntegerValue: 999, IncludedInProfileCompleteness: true },
                                        { Label: 'Medicare bed count', Key: 'BedsMedicareCount', MinIntegerValue: 0, MaxIntegerValue: 999, IncludedInProfileCompleteness: true  }
                                    ], false)
                                }
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <h4>Payment types</h4>
                            </div>
                            <div className="card-body my-4">
                                { formCommon.renderPercent(register, errors, [{ Label: '% of residents/patients who use private insurance to pay for care', Key: 'PrivateInsurancePc' }], false) }
                                {
                                    formCommon.renderRadioGroup(register, setValue, [
                                        { Label: 'Managed care contracts accepted as in-network?', Key: 'ManagedCareContractsInNetwork', IncludedInProfileCompleteness: true },
                                        { Label: 'Do you accept Medicare Advantage plans?', Key: 'AcceptMedicareAdvantage', IncludedInProfileCompleteness: true },
                                        { Label: 'Do you accept Medicaid pending patients?', Key: 'AcceptMedicaidPending', IncludedInProfileCompleteness: true }
                                    ], false)
                                } 
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <h4>Insurance Providers Accepted</h4>
                            </div>
                            <div className="card-body my-4 card-form">
                                {
                                    (insuranceTypes || []).map(({ PropertyInsuranceTypeID, Name }: IPropertyCoreDetailInsuranceType) =>
                                        <div className="row mb-4" key={`insurance-type-${PropertyInsuranceTypeID}`}>
                                            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                                                { Name }
                                            </div>
                                            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                                                <div className='d-flex align-items-center'>
                                                    { formCommon.renderRadios(register, `InsuranceType_${PropertyInsuranceTypeID}`, false) }
                                                </div>
                                                <div>
                                                    <button
                                                        title='reset'
                                                        onClick={(e) => { 
                                                            e.preventDefault();
                                                            setValue(`InsuranceType_${PropertyInsuranceTypeID}`, null, { shouldDirty: true });    
                                                        }}
                                                        className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1"
                                                    >
                                                        <i className="text-primary fa fa-history" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div> 
                </div> 
            </div> 
            <div className="d-flex justify-content-center sticky-bottom my-4 pb-4">
                <button 
                    type="submit" 
                    className="btn btn-primary shadow"
                    disabled={!isDirty || isLoading}
                >
                    Update
                </button>
            </div>
        </form>
    );
};

export default PropertyCostFundingForm;
