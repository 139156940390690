import { get } from 'lodash';
import { secureHttp } from '../utils/request';

export const getReviewReplyData = async (dateFrom: string, dateTo: string, nhID: number) => {
    try {
        const params = {
            DateFrom: dateFrom,
            DateTo: dateTo,
            NHID: nhID
        };

        const resp = await secureHttp.get('/chart/reviewreply', { params });
        const data = get(resp, 'data.data', []);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return [];
    }
};