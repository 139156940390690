import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import DisplayAsset from '../../components/assets/display_asset';
import UploadWidget from '../../components/cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';
import cx from 'classnames';

import { 
    NHAssetType,
    ICloudinaryData,
    IAssetUploadLog,
    IAssetPayLoad,
    IGetPortalPropertyWebAndCardLogoAsset
 } from '../../interfaces/asset';

interface IProps {
    handleReloadPropertyLogos: (nhID: number[]) => Promise<IGetPortalPropertyWebAndCardLogoAsset[]>;
    orgNHID?: number | null;
    nhIDs?: number[];
    selNHID: number | null;
    propertyLogos: IGetPortalPropertyWebAndCardLogoAsset[];
    setPropertyLogos: (logos: IGetPortalPropertyWebAndCardLogoAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    handleClose?: any;
}

const IndividualPropertyLogo = ({
    handleReloadPropertyLogos,
    orgNHID,
    nhIDs,
    selNHID,
    propertyLogos,
    setPropertyLogos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleClose
}: IProps) => {

    const refreshNHID = orgNHID ? [orgNHID] : nhIDs ? nhIDs : [selNHID];

    const [ uploadLogoType, setUploadLogoType ] = useState<number|null>(null);
    const [ isUploadLogoTypeSelected, setIsUploadLogoTypeSelected ] = useState<boolean>(false);
   
    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { 
            NHID: selNHID,
            AssetType: uploadLogoType === NHAssetType.WebAndCardLogos ? NHAssetType.Logos : uploadLogoType,
            ...cloudinaryData,
            CreateCardLogoFromWebLogo: uploadLogoType === NHAssetType.WebAndCardLogos ?  true : false
        };
        const assetID = await assetApi.createAsset(assetPayLoad);
        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0]
        };
    };
    
    const handleShowAssetUploadResult = async(assetUploadLog : IAssetUploadLog[]) => {
        let hasAssetUploaded = false;
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasAssetUploaded = true;
                break;
            }
        }

        if( !hasAssetUploaded ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error occured while uploading logo, please try again.'
            });
        } else {
            try {
                setBusyLoadingAsset(true);
                const updatedPropertyLogos = await handleReloadPropertyLogos(refreshNHID);
                setPropertyLogos(updatedPropertyLogos);
                handleClose ?. ();     
            } catch (err) {
                Swal.fire({
                    title: 'Failed to upload the logo',
                    text: 'Unknown error',
                    icon: 'error'
                });
            } finally {
                setBusyLoadingAsset(false);
            }
        }
    };

    const handleDelete = async(assetType: number) => {
        Swal.fire({
            title: 'Are you sure you want to delete this logo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0abb87',
            cancelButtonColor: '#fd397a',
            confirmButtonText: 'Yes'
        }).then(async (result: any) => {
            if (result.value) {
                setBusyLoadingAsset(true);
                try {
                    let  deletedAssetID: number;
                    if(assetType === NHAssetType.Logos) {
                        deletedAssetID = await assetApi.deletePropertyLogo(propertyLogos[0]?.NHID);
                    } else if(assetType === NHAssetType.CardLogos) {
                        deletedAssetID = await assetApi.deletePropertyCardLogo(propertyLogos[0]?.NHID);
                    }
                    if (!deletedAssetID) {
                        throw new Error('Failed to delete Logo');
                    }
                    const updatedPropertyLogos = await handleReloadPropertyLogos(refreshNHID);
                    setPropertyLogos(updatedPropertyLogos);
                    handleClose ?. ();
                } catch (err) {
                    Swal.fire({
                        title: 'Failed to delete Logo',
                        text: 'Unknown error',
                        icon: 'error'
                    });
                } finally {
                    setBusyLoadingAsset(false);
                }
            }
        });
    };

    const handleCreateLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploadLogoTypeSelected(false);
        setUploadLogoType(parseInt(e.target.value, 10));
    };

    const renderIndividualPropertyLogo = () => {
        const property = (propertyLogos || []).length > 0  ? propertyLogos[0].Property : null;
        const asset = property && (propertyLogos[0].Asset || []).length > 0  ? propertyLogos[0].Asset[0] : null;
        return (
            <>
                <div className="widget mt-0" data-widget-modal={`${handleClose ? 'true' :  'false'}`}>
                    <div className="widget-body">
                        <div className="media-upload">
                            <div className="card" data-card="Media Scroller">
                                <div className="card-header">
                                    <h4>
                                        Website Logo for {property?.Name ?  property.Name : ''}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-info no-scroll">
                                        <div>
                                        {
                                            asset && asset.WebLogoAssetID > 0 ?
                                                <div key={asset.WebLogoAssetID} className="media-edit media-logo">
                                                    <DisplayAsset
                                                        TransformedAssetUrl={asset.WebLogoTransformedAssetUrl}
                                                        FileName={asset.WebLogoFileName}
                                                        AssetType={NHAssetType.Logos} 
                                                    />
                                                    <div>
                                                        <ul>
                                                            <li>
                                                                <a href=""
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleDelete(NHAssetType.Logos);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            :
                                                <span className="msg msg-error">
                                                    No logo uploaded
                                                </span>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <h4>
                                        Review Card Logo for {property?.Name ?  property.Name : ''}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-info no-scroll">
                                        <div>
                                        {
                                             asset && asset.CardLogoAssetID > 0 ?
                                                <div key={asset.CardLogoAssetID} className="media-edit media-logo">
                                                    <DisplayAsset
                                                        TransformedAssetUrl={asset.CardLogoTransformedAssetUrl}
                                                        FileName={asset.CardLogoFileName}
                                                        AssetType={NHAssetType.CardLogos} 
                                                    />
                                                    <div>
                                                        <ul>
                                                            <li>
                                                                <span className="d-block lh-sm mb-3">
                                                                    <a href=""
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleDelete(NHAssetType.CardLogos);
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </a>
                                                                </span>
                                                                <span className="d-block lh-sm mb-3">
                                                                    <a 
                                                                        rel="noreferrer" 
                                                                        target="_blank" 
                                                                        href={`https://www.nursinghomes.com/logos/reviewcard/${asset.CardLogoFileName}.pdf`}
                                                                    >
                                                                        Download PDF Print Version
                                                                    </a>
                                                                </span>
                                                                <div className="d-block lh-sm">
                                                                    <Link    
                                                                        to="/review-cards"
                                                                        state={{ NHID: selNHID }}
                                                                    >
                                                                        Order Review Cards
                                                                    </Link>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            :
                                                <span className="msg msg-error">
                                                    No review card logo uploaded
                                                </span>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-card="Media Upload">
                                <div className="card-header">
                                    <h4>
                                        Upload a New Logo
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <>
                                        <div className="card-info">
                                            <ul>
                                                <li>
                                                    <div>
                                                        <h5 className={cx('', !isUploadLogoTypeSelected ? '' : 'msg-error')}>Please select where you want to use this logo*</h5>
                                                        <div className="kt-radio-list kt-radio-list--inline mt-2 mb-4">
                                                            <label className="kt-radio kt-radio--brand" style={{ marginRight: '20px' }}>
                                                                <input
                                                                    type="radio"
                                                                    name="createLogo"
                                                                    value={NHAssetType.WebAndCardLogos}
                                                                    onChange={handleCreateLogo}
                                                                    defaultChecked={uploadLogoType === NHAssetType.WebAndCardLogos ? true : false}
                                                                />
                                                                   &nbsp;For Website & Review Card
                                                                <span></span>
                                                            </label>

                                                            <label className="kt-radio kt-radio--brand" style={{ marginRight: '20px' }}>
                                                                <input
                                                                    type="radio"
                                                                    name="createLogo"
                                                                    value={NHAssetType.Logos}
                                                                    onChange={handleCreateLogo}
                                                                    defaultChecked={uploadLogoType === NHAssetType.Logos ? true : false}
                                                                />
                                                                    &nbsp;For Website Only
                                                                <span></span>
                                                            </label>

                                                            <label className="kt-radio kt-radio--brand" style={{ marginRight: '20px' }}>
                                                                <input
                                                                    type="radio"
                                                                    name="createLogo"
                                                                    value={NHAssetType.CardLogos}
                                                                    onChange={handleCreateLogo}
                                                                    defaultChecked={uploadLogoType === NHAssetType.CardLogos ? true : false}
                                                                />
                                                                    &nbsp;For Review Card Only
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>  
                                                    
                                                    <div>
                                                        <UploadWidget 
                                                            nHID = {selNHID}
                                                            fileName={property?.Slug}
                                                            assetTypeName = 'Select Logo to Upload' 
                                                            assetType = {uploadLogoType}
                                                            onAssetUpload = {handleAssetUpload}
                                                            showAssetUploadResult = {handleShowAssetUploadResult}
                                                            setIsUploadLogoTypeSelected= {setIsUploadLogoTypeSelected}
                                                        />
                                                        <p>
                                                            Please note maximum file size 40MB.
                                                        </p>
                                                        <p>
                                                            Large logo file can take up to 5 minutes to upload on slower connections.
                                                        </p>
                                                        <p>If in doubt please refresh the page after a couple of minutes.</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-info-box">
                                            <h5>
                                                Uploading Logo - Tips &amp; Terms
                                            </h5>
                                            <p>
                                                Your Logo Uploaded will automatically resized and cropped to best fit our logo template (192 pixels width). If you are not happy with the automatic resize and crop please upload a pre-formatted logo to the exact logo template specification.
                                            </p>
                                            <p>
                                                NursingHomes.com reserve the right to remove any logos we deem unsuitable.
                                            </p>
                                            <p>
                                                <strong>By pressing Upload button, I/we agree to the terms & conditions set out on <a href="https://>www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                                            </p>
                                        </div>
                                    </>
                                    { asset && (asset?.CardLogoAssetID > 0 || asset?.WebLogoAssetID > 0) ?
                                        <div className="card-info-box">
                                            <span className="msg msg-error">
                                                Please note any new logos uploaded will overwrite your existing logo
                                            </span>
                                        </div>
                                    :
                                        ''
                                        
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        
    };

    return (
        <>
            { busyLoadingAsset ? <LoadingSpinner show={true} /> : null }
            { renderIndividualPropertyLogo() }
        </>
    );
};

export default IndividualPropertyLogo;