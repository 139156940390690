import React, { useRef } from 'react';
import { useIntersection} from '../../hooks/use_intersection';
import { intersectionObserverAvailable } from '../../utils/common';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { get } from 'lodash';

interface IProps {
    data: any;
    setActiveReply?: any;
    setHasReply?: any;
    createReplyBtnText? : string;
    updateReplyBtnText? : string;
}

const ReviewListReplyButton = ({ 
    data, 
    setActiveReply, 
    setHasReply, 
    updateReplyBtnText, 
    createReplyBtnText 
}: IProps) => {

    const ref = useRef();
    const inViewport = intersectionObserverAvailable() ? useIntersection(ref) : true; 

    const extras = {
        PropertyName: data.Name,
        ReviewContent: data.ReviewContent,
        ReviewID: data.ReviewID,
        OverallRating: data.OverallRating,
        ExperienceTypeName: data.ExperienceTypeName,
        ConnectionName: data.ConnectionName
    };

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const canReplyToReview = get(sessionState, 'Session.Perms.CanReplyToReview', false);

    return (
        <div ref={ref}>
            {
                inViewport && canReplyToReview ?
                <button
                className="review-replybutton btn btn-primary"
                onClick={() => {
                    setActiveReply(
                        data.Reply ?
                        {...data.Reply, ...extras }
                        : { ...extras }
                    );
                    setHasReply(data.Reply !== null);
                }}
                >
                    { data.Reply ? updateReplyBtnText || 'Amend Reply' : createReplyBtnText || 'Add a Reply' }
                </button>: null
            }
        </div>
    );
};
export default ReviewListReplyButton;
