import React from 'react';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { LoadingSpinner } from '../../components/common/loading_spinner';
interface IExportDisplay {
    totalItemsToFetch: number;
    totalItemsFetched: number;
    etaMsecs: number;
}

const ExportDisplay = (props: IExportDisplay) => {
    const getProgressPercentage = () => {
        const {totalItemsToFetch, totalItemsFetched} = props;

        if (totalItemsToFetch === null) {
            return 0;
        }

        return totalItemsToFetch > 0 ? Math.min(Math.floor(totalItemsFetched * 100 / totalItemsToFetch), 100) : 0;
    };

    const getProgressMessage = () => {
        const {totalItemsToFetch, totalItemsFetched, etaMsecs} = props;
        const timeEstimate = etaMsecs !== -1 ? `. Time remaining ${Math.floor(etaMsecs / 1000)} seconds` : '';

        return `Fetched ${totalItemsFetched} of ${totalItemsToFetch} items${timeEstimate}`;
    };

    const renderProgress = () => {
        if (props.totalItemsToFetch === null) {
            return <h5>Preparing export...<LoadingSpinner show={true}/></h5>;
        }

        return <div>
            <h5>Exporting</h5>
            <ProgressBar animated now={getProgressPercentage()}/>
            <div className="review pt-4 pb-4">
                <div className="container-fluid">
                    <div>
                        <h5>{getProgressMessage()}</h5>
                    </div>
                </div>
            </div>
        </div>;
    };


    return (
        <div className="px-4 pt-4">
            {renderProgress()}
        </div>
    );

};

export default ExportDisplay;