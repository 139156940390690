import { useSelector } from 'react-redux';
import { ISessionState } from '../interfaces/session';
import { RootState } from '../store/reducers';

// Can only be used within the redux context
const useSessionState = (): ISessionState => {
    return useSelector((state: RootState) => state.SessionState);
};

export { useSessionState };
