import { get } from 'lodash';

import {
    IPropertyCountTotalsFilters,
    IReviewCountTotals,
    IReviewFilters,
    IReviewScoreFilters,
    IReviewStatisticTotals,
    IReviewScoreBreakdownParams
} from '../interfaces/review';

import { timerStart, timerStop } from '../utils/common';
import { secureHttp } from '../utils/request';
import { IReply } from '../interfaces/reply';
import { ReviewPublicationStatusType } from '../interfaces/review';

export const getReviews = async (params: IReviewFilters): Promise<any[]> => {
    try {

        const startT = timerStart();
        const { data } = await secureHttp.get('/review', { params });
        timerStop(startT, data.message, 'msecs');

        return data;
    } catch (e: any) {
        if (e.status !== 404) console.error(e);

        return [];
    }
};

export const getReviewsBySearchString = async (str: string, params: any) => {
    try {
        const { data: { data } } = await secureHttp.get('/review/search', {
            params: {
                SearchStr: str,
                ...params
            }
        });

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return [];
    }
};

export const createReply = async (reviewID: number, reply: IReply) => {
    try {
        const { data: { data } } = await secureHttp.post(`/review/${reviewID}/reply`, reply);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const updateReply = async (reviewID: number, reply: IReply) => {
    try {
        const { data: { data } } = await secureHttp.patch(`/review/${reviewID}/reply`, reply);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const deleteReply = async (reviewID: number) => {
    try {
        const { data: { data } } = await secureHttp.delete(`/review/${reviewID}/reply`);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const updateReviewPublicationAuxStatus = async (reviewID: number, pubAuxStatus: ReviewPublicationStatusType) => {
    try {
        const { data: { data } } = await secureHttp.patch(`/review/publicationauxstatus/${reviewID}/${pubAuxStatus}`);
        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getReviewScheme = async () => {
    try {
        const storageItem: any = window.sessionStorage.getItem('ReviewScheme');
 
        if (storageItem) {
            try {

                const cacheData: any = JSON.parse(storageItem);
                return cacheData;

            } catch (err) {
                window.sessionStorage.removeItem('ReviewScheme');
            }
        }

        const res = await secureHttp.get('/review/schemes/reviewschemes');
        const data: any = get(res, 'data.data', [])[0];

        window.sessionStorage.setItem('ReviewScheme', JSON.stringify(data));

        return data;

    } catch (e) {
        console.error(e);
    }
};

export const getConnections = async () => {
    try {
        const storageItem: any = window.sessionStorage.getItem('ReviewerConnections');
 
        if (storageItem) {
            try {

                const cacheData: any = JSON.parse(storageItem);
                return cacheData;

            } catch (err) {
                window.sessionStorage.removeItem('ReviewerConnections');
            }
        }

        const res = await secureHttp.get('/review/connections');
        const data: any = get(res, 'data.data', []);

        window.sessionStorage.setItem('ReviewerConnections', JSON.stringify(data));

        return data;

    } catch (e) {
        console.error(e);
    }
};

export const getReviewStatisticTotals = async (nhID: number): Promise<IReviewStatisticTotals> => {  
    const params = {
        NHID: nhID
    };

    try {
        const { data: { data } } = await secureHttp.get('/review/totals/statistic', { params });
        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getReviewScore = async (params: IReviewScoreFilters): Promise<any> => {
    try {
        const { data: { data } } = await secureHttp.get('/review/reviewscore', { params });

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return [];
    }
};

export const getReviewScoreForNHID = async (nhID: number) => {
    try {
        const { data: { data } } = await secureHttp.get(`/review/reviewscore/${nhID}`);
        return data;
    } catch (e: any) {
        console.error(e);
        return null;
    }
};

export const getReviewScoreQuantityScores = async (reviewSchemeID: number): Promise<any> => {
    try {
        const { data: { data } } = await secureHttp.get(`/review/reviewscore/quantityscores/${reviewSchemeID}`);

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return [];
    }
};

export const getReviewScoreBreakdownHTML = async (NHID: number, params: IReviewScoreBreakdownParams) => {

    const endPoint = `/review/reviewscorebreakdownhtml/${NHID}`;
    try {
        const { data: { data } } = await secureHttp.get(endPoint, { params });
        return data;

    } catch (e) {
        console.error(e);
        return null;
    }
};

export const getPropertyCountTotals = async (params: IPropertyCountTotalsFilters) => {

    try {
        const { data: { data } } = await secureHttp.get('/review/totals/propertycount', { params });

        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            console.error(e);
        }

        return null;
    }
};

export const getReviewCountTotals = async (nhID: number, todaysDateUTC: string)
    : Promise<IReviewCountTotals> => {    
    try {

        const params = {
            NHID: nhID,
            TodaysDateUTC: todaysDateUTC
        };

        const { data: { data } } = await secureHttp.get('/review/totals/count', { params });
        return data;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getCollectionSources = async (): Promise<any[]> => {
    try {

        const startT = timerStart();
        const { data } = await secureHttp.get('/review/collectionsource');
        timerStop(startT, data.message, 'msecs');

        return data;
    } catch (e: any) {
        if (e.status !== 404) console.error(e);

        return [];
    }
};
