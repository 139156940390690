import React, { FC } from 'react';

interface Props {
    title: string;
    subtitle?: string;
    rightSideText?: string;
}

const ModalTitle: FC<Props> = (props) => {
    const { title, subtitle, rightSideText } = props;

    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
            <div className="d-inline-flex flex flex-row flex-wrap gap-2 w-100">
                <h4 className="m-0 d-inline me-2">{title}</h4>
                {subtitle && (
                    <span className="fs-6 font-weight-normal d-inline-block d-flex align-items-end">
                        {subtitle}
                    </span>
                )}
            </div>
            <div className="fs-5 text-secondary fw-normal w-100 d-flex justify-content-md-end">
                {rightSideText}
            </div>
        </div>
    );
};

export { ModalTitle };
