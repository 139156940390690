import React, { ReactNode } from 'react';
import {
    FormProvider,
    useForm,
    useFormContext,
    UseFormReturn
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const SchemaContext = React.createContext(null);

interface Props<T = NonNullable<unknown>> {
    defaultValues?: T;
    onSubmit: (values: T) => void;
    validationSchema?: yup.AnyObjectSchema;
    id?: string;
    children: ReactNode | ((methods: UseFormReturn<T>) => ReactNode);
    className?: string;
    onChange?: React.FormEventHandler
}

const Form = <T,>({
    defaultValues,
    children,
    className,
    onSubmit,
    onChange,
    validationSchema,
    id
}: Props<T>) => {
    const methods = useForm({
        defaultValues,
        ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {})
    });

    return (
        <FormProvider {...methods}>
            <SchemaContext.Provider value={validationSchema}>
                <div className="w-100">
                    <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        id={id}
                        className={className}
                        onChange={onChange}
                    >
                        {typeof children === 'function'
                            ? children(methods)
                            : children}
                    </form>
                </div>
            </SchemaContext.Provider>
        </FormProvider>
    );
};

const ConnectForm = ({ children }: any) => {
    const schema = React.useContext(SchemaContext);
    const methods = useFormContext();

    const isFieldRequired = (fieldName: string) => {
        if (!schema) {
            return false;
        }

        const field = schema.describe().fields[fieldName];
        if (!field) {
            return false;
        }
        return !field.optional;
    };

    return children({ ...methods, isRequired: isFieldRequired });
};

export { Form, ConnectForm };
export type { Props as FormProps };

