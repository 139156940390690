
import React from 'react';
// import React, { useCallback, useEffect, useState } from 'react';
// import Select, { components, MultiValue } from 'react-select';
// import DatePicker from 'react-datepicker';
// import { useSelector } from 'react-redux';

// import _ from 'lodash';

// import * as am5 from '@amcharts/amcharts5';
// import * as am5wc from '@amcharts/amcharts5/wc';

// import { getWordCloudData } from '../../api/charts';
// import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
// import { IWordCloudData, IWordCloudParams } from '../../interfaces/chart';
// import { FilterType } from '../../interfaces/filters';
// import { ISessionState, SessionType as EnumSessionType } from '../../interfaces/session';
// import FilterMatrix from '../../modules/filter_matrix';
// import { LoadingSpinner } from '../../components/common/loading_spinner';

// const chartContainer = 'WordCloudChartContainer';

// interface IProps {
//     memberIDs: number[];
//     groupID: number;
// }

// const minWordCount = 1;
// const maxWordCount = 100;
// const defaultWordCount = 50;
// const maxExcludeWordCount = 10;
// const minWordNumber= 1;
// const maxWordNumber = 5;
// const SENTIMENT_COLOR = [0x007f00, 0xFF0000, 0x0000FF, 0x800000];
// const SENTIMENT_OPTIONS = [
//     { value: 1, label: 'Positive' },
//     { value: 2, label: 'Negative' },
//     { value: 3, label: 'Neutral' },
//     { value: 4, label: 'Mixed' }
//   ];

// const ReviewWordCloud = ({ memberIDs, groupID }: IProps) => {
    const ReviewWordCloud = () => {
        return (<></>);  
    // const { Session }: ISessionState = useSelector(
    //     ({ SessionState }: any) => SessionState
    // );

    // const { SessionType } = Session;
    
    // const [, updateState] = useState<any>();
    // const [ isLoading, setIsLoading ] = useState<boolean>(true);
    // const [ wordCloudData, setWordCloudData] = useState<IWordCloudData[]>([]); 
    // const [ filterMatrix ] = useState(new FilterMatrix([
    //     { 
    //         Key: 'DateFrom',
    //         FilterFormLabel: 'Date Submitted From',
    //         Type: FilterType.DateFromUTC,
    //         FilterPropName: 'DateFrom'
    //     },
    //     {
    //         Key: 'DateTo',
    //         FilterFormLabel: 'Date Submitted To',
    //         Type: FilterType.DateToUTC,
    //         FilterPropName: 'DateTo'
    //     },
    //     {
    //         Key: 'WordCloudLimit',
    //         FilterFormLabel: 'Maximum Words',
    //         Type: FilterType.Number,
    //         FilterPropName: 'WordCloudLimit',
    //         DefaultValue: defaultWordCount
    //     },
    //     {
    //         Key: 'WordCloudNumberMin',
    //         FilterFormLabel: 'Minimum Word Number',
    //         Type: FilterType.Number,
    //         FilterPropName: 'WordCloudNumberMin',
    //         DefaultValue: minWordNumber
    //     },
    //     {
    //         Key: 'WordCloudExclude',
    //         FilterFormLabel: 'Exclude Words',
    //         Type: FilterType.StringArray,
    //         FilterPropName: 'WordCloudExclude',
    //         Options: {
    //             Delimiter: ',',
    //             MaxLength: maxExcludeWordCount
    //         }
    //     },
    //     {
    //         Key: 'SentimentType',
    //         FilterFormLabel: 'Sentiment Type',
    //         Type: FilterType.LabelValue,
    //         FilterPropName: 'SentimentType',
    //         DefaultValue: '',
    //         Options: {
    //             Delimiter: ','
    //         }
    //     },
    //     {
    //         Key: 'MemberID',
    //         FilterFormLabel: 'MemberID',
    //         Type: FilterType.Number,
    //         FilterPropName: 'MemberID',
    //         Hidden: true
    //     }
    // ]));

    // useEffect(() => {
    //     filterMatrix.reset();
    //     getData();
    // }, [ memberIDs, groupID ]);

    // useEffect(() => {
    //     if (!wordCloudData.length) return;

    //     const root = am5.Root.new(chartContainer);

    //     const series = root.container.children.push(am5wc.WordCloud.new(root, {
    //         maxCount: filterMatrix.getFormFieldValue('WordCloudLimit'),
    //         minFontSize: am5.percent(4),
    //         maxFontSize: am5.percent(30)
    //     }));

    //     series.labels.template.setAll({
    //         templateField: 'labelSettings'
    //     });

    //     series.data.setAll(wordCloudData);

    //     return () => {
    //         root.dispose();
    //     };
    // }, [ wordCloudData ]);

    // const forceUpdate = useCallback(() => updateState({}), []);

    // const getDataDebounced = useCallback(_.debounce(() => getData(), 750), []);

    // const getData = async () => {
    //     setIsLoading(true);

    //     const params: IWordCloudParams = filterMatrix.getRequestParams();
    //     const data = await getWordCloudData(params);
    //     const chartData = data.map((r: any) => ({ category: r.item, value: r.wordOccurances, labelSettings: { fill: am5.color(SENTIMENT_COLOR[r.sentimentID - 1])} }));

    //     setWordCloudData(chartData);
    //     setIsLoading(false);
    // };
    
    // const handleSelect = (memberID: number | null) => {
    //     applyFilter('MemberID', memberID);
    // };

    // const applyFilter = (key: string, value: any, refreshData = true) => {
    //     filterMatrix.setValue(key, value);
    //     forceUpdate();

    //     if (refreshData) {
    //         getDataDebounced();
    //     }
    // };

    // const renderWordCloud = () => {
    //     return (
    //         <div 
    //             id={chartContainer} 
    //             className="row mb-4"
    //             style={{ width: '95%', height: '400px' }}  
    //         ></div>
    //     );
    // };

    // const renderNoData = () => {
    //     return (
    //         <div className="row">
    //             <div className="col sm-12 text-center">No reviews found for these search criteria.</div>
    //         </div>
    //     );
    // };

    // const renderExcludedWordsMessage = () => {
    //     const selMemberId = filterMatrix.getFormFieldValue('MemberID');

    //     if (SessionType === EnumSessionType.SessionTypeGroup) {
    //         if (selMemberId) {
    //             return 'Please note the Provider Name & Facility Name are excluded from the Word Cloud.';
    //         }

    //         return 'Please note the Provider Name & all the Facility Names are excluded from the Word Cloud.';
    //     }

    //     if (memberIDs.length > 1 && !selMemberId) {
    //         return 'Please note all the Facility Names are excluded from the Word Cloud.';
    //     }

    //     return 'Please note the Facility Name is excluded from the Word Cloud.';
    // };

    // const applyMinMax = (filter: string, val: string, min: number, max: number) => {
    //     applyFilter(filter, val, false);

    //     if (!val.trim().length) return false;

    //     setTimeout(() => {
    //         const numValue = Number(filterMatrix.getFormFieldValue(filter));

    //         if (isNaN(numValue) || numValue < min || numValue > max) return false; 
    
    //         getDataDebounced();
    //     }, 750);
 
    // };

    // const wordCloudExcludeChange = (val: string) => {
    //     const words: string[] = val.split(',');

    //     if (words.length > maxExcludeWordCount) {
    //         return false;
    //     }

    //     applyFilter('WordCloudExclude', val);
    // };

    // const resetFilters = () => {
    //     filterMatrix.reset();
    //     getDataDebounced();
    // };

    // return (
    //     <>
    //         { isLoading ? <LoadingSpinner show={true} /> : null }
   
    //         <PageCommunitySelectWrapper
    //             label={'Key Phrases for'}
    //             handleSelect={handleSelect}
    //             selMemberID={filterMatrix.getFormFieldValue('MemberID')}
    //         />

    //         <div className="widget widget-fullwidth">
    //             <div className="row widget-body">
    //                 <div className="col-12">
    //                     <div className="card">
    //                         <div className="card-body p-0">
    //                             <div className="table-info p-4 pb-4">
    //                                 <div className="row align-items-end">
    //                                     <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3">
    //                                         <label htmlFor="DateFrom" className='mb-2'>Date Submitted From</label>
    //                                         <DatePicker 
    //                                             id="DateFrom"
    //                                             className='form-control' 
    //                                             dateFormat={'dd MMM yyyy'}
    //                                             selected={filterMatrix.getFormFieldValue('DateFrom')}
    //                                             placeholderText="Click to select a date"
    //                                             maxDate={filterMatrix.getFormFieldValue('DateTo') || new Date()}
    //                                             onChange={(date: Date) => {
    //                                                 if (date) date.setHours(0, 0, 0, 0);
    //                                                 applyFilter('DateFrom', date);
    //                                             }}
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3">
    //                                         <label htmlFor="DateTo" className='mb-2'>Date Submitted To</label>
    //                                         <DatePicker 
    //                                             id="DateTo"
    //                                             className='form-control' 
    //                                             dateFormat={'dd MMM yyyy'}
    //                                             selected={filterMatrix.getFormFieldValue('DateTo')}
    //                                             placeholderText="Click to select a date"
    //                                             minDate={filterMatrix.getFormFieldValue('DateFrom')}
    //                                             maxDate={new Date()}
    //                                             onChange={(date: Date) => {
    //                                                 if (date) date.setHours(23, 59, 59, 999);
    //                                                 applyFilter('DateTo', date);
    //                                             }}
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3">
    //                                         <label className='mb-2' htmlFor="WordCloudLimit">Maximum Key Phrases ({ minWordCount } - { maxWordCount })</label>
    //                                         <input
    //                                             type="number"
    //                                             id="WordCloudLimit"
    //                                             className="form-control"
    //                                             onChange={(e) => applyMinMax('WordCloudLimit', e.target.value, minWordCount, maxWordCount)}
    //                                             value={filterMatrix.getFormFieldValue('WordCloudLimit')}
    //                                             min={minWordCount} 
    //                                             max={maxWordCount}
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3">
    //                                         <label className='mb-2' htmlFor="WordCloudNumberMin">Minimum Word(s) in Key Phrase ({ minWordNumber } - { maxWordNumber })</label>
    //                                         <input
    //                                             type="number"
    //                                             id="WordCloudNumberMin"
    //                                             className="form-control"
    //                                             onChange={(e) => applyMinMax('WordCloudNumberMin', e.target.value, minWordNumber, maxWordNumber)}
    //                                             value={filterMatrix.getFormFieldValue('WordCloudNumberMin')}
    //                                             min={minWordNumber}
    //                                             max={maxWordNumber}
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3">
    //                                         <label className='mb-2' htmlFor="WordCloudExclude">Excluded Words (max: { maxExcludeWordCount })</label>
    //                                         <input
    //                                             type="text"
    //                                             id="WordCloudExclude"
    //                                             className="form-control"
    //                                             onChange={(e) => wordCloudExcludeChange(e.target.value)}
    //                                             value={filterMatrix.getFormFieldValue('WordCloudExclude')}
    //                                             placeholder="Enter key phrase you wish to exclude here, seperated by a comma"
    //                                         />
    //                                     </div>
    //                                     <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3">
    //                                         <label htmlFor="sentimentTypeSelect">Sentiment Type</label>
    //                                         <Select
    //                                             id="sentimentTypeSelect"
    //                                             onChange={(option: any) => applyFilter('SentimentType', option)}
    //                                             options={SENTIMENT_OPTIONS}
    //                                             isMulti
    //                                             name="sentimentType"
    //                                             className="basic-multi-select"
    //                                             classNamePrefix="select"
    //                                             value={filterMatrix.getFormFieldValue('SentimentType')}
    //                                             placeholder="All"
    //                                         />
    //                                     </div>
    //                                     <div className="row">
    //                                         <div className="d-flex justify-content-end">
    //                                             <button
    //                                                 className="btn btn-primary"
    //                                                 onClick={resetFilters}>Reset
    //                                             </button>
    //                                         </div>
    //                                     </div>
                                            
    //                                     {/* <div className='col-12 mt-xl-3'>
    //                                         * <small>{ renderExcludedWordsMessage() }</small>
    //                                     </div> */}
    //                                 </div>
    //                             </div>
    //                             { !isLoading && !wordCloudData?.length ? renderNoData() : renderWordCloud() }
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
};

export default ReviewWordCloud;
