import React from 'react';
import { FormRadioGroup } from '../../../../components/common/form_radio_group';
import { yesNoOptions, convertBooleanToYesOrNo } from '../../property_core_details/helpers';
import { ProfileCompletenessTag } from '../../property_core_details/tabs/components/profile_completeness_tag';
import { SectionHeading } from '../../property_core_details/tabs/components/section_heading';
import {
    ITab,
    tabLoadingPlaceholderConfig
} from '../../../../hooks/use_create_tabs';
import { UseQueryResult } from '@tanstack/react-query';
import {
    IPropertyService,
    IPropertyServiceCustomExtra,
    IPropertyServiceExtras
} from '../../../../interfaces/service';
import {
    ExtrasFields,
} from './components/extras_fields';
import { ServicesFields } from './components/services_fields';
import { FormInput } from '../../../../components/common/form_input';
import { longFormatDate } from '../../../../utils/date';

type TabConfigWithDefaultFormValues = {
    tabConfig: ITab[];
    defaultValues?: Record<string, any>;
};

type Services = UseQueryResult<IPropertyService[]>;
type Extras = UseQueryResult<IPropertyServiceExtras[]>;
type CustomExtras = UseQueryResult<IPropertyServiceCustomExtra[]>;

const prepareTabs = (
    services: Services,
    extras: Extras,
    customExtras: CustomExtras,
    options: { onlyShowProfileCompletenessFields: true | false | null }
): TabConfigWithDefaultFormValues => {
    const defaultValues: Record<string, any> = {
        services: {},
        extras: {},
        customExtras: {},
    };

    const { onlyShowProfileCompletenessFields } = options;

    if (services.isLoading || extras.isLoading || customExtras.isLoading) {
        return { tabConfig: tabLoadingPlaceholderConfig() };
    }

    const sections = services.data?.reduce((acc, service) => {
        const { ResourceName, ResourceID, ResourceCategoryTypeName, ResourceCategoryName, IsActive } = service;
        const inputName = `${ResourceID}`;
        const sectionHeading = ResourceCategoryTypeName;
        const subSectionHeading = ResourceCategoryName;

        const showAllFields = onlyShowProfileCompletenessFields === null;
        const showProfileCompletenessFields = service.IncludedInProfileCompleteness && onlyShowProfileCompletenessFields;
        const showNonProfileCompletenessFields = !service.IncludedInProfileCompleteness && !onlyShowProfileCompletenessFields;
        
        defaultValues['services'][inputName] = convertBooleanToYesOrNo(IsActive);
        if (showAllFields || showProfileCompletenessFields || showNonProfileCompletenessFields) {
            acc[sectionHeading] = acc[sectionHeading] || {};
            acc[sectionHeading][subSectionHeading] = acc[sectionHeading][subSectionHeading] || [];

            acc[sectionHeading][subSectionHeading].push(
                <FormRadioGroup
                    key={inputName}
                    groupLabel={ResourceName}
                    options={yesNoOptions}
                    name={`services.${inputName}`}
                    inlineLabelWidth="large"
                    info={<ProfileCompletenessTag isVisible={service.IncludedInProfileCompleteness} />}
                />
            );
        }

        return acc;
    }, {} as Record<string, any>) || {};



    const extrasFieldConfig = extras.data?.reduce((acc, curr) => {
        const { ResourceExtraHeaderName, ResourceExtraHeaderID, ResourceExtraOptionID, ResourceExtraOptionName, IsActive } = curr;
        const inputName = `${ResourceExtraHeaderID}`;
        const option = { label: ResourceExtraOptionName, value: ResourceExtraOptionID };
        const sectionHeading = 'Misc Services';

        acc[sectionHeading] = acc[sectionHeading] || {};
        acc[sectionHeading][ResourceExtraHeaderName] = acc[sectionHeading][ResourceExtraHeaderName] || {
            options: [],
            selectedValue: null,
            inputName: `extras.${inputName}`
        };

        acc[sectionHeading][ResourceExtraHeaderName].options.push(option);
        if (IsActive) {
            defaultValues['extras'][ResourceExtraHeaderID] = ResourceExtraOptionID;
            acc[sectionHeading][ResourceExtraHeaderName].selectedValue = ResourceExtraOptionID;
        }

        return acc;
    }, {} as Record<string, any>) || {};

    const tabConfigForServices: ITab[] = Object.entries(sections).map(([section, subSections]) => ({
        tabHeader: section,
        tabKey: section,
        tabContent: () => (
            <>
                <SectionHeading title={section} />
                <ServicesFields data={subSections} />
            </>
        )
    }));

    const customExtraFields = customExtras?.data?.map((extra) => {
        const { ResourceCustomExtraHeaderName, ResourceCustomExtraHeaderID, Description } = extra;
        const inputName = `${ResourceCustomExtraHeaderID}`;
        defaultValues['customExtras'][inputName] = Description;

        return (
            <div className="ps-2" key={inputName}>
                <FormInput
                    maxWidth='350px'
                    name={`customExtras.${inputName}`}
                    label={ResourceCustomExtraHeaderName}
                    inlineLabelWidth="large"
                />
            </div>
        );
    });

    const tabConfigForExtras: ITab[] = Object.entries(extrasFieldConfig).map(([section, fields]) => ({
        tabHeader: section,
        tabKey: section,
        tabContent: () => (
            <div className="mb-2">
                <SectionHeading title={section} />
                <ExtrasFields data={fields} />
                {customExtraFields}
            </div>
        )
    }));

    return {
        tabConfig: [...tabConfigForExtras, ...tabConfigForServices],
        defaultValues
    };
};


const prepareServicesFormValuesForSubmission = (
    values: Record<string, 'Yes' | 'No' | null>
) => {
    const answerMap = {
        Yes: true,
        No: false
    };

    return Object.entries(values)
        .map(([key, value]) => {
            return {
                ResourceID: Number(key),
                Status: answerMap[value]
            };
        })
        .filter((item) => typeof item?.Status === 'boolean');
};

const prepareExtrasFormValuesForSubmission = (
    values: Record<string, number>
) => {
    return Object.entries(values)
        .filter((item) => !!item?.[1])
        .map(([key, value]) => {
            return {
                ResourceExtraHeaderID: Number(key),
                ResourceExtraOptionID: Number(value)
            };
        });
};

const prepareCustomExtrasFormValuesForSubmission = (
    values: Record<string, number>
) => {
    return Object.entries(values)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => !!value)
        .map(([key, value]) => {
            return {
                Description: value || null,
                ResourceCustomExtraHeaderID:  Number(key)
            };
        });
};


const getLastUpdatedTextFromQueryResults = (...queryResults: UseQueryResult<{LastUpdated: string}[]>[]) => {

    const isLoading = queryResults.some(({isLoading}) => isLoading);

    if (isLoading) {
        return '';
    }

    const allItems = queryResults.map(({ data }) => data || []).flat();

    const lastUpdatedDateTime = allItems.reduce((maxLastUpdated: any, item: any) => {
        if (item?.LastUpdated && maxLastUpdated < item?.LastUpdated) {
            return item?.LastUpdated;
        }
        return maxLastUpdated || item.LastUpdated;
    }, []);

    return `Last Updated: ${lastUpdatedDateTime ? longFormatDate(lastUpdatedDateTime): 'No updates made' }`;
};

export {
    prepareTabs,
    prepareServicesFormValuesForSubmission,
    prepareExtrasFormValuesForSubmission,
    prepareCustomExtrasFormValuesForSubmission,
    getLastUpdatedTextFromQueryResults
};
