import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
interface IProps {
    handleProfileCompletenessFilter: (profileCompletenessFilterValue: boolean | null) => void;
    handlePhotoNotPublishedFilter: (nonPublished: boolean) => void;
    nonPublished: boolean;
    profileCompletenessFilterValue: boolean | null;
}

const ProfileCompletenessPhotoFilter = ({ 
    nonPublished, 
    handleProfileCompletenessFilter, 
    handlePhotoNotPublishedFilter,
    profileCompletenessFilterValue
}: IProps) => {

    return (
        <>
            <div className="d-flex flex-column justify-content-center bg-ghostwhite py-3 mt-6 mt-xl-0 rounded-5 ms-md-4">
                <div className="d-flex flex-column flex-md-row px-10 align-items-center">
                    <div className="me-4 me-xl-10 mb-3 mb-md-0 text-md-nowrap lh-sm">
                        <strong>Profile Complete</strong><br/>
                        <span className="fs-7">for Photos</span>                    
                    </div>
                    <div className="d-flex align-items-center h-100">
                        <div className="form-check form-check-custom mb-0">
                            <label className="form-check-label me-3" htmlFor="filter-all">
                                All
                            </label>         
                            <input
                                id="filter"
                                type="radio"
                                onChange={() => handleProfileCompletenessFilter(null)}
                                checked={profileCompletenessFilterValue === null}
                                name="complete"
                                className="form-check-input mb-0 ms-0"
                            />                   
                        </div>
                        <div className="form-check form-check-custom mb-0 ">
                            <label className="form-check-label me-3 d-flex align-items-center" htmlFor="filter-yes">
                                <FontAwesomeIcon icon={faCheck} className="text-success fs-3"/>
                            </label>             
                            <input
                                id="filter"
                                type="radio"
                                onChange={() => handleProfileCompletenessFilter(true)}
                                checked={profileCompletenessFilterValue === true}
                                name="complete"
                                className="form-check-input mb-0 ms-0"
                            />                               
                        </div>
                        <div className="form-check form-check-custom mb-0">
                            <label className="form-check-label me-3 d-flex align-items-center" htmlFor="filter-no">
                                <FontAwesomeIcon icon={faXmark} className="text-danger fs-3"/>
                            </label>      
                            <input
                                id="filter"
                                type="radio"
                                onChange={() => handleProfileCompletenessFilter(false)}
                                checked={profileCompletenessFilterValue === false}
                                name="complete"
                                className="form-check-input mb-0 ms-0"
                            />                                     
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row border-top mt-3 pt-3 px-10 align-items-center w-100">
                    <div className="me-4 me-xl-10 mb-3 mb-md-0 text-md-nowrap lh-sm">
                        <strong>Show Facilities with Non Published Photos</strong><br/>
                        <span className="fs-7">Some photos may be deemed too low resolution or non compliant</span>
                    </div>
                    <div className="d-flex align-items-center h-100">
                        <div className="form-check form-check-custom mb-0 ms-auto">
                            <input
                                type="checkbox"
                                className="form-check-input mb-0"
                                name="lowres"
                                checked={nonPublished}
                                onChange={() => handlePhotoNotPublishedFilter(!nonPublished)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileCompletenessPhotoFilter;
