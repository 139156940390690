import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ISessionState } from '../../interfaces/session';
import { LoadingSpinner } from '../common/loading_spinner';
import DataTable from '../../components/common/data_table';
import DisplayAsset from './display_asset';
import AppContext from '../../context/app_context';
import { 
    NHAssetType,
    IGetPortalPropertyWebAndCardLogoAsset
} from '../../interfaces/asset';
import cx from 'classnames';

import { 
    INhPortalScoreItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { 
    mapProfileExtraDatatoDisplay,
    getFacilityCountFromProfileData
} from '../../utils/profile_completeness';
import LogoUploadModal from './logo_upload_modal';

interface IProps {
    handleSelNHID: (NHID: number | null) => void;
    orgNHID?: number | null;
    nhIDs: number[];
    selNHID: number | null;
    noLogoPropertyFilter: boolean | null;
    handleNoLogoPropertyFilter: (noLogoPropertyFilterValue:boolean | null) => void;
    propertyLogos: IGetPortalPropertyWebAndCardLogoAsset[];
    setPropertyLogos: any;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: any;
    handleReloadPropertyLogos: any;
}

const MultiOrgPropertyLogos = ({
    handleSelNHID,
    orgNHID,
    nhIDs,
    selNHID,
    noLogoPropertyFilter,
    handleNoLogoPropertyFilter,
    propertyLogos,
    setPropertyLogos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleReloadPropertyLogos

}: IProps) => {

    const [ showLogoModal, setShowLogoModal ] = useState<boolean>(false);
    const [ updateLogoNHID, setUpdateLogoNHID ] = useState<number>(null);
    const [ individualPropertyLogos, setIndividualPropertyLogos] = useState<any[]>([]);
    const [ scoreExtra, setScoreExtra ] = useState<IScoreExtraDisplayItem>();

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const appContext: any = useContext(AppContext);

    const property = (propertyLogos || []).length > 0  ? propertyLogos[0] : null;
    const asset = property && (property.Asset || []).length > 0  ? property.Asset[0] : null;

    const isOrgLogoExists: boolean = property?.NHID === orgNHID && asset?.WebLogoAssetID > 0 ? true : false;
    const isOrgCardLogoExists: boolean = property?.NHID === orgNHID && asset?.CardLogoAssetID > 0 ? true : false;

    const columns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            minWidth: appContext.isMobile ? '146px' : '200px',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => formatName(row)
        },
        {
            name: <>Logo showing on website</>,
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => displayWebLogo(row)
        },
        {
            name: 'Logo used for Review Card',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => displayReviewCardLogo(row)
        },
        {
            name: '',
            width: '225px',
            cell: (row: IGetPortalPropertyWebAndCardLogoAsset) => updateLogos(row?.Property?.NHID ? row.Property.NHID: null)
        }
    ];

    const formatName = (row: IGetPortalPropertyWebAndCardLogoAsset) => {

        const { Property } = row;

        const isOrg = Property.NHID === orgNHID ? true : false;
        let propertyWebsiteLink;
        if(isOrg) {
            propertyWebsiteLink = `${Property.WebsiteURL}/provider/${Property.Slug}`;
        } else {
            propertyWebsiteLink = `${Property.WebsiteURL}/{${Property.StateSlug}/${Property.CitySlug}/${Property.Slug}`;
        }
        return (
            <div className="py-2">
                <a 
                    href="#" 
                    className='fw-bolder d-block'
                    onClick={(e) => handleLinkClick(Property.NHID, e)}
                >
                    { Property.Name }
                </a>
                {
                    !isOrg ?
                        <>
                            <small>{ Property.Address }</small>&nbsp;
                        </>
                    :
                        ''
                }
                <a
                    className="small text-link text-link-blue text-nowrap"
                    href={propertyWebsiteLink}
                    target="_blank"
                >
                    view on website
                </a> 
            </div>  
        );
    };

    const displayWebLogo = (row: IGetPortalPropertyWebAndCardLogoAsset) => {

        const { Property, Asset } = row;

        return (
            <div className="card-info no-scroll mt-2">
                <div>
                {
                    Asset.length > 0 && Asset[0]?.WebLogoAssetID > 0 ?
                        <div key={Asset[0].WebLogoAssetID} className="media-edit media-logo">
                            <DisplayAsset
                                TransformedAssetUrl={Asset[0].WebLogoTransformedAssetUrl}
                                FileName={Asset[0].WebLogoFileName}
                                AssetType={NHAssetType.Logos} 
                            />
                        </div>
                    :
                        <span className={cx('msg', isOrgLogoExists ? 'msg-info' : 'msg-error')}>
                            {isOrgLogoExists && orgNHID !== Property.NHID ?
                                'Provider logo being used'
                            :
                                'No logo currently showing on website'
                            }
                        </span>  
                }
                </div>
                {   orgNHID && orgNHID === Property.NHID ?
                        <div className="mt-2 mb-2">
                            <span className="msg msg-info">
                            { isOrgLogoExists ? 
                                'This Provider logo will show on all Facility pages where no Facility logo has been uploaded'
                            :
                                'If a Provider logo is uploaded it will show on all Facility pages where no Facility logo has been uploaded'

                            }
                            </span>
                        </div>
                    :
                        ''
                }
            </div>  
        );
    };

    const handleOpenModal = async(
        nhID: number, 
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const individualPropertyLogos = await handleReloadPropertyLogos([nhID]);
        if (individualPropertyLogos) {
            setIndividualPropertyLogos(individualPropertyLogos);
        }
        setUpdateLogoNHID(nhID);
        setShowLogoModal(true);
    };

    const updateLogos = (nhID: number) => {

        return (
            <div className="card-toolbar">
                { 
                nhID ? 
                    <button 
                        key='update-property-logo-${nhID}'
                        className="btn btn-primary text-nowrap"
                        onClick={(e) => {
                            e.preventDefault();
                            handleOpenModal(nhID, e);
                            window.scrollTo(0, 0);
                        }}
                    >
                        Update Logos
                    </button>
                :
                    ''
                }
            </div>
        );
    };

    const displayReviewCardLogo = (row: IGetPortalPropertyWebAndCardLogoAsset) => {

        const { Property, Asset } = row;

        return (
            <div className="card-info no-scroll mt-2">
                <div>
                {
                    Asset.length > 0 && Asset[0]?.CardLogoAssetID > 0 ?
                        <div key={Asset[0].CardLogoAssetID} className="media-edit media-logo">
                            <DisplayAsset
                                TransformedAssetUrl={Asset[0].CardLogoTransformedAssetUrl}
                                FileName={Asset[0].CardLogoFileName}
                                AssetType={NHAssetType.CardLogos} 
                            />
                            <div className="d-block lh-sm mt-2 mb-2">
                                <a 
                                    className="small text-link text-link-blue text-nowrap"
                                    rel="noreferrer" 
                                    target="_blank" 
                                    href={`https://www.nursinghomes.com/logos/reviewcard/${Asset[0].CardLogoFileName}.pdf`}
                                >
                                    Download PDF Print Version
                                </a>
                            </div>
                            <div className="d-block lh-sm mb-2">
                                <Link    
                                    to="/review-cards"
                                    state={{ NHID: row.NHID }}
                                    className='small text-link-blue text-nowrap'
                                >
                                    Order Review Cards
                                </Link>
                            </div>
                        </div>
                        
                    :
                        <span className={cx('msg', isOrgCardLogoExists ? 'msg-info' : 'msg-error')}>
                            {isOrgCardLogoExists && orgNHID !== Property.NHID ?
                                'Provider review card logo being used'
                            :
                                'Currently no review card logo'
                            }
                        </span>  
                }
                </div>
                {   orgNHID && orgNHID === Property.NHID ?
                        <div className="mt-2 mb-2">
                            <span className="msg msg-info">
                            { isOrgCardLogoExists ? 
                                'This Provider review card logo will be used for all Facility where no Facility review card logo has been uploaded'
                            :
                                'If a Provider review card logo is uploaded it will used for Facility where no Facility review card logo has been uploaded'

                            }
                            </span>
                        </div>
                    :
                        ''
                }
            </div>  
        );
    };

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelNHID(nhID);
        window.scrollTo(0, 0);
    };

    useEffect(() => {        
        const scoreExtra = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
        setScoreExtra(scoreExtra);
    }, [ orgNHID, selNHID, profileCompletenessState ]);


    if (!scoreExtra) {
        return <LoadingSpinner/>;
    }

    const { HasLogoCount } = scoreExtra;
    const facilityCount = selNHID ? 1 : getFacilityCountFromProfileData(profileCompletenessState);

    const renderMultiPropertiesLogos = () => {

        return (
            <>
            <div className="widget">
                <div className="widget-body">
                    <div className="alert flex-column flex-md-row alert-primary d-flex p-4 p-xl-6 m-0 mb-4 justify-content-between">
                        <div className="d-flex p-0">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-0 e-xl-6 fs-5 lh-base">{HasLogoCount}/{facilityCount} Facilities have Logos showing on website</h4>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center bg-ghostwhite py-3 mt-6 mt-xl-0 rounded-5 ms-md-4 ">
                            {/* <div className="d-flex flex-column flex-md-row px-10 align-items-center w-100">
                                <div className="me-4 me-xl-10 mb-3 mb-md-0 text-md-nowrap lh-sm">
                                    <strong>Show Facilities with no Logo</strong>
                                </div>
                                <div className="d-flex align-items-center h-100">
                                    <div className="form-check form-check-custom mb-0 ms-auto">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mb-0"
                                            name="profileCompleteness"
                                            checked={noLogoPropertyFilter}
                                            onChange={() => handleNoLogoPropertyFilter(!noLogoPropertyFilter)}
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="widget">
                <div className="widget-body">
                    <div className={'coredetails_dt sticky_dt table_dt expander_dt overflow_dt'}>
                        <DataTable
                            columns={columns}
                            data={propertyLogos}
                        />
                    </div>                                                  
                </div>
            </div>
            {
                showLogoModal ?
                    <LogoUploadModal
                        handleClose={() => setShowLogoModal(false)}
                        handleReloadPropertyLogos={handleReloadPropertyLogos}
                        orgNHID={orgNHID}
                        nhIDs={nhIDs}
                        selNHID={updateLogoNHID}
                        propertyLogos={individualPropertyLogos}
                        setPropertyLogos={setPropertyLogos}
                        busyLoadingAsset={busyLoadingAsset}
                        setBusyLoadingAsset={setBusyLoadingAsset}
                    />
                : 
                    null
            }                    
            </>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1  
        ? 
            busyLoadingAsset ? 
                <LoadingSpinner show={true} /> 
            : 
                renderMultiPropertiesLogos() 
        : 
            null;

};

export default MultiOrgPropertyLogos;