import React from 'react';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { getClientFormattedDate } from '../../utils/date';
import DisplayAsset from '../../components/assets/display_asset';
import UploadWidget from '../../components/cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import Swal from 'sweetalert2';

import { 
    NHAssetType,
    IPortalPropertyAsset,
    IPortalPropertyAssetObj,
    ICloudinaryData,
    IAssetUploadLog,
    IAssetPayLoad
 } from '../../interfaces/asset';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

interface IProps {
    handleReloadPropertyVideos: (nhID: number[]) => Promise<IPortalPropertyAsset[]>;
    orgNHID?: number | null;
    nhIDs?: number[];
    selNHID: number | null;
    propertyVideos: IPortalPropertyAsset[];
    setPropertyVideos: (videos: IPortalPropertyAsset[]) => void;
    busyLoadingAsset: boolean;
    setBusyLoadingAsset: (busyState: boolean) => void;
    handleClose?: any;
}

const IndividualPropertyVideos = ({
    handleReloadPropertyVideos,
    orgNHID,
    nhIDs,
    selNHID,
    propertyVideos,
    setPropertyVideos,
    busyLoadingAsset,
    setBusyLoadingAsset,
    handleClose
}: IProps) => {

    const refreshNHID = orgNHID ? [orgNHID] : nhIDs ? nhIDs : [selNHID];
   
    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { NHID: selNHID, AssetType: NHAssetType.Videos, ...cloudinaryData };
        const assetID = await assetApi.createAsset(assetPayLoad);

        updateProfileCompleteness();

        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0]
        };
    };
    
    const handleShowAssetUploadResult = async(assetUploadLog : IAssetUploadLog[]) => {
        let hasAssetUploaded = false;
        const currentDate = new Date();
        const assetObj:IPortalPropertyAssetObj[] = [];
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasAssetUploaded = true;
                break;
            }
        }

        for (const item of assetUploadLog) {
            assetObj.push({
                AssetID: item.AssetDetail.AssetID,
                FileName: item.AssetDetail.FileName,
                Sequence:  item.AssetDetail.Sequence,
                TransformedAssetUrl: item.AssetDetail.TransformedAssetUrl,
                CreatedDate: `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}`
            });
        }

        if( !hasAssetUploaded ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error occured while uploading video, please try again.'
            });
        } else {
            try {
                setBusyLoadingAsset(true);
                const updatedPropertyVideos = await handleReloadPropertyVideos(refreshNHID);
                setPropertyVideos(updatedPropertyVideos);    
                handleClose ?. ();       
            } catch (err) {
                Swal.fire({
                    title: 'Failed to upload the video',
                    text: 'Unknown error',
                    icon: 'error'
                });
            } finally {
                updateProfileCompleteness();
                setBusyLoadingAsset(false);
            }
        }
    };

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure you want to delete this video ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0abb87',
            cancelButtonColor: '#fd397a',
            confirmButtonText: 'Yes'
        }).then(async (result: any) => {
            if (result.value) {
                setBusyLoadingAsset(true);
                try {
                    const deletedAssetID:number = await assetApi.deletePropertyVideo(propertyVideos[0]?.NHID);

                    if (!deletedAssetID) {
                        throw new Error('Failed to delete Video');
                    }

                    const updatedPropertyVideos = await handleReloadPropertyVideos(refreshNHID);
                    setPropertyVideos(updatedPropertyVideos);
                    handleClose ?. ();
                } catch (err) {
                    Swal.fire({
                        title: 'Failed to delete Video',
                        text: 'Unknown error',
                        icon: 'error'
                    });
                } finally {
                    setBusyLoadingAsset(false);
                }
            }
        });
    };

    const renderIndividualPropertyVideos = () => {
        const property = propertyVideos && propertyVideos.length > 0 ? propertyVideos[0] : null;
        return (
            <>
                <div className="widget mt-0">
                    <div className="widget-body">
                        <div className="media-upload">
                            <div className="card" data-card="Media Scroller">
                                <div className="card-header">
                                    <h4>
                                        {property?.Property ?  property.Property.Name : ''}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-info no-scroll">
                                    <div>
                                        {
                                            property?.Asset.length > 0 ?
                                                property.Asset.map((video:IPortalPropertyAssetObj) => {
                                                    return (
                                                        <div key={video.AssetID} className="media-edit media-video">
                                                            <DisplayAsset
                                                                TransformedAssetUrl={video.TransformedAssetUrl}
                                                                FileName={video.FileName}
                                                                AssetType={NHAssetType.Videos} 
                                                            />
                                                            <div>
                                                                <span>
                                                                    Date uploaded: {video.CreatedDate ? getClientFormattedDate(video.CreatedDate) : ''}
                                                                </span>
                                                                <ul>
                                                                    <li>
                                                                        <a href=""
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleDelete();
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            :
                                                <span className="msg msg-error">
                                                    No video uploaded
                                                </span>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" data-card="Media Upload">
                                <div className="card-header">
                                    <h4>
                                        Upload a New Video
                                    </h4>
                                </div>
                                <div className="card-body">
                                    { property?.Asset.length === 0  ?
                                    <>
                                        <div className="card-info">
                                            <ul>
                                                <li>
                                                    <header>
                                                        <h4>
                                                            Choose Video to Upload
                                                        </h4>
                                                    </header>
                                                    <div>
                                                        <UploadWidget 
                                                            nHID = {selNHID}
                                                            fileName={property.Property.Slug}
                                                            assetTypeName = 'Select Video to Upload' 
                                                            assetType = {NHAssetType.Videos}
                                                            onAssetUpload = {handleAssetUpload}
                                                            showAssetUploadResult = {handleShowAssetUploadResult}
                                                        />
                                                        <p>
                                                            Please note maximum file size 1GB.
                                                        </p>
                                                        <p>
                                                        Large video files can take up to 5 minutes to upload on slower connections.
                                                        </p>
                                                        <p>If in doubt please refresh the page after a couple of minutes.</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-info-box">
                                            <h5>
                                                Uploading Videos - Tips &amp; Terms
                                            </h5>
                                            <p>
                                                Your Video Uploaded will automatically be saved as a .mp4 file, resized and cropped to best fit our video template (360 pixels height). If you are not happy with the automatic resize and crop please upload a pre-formatted video to the exact video template specification.
                                            </p>
                                            <p>
                                                NursingHomes.com reserve the right to remove any videos we deem unsuitable.
                                            </p>
                                            <p>
                                                <strong>By pressing Upload button, I/we agree to the terms & conditions set out on <a href="https://>www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                                            </p>
                                        </div>
                                    </>
                                    :
                                    <div className="card-info-box">
                                        <span className="msg msg-error">
                                            You are currently allowed to upload one video. If you want to change the existing video, please delete the relevant video first then re-upload. Please note if you have recently uploaded any videos it might take up to 60 minutes to appear on this page.
                                        </span>
                                    </div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <LoadingSpinner show={busyLoadingAsset} />
            { renderIndividualPropertyVideos() }
        </>
    );
};

export default IndividualPropertyVideos;