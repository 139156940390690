import { INhPortalScoreItem } from '../../interfaces/property';

interface IAction {
    type: string,
    payload?: INhPortalScoreItem
}

const DEFAULT_STATE: INhPortalScoreItem = {
    OrgScores: [],
    TotalOrgProfileScore: 0,
    OrgFacilityCount: 0,
    PropertyScores: []
};

export default (state = DEFAULT_STATE, action: IAction) => {
    switch (action.type) {
        case 'SET_PROFILE_COMPLETENESS':
            state = {
                ...state,
                ...action.payload
            };
            break;
    }

    return state;
};
