import { FC, useEffect } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

interface Props {
    isOpen: boolean;
    title?: string;
    subtitle?: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

const DeleteEntityDialog: FC<Props> = (props) => {
    const { isOpen, title, subtitle, onClose, onConfirm } = props;

    const dialogSettings: SweetAlertOptions = {
        title: title || 'Delete item',
        html: subtitle || 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0abb87',
        cancelButtonColor: '#fd397a',
        confirmButtonText: 'Yes',
        preConfirm: onConfirm,
        didClose: onClose,
        allowOutsideClick: false
    };

    useEffect(() => {
        if (isOpen) {
            Swal.fire(dialogSettings);
        }
    }, [isOpen]);

    return null;
};

export { DeleteEntityDialog };
