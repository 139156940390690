import { INotEnteredOption } from './filters';

export enum ExpressionType {
    Equal = 'EQ',
    NotEqual = 'NE',
    GreaterThan = 'GT',
    GreaterThanOrEqual = 'GE',
    LessThan = 'LT',
    LessThanOrEqual = 'LE',
    Between = 'BT',
    In = 'IN',
    NotEntered = 'XX'
}

export interface RangeDefault {
    filterKey: string;
    sqlRange: SqlRange;
}

export class SqlRange {
    constructor(public operation: ExpressionType, public from?: string, public to?: string) {
    }
}

export const rangeSelectOptions = (
    notEnteredOption: INotEnteredOption = { showNotEnteredOption : true }
): any[] => {
    const options = [
        {label: 'Equal To', value: ExpressionType.Equal},
        {label: 'Greater Than', value: ExpressionType.GreaterThan},
        {label: 'Greater Than Or Equal', value: ExpressionType.GreaterThanOrEqual},
        {label: 'Less Than', value: ExpressionType.LessThan},
        {label: 'Less Than Or Equal', value: ExpressionType.LessThanOrEqual},
        {label: 'Not Equal', value: ExpressionType.NotEqual},
        {label: 'Between', value: ExpressionType.Between},
        {label: 'In (eg 1,2,3,4)', value: ExpressionType.In}
    ];

    const { showNotEnteredOption, notEnteredLabel } = notEnteredOption;
    if (showNotEnteredOption) {
        options.push({
            label: notEnteredLabel ? `No ${notEnteredLabel}` : 'Not Entered',
            value: ExpressionType.NotEntered
        });
    }
    
    return options;
};
