import React, { FC } from 'react';
import { ITourDefaultValues } from '../types';
import { replaceNewlinesWithBreakElements } from '../../../../utils/text';

interface IProps {
    data: ITourDefaultValues;
}

const TableExpandedRowItem: FC<IProps> = ({ data }) => {
    return (
        <div className="border-bottom p-4 ps-1 bg-light">
            {data.PhysicalDescription && (
                <>
                    <h2 className='fs-5 mb-0'>Onsite Tours</h2>
                    <p className='fs-5'>{replaceNewlinesWithBreakElements(data.PhysicalDescription)}</p>
                </>
            )}
            {data.VirtualDescription && (
                <>
                    <h2 className='fs-5 mb-0'>Remote Tours</h2>
                    <p className='fs-5'>{replaceNewlinesWithBreakElements(data.VirtualDescription)}</p>
                </>
            )}
        </div>
    );
};

export { TableExpandedRowItem };
