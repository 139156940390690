import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlashForward } from '@fortawesome/pro-light-svg-icons';
import { faCreditCardFront } from '@fortawesome/pro-thin-svg-icons';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { getReviewScoreForNHID, getReviewStatisticTotals } from '../../api/review';
import { IReviewStatisticTotals } from '../../interfaces/review';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import ReviewScoreWidget from '../../components/dashboard/review_score_widget';
import { getSingleProperty, getCommunityMode } from '../../utils/common';
import { PropertyMode } from '../../interfaces/session';
import { IReviewScore } from '../../interfaces/review';
import { get } from 'lodash';
import ReviewScoreDisplayFormatted from '../common/review_score_display_formatted';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID?: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    showPortletArray: string[];
}

export const ShowcaseReviewsWidget = ({ nhIDs, orgNHID, selNHID, setIsLoading, isLoading, showPortletArray }: IProps) => { 
    const [ stats, setStats ] = useState<IReviewStatisticTotals>(null);
    const [ reviewScore, setReviewScore ] = useState<number>();

    useEffect(() => {   
        if (!orgNHID && !nhIDs) {
            return;
        }

        const fetchData = async (): Promise<void> => {    
            try {
                const [data, rsData]: [IReviewStatisticTotals, any] = 
                    await Promise.all([
                        getReviewStatisticTotals(selNHID),
                        fetchReviewScore()
                    ]);

                const { ReviewScoreDisplay } = rsData || {};

                setStats(data);
                setReviewScore(ReviewScoreDisplay);
            } catch (e) {
                console.error(e);
            } finally {
                if (setIsLoading) {
                    setIsLoading(false);
                }
            }
        };

       fetchData();
    }, [ orgNHID, nhIDs, selNHID ]);
    
    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    if (!Session) {
        return null;
    }

    const { SessionType } = Session;
    const propertyMode = getCommunityMode(orgNHID, nhIDs, selNHID);
    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const nhID = singleProperty?.NHID;

    const fetchReviewScore = async (): Promise<IReviewScore> => {
        const firstProperty: any = get(Properties, '[0]');

        return propertyMode === PropertyMode.SingleProperty 
            ? getReviewScoreForNHID(selNHID || nhIDs[0]) 
            : firstProperty 
                ? getReviewScoreForNHID(firstProperty.OrgNHID) 
                : null;
    };
    
    const renderStat = (stat: number) => <>{ stat } <span><FontAwesomeIcon icon={faSlashForward} /> { Properties ? Properties.length : 'N/A' }</span></>;

    const renderPostcards = () => {
        let totalNoReviewCardsLast12 = 0;

        if (stats) {
            totalNoReviewCardsLast12 = stats.TotalNoReviewCardsLast12 || 0;
        }

        return (
            <div className="card" data-card="Review Cards" key={'postcards'}>
                <div className="card-header">
                    <h4>
                        Review Cards
                    </h4>
                </div>
                <div className="card-body">
                    <p>
                        { 
                            singleProperty 
                                ? 'Review Cards ordered in the last 12 months?'
                                : <>Number of Communtities that have <span className="fw-bold">NO</span> review cards ordered in the last 12 months:</>
                        }
                    </p>
                    <div className="card-info">
                        <FontAwesomeIcon icon={faCreditCardFront} />                        
                        <span className="card-stat">
                            { 
                                singleProperty 
                                    ? <div>{ !totalNoReviewCardsLast12 ? 'Yes' : 'No' }</div>
                                    : <div>{ renderStat(totalNoReviewCardsLast12) }</div>
                            }
                        </span>
                    </div>
                    <p>Hand out / display reviews cards in your facility to generate more reviews.</p>
                </div>
                <div className="card-footer">
                    <Link    
                        to="/review-cards"
                        state={{ NHID: nhID }}
                    >
                        <div className="btn btn-primary">
                        Order Cards
                        </div>
                    </Link>
                </div>
            </div>
        );
    };

    const renderPostcardsv2 = () => {
        let totalNoReviewCardsLast12 = 0;

        if (stats) {
            totalNoReviewCardsLast12 = stats.TotalNoReviewCardsLast12 || 0;
        }

        return (
            <div className="card" data-card="Review Cards" key={'postcards-v2'}>
                <div className="card-header">
                    <h4>
                        Review Cards
                    </h4>
                </div>
                <div className="card-body">
                    <p>
                        { 
                            singleProperty 
                                ? 'Review Cards ordered in the last 12 months?'
                                : <>Number of Communtities that have <span className="fw-bold">NO</span> review cards ordered in the last 12 months:</>
                        }
                    </p>
                    <div className="card-info">
                        <FontAwesomeIcon icon={faCreditCardFront} />
                        <span className="card-stat">
                            { 
                                singleProperty 
                                    ? <div>{ !totalNoReviewCardsLast12 ? 'Yes' : 'No' }</div>
                                    : <div>{ renderStat(totalNoReviewCardsLast12) }</div>
                            }
                        </span>
                    </div>
                    <p>Hand out / display reviews cards in your facility to generate more reviews.</p>
                </div>
                <div className="card-footer">
                    <Link 
                        to="/review-cards"
                        state={{ NHID: nhID }}
                    >
                        <div className="btn btn-primary">
                            Order Cards
                        </div>
                    </Link>
                </div>
            </div>
        );
    };

    const renderInviteToReview = () => {
        return (
            <div className="card" data-card="Invite to Review" key={'invite-to-review'}>
                <div className="card-header">
                    <h4>
                        Invite to Review
                    </h4>
                </div>
                <div className="card-body">
                    <p>Request Reviews with Invite to Review via:</p>
                    <div className="card-info">
                        <span className="color-email" title="Email">
                            <i className="fa fa-envelope"></i><strong>Email</strong>
                        </span>
                        <span className="color-facebook" title="Facebook">
                            <i className="fa fa-facebook"></i><strong>Facebook</strong>
                        </span>
                        <span className="color-whatsapp" title="Whatsapp">
                            <i className="fa fa-whatsapp"></i><strong>WhatsApp</strong>
                        </span>
                    </div>
                </div>
                <div className="card-footer">
                    <Link 
                        to="/invite-to-review"
                        state={{ NHID: nhID }}
                    >
                        <div className="btn btn-primary">
                            Send Review Invites
                        </div>
                    </Link>
                </div>
            </div>
        );
    };

    const renderReviewScoreCertificate = () => {
        return (
            <div className="card" data-card="Review Score Certificates" key={'review-score-cert'}>
                <div className="card-header">
                    <h4>
                        Review Score Certificates
                    </h4>
                </div>
                <div className="card-body">
                    <p>Download a Review Score Certificate (includes a &apos;Review us&apos; QR Code) to display in your { singleProperty ? 'Community' : 'Communities' }.</p>
                    <div className="card-info">
                        <div className="certificates-review-score">
                           <ReviewScoreDisplayFormatted 
                                value={reviewScore}
                                showAsNotApplicableOnNull={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <NavLink 
                        className="btn btn-primary" 
                        to='/review-score-certificate'
                        state={{ NHID: nhID }}
                    >
                        Download
                    </NavLink>
                </div>
            </div>
        );
    };

    const renderQRCodePosters = () => {
        return (
            <div className="card" data-card="Review Us QR Code Posters" key={'qrcode-posters'}>
                <div className="card-header">
                    <h4>
                        &apos;Review us&apos; QR Code Posters
                    </h4>
                </div>
                <div className="card-body">
                    <p>
                        Download a 'Review us' QR Code Poster to display in your public areas and reception areas(s).
                    </p>
                    <div className="card-info">
                        <img src="/images/widget/qr.jpg" width="100" height="100" alt="QR" />
                    </div>
                </div>
                <div className="card-footer">
                    <Link 
                        to="/qr-code-posters"
                        state={{ NHID: nhID }}
                    >
                        <div className="btn btn-primary">
                            Download
                        </div>
                    </Link>
                </div>
            </div>
        );
    };

    const renderYourReviewScore = () => {
        return (
            <ReviewScoreWidget
                orgNHID={orgNHID}
                nhIDs={nhIDs}
                selNHID={selNHID}
                propertyMode={propertyMode}
                widgetSize={'small'}
            />
        );
    };


    const renderPortlets = () => {

        const portletFnArray = [];

        if (showPortletArray.includes('invite-to-review')) {
            portletFnArray.push(renderInviteToReview);
        }
        if (showPortletArray.includes('postcards')) {
            portletFnArray.push(renderPostcards);
        }
        if (showPortletArray.includes('postcards-v2')) {
            portletFnArray.push(renderPostcardsv2);
        }
        if (showPortletArray.includes('qr-code-posters')) {
            portletFnArray.push(renderQRCodePosters);
        }
        if (showPortletArray.includes('review-score')) {
            portletFnArray.push(renderYourReviewScore);
        }
        if (showPortletArray.includes('review-score-certificate')) {
            portletFnArray.push(renderReviewScoreCertificate);
        }

        return portletFnArray.map((portletFn: any) => portletFn());
    };

    const renderWidget = () => {
        return (
            <>
                <div className="widget-header">
                    <h3>
                        Generate &amp; Showcase Reviews
                    </h3>
                </div>
                <div className="widget-body">
                    { renderPortlets() } 
                </div>
            </>
        );    
    };

    const renderLoading = () => {
        return <LoadingSpinner showInPlace={true} show={true}/>;
    };

    return (
        <>
            { isLoading ? renderLoading() : renderWidget() }
        </>
    );
};
