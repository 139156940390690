import React from 'react';
import cx from 'classnames';

interface ProfileCompletenessTagProps {
    isVisible: boolean;
    direction?: 'left' | 'right'
}

const ProfileCompletenessTag: React.FC<ProfileCompletenessTagProps> = ({ isVisible, direction = 'left' }) => {
    const bannerClassName = cx('badge badge-secondary signpost fw-normal', {
        'invisible': !isVisible,
        'right': direction === 'right'
    });
    return (
        <div>
            <span className={bannerClassName}>
                Included in Profile Completeness
            </span>
        </div>
    );
};

export { ProfileCompletenessTag };