import { MeetTheTeamMemberPayload } from '../pages/property_details/property_team/types';
import { secureHttp } from '../utils/request';

export const getAllPropertiesTeamMembers = async (
    propertyIds: number[]
): Promise<any[]> => {
    const {
        data: { data: rows }
    } = await secureHttp.get(
        `/meet-the-team/properties?propertyIds=${propertyIds.join(',')}`
    );

    return rows;
};

export const getPropertyTeamMembers = async (
    propertyId: number
): Promise<any[]> => {
    try {
        const {
            data: { data: rows }
        } = await secureHttp.get(`/meet-the-team/property/${propertyId}`);

        return rows;
    } catch (e) {
        console.error(e);
        return [];
    }
};

export const updatePropertyTeamMember = async (
    propertyId: number,
    memberId: number,
    payload: MeetTheTeamMemberPayload
): Promise<void> => {
    await secureHttp.put(
        `/meet-the-team/${memberId}/property/${propertyId}`,
        payload
    );
};

export const createPropertyTeamMember = async (
    propertyId: number,
    payload: MeetTheTeamMemberPayload
): Promise<{ id: number }> => {
    try {
        const {
            data: { data }
        } = await secureHttp.post(
            `/meet-the-team/property/${propertyId}`,
            payload
        );

        return { id: data?.ID };
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const deletePropertyTeamMember = async (
    propertyId: number,
    memberId: number
): Promise<void> => {
    try {
        await secureHttp.delete(
            `/meet-the-team/${memberId}/property/${propertyId}`
        );
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const updateTeamSequenceOrder = async (
    propertyId: number,
    payload: { memberId: number; sequence: number }
): Promise<void> => {
    try {
        await secureHttp.put(`/meet-the-team/property/${propertyId}`, payload);
    } catch (e) {
        console.error(e);
        return null;
    }
};
