import React, { FC } from 'react';

import { OnDragEndResponder } from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { ISessionProperty } from '../../../../../interfaces/session';
import { useUpdateTeamSequenceOrderMutation } from '../../hooks/useUpdateTeamSequenceOrderMutation';
import { AddTeamMemberModal, LoadingSpinner } from '../../components';
import { Button } from '../../../../../components/common/button';
import { DroppableMemberCardArea } from '../member_cards';
import ProfileCompletenessText from '../../../../../components/profile_completeness/profile_completeness_text';
import { ProfileScoreType } from '../../../../../interfaces/property';

interface Props {
    disabled: boolean;
    isLoading: boolean;
    property: ISessionProperty;
    data: MeetTheTeamMemberType[];
    onSelectProperty: (propertyId: number) => void;
}

const DroppableAreaContainer: FC<Props> = ({
    disabled = false,
    isLoading,
    property,
    data,
    onSelectProperty
}) => {
    const {
        Address1,
        City,
        CitySlug,
        Name,
        NHID,
        PostCode,
        RegionCode,
        Slug,
        StateSlug,
        WebsiteURL
    } = property;

    const updateTeamSequenceOrderMutation = useUpdateTeamSequenceOrderMutation(
        property.NHID,
        ['meet-the-team', 'property']
    );

    const handleDragEnd: OnDragEndResponder = async ({
        destination,
        source
    }) => {
        // eslint-disable-next-line
        if (destination?.index == null) return;

        updateTeamSequenceOrderMutation({
            sequence: destination.index + 1, // we want to start sequence count at 1
            memberId: data[source.index].id
        });
    };

    const header = (
        <div className="card-header">
            <span>
                <h4>
                    {Name}
                    <small className="ms-2">
                        {Address1}, {City}, {RegionCode} {PostCode}
                    </small>&nbsp;
                    <a
                        className="ms-2 small text-link text-nowrap"
                        href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                        target="_blank" 
                    >
                        view on website
                    </a>      
                </h4>
                <ProfileCompletenessText
                    NHID={NHID}
                    profileScoreType={ProfileScoreType.MeetTheTeam}
                />
                         
            </span>
            <div className="card-toolbar">
                <AddTeamMemberModal propertyId={NHID} />
                {data?.length > 0 && (
                    <Button onClick={() => onSelectProperty(NHID)}>
                        View all Team Members
                    </Button>
                )}
            </div>
        </div>
    );

    return (
        <div className="card" data-card="Media Scroller">
            {header}
            {isLoading ? (
                <LoadingSpinner minHeight="200px" />
            ) : (
                <DroppableMemberCardArea
                    disabled={disabled}
                    propertyId={property.NHID}
                    key={property.NHID}
                    onDragEnd={handleDragEnd}
                    teamMembers={data}
                />
            )}
        </div>
    );
};

export { DroppableAreaContainer };
