import { formatDateMMMDDYYYY } from './date';

export const handleExternalDownload = (url: string, filename: string) => {
    fetch(url).then((response) => {
        return response.blob();
    }).then((blob: any) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove(); 
    }).catch((err: any) => {
        // TODO - User Message
        console.log(err);
    });
};

export const formatFileNameForCsvExport = (fileName: string): string => {
    const fileNamePlusDate = (`${fileName}_${formatDateMMMDDYYYY(new Date)}`)
        .trim()
        .toLowerCase()
        .replace(/[ -]/g,'_')
        .replace(/[^\w ]/g, '');

    return `${fileNamePlusDate}.csv`;
};
    