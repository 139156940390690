import React, {
    ChangeEvent,
    FC,
    forwardRef,
    ForwardRefRenderFunction,
    InputHTMLAttributes,
    MouseEvent,
    ReactElement,
    ReactNode
} from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';

type RadioOption = {
    value: string;
    label: string;
};

interface GroupProps {
    groupLabel?: string;
    name: string;
    options?: RadioOption[];
    inline?: boolean;
    inlineOptions?: boolean;
    showResetButton?: boolean;
    labelStyle?: 'normal' | 'bold';
    followUpInput?: ReactElement;
    info?: ReactNode;
    inlineLabelWidth?: 'small' | 'large' | 'auto';
    disabled?: boolean;
}

interface RadioButtonProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    value: string;
    label: string;
    name: string;
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const FormRadioGroup: FC<GroupProps> = (props) => {
    const {
        name,
        options,
        groupLabel,
        inline = true,
        inlineOptions = inline,
        showResetButton = false,
        labelStyle = 'normal',
        followUpInput,
        info,
        inlineLabelWidth = 'small',
        disabled = false,
    } = props;

    const inlineLabelWidths = {
        small: 150,
        large: 250,
        auto: 0
    };

    return (
        <ConnectForm>
            {({ register, formState, watch, setValue, isRequired }: UseFormReturn & FieldRequired) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute">
                        {fieldError?.message}
                    </small>
                );

                const optionsWrapperClassName = cx(
                    'position-relative d-flex w-100',
                    {
                        'row-gap-2 column-gap-5 flex-row flex-wrap': inlineOptions,
                        'gap-2 flex-column': !inlineOptions
                    }
                );

                const handleChange = ({
                    target
                }: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, target?.value, { shouldDirty: true });
                };

                const inputWrapperClassName = cx('d-flex flex-column', {
                    'mt-3': groupLabel,
                    'flex-sm-row justify-items-center': inline
                });

                const labelClassName = cx('fs-md-5', {
                    'd-flex align-items-center me-sm-5':
                        inlineOptions && inline,
                    'mb-1 mt-2': !inlineOptions,
                    'fw-label': labelStyle === 'bold'
                });

                const handleReset = (event: MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                    setValue(name, null, { shouldDirty: true });
                };

                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {groupLabel && (
                            <div
                                className={cx(labelClassName)}
                                style={{
                                    minWidth: inline
                                        ? inlineLabelWidth === 'auto'
                                            ? ''
                                            : `${inlineLabelWidths[inlineLabelWidth]}px`
                                        : '100%',
                                    maxWidth: '100%'
                                }}
                            >
                                {groupLabel}
                                {isRequired(name) && '*'}
                            </div>
                        )}
                        <div className="flex-fill">
                            <div className={optionsWrapperClassName}>
                                <div className="d-flex flex-column flex-sm-row">
                                    <div className={optionsWrapperClassName}>
                                        {options.map((option) => {
                                            return (
                                                <RadioButtonWithRefs
                                                    checked={
                                                        option.value === watch(name)
                                                    }
                                                    value={option.value}
                                                    label={option.label}
                                                    key={option.value}
                                                    className="me-2"
                                                    {...register(name)}
                                                    disabled={disabled}
                                                    onChange={handleChange}
                                                />
                                            );
                                        })}
                                        {showResetButton && (
                                            <button
                                                title="reset"
                                                onClick={handleReset}
                                                type="button"
                                                className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1 focus-ring"
                                                disabled={disabled}
                                            >
                                                <i className="text-primary fa fa-history" />
                                            </button>
                                        )}
                                    </div>
                                    <div>
                                        {followUpInput}
                                    </div>
                                </div>
                                {info && (
                                    <div className="d-flex align-items-center ms-4">
                                        {info}
                                    </div>
                                )}
                            </div>
                            {fieldError && errorMessage}
                        </div>
                    </div>
                );
            }}
        </ConnectForm>
    );
};

const RadioButton: ForwardRefRenderFunction<any, RadioButtonProps> = (
    props,
    ref
) => {
    const { id, label: labelText, className, value, ...rest } = props;

    const inputId: string = id || `${rest.name}-${value}`;
    const inputClassName: string = cx('form-check-input', className);

    return (
        <label
            htmlFor={inputId}
            style={{ height: '37px' }} // same as text input height
            className={cx(
                'form-check-label text-nowrap m-0 d-flex align-items-center'
            )}
        >
            <input
                type="radio"
                id={inputId}
                className={inputClassName}
                value={value}
                ref={ref}
                {...rest}
            />
            <span />
            {labelText}
        </label>
    );
};

const RadioButtonWithRefs = forwardRef(RadioButton);

export { FormRadioGroup };
