import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import {
    EditTeamMemberModal,
    DeleteTeamMemberDialogButton,
    ProfileImage
} from '../../components';
import cx from 'classnames';
import { Tooltip } from 'react-tooltip';
interface Props extends MeetTheTeamMemberType {
    imgUrl?: string;
    id?: number;
    index: number;
}

const DraggableMemberCard: FC<Props> = (props) => {
    const {
        id,
        index,
        firstName,
        lastName,
        photoUrl,
        publishWebsite,
        unpublishWebsiteComment
    } = props;
    const cardWidth = '180px';

    const image = (
        <ProfileImage
            alt={`Photo of ${firstName}`}
            style={{ width: cardWidth, height: '180px' }}
            imgUrl={photoUrl}
        />
    );

    const fullName = (
        <div className="pt-2 px-3 fw-bold text-center text-truncate">
            {firstName} {lastName}
        </div>
    );

    const reasonNotPublished = (
        <>
            <div
                className="px-3 text-center text-truncate text-danger cursor-pointer"
                data-tooltip-id={`not-published-reason-${id}`}
            >
                {unpublishWebsiteComment}
            </div>
            <Tooltip
                style={{
                    maxWidth: '250px',
                    textWrap: 'wrap',
                    lineHeight: '1.5em'
                }}
                id={`not-published-reason-${id}`}
                delayShow={1000}
                float={true}
                place="right"
                content={
                    unpublishWebsiteComment &&
                    `Not currently published on the website:
                        ${unpublishWebsiteComment}`
                }
            />
        </>
    );

    const publishedToWebsiteStatus = (
        <div className="border-bottom px-3 d-flex justify-content-end align-items-center fs-8 fst-italic pb-1 gap-2">
            <span>Published to Website</span>
            <span
                className={cx('fa fs-6 p-1', {
                    'fa-close text-danger': !publishWebsite,
                    'fa-check text-success': publishWebsite
                })}
            />
        </div>
    );

    const buttons = (
        <div className="px-3 py-3 d-flex align-items-between justify-content-between">
            <EditTeamMemberModal
                styleType="link"
                className="fs-sm"
                memberDetails={props}
            />
            <DeleteTeamMemberDialogButton
                memberDetails={props}
                styleType="link"
                className="fs-sm"
            />
        </div>
    );

    return (
        <Draggable key={id} draggableId={String(id)} index={index}>
            {(provided) => (
                <div
                    className="mx-3"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className="card-info">
                        <div className="border rounded overflow-hidden">
                            <div
                                className="media-edit media-team"
                                style={{ maxWidth: cardWidth }}
                            >
                                {image}
                                <div className="fs-sm bg-white text-truncate border-top">
                                    <div
                                        className="d-flex flex-column align-items-between justify-content-between"
                                        style={{ height: '75px' }}
                                    >
                                        {fullName}
                                        {reasonNotPublished}
                                        {publishedToWebsiteStatus}
                                    </div>
                                    {buttons}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export { DraggableMemberCard };
