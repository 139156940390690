import React, { useEffect, useState, useCallback }  from 'react';
import DatePicker from 'react-datepicker';
import FilterMatrix from '../../../modules/filter_matrix';
import ReviewRatingTypeFilter from './review_rating_type_filter';
import ReviewPublicationStatusFilter from './review_publication_status_filter';
import ReviewExperienceTypeFilter from './review_experiencetype_filter';
import ReviewerConnectionFilter from './reviewer_connection_filter';

import { getReviewsBySearchString, getReviewScheme } from '../../../api/review';
import AutoComplete from '../../common/autocomplete';
import moment from 'moment';

interface IProps {
    filterMatrix: FilterMatrix;
    applyFilter(key: string, value: any, refreshData: boolean): void;
    refreshData: () => void;
    published: boolean;
    applyReviewScoreFilters?: () => void
}

const ReviewFilters = ({ filterMatrix, applyFilter, refreshData, published, applyReviewScoreFilters }: IProps) => {

    const [reviewScheme, setReviewScheme] = useState<any>();
    const [, updateState] = useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [ reviewSearchOptions, setReviewSearchOptions ] = useState<any>([]);

    const handleFilterChange = (key: string, value: any, refreshData = true) => {
        applyFilter(key, value, refreshData);
        forceUpdate();
    };

    const resetFilters = () => {
        filterMatrix.reset();
        refreshData();
    };

    useEffect(() => {
        (async () => {
            try {
                const reviewSchemeData: any = await getReviewScheme();
                setReviewScheme(reviewSchemeData);
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    const getLabelKey = (option: any) => {
        const by = option.ReviewerDisplayName ? `by ${option.ReviewerDisplayName.trim()}` : '';

        return `Review ID: ${option.ReviewID} ${by} on ${moment(option.CreatedAt).format('MMMM Do YYYY h:mm a')}`;
    };

    const onSelectCompleted = (option: any) => {
        if (!option) {
            applyFilter('ReviewID', null, true);
            return;
        }

        applyFilter('ReviewID', option.ReviewID, true);
    };

    const getReviewsByIDSearch = async (query: any) => {
        const data = await getReviewsBySearchString(query, {
            IsPublished: true
        });

        setReviewSearchOptions(data);

        return data;
    };

    return (
        <>
            <div>
                <div className="p-4">
                    <div className='row mb-3 align-items-center'>
                        <div className='col-md-6 col-xl-3'>
                            <label htmlFor="DateSubmittedFrom">Date Submitted From</label>
                            <DatePicker
                                id="DateSubmittedFrom"
                                className='form-control'
                                selected={filterMatrix.getFormFieldValue('DateSubmittedFrom')}
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(0, 0, 0, 0);
                                    }
                                    handleFilterChange('DateSubmittedFrom', date);
                                }}
                                dateFormat={'dd MMM yyyy'}
                                placeholderText="Click to select a date"
                            />
                        </div>
                        <div className='col-md-6 col-xl-3'>
                            <label htmlFor="DateSubmittedTo">Date Submitted To</label>
                            <DatePicker
                                id="DateSubmittedTo"
                                className='form-control'
                                selected={filterMatrix.getFormFieldValue('DateSubmittedTo')}
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(23, 59, 59, 999);
                                    }
                                    handleFilterChange('DateSubmittedTo', date);
                                }}
                                dateFormat={'dd MMM yyyy'}
                                placeholderText="Click to select a date"
                            />
                        </div>
                    </div>
                    {
                    published ?

                        <div className='row mb-3 align-items-center'>
                            <div className='col-md-6 col-xl-3'>
                                <label htmlFor="DatePublishedFrom">Date Published From</label>
                                <DatePicker
                                    id="DatePublishedFrom"
                                    className='form-control'
                                    selected={filterMatrix.getFormFieldValue('DatePublishedFrom')}
                                    onChange={(date: Date) => {
                                        if (date) {
                                            date.setHours(0, 0, 0, 0);
                                        }
                                        handleFilterChange('DatePublishedFrom', date);
                                    }}
                                    dateFormat={'dd MMM yyyy'}
                                    placeholderText="Click to select a date"
                                />
                            </div>
                            <div className='col-md-6 col-xl-3'>
                                <label htmlFor="DatePublishedTo">Date Published To</label>
                                <DatePicker
                                    id="DatePublishedTo"
                                    className='form-control'
                                    selected={filterMatrix.getFormFieldValue('DatePublishedTo')}
                                    onChange={(date: Date) => {
                                        if (date) {
                                            date.setHours(23, 59, 59, 999);
                                        }
                                        handleFilterChange('DatePublishedTo', date);
                                    }}
                                    dateFormat={'dd MMM yyyy'}
                                    placeholderText="Click to select a date"
                                />
                            </div>
                        </div> : null
                    }

                    <div className='row mb-3 align-items-center'>
                        <div className='col-md-6 col-xl-3'>
                            <label htmlFor="ReviewerDisplayName">Reviewer Display Name</label>
                            <input
                                type="text"
                                id="ReviewerDisplayName"
                                className="form-control"
                                onBlur={(e) => handleFilterChange('ReviewerDisplayName', e.target.value)}
                                onChange={(e) => handleFilterChange('ReviewerDisplayName', e.target.value)}
                                onKeyUp={(e: any) => e.keyCode === 13 ? handleFilterChange('ReviewerDisplayName', e.target.value) : ''}
                                value={filterMatrix.getFormFieldValue('ReviewerDisplayName')}
                             />
                        </div>
                        <div className='col-md-6 col-xl-3'>
                            <label htmlFor="ReviewText">Review Text</label>
                            <input
                                type="text"
                                id="ReviewText"
                                className="form-control"
                                onBlur={(e) => handleFilterChange('ReviewText', e.target.value)}
                                onChange={(e) => handleFilterChange('ReviewText', e.target.value)}
                                onKeyUp={(e: any) => e.keyCode === 13 ? handleFilterChange('ReviewText', e.target.value) : ''}
                                value={filterMatrix.getFormFieldValue('ReviewText')}
                            />
                        </div>
                        <div className='col-md-6 col-xl-5'>
                            <label htmlFor="ReviewID">Review ID</label>
                            <AutoComplete
                                id="ReviewSearch"
                                isLoading={false}
                                getLabelKey={getLabelKey}
                                usePagination={true}
                                defaultValue={''}
                                onSelectCompleted={onSelectCompleted}
                                getData={getReviewsByIDSearch}
                                options={reviewSearchOptions}
                                customFilterBy={() => true}
                            />
                        </div>
                    </div>

                    <div className='row mb-3 align-items-center'>

                            <ReviewExperienceTypeFilter
                                filterMatrix={filterMatrix}
                                applyFilter={handleFilterChange}
                                reviewScheme={reviewScheme}
                            />
                            <ReviewerConnectionFilter
                                filterMatrix={filterMatrix}
                                applyFilter={handleFilterChange}
                            />
                    </div>
                    {
                    published ? 
                        <div className='row mb-3 align-items-center'>
                            <ReviewRatingTypeFilter
                                filterMatrix={filterMatrix}
                                applyFilter={handleFilterChange}
                                refreshData={refreshData}
                                reviewScheme={reviewScheme}
                            />
                        </div> : null
                    }
                    {
                    !published ? 
                        <div className='row mb-3 align-items-center'>
                            <ReviewPublicationStatusFilter
                                filterMatrix={filterMatrix}
                                applyFilter={handleFilterChange}
                                reviewScheme={reviewScheme}
                            />
                        </div> : null
                    }
                    {
                    published ?
                        <div className='row mt-4 align-items-center'>
                            <div className='col-sm-1'>
                                Reply Status                        
                            </div>
                            <div className='col-sm-6'>
                                <label className="kt-radio kt-radio--brand mr-4">
                                    <input 
                                        type="radio"
                                        name='replyStatus'
                                        checked={!filterMatrix.getFormFieldValue('HasReply')}
                                        onChange={() => handleFilterChange('HasReply', undefined)}
                                    />
                                    &nbsp;Any&nbsp;&nbsp;&nbsp;
                                    <span></span>
                                </label>
                                <label className="kt-radio kt-radio--brand mr-4">
                                    <input 
                                        type="radio"
                                        name='replyStatus'
                                        checked={filterMatrix.getFormFieldValue('HasReply') === 'true'}
                                        onChange={() => handleFilterChange('HasReply', 'true')}
                                    />
                                    &nbsp;Has a Reply (Published or Pending Verification)&nbsp;&nbsp;&nbsp;
                                    <span></span>
                                </label>
                                <label className="kt-radio kt-radio--brand mr-4">
                                    <input 
                                        type="radio"
                                        name='replyStatus'
                                        checked={filterMatrix.getFormFieldValue('HasReply') === 'false'}
                                        onChange={() => handleFilterChange('HasReply', 'false')}
                                    />
                                    &nbsp;Has No Reply&nbsp;&nbsp;&nbsp;
                                    <span></span>
                                </label>
                            </div>
                        </div> : null
                    }

                    {
                        applyReviewScoreFilters ? 
                            <div className='row mt-4 align-items-center'>
                                <div className='col-sm-12 pb-2'>
                                    <a className="text-decoration-underline support-link" onClick={applyReviewScoreFilters}>
                                        Select all relevant filters to show only Reviews used in the Review Score
                                    </a>
                                </div> 
                            </div> : null
                    }

                    <div className="row">
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-primary"
                                onClick={resetFilters}>Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReviewFilters;
