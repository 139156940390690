import React, { FC } from 'react';
import { getAllPropertiesTeamMembers } from '../../../../api/meet_the_team';
import { useQuery } from '@tanstack/react-query';

import { useSessionState } from '../../../../hooks/use_session_state';
import { DroppableAreaContainer } from './droppable_area_container';
import { textSort } from '../../../../utils/common';
import { DataTableSortOrder } from '../../../../components/common/data_table';

interface Props {
    onSelectProperty: (propertyId: number) => void;
    profileCompletenessFilterValue: boolean | null;
}
const AllPropertiesMembersView: FC<Props> = ({ onSelectProperty, profileCompletenessFilterValue }) => {
    const queryKey = ['meet-the-team', 'property'];
    const { Properties } = useSessionState();

    const NHIDs = Properties.map((property) => property.NHID);

    const {
        data = [],
        isLoading,
        isFetching
    } = useQuery({
        enabled: !!NHIDs.length, // don't make the request until nhIDs are available
        queryKey,
        staleTime: 5000,
        refetchOnWindowFocus: false,
        queryFn: () => getAllPropertiesTeamMembers(NHIDs)
    });

    const dataMap = new Map();
    for (const item of data) {
        if (dataMap.has(item.propertyId)) {
            dataMap.get(item.propertyId).push(item);
        } else {
            dataMap.set(item.propertyId, [item]);
        }
    }

    const filteredProperties = Properties.filter((property: any) => {

        const propertyMembers = dataMap.get(property.NHID);

        if (profileCompletenessFilterValue === null) {
            return true;
        } else if (profileCompletenessFilterValue) {
            return propertyMembers?.length;
        } else {
            return !propertyMembers?.length;
        }
    }).sort(textSort('Name', DataTableSortOrder.Ascending));

    return (
        <>
            {
                filteredProperties.map((property) => {
                    const propertyTeamMembers = dataMap.get(property.NHID);
                    return (
                        <DroppableAreaContainer
                            disabled={isFetching}
                            key={property.NHID}
                            onSelectProperty={onSelectProperty}
                            isLoading={isLoading}
                            property={property}
                            data={propertyTeamMembers}
                        />
                    );
                })
            }
        </>
    );
};

export { AllPropertiesMembersView };
