import React from 'react';
import FilterMatrix from '../../modules/filter_matrix';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
    filterMatrices: FilterMatrix[];
    totalFilteredSize?: number;
    totalSize?: number;
    isLoading: boolean;
    pageOffset: number;
    pageLimit: number;
    singularNoun: string;
    pluralNoun: string;
    showTotals: boolean;
    applyFilter: any;
    showResetLink?: boolean;
    refreshData?(): void;
}

const ActiveFilters = ({ 
    filterMatrices, 
    totalFilteredSize, 
    totalSize,
    isLoading, 
    pageOffset, 
    pageLimit, 
    singularNoun, 
    pluralNoun,
    showTotals,
    applyFilter,
    showResetLink,
    refreshData
}: IProps) => {

    const renderFilterHeaderStr = () => {

        if (isLoading) {
            return null;
        }

        let numFiltersApplied = 0;

        for (let i = 0; i < filterMatrices.length; i++) {
            numFiltersApplied += filterMatrices[i].getActiveCount();
        }

        let str = '';

        if (showTotals) {
            if (numFiltersApplied === 0) {
                str = `All ${totalSize} ${pluralNoun}`;
            } else {
                str = totalFilteredSize > 0 
                ? `${totalFilteredSize} ${totalFilteredSize === 1 ? 'match' : 'matches'} from all ${totalSize} ${pluralNoun}`
                : `No ${pluralNoun.toLowerCase()} found from all ${totalSize} ${pluralNoun}`;
            }

        } else {

            const resultCount = totalFilteredSize !== undefined 
            ? `${totalFilteredSize.toLocaleString()} ${totalFilteredSize === 1 ? singularNoun : pluralNoun}` : '';

            const startOffset = pageOffset + 1;
            const endOffset = Math.min(pageOffset + pageLimit, totalFilteredSize); 

            str = endOffset > 0 
                ? `Showing ${startOffset} to ${endOffset} of ${resultCount}`
                : `No ${pluralNoun.toLowerCase()} found`;
        }

        return (
            <>
            {
                numFiltersApplied ? 
                <p className="m-0">
                    <strong>{ str }</strong>, {numFiltersApplied ? numFiltersApplied : 'No'}&nbsp;
                    Filter{ numFiltersApplied !== 1 ? 's' : '' } Applied { numFiltersApplied ? ':' : '' }
                </p> 
                
                : 

                <p className="m-0">
                    <strong>{ str }</strong>
                </p>
            }
          </>
        );
    };

    const resetFilterMatrices = () => {
        filterMatrices.forEach((filterMatrix: any) => {
            filterMatrix.reset();
            refreshData();
        });
    };

    const renderResetLink = () => {
        if (!showResetLink) {
            return <></>;
        }

        let count = 0;

        filterMatrices.forEach((filterMatrix: any) => {
            count += filterMatrix.getActiveCount();
        });

        if (!count) {
            return <></>;
        }

        return (
            <span className="ms-3 text-primary cursor-pointer" onClick={() => resetFilterMatrices()}>
                Remove filters to view all reviews
            </span>
        );
    };

    const renderActiveFilters = (): any => {
        let labels: any[] = [];

        for (let i = 0; i < filterMatrices.length; i++) {
            filterMatrices[i].filters.forEach((filterItem: any) => {
                const textLabel = filterMatrices[i].getTextLabel(filterItem);

                if (filterItem && textLabel) {
                    labels.push({
                        label: textLabel,
                        key: filterItem.Key
                    });
                }
            });
        }

        labels = labels.map((item: any) => {
            const split = item.label.split(':');
            const label = (split[0] || '').trim();
            const value = (split[1] || '').trim();

            return {
                label,
                value,
                key: item.key
            };
        }).filter((item: any) => item.label);

        if (!labels.length) {
            return null;
        }

        return (
            <ul className="filters-active">
                {
                    labels.map((item: any, index: number) => {
                        return (
                            <li
                                key={`active-filter-${index}`}
                                onClick={() => applyFilter(item.key, null, true)}
                            >
                                <strong>{item.label}{item.value.length ? ':' : ''}&nbsp;</strong>
                                {item.value}
                                <FontAwesomeIcon
                                    icon={faXmark}
                                />
                                {/* index < labels.length -1 ? <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; </>: null*/}
                            </li>
                        );                   
                    })
                }
                { renderResetLink() }
            </ul>
        );
    };

    return (
        <div>
            <div className="row">
                { renderFilterHeaderStr() }
            </div>
            <div className="row">
                { renderActiveFilters() }
            </div>
        </div> 
    );
};


export default ActiveFilters;