import React from 'react';
import parse from 'html-react-parser';

type Props = {
    data: any;
};

export default function LeadEmailTableExpandable(props: Props) {
    return (
        <div className="px-2 py-4">
            {props.data.SenderInquiry ? parse(props.data.SenderInquiry) : null}
        </div>
    );
}
