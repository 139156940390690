import { IMenuStateTotals } from '../interfaces/menu';

export const getLinkTotal = (menuTotals: IMenuStateTotals, slug: string): number => {
    switch(slug) {
        case 'pending-reviews':
            return menuTotals['TotalPendingPublication'];
        case 'your-reviews':
            return menuTotals['TotalPublished'];
        case 'list-users':
            return menuTotals['TotalUsers'];
        default:
            return undefined;
    } 
};