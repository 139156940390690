import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../common/loading_spinner';
import {  getReviewScoreBreakdownHTML } from '../../api/review';
import parse from 'html-react-parser';

import { 
    IPropertyReviewScore, 
    IReviewScoreBreakdownParams 
} from '../../interfaces/review';

interface IProps {
    data: IPropertyReviewScore;
}

const ReviewScoreBreakdown = ({ data }: IProps) => {
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ breakdownHtml, setBreakdownHtml ] = useState<string>('');
    const [ breakdownCss, setBreakdownCss ] = useState<string>('');

    useEffect(() => {        

        const fetchData = async (): Promise<void> => {    
            try {
                setIsLoading(true);

                 const {
                    NHID,
                    Name,
                    ReviewScoreDisplay,
                    ReviewScoreFull,
                    ReviewScoreTotalReviewCount,
                    ReviewScoreQualityScore,
                    ReviewScorePositiveReviewCountThreshold,
                    ReviewScorePositiveReviewCount,
                    ReviewScoreQuantityScore
                } = data;

                const params: IReviewScoreBreakdownParams = {
                    ReviewScoreDisplay: ReviewScoreDisplay || 0,
                    ReviewScoreFull: ReviewScoreFull || 0,
                    ReviewScoreTotalReviewCount: ReviewScoreTotalReviewCount || 0,
                    ReviewScoreQuantityScore: ReviewScoreQuantityScore || 0,
                    ReviewScoreQualityScore: ReviewScoreQualityScore || 0,
                    ReviewScorePositiveReviewCount: ReviewScorePositiveReviewCount || 0,
                    ReviewScorePositiveReviewCountThreshold: ReviewScorePositiveReviewCountThreshold  || 0
                };

                if (ReviewScoreTotalReviewCount) {
                    const resp = await getReviewScoreBreakdownHTML(NHID, params);
                    let html = (resp?.Html || '').toString().replace(/\\"/g,'"');
                    html = html.replaceAll('{{propertyName}}', Name);
                    setBreakdownHtml(html);
                    setBreakdownCss(resp?.Css);
                }

            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

       fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div key={`review-score-breakdown-${data?.NHID}`}>
            <div className="text-xs md:text-sm">
                { parse(` <style type="text/css">${breakdownCss}</style>${breakdownHtml}`)}
            </div>
        </div>
    );
};

export default ReviewScoreBreakdown;
