import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { LoadingSpinner } from '../common/loading_spinner';
import { getReviewReplyData } from '../../api/charts';
import ReviewsRepliesBarChart from './reviews_replies_bar_chart';
import moment from 'moment';
import { formatFromDateForAPIRequestUTC, formatToDateForAPIRequestUTC } from '../../utils/date';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    cumulativeDefault: boolean;
    title?: string;
    showFilters?: boolean;
}

export const ReviewReplyChartWidget = ({ 
    nhIDs, 
    orgNHID, 
    selNHID, 
    cumulativeDefault,
    title,
    showFilters = true
}: IProps) => {

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    /**
     *  Code below needs reverting back once we have a years worth of data
     * 
     * const [ dateFrom, setDateFrom ] = useState<any>(moment(now).add(1, 'year').startOf('month').toDate());
     * const [ dateTo, setDateTo ] = useState<any>(moment(now).toDate());
     */

    const now = new Date();
    const startDate = new Date('2024-02-01');

    const [ dateFrom, setDateFrom ] = useState<any>(moment(startDate).toDate());
    const [ dateTo, setDateTo ] = useState<any>(moment(now).toDate());

    const [ cumulative, setCumulative ] = useState<boolean>(cumulativeDefault);
    const [ data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (!orgNHID && !nhIDs) return;

        const fetchData = async (): Promise<void> => {    
            try {
                setIsLoading(true);
                const data: any[] = await getReviewReplyData(
                    formatFromDateForAPIRequestUTC(dateFrom), 
                    formatToDateForAPIRequestUTC(dateTo),
                    selNHID
                ); 
                setData(data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

       fetchData();
    }, [ orgNHID, nhIDs, dateFrom, dateTo, selNHID]);

    const renderFilters = () => {

        return (
            <>
                <div className='row mb-3 align-items-center'>
                    <div className='col-sm-3'>
                        <p>Date Submitted From</p>
                        <DatePicker
                            className='form-control'
                            selected={dateFrom}
                            onChange={(date: Date) => {
                                setDateFrom(date);
                            }}
                            dateFormat={'dd MMM yyyy'}
                            minDate={startDate}
                            maxDate={new Date(dateTo)}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <p>Date Submitted To</p>
                        <DatePicker
                            className='form-control'
                            selected={dateTo}
                            onChange={(date: Date) => {
                                if (date) {
                                    date.setHours(23, 59, 59, 999);
                                }
                                setDateTo(date);
                            }}
                            dateFormat={'dd MMM yyyy'}
                            minDate={new Date(dateFrom)}
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="col-sm-6">
                        <div className="kt-radio-list kt-radio-list--inline mt-4">
                            <label className="kt-radio kt-radio--brand mr-4">
                                <input 
                                    type="radio"
                                    name='cumulative'
                                    checked={!cumulative}
                                    onChange={() => setCumulative(false)}
                                />
                                &nbsp;Per Month&nbsp;&nbsp;&nbsp;
                                <span></span>
                            </label>
                            <label className="kt-radio kt-radio--brand mr-4">
                                <input 
                                    type="radio"
                                    name='cumulative'
                                    checked={cumulative}
                                    onChange={() => setCumulative(true)}
                                />
                                &nbsp;Cumulative&nbsp;
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="card">
            <div className="card-body">
                { showFilters ? 
                    <div className="pt-4 px-4">
                        { renderFilters() }
                    </div>
                : null }
                    { isLoading ? <LoadingSpinner showInPlace={true} show={true}/> : null }
                    <ReviewsRepliesBarChart
                        chartData={data}
                        cumulative={cumulative}
                        title={title}
                    />
            </div>
        </div>
    );
};
