export const columnStyles = [
    { minWidth: '50px', maxWidth: '50px'},
    { minWidth: '170px', maxWidth: '170px'},
    { minWidth: '180px', maxWidth: '180px'},
    { minWidth: '180px', maxWidth: '180px'},
    { minWidth: '90px', maxWidth: '90px'},
    { minWidth: '380px', maxWidth: '380px'},
    { minWidth: '90px', maxWidth: '90px'},
    { minWidth: '100px', maxWidth: '100px'}
];
