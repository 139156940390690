import { IDataControls } from './common';
export interface IPropertyLiveDescriptionParams {
    NHID: number;
    HasLiveDescription: boolean;
}

export interface IPropertyLiveDescription  {
    NHID: number;
    PropertyName: string;
    Address1: string;
    Address2: string;
    CityName: string;
    StateCode: string;
    ZipCode: number;
    Description: string; 
    IsOrg: boolean;
}

export interface IPropertyContactDetailsParams extends IDataControls{
    OrgNHID: number;
    NHIDs: number[];
}

export interface IPropertyContactDetails  {
    NHID: number;
    OrgNHID: number;
    Name: string;
    Address1: string;
    Address2: string;
    Address3: string;
    CityName: string;
    StateCode: string;
    ZipCode: number;
    Slug: string;
    WebsiteURL: string;
    StateSlug: string; 
    CitySlug: string;
    GeneralPhoneNumber: string;
    GeneralEmail: string;
    GeneralWebsite: string;
    CarePhoneNumber: string;
    JobPhoneNumber: string;
    GeneralEmail2: string;
    GeneralEmail3: string;
    CareEmail: string;
    CareEmail2: string;
    CareEmail3: string;
    JobEmail: string;
    JobEmail2: string;
    JobEmail3: string;
}

export interface IPropertyUpdateContactDetailsParams  {
    GeneralPhoneNumber?: string;
    GeneralEmail?: string;
    GeneralWebsite?: string;
    CarePhoneNumber?: string;
    JobPhoneNumber?: string;
    GeneralEmail2?: string;
    GeneralEmail3?: string;
    CareEmail?: string;
    CareEmail2?: string;
    CareEmail3?: string;
    JobEmail?: string;
    JobEmail2?: string;
    JobEmail3?: string;
}


export interface IPropertyBulkDetailsPayload extends IPropertyUpdateContactDetailsParams {
    GeneralPhoneNumberOp?: number;
    GeneralEmailOp?: number;
    GeneralWebsiteOp?: number;
    CarePhoneNumberOp?: number;
    JobPhoneNumberOp?: number;
    GeneralEmail2Op?: number;
    GeneralEmail3Op?: number;
    CareEmailOp?: number;
    CareEmail2Op?: number;
    CareEmail3Op?: number;
    JobEmailOp?: number;
    JobEmail2Op?: number;
    JobEmail3Op?: number;
    OrgNHID?: number;
    NHIDs?: number[];
}

export enum ContactDetailsHeaders {
    General = 'Default Inquiries',
    GeneralEmails = 'Default Email Addresses',
    GeneralEmail = 'Default Email',
    GeneralEmail1= 'Default Email 1',
    GeneralEmail2= 'Default Email 2',
    GeneralEmail3= 'Default Email 3',
    GeneralPhoneNumber = 'Default Number',
    Care = 'Care Inquiries',
    CareExtended = 'Care Inquiries (includes Care Inquiries, Brochure Inquiries and Tour Inquiries)',
    Care1 = 'Care Inquiries 1',
    Care2 = 'Care Inquiries 2',
    Care3 = 'Care Inquiries 3',
    Job= 'Job Inquiries',
    Job1= 'Job Inquiries 1',
    Job2= 'Job Inquiries 2',
    Job3= 'Job Inquiries 3',
    Tour= 'Tour Requests',
    Tour1= 'Tour Requests 1',
    Tour2= 'Tour Requests 2',
    Tour3= 'Tour Requests 3',
    GeneralWebsite= 'Website URL',
}

export enum ContactDetailsPlaceholders {
    PhoneNumber = 'phone number',
    Email1= 'email 1',
    Email2= 'email 2',
    Email3= 'email 3',
    GeneralEmail1= 'Default Email 1',
    GeneralEmail2= 'Default Email 2',
    GeneralEmail3= 'Default Email 3',
    CareEmail1 = 'Care Inquiry Email 1',
    CareEmail2 = 'Care Inquiry Email 2',
    CareEmail3 = 'Care Inquiry Email 3',
    JobEmail1= 'Job Inquiry Email 1',
    JobEmail2= 'Job Inquiry Email 2',
    JobEmail3= 'Job Inquiry Email 3',
    Website = 'website'
}

export enum ContactDetailsHTMLType{
    PhoneNumber = 'PhoneNumber',
    Email= 'Email',
    Website = 'Website'
}

export interface IPropertyProfileCompleteness {
    TotalProfileCompletenessScore: number;
    ServicesAndAmenitiesScore: number;
    PhotoWithExternalTagScore: number;
    FiveTaggedPhotosWithoutExternalTagScore: number;
    VideoScore: number;
    FacilityBaseScore: number;
    DescriptionScore: number;
    MeetTheTeamScore: number;
}

export interface INhPropertyScoresItem {
    KeyName: string;
    FieldLabel: string;
    FieldName: string;
    Score: number;
    AvailableScore: number;
    FieldDescription: string;
}

export interface INhPropertyScores {
    NHID: number;
    Scores: INhPropertyScoresItem[];
    ScoreExtras: INhPropertyScoreExtras;
}

export interface INhPropertyScoreExtras {
    HasPhoneNumber: boolean;
    HasEmailAddress: boolean;
    HasWebsite: boolean;
    HasPortalUsers: boolean;
    HasLogo: boolean;
    HasPDFBrochure: boolean;
}

export interface INhOrgScoreFacilityCountItem {
    KeyName: string;
    FieldLabel: string;
    FieldName: string;
    Count: number;
}

export interface INhPortalScoreItem {
    OrgScores: INhOrgScoreFacilityCountItem[];
    TotalOrgProfileScore: number;
    OrgFacilityCount: number;
    PropertyScores: INhPropertyScores[]
}

export interface IScoreDisplayItem {
    TotalScore: number;
    AvailableScore: number;
    Label: string;
    KeyName: string;
    Description: string;
    FacilityCount: number;
    AchievedScoreCount: number;
    TotalAvailableScore: number;
    ScorePC: number;
    InternalURL: string;
}

export interface IScoreExtraDisplayItem {
    FacilityCount: number;
    HasPhoneNumberCount: number;
    HasEmailAddressCount: number;
    HasWebsiteCount: number;
    HasPortalUsersCount: number;
    HasLogoCount: number;
    HasPDFBrochureCount: number;
    InternalURL: string;
}

export interface IPropertyContactNoValueDetails  {
    NoEmailCount?: number;
    NoPhoneCount?: number;
    NoWebsiteCount?: number;
}

export enum ProfileScoreType {
    FacilityBaseData = 'facility-base-data',
    CostFunding = 'cost-funding',
    ServicesAndAmenities = 'services-and-amenities',
    Description = 'description-present',
    Photo = 'plus-10-photos',
    Video = 'video-present',
    MeetTheTeam = 'plus-one-meet-the-team-profile',
    PdfBrochure = 'pdf-brochure-present',
    TourAvailability = 'tour-availability',
    Total = 'total-score'
}

export interface IProfileCompleteItem {
    NHID: number;
    FacilityBaseDataComplete: boolean;
    ServicesAndAmenitiesComplete: boolean;
    DescriptionComplete: boolean;
    PhotoComplete: boolean;
    VideoComplete: boolean;
    MeetTheTeamComplete: boolean;
    TotalComplete: boolean;
}

export interface IPropertyCoreDetailBasic {
    NHID: number,
    CoreDetailsLastUpdated: string,
    CostFundingLastUpdated: string,
    LongTermCare: boolean,
    ShortTermCare: boolean,
    RespiteCare: boolean,
    OtherFacilityTypes: string;
    AffiliatedHospitals: string;
    AvgShortTermResidentsPc: number;
    AvgShortStayDuration: number;
    AvgLongTermResidentsPc: number;
    AvgLongTermStayDuration: number;
    MinAgeRequirement: boolean;
    MinAge: number;
    AdmitPediatricResidents: boolean;
    PediatricAvgShortTermResidentsPc: number;
    PediatricAvgShortStayDuration: number;
    PediatricAvgLongTermResidentsPc: number;
    PediatricAvgLongTermStayDuration: number;
    PediatricMaxAgeRequirement: boolean;
    PediatricMaxAge: number;
    RoomTypeOther: string;
    RoomTypeSemiPrivate: boolean;
    RoomTypeShared: boolean;
    RoomTypePrivateSuite: boolean;
    RoomTypePrivateRoom: boolean;
	LongTermSharedCostPerDay: number;
	LongTermPrivateCostPerDay: number;
	LongTermSemiPrivateCostPerDay: number;
	LongTermPrivateSuiteCostPerDay: number;
	LongTermRatesAllInclusive: boolean;
	LongTermRatePackagesAvailable: boolean;
	ShortTermSharedCostPerDay: number;
	ShortTermPrivateCostPerDay: number;
	ShortTermSemiPrivateCostPerDay: number;
	ShortTermPrivateSuiteCostPerDay: number;
	ShortTermRatesAllInclusive: boolean;
	ShortTermRatePackagesAvailable: boolean;
	RespiteSharedCostPerDay: number;
	RespitePrivateCostPerDay: number;
	RespiteSemiPrivateCostPerDay: number;
	RespitePrivateSuiteCostPerDay: number;
    RespiteMinStayRequirement: boolean;
    RespiteMinStay: number;
	RespiteRatesAllInclusive: boolean;
	RespiteRatePackagesAvailable: boolean;
    AcceptMedicareAdvantage: boolean;
    AcceptMedicaidPending: boolean;
    BedsAllDualLicenced: boolean;
    BedsMedicareCount: number;
    BedsMedicaidCount: number;
    PrivateInsurancePc: number;
    ManagedCareContractsInNetwork: boolean;
    FacilityHasMemoryCareUnit: boolean;
    FacilityHasBehaviouralHealthUnit: boolean;
}

export interface IPropertyCoreDetailInsuranceType {
    Name: string;
    Sequence: number;
    IsActive: boolean;
    PropertyInsuranceTypeID: number;
}

export interface IPropertyCoreDetailNHID {
    NHID: number;
    CoreDetails: IPropertyCoreDetailBasic
    InsuranceTypes: IPropertyCoreDetailInsuranceType[];
}

export interface IPropertyInsuranceTypesPayloadItem {
    PropertyInsuranceTypeID: number;
    IsActive: boolean;
}

export interface IPropertyCoreDetailPayload extends Partial<IPropertyCoreDetailBasic> {
    InsuranceTypes?: IPropertyInsuranceTypesPayloadItem[];
}

