import React from 'react';

interface IProps {
    show?: boolean;
    className?: string;
    showInPlace?: boolean;
}

export const LoadingSpinner = (props: IProps) => {
    return (
        <div> 
            <div 
                hidden={!props.show}
                className={`${props.showInPlace ? '' : 'centre-fixed'} ${props.className?? ''}`}
            >
                <div className="kt-spinner kt-spinner--md kt-spinner--info"></div>
            </div>
        </div>
    );
};
