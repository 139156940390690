import { object } from 'yup';
import * as yup from 'yup';

const yupNullableContactEmail = yup
    .string()
    .email('Please enter a Valid Email Address')
    .min(3, 'Website URL Must be at least 5 characters')
    .max(255, 'Email Must be 255 characters or less')
    .nullable()
    .transform((o, c) => (o?.trim() === '' ? null : c))
    .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Please enter a Valid Email Address'
    );

const yupNullableContactWebsite = yup
    .string()
    .nullable()
    .transform((o, c) => (o?.trim() === '' ? null : c))
    .url(
        'Please enter a valid Website URL, it must start with https:// and contain no spaces (check for trailing spaces)'
    )
    .min(5, 'Website URL Must be at least 5 characters')
    .max(2000, 'Website URL Must be 200 characters or less')
    .matches(
        new RegExp('^https://', 'i'),
        'Please enter a valid Website URL, it must start with https:// and contain no spaces (check for trailing spaces)'
    );

const yupNullablePhoneNumber = yup
    .number()
    .typeError('Please enter a valid Phone Number')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null));

const yupBulkUpdateOperations = yup
    .number()
    .min(1)
    .max(3)
    .typeError('Please select a valid operation')
    .transform((_, val) => (val !== '' ? Number(val) : null));

export const updateContactObjectSchema = object({
    GeneralPhoneNumber: yupNullablePhoneNumber,
    CarePhoneNumber: yupNullablePhoneNumber,
    JobPhoneNumber: yupNullablePhoneNumber,
    GeneralEmail: yupNullableContactEmail,
    GeneralEmail2: yupNullableContactEmail,
    GeneralEmail3: yupNullableContactEmail,
    CareEmail: yupNullableContactEmail,
    CareEmail2: yupNullableContactEmail,
    CareEmail3: yupNullableContactEmail,
    JobEmail: yupNullableContactEmail,
    JobEmail2: yupNullableContactEmail,
    JobEmail3: yupNullableContactEmail,
    GeneralWebsite: yupNullableContactWebsite
});

export const bulkUpdateContactDetailsObjectSchema = object({
    GeneralPhoneNumber: yupNullablePhoneNumber,
    GeneralEmail: yupNullableContactEmail,
    GeneralWebsite: yupNullableContactWebsite,
    CarePhoneNumber: yupNullablePhoneNumber,
    JobPhoneNumber: yupNullablePhoneNumber,
    GeneralEmail2: yupNullableContactEmail,
    GeneralEmail3: yupNullableContactEmail,
    CareEmail: yupNullableContactEmail,
    CareEmail2: yupNullableContactEmail,
    CareEmail3: yupNullableContactEmail,
    JobEmail: yupNullableContactEmail,
    JobEmail2: yupNullableContactEmail,
    JobEmail3: yupNullableContactEmail,
    GeneralPhoneNumberOp: yupBulkUpdateOperations,
    GeneralEmailOp: yupBulkUpdateOperations,
    GeneralWebsiteOp: yupBulkUpdateOperations,
    CarePhoneNumberOp: yupBulkUpdateOperations,
    JobPhoneNumberOp: yupBulkUpdateOperations,
    GeneralEmail2Op: yupBulkUpdateOperations,
    GeneralEmail3Op: yupBulkUpdateOperations,
    CareEmailOp: yupBulkUpdateOperations,
    CareEmail2Op: yupBulkUpdateOperations,
    CareEmail3Op: yupBulkUpdateOperations,
    JobEmailOp: yupBulkUpdateOperations,
    JobEmail2Op: yupBulkUpdateOperations,
    JobEmail3Op: yupBulkUpdateOperations
});
