import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePropertyServices } from '../../../../../api/service';

interface Action  { 
    onSuccess?: () => void; 
}

const useUpdatePropertyServices = (nhID: number, { onSuccess }: Action) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: async (payload: any) =>
            updatePropertyServices(nhID, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['portal', 'property', 'profilecompleteness', nhID]
            });
            queryClient.invalidateQueries({
                queryKey: ['portal', 'service', 'extras', nhID]
            });
            queryClient.invalidateQueries({
                queryKey: ['portal', 'service', 'extras', 'custom', nhID]
            });
            queryClient.invalidateQueries({
                queryKey: ['portal', 'service', 'totals']
            });

            onSuccess?.();
        },
    });

    return mutation;
};


export { useUpdatePropertyServices };