import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableString,
    yupNullableStringToNumberLT100,
    yupNullableStringToNumberPc,
    yupNullableStringToNumberLT1000,
    yupStringToNumberLT100,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    AvgLongTermStayDuration: yupNullableStringToNumberLT100,
    AvgLongTermResidentsPc: yupNullableStringToNumberPc,
    AvgShortStayDuration: yupNullableStringToNumberLT1000,
    AvgShortTermResidentsPc: yupNullableStringToNumberPc,
    AffiliatedHospitals: yupNullableString,
    OtherFacilityTypes: yupNullableString,
    FacilityHasBehaviouralHealthUnit: yupNullableStringToBool,
    FacilityHasMemoryCareUnit: yupNullableStringToBool,
    MinAgeRequirement: yupNullableStringToBool,
    MinAge: yup.string().when('MinAgeRequirement', {
        is: (val: any) => val === true || val === '1' || val === 'Yes',
        then: () => yupStringToNumberLT100.required('Please enter a minumum age requirement'),
        otherwise: () => yupNullableStringToNumberLT100
    }),
    LongTermCare: yupNullableStringToBool,
    ShortTermCare: yupNullableStringToBool,
    RespiteCare: yupNullableStringToBool,
});

export default schema;