import React from 'react';

interface IProps {
    loading: boolean;
    loadingMessage?: string;
    noDataMessage?: string;
}

const TableLoading = ({ loading, loadingMessage, noDataMessage }: IProps) => {
    return (
        <>
            <div className="row mt-4 mb-4">
                <p className="no-data-msg mb-4">
                    { loading ? loadingMessage ?? 'Loading...' : noDataMessage ?? 'No data found' }
                </p>
            </div>
        </>
    );
};

export default TableLoading;
