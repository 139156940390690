import React, { FC } from 'react';

interface IProps {
    message: string;
}

const ErrorMessage: FC<IProps> = ({ message }) => {
    return <small className="text-danger position-absolute">{message}</small>;
};

export { ErrorMessage };
