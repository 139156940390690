import { combineReducers } from 'redux';
import SessionReducer from './session_reducer';
import MenuReducer from './menu_reducer';
import MenuTotalsReducer from './menu_totals_reducer';
import SettingsReducer from './settings_reducer';
import ProfileCompletenessReducer from './profile_completeness_reducer';

const rootReducer = combineReducers({
    SessionState: SessionReducer,
    MenuState: MenuReducer,
    MenuTotalsState: MenuTotalsReducer,
    SettingsState: SettingsReducer,
    ProfileCompletenessState: ProfileCompletenessReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
