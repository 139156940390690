import { ISessionState } from '../../interfaces/session';

interface IAction {
    type: string;
    payload?: any;
}

const DEFAULT_STATE: ISessionState = {
    Session: null,
    Org: null,
    Properties: null,
    AccountManager: null
};

export default (state = DEFAULT_STATE, action: IAction) => {
    switch (action.type) {
        case 'SET_CURRENT_SESSION':
            state = {
                ...state,
                ...action.payload
            };
            break;
    }

    return state;
};
