import React from 'react';
import { ShowcaseReviewsWidget } from '../dashboard/showcase_reviews_widget';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const ReviewListPublishedBanner = ({ nhIDs, orgNHID }: IProps) => {
    const showPortletArray = ['postcards-v2','invite-to-review','qr-code-posters','review-score'];

    return (
        <div 
            className="widget widget-fullwidth" 
            data-widget="Generate & Showcase Reviews" 
            data-widget-header="0" 
            data-widget-footer="0" 
            data-widget-columns="4"
        >
            <ShowcaseReviewsWidget
                orgNHID={orgNHID}
                nhIDs={nhIDs}
                showPortletArray={showPortletArray}
            />
        </div>
    );
};

export default ReviewListPublishedBanner;
