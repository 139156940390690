import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MeetTheTeamMemberType } from '../types';
import { prepareUpdateTeamMemberPayload } from '../components/property_team_form/helpers';
import { updatePropertyTeamMember } from '../../../../api/meet_the_team';
import { createQueryKey } from './helpers';
import { updateProfileCompleteness } from '../../../../utils/profile_completeness'; 

interface Props {
    includeIdInQueryKey: boolean;
    propertyId: number;
}

const useUpdateTeamMemberMutation = (args: Props) => {
    const { includeIdInQueryKey, propertyId } = args;
    const queryClient = useQueryClient();
    const queryKey = createQueryKey(propertyId, includeIdInQueryKey);

    const mutation = useMutation({
        mutationFn: async (formValues: MeetTheTeamMemberType) => {
            const { id: memberId, ...otherFields } = formValues;
            const payload = prepareUpdateTeamMemberPayload(otherFields);

            // the propertyId here comes from the outer scope and refers to
            // the original propertyId. The one from the formValues object is
            // the updated propertyId from the form

            const ret = await updatePropertyTeamMember(propertyId, memberId, payload);
            updateProfileCompleteness();

            return ret;
        },
        onMutate: async (values: MeetTheTeamMemberType) => {
            // prevent any query updates while we perform optimistic update
            await queryClient.cancelQueries({ queryKey });

            // copy the current data as it exists in the react-query store
            const previous = queryClient.getQueryData(queryKey);

            // Optimistic update
            queryClient.setQueryData(
                queryKey,
                (oldData: MeetTheTeamMemberType[]) => {
                    const optimisticMember = {
                        ...values,
                        publishWebsite: values.publishWebsite === 'yes'
                    };

                    const dataWithOptimisticMember = () =>
                        oldData.map((member) => {
                            if (member.id === values.id) {
                                return optimisticMember;
                            }
                            return member;
                        });

                    const removeSelectedMember = (
                        member: MeetTheTeamMemberType
                    ) => member.id !== values.id;

                    // when moved to new property, member is put in last position
                    const dataWithOptimisticMemberInLastIndex = () => [
                        ...oldData.filter(removeSelectedMember),
                        optimisticMember
                    ];

                    // if moved to new property in single property view, just remove the member from
                    // the single property view optimistically
                    const dataWithoutMember = () => [
                        ...oldData.filter(removeSelectedMember)
                    ];

                    const isPropertyUnchanged =
                        propertyId === values.propertyId;

                    if (includeIdInQueryKey) {
                        return isPropertyUnchanged
                            ? dataWithOptimisticMember()
                            : dataWithoutMember();
                    }

                    return isPropertyUnchanged
                        ? dataWithOptimisticMember()
                        : dataWithOptimisticMemberInLastIndex();
                }
            );

            // this gets added to the context in case we need to rollback
            return { previous };
        },
        onError: (err, _, context) => {
            console.log(err);
            // set the data in the store back to how it was before the optimistic update
            queryClient.setQueryData(queryKey, context.previous);
        },

        onSettled: () => {
            // we need to invalidate the queries used in both the single and multiple property views
            queryClient.invalidateQueries({
                queryKey: createQueryKey(propertyId)
            });
        }
    });

    return mutation;
};

export { useUpdateTeamMemberMutation };
