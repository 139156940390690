import React  from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

interface IProps {
    id: string;
    placeholder?: string;
    isLoading: boolean;
    getLabelKey(option: any): any;
    usePagination: boolean;
    options: any;
    defaultValue?: string;
    multiChoice?: boolean;
    disable?: boolean;
    customFilterBy?: any;
    selected?: any;
    onSelectCompleted: any;
    pageSize?: number;
    getData(query: string): any;
    onPaginate?(params: any): any;
}

const AutoComplete = ({
    id,
    placeholder,
    isLoading,
    getLabelKey,
    usePagination,
    options,
    defaultValue,
    multiChoice,
    disable,
    customFilterBy,
    selected,
    onSelectCompleted,
    pageSize = 100,
    getData,
    onPaginate
}: IProps) => {
    return <AsyncTypeahead
        key={id}
        id={id}
        placeholder={placeholder}
        minLength={1}
        isLoading={isLoading}
        clearButton={true}
        emptyLabel="No matches found."
        labelKey={(option: any) => getLabelKey(option)}
        maxResults={pageSize}
        onSearch={(query: string) => getData(query)}
        ignoreDiacritics={true}
        paginate={usePagination}
        onPaginate={onPaginate}
        paginationText="More..."
        options={options}
        onChange={selected => onSelectCompleted(multiChoice ? selected : selected[0])}
        defaultInputValue={defaultValue || ''}
        multiple={multiChoice ? true : false}
        disabled={disable}
        filterBy={customFilterBy}
        useCache={false}
        selected={selected}
    />;
};

export default AutoComplete;
