import React, { FC } from 'react';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { NoResultsPlaceholder } from '../../components/no_results_placeholder';
import { DraggableMemberCard } from './draggable_member_card';

interface Props {
    teamMembers: MeetTheTeamMemberType[];
    onDragEnd: OnDragEndResponder;
}

const DroppableMemberCardArea: FC<Props> = ({ teamMembers, onDragEnd }) => {
    const draggableCards = teamMembers?.map(
        (member: MeetTheTeamMemberType, index: number) => (
            <DraggableMemberCard key={member.id} index={index} {...member} />
        )
    );

    const dropArea = (
        <Droppable droppableId="meet-the-team">
            {(provided) => (
                <div className="card-info"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {draggableCards}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {teamMembers?.length ? dropArea : <NoResultsPlaceholder />}
        </DragDropContext>
    );
};

export { DroppableMemberCardArea };
