import React, { ReactNode } from 'react';
import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';
import Footer from '../components/layout/footer'; 
import { Navigate, useLocation } from 'react-router-dom';

import { 
    getJwtToken,
} from '../modules/login';

interface IProps {
    children: ReactNode;
}

const ProtectedRoute = ({
    children
}: IProps) => {

    const location = useLocation();
    const pathname = location.pathname + location.search;
    const token = getJwtToken();

    if (!token) {
        return <Navigate 
            to={'/login'}
            state={{ from: pathname }}
        />;
    }

    return (
        <div className="app-root">
            <div className="wrapper d-flex flex-column flex-row-fluid w-100">
                <Sidebar 
                    pathName={pathname}
                />                            
                <Header />
                <div className="app-content container-fluid order-3">
                    { children }
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ProtectedRoute;
