import { TagKeyName } from './tag';

export interface INhAssetType {
    AssetTypeName: string;
    AssetTypeID: number;
}

export interface IMember {
    MemberID: number;
    AlternativeMemberID: number;
    GroupID: number;
    GroupName: string;
    Name: string;
    Address: string;
    Address1: string;
    Address2: string;
    City: string;
    Locality: string;
    RegionCode: string;
    PostalCode: string;
    Categories: any;
    Description: string;
    IsReferrable: boolean;
    Latitude: number;
    Longitude: number;
    Website: string;
    Images: any;
    ProfileURL: string;
    AlternativeProfileURL?: string;
    ReviewSubmissionURL: string;
    AlternativeReviewSubmissionURL?: string;
    ReviewSchemes?: any;
    CareTypeLabels?: any;
    MemberReviewCount?: any;
    LastReviewDate?: string;
}

export interface INHSignatureParams {
    UploadPreset: string;
    FileName?: string;
}

export interface INHUploadSignature {
    SignatureTimestamp: number;
    Signature: string;
    CloudName: string;
    ApiKey: string;
    FileName?: string;
}
export interface ICloudinaryData {
    AssetUrl: string;
    TransformedAssetUrl: string;
    IsTransfromationComplete: boolean;
    Sequence: number;
    CloudName: string;
    PageCount?: number;
}

export interface IAssetPayLoad {
    NHID: number;
    AssetType: number;
    AssetUrl: string;
    TransformedAssetUrl: string;
    IsTransfromationComplete: boolean;
    Sequence?: number;
    CloudName: string;
    PageCount?: number;
    CreateCardLogoFromWebLogo?: boolean;
    TagID?: number;
 }

export interface IAssetUploadLog {
    Type: string;
    FileName: string;
    Message: string;
    PageCount?: number; 
    AssetDetail?: any;
 }

export interface IGetPropertyAsset {
    AssetID: number;
    TransformedAssetUrl: string;
    IsTransformationCompleted: boolean;
    Title: string;
    Sequence: number;
    FileName: string;
    PageCount: number;
    TagTypeID: number;
    AssetTagIDs: string;
}

export enum NHAssetType {
    Photos = 1,
    Logos = 2,
    Videos= 3,
    CardLogos = 4,
    BrochurePdfs = 5,
    MeetTeamPhotos = 6,
    WebAndCardLogos = 7
}

export interface IUpdateSequencePayLoad {
    AssetIDs: number[];
    NHID: number;
}

export interface IPropertyAssetTitle {
    AssetID: number;
    Title: string;
}

export interface IUpdateTitlePayLoad {
    NHID: number;
    AssetID: number;
    Title: string;
}

export interface INhAssetListingItem {
    NHID: number;
    FedID: string;
    Name: string;
    Slug: string;
    Address1: string;
    Address2: string;
    Address3: string;
    CityID: number;
    CityName: string;
    CitySlug: string;
    CountyID: number;
    CountyName: string;
    CountyFullName: string;
    StateCode: string;
    Zip: string;
    AssetID: number;
    AssetTypeID: number;
    TransformedAssetURL: string;
    Title: string;
    Sequence: number;
    IsActive: boolean;
    VerifiedByUser: string;
    VerifiedDate: string;
    IsVerified: boolean;
    UpdatedByUser: string;
    UpdatedDate: string;
    AssetTypeName: string;
    AssetTypeKeyName: string;
    IsShortlisted: boolean;
    WebsiteID: number;
    WebsiteUrl: string;
    AssetTagIDs: string;
    TagTypeID: number;
    WeblogoURL: string;
    FacilityCount: number;
    OrgCount: number;
}

export interface IDeletePayLoad {
    NHID: number;
    AssetID: number;
    AssetTypeID: number;
}

export interface IVerifyPayLoad {
    NHID: number;
    AssetID: number;
    AssetTypeID: number;
    VerifyValue: boolean;
}

export enum TagType {
    NoTag = 1,
    SingleTag = 2,
    MultipleTag   
}

export interface IUpdateSequencePayLoad {
    AssetIDs: number[];
    NHID: number;
}

export interface IPortalPropertyObj{
    NHID: number;
    Name: string;
    Slug: string;
    Address: string;
    WebsiteID: number;
    WebsiteURL: string;
    StateSlug: string; 
    CitySlug: string;
}

export interface IPortalPropertyPhotoObj{
    AssetID: number;
    FileName: string;
    PhotoSequence: number;
    TagID: number;
    TagName: string;
    Title: string;
    TransformedAssetUrl: string;
}

export interface IPortalPropertyTagObj{
    AssetTagID: number;
    TagID: number;
    TagName: string;
    TagKeyName?: TagKeyName;
    TagSequence: number;
    IsTagPublishWebsite: boolean;
    IsTagPublishCMS: boolean;
    IsTagPublishPortal: boolean;
    Photo: IPortalPropertyPhotoObj[]
}

export interface IPortalPropertyPhotoAsset{
    NHID: number;
    Property: IPortalPropertyObj;
    Tag: IPortalPropertyTagObj[];
    Photo: IPortalPropertyPhotoObj[];
}

export interface IAssetTagPayLoad {
    NHID: number;
    AssetID: number;
    TagIDs: string;
}

export interface IPortalPropertyTagModalData{
    TagID: number;
    TagName: string;
    TagSequence: number;
    IsTagPublishWebsite: boolean;
    IsTagPublishCMS: boolean;
    IsTagPublishPortal: boolean;
    AssetID: number;
}

export interface IPortalPropertyAssetObj{
    AssetID: number;
    FileName: string;
    Sequence: number;
    TransformedAssetUrl: string;
    CreatedDate: string;
    PageCount?: number;
}

export interface IPortalPropertyAsset{
    NHID: number;
    Property: IPortalPropertyObj;
    Asset: IPortalPropertyAssetObj[];
}

export interface IPortalPropertyWebAndCardLogoAssetObj{
    WebLogoAssetID: number;
    WebLogoFileName: string;
    WebLogoSequence: number;
    WebLogoTransformedAssetUrl: string;
    WebLogoCreatedDate: string;
    CardLogoAssetID: number;
    CardLogoFileName: string;
    CardLogoSequence: number;
    CardLogoTransformedAssetUrl: string;
    CardLogoCreatedDate: string;
}

export interface IGetPortalPropertyWebAndCardLogoAsset {
    NHID: number;
    Property: IPortalPropertyObj;
    Asset: IPortalPropertyWebAndCardLogoAssetObj[];
}