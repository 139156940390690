import React from 'react';
import { ILeadTotals, LeadMetric } from '../../interfaces/leads';

type Props = {
    metric: LeadMetric;
    handleChange(tab: LeadMetric): void;
    totals: ILeadTotals;
    selNHID: number;
};

interface ITab {
    name: string;
    value: LeadMetric;
}

const tabs: ITab[] = [
    {
        name: 'All Channels',
        value: 'All'
    },
    {
        name: 'Page Views',
        value: 'PageViews'
    },
    {
        name: 'Website Referrals',
        value: 'WebsiteReferrals'
    },
    {
        name: 'Phone Number Views',
        value: 'PhoneNumberViews'
    },
    {
        name: 'Email Inquiries',
        value: 'EmailInquiries'
    },
    {
        name: 'Brochure Inquiries',
        value: 'BrochureInquiries'
    }
];

export default function LeadInsightsTabs(props: Props) {
    return (
        <ul>
            {
                tabs.map((tab: ITab, index: number) => {
                    const active = tab.value === props.metric;
                    const key = tab.value as keyof ILeadTotals;

                    return (
                        <li
                            key={index}
                            className={`items-center ${active ? 'selected' : ''} ${tab.value === 'All' ? 'all-tab' : ''}`}
                            onClick={() => props.handleChange(tab.value)}
                        >
                            <span>
                                {
                                    tab.value === 'All' ? null
                                    : props.totals[key]?.toLocaleString() || 0
                                }
                            </span>
                            {tab.name}
                        </li>
                    );
                })
            }
        </ul>
    );
}