import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { useFormContext } from 'react-hook-form';
import { SectionHeading } from '../../components/section_heading';
import { RoomTypeLink } from '../../components/room_type_link';

const ShortTermCareCostTab = () => {
    const { watch } = useFormContext();
    const inline = true;

    const isShortTermCareSelected = watch('ShortTermCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected = watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected = watch('RoomTypeSemiPrivate') === 'Yes';
    const isRoomTypePrivateSuiteSelected = watch('RoomTypePrivateSuite') === 'Yes';

    const canUsePrivateRoomShortTerm = isShortTermCareSelected && isRoomTypePrivateRoomSelected;
    const canUseSemiPrivateRoomShortTerm = isShortTermCareSelected && isRoomTypeSemiPrivateSelected;
    const canUseSharedRoomShortTerm = isShortTermCareSelected && isRoomTypeSharedSelected;
    const canUsePrivateSuiteShortTerm = isShortTermCareSelected && isRoomTypePrivateSuiteSelected;

    const allFieldsDisabled = !(
        canUsePrivateRoomShortTerm ||
        canUseSemiPrivateRoomShortTerm ||
        canUseSharedRoomShortTerm ||
        canUsePrivateSuiteShortTerm
    );

    return (
        <div className="mt-4">
            <SectionHeading
                title="Short Term Care Cost"
                subtitle={<small className="me-2">To edit the below inputs &lsquo;<a href={'/core-details'} className="fs-7">Short Term Care</a>&rsquo; and the relevant { <RoomTypeLink/> } must be set to &lsquo;Yes&rsquo;.</small>}

            />
            <div className="ps-2">
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSharedRoomShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSharedRoomShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Shared room"
                    maxWidth='270px'
                    name="ShortTermSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUsePrivateRoomShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUsePrivateRoomShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Private room"
                    maxWidth='270px'
                    name="ShortTermPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSemiPrivateRoomShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSemiPrivateRoomShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Semi-private room"
                    maxWidth='270px'
                    name="ShortTermSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUsePrivateSuiteShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUsePrivateSuiteShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Private suite"
                    maxWidth='270px'
                    name="ShortTermPrivateSuiteCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="ShortTermRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="ShortTermRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </div>
    );
};

export { ShortTermCareCostTab };
