import React from 'react';
import { ProfileScoreType } from '../../interfaces/property';
import ProfileCompletenessDial from './profile_completeness_dial';
import ProfileCompletenessVideoFilter from './profile_completeness_video_filter';
import ProfileCompletenessSingle from '../../components/profile_completeness/profile_completeness_single';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    handleProfileCompletenessFilter: any;
    handleNoVideoFilter: any;
    showWithNoVideo: boolean;
    profileCompletenessFilterValue: boolean | null;
}

const ProfileCompletenessVideoHeader = ({ 
    selNHID, 
    nhIDs, 
    orgNHID,
    handleProfileCompletenessFilter,
    handleNoVideoFilter,
    showWithNoVideo,
    profileCompletenessFilterValue
}: IProps) => {

    const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
    const orgNHIDSelected =  orgNHID && orgNHID === singleNHID;

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const orgDetails = sessionState?.Org;
    const websiteURL = sessionState?.Org?.WebsiteURL;

    return (
        <>
             {
                singleNHID ? orgNHIDSelected ? 
                    <>
                        <div className="card" data-card="Media Scroller Filter">
                            <div className="card-header">
                                    {
                                        orgDetails ?
                                        <h4>
                                            {orgDetails.Name}
                                            {
                                                websiteURL ?
                                                <>
                                                    <a
                                                        className="small text-link text-nowrap"
                                                        href={`${websiteURL}/provider/${orgDetails.Slug}`}
                                                        target="_blank"
                                                    >
                                                        view on website
                                                    </a>
                                                </> : null
                                            }    
                                        </h4>
                                        : null
                                    }
                            </div>
                        </div>                   
                    </> : <>
                    
                        <ProfileCompletenessSingle
                            NHID={singleNHID}
                            profileScoreType={ProfileScoreType.Video}
                        /> 
                    </>
                    :
                    <div className="alert flex-column flex-md-row alert-primary d-flex p-4 p-xl-6 m-0 mb-4 justify-content-between">
                        <ProfileCompletenessDial
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.Video}
                        />
                        <ProfileCompletenessVideoFilter
                            handleProfileCompletenessFilter={handleProfileCompletenessFilter}
                            showWithNoVideo={showWithNoVideo}
                            handleNoVideoFilter={handleNoVideoFilter}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                        />
                    </div>
            }
        </>
    );
};

export default ProfileCompletenessVideoHeader;
