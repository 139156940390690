import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableStringToNumberCost,
    yupNullableStringToNumberLT1000,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    RespiteSharedCostPerDay: yupNullableStringToNumberCost,
    RespitePrivateCostPerDay: yupNullableStringToNumberCost,
    RespiteSemiPrivateCostPerDay: yupNullableStringToNumberCost,
    RespiteMinStayRequirement: yupNullableStringToBool,
    RespiteMinStay: yupNullableStringToNumberLT1000,
    RespiteRatesAllInclusive: yupNullableStringToBool,
    RespiteRatePackagesAvailable: yupNullableStringToBool,
});

export default schema;