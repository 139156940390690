import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleQuestion,
    faUser,
    faRightFromBracket,
    faPhoneFlip,
    faEnvelope
} from '@fortawesome/pro-light-svg-icons';
import HeaderSessionText from '../../components/common/header_session_text';
import { logout } from '../../modules/login';
import HeaderContactInfo from '../common/header_contact_info';

import { secureHttp } from '../../utils/request';
import { ENV } from '../../config/keys';
import _ from 'lodash';
import { Modal } from '../common/modal';
import { useSessionState } from '../../hooks/use_session_state';

const Header = () => {
    const [ showInfoModal, setShowInfoModal ] = useState<boolean>(false);
    const [ showAccountManagerModal, setShowAccountManagerModal] = useState<boolean>(false);
    const sessionState = useSessionState();

    const navigate = useNavigate();

    const firstName = _.get(sessionState, 'User.FirstName', '');
    const lastName = _.get(sessionState, 'User.LastName', '');

    const {AccountManager} = sessionState;

    const accountManagerProfileImage = <img
        src={sessionState.AccountManager?.PhotoURL || '/images/profile-image-placeholder.png'}
        alt={`Profile image for ${sessionState.AccountManager?.Name}`} 
    />;
    
    const modalContent = <>
        <div className="app-header-account-manager-modal">
            <div>
                <h2>
                {AccountManager?.Name}
                </h2>
                <p>
                    Any questions, please contact your Account Manager, <strong>{AccountManager?.Name}</strong>.
                </p>
                <ul>
                    { 
                        AccountManager?.PhoneNumber && <li>
                            <FontAwesomeIcon icon={faPhoneFlip} />
                            <a href={`tel:${AccountManager.PhoneNumber}`}>{AccountManager.PhoneNumber}</a>
                        </li>
                    }
                    { AccountManager?.Email && <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <a href={`mailto:${AccountManager.Email}`}>
                            {AccountManager.Email}
                        </a>
                    </li>
                    }
                </ul>            
            </div>
            <span>
                {accountManagerProfileImage}
            </span>
        </div>
    </>;

    return (
        <div className="app-header d-flex order-lg-1">
            <Modal
                isOpen={showAccountManagerModal}
                onClose={() => setShowAccountManagerModal(false)}
                title={'Your account manager'}
                showFooter={false}
            >
                {modalContent}
            </Modal>
            <div className="app-header-bottom">

                <div className="app-header-logo d-none d-lg-flex align-items-center">
                    <a href="/" className="d-flex flex-column align-items-center text-secondary">
                        <img src="/logo.svg" className="logo-large" alt="Portal" />
                        <img src="/logo_bird.svg" className="logo-small" alt="Portal" />
                        <small>Portal</small>
                    </a>
                </div>

                <div className="app-header-details order-3 order-lg-1">
                    <button style={{all: 'unset'}} onClick={() => setShowAccountManagerModal(true)} className='cursor-pointer'>
                        <div className="app-header-details-manager">
                            <span>
                                {accountManagerProfileImage}
                            </span>
                            <div>
                                <span>
                                    Contact your <br/>account manager
                                </span>
                                <strong>
                                    { sessionState.AccountManager?.Name }
                                </strong>
                            </div>
                        </div>
                    </button>
                    <div className="app-header-details-name">
                        <HeaderSessionText/>
                    </div>
                    <div className="app-header-details-banner">
                        {/*<div>
                            <span>
                                A Complete Profile
                            </span>
                            <div className="profile_completeness_single_chart">
                                <svg viewBox="0 0 36 36" className="profile_completeness_circular_chart green">
                                    <path className="profile_completeness_circle_bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                    <path className="profile_completeness_circle" strokeDasharray="100,100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                    <text x="18" y="11.2" className="profile_completeness_percentage-before">Profile</text>
                                    <text x="18" y="22" className="profile_completeness_percentage">100<tspan>%</tspan></text>
                                    <text x="18" y="27" className="profile_completeness_percentage-after">Complete</text>
                                </svg>
                            </div>
                            <FontAwesomeIcon icon={faPlus} />
                            <span>
                                A High Review Score
                            </span>
                            <div className="review-score__score">
                                10
                            </div>
                            <FontAwesomeIcon icon={faEquals} />
                            <span>
                                <FontAwesomeIcon icon={faFilter} />
                                <strong>More Leads</strong>
                            </span>
                        </div>*/}
                    </div>
                </div>

                <div className="app-header-info order-1 order-lg-2">
                    <div className="app-header-info-help">
                        {/*<div className="permission-label__reviews fs-6 me-2"> Reply to Reviews <span>3</span></div>
                        <div className="permission-label__notifications fs-6 me-2"> Review Notifications <span>6</span></div>*/}
                        {
                            ENV !== 'production' ?
                            <a 
                                href='#'
                                onClick={() => {
                                    secureHttp.get('/auth/expireToken');
                                }}
                            >
                                Expire JWT
                            </a> : null
                        }
                        {/*<button className="btn btn-link text-body fw-bold" onClick={() => setShowInfoModal(true)}>
                            <FontAwesomeIcon icon={faCircleQuestion} className="text-primary me-2"/> Help
                        </button>*/}
                    </div>
                    <div className="app-header-info-account"> 
                        <FontAwesomeIcon icon={faUser} />
                        <strong>
                            {firstName} {lastName}
                        </strong>
                        <a 
                            href='#'
                            onClick={() => {
                                logout();      
                                navigate(0);     
                            }}
                        >
                            <FontAwesomeIcon icon={faRightFromBracket} />
                        </a>       
                    </div>
                </div>
            </div>

            <HeaderContactInfo
                show={showInfoModal}
                handleClose={() => setShowInfoModal(false)}
            />

        </div>
    );
};

export default Header;
