import React, { useEffect, useState } from 'react';

import { faSlashForward } from '@fortawesome/pro-light-svg-icons';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getSingleProperty } from '../../utils/common';
import { getPropertyCountTotals, getReviewScoreForNHID } from '../../api/review';
import { 
    IPropertyCountTotalsFilters,
    IPropertyCountTotals,
    IReviewScore,
    IReviewScoreFilterState
} from '../../interfaces/review';
import { formatFromDateForAPIRequestUTC } from '../../utils/date';
import { PropertyMode } from '../../interfaces/session';

import { get } from 'lodash';
import ReviewScoreDisplayFormatted from '../common/review_score_display_formatted';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    propertyMode: PropertyMode,
    widgetSize: 'small' | 'large';
}

const ReviewScoreWidget = ({  
    nhIDs, 
    orgNHID, 
    selNHID, 
    setIsLoading, 
    propertyMode,
    widgetSize
}: IProps) => {
    const { Properties, Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const [ propertyCountData, setPropertyCountData ] = useState<IPropertyCountTotals>(null);
    const [ reviewScoreDisplay, setReviewScoreDisplay ] = useState<number>();
    const [ reviewScoreFull, setReviewScoreFull ] = useState<number>(null);

    const { SessionType } = Session;
    const multiMode = [PropertyMode.MultiProperty, PropertyMode.Org].includes(propertyMode);
    const singleProperty = getSingleProperty(SessionType, selNHID, Properties);
    const singlePropertyName = singleProperty ? singleProperty.Name : '';

    const fetchReviewScore = async (): Promise<IReviewScore> => {
        const firstProperty: any = get(Properties, '[0]');

        return propertyMode === PropertyMode.SingleProperty 
            ? getReviewScoreForNHID(selNHID || nhIDs[0]) 
            : firstProperty 
                ? getReviewScoreForNHID(firstProperty.OrgNHID) 
                : null;
    };

    useEffect(() => {        
        if (!orgNHID && !nhIDs) {
            return;
        }

        const fetchData = async (): Promise<void> => {    
            try {

                const dateNow = new Date();
                dateNow.setHours(0, 0, 0, 0);
                const todayUTC = formatFromDateForAPIRequestUTC(dateNow);
                const filters: IPropertyCountTotalsFilters = {
                    NHID: selNHID,
                    TodaysDateUTC: todayUTC
                };

                const [data, rsData]: [IPropertyCountTotals, any] = 
                    await Promise.all([
                        getPropertyCountTotals(filters),
                        fetchReviewScore()
                    ]);
                
                const { ReviewScoreDisplay, ReviewScoreFull } = rsData || {};

                setPropertyCountData(data);
                setReviewScoreDisplay(ReviewScoreDisplay);
                setReviewScoreFull(ReviewScoreFull);
                
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading && setIsLoading(false);
            }
        };

       fetchData();   
    }, [ orgNHID, nhIDs, selNHID ]);

    const renderHeader = () => {
        switch(propertyMode) {
            case PropertyMode.MultiProperty:
            case PropertyMode.Org:
                return renderMultiHeader();
                break;
            case PropertyMode.SingleProperty:
                return renderSingleHeader();
                break;
            default:
                return '';
        }
    };

    const renderMultiHeader = () => {
        const orgName: string = get(Properties, '[0].OrgName', '');
        return widgetSize === 'large' ?
            <h4>
                Provider Review Score for {orgName}
            </h4> 
            : 
            <>
                <h4>
                    Your Provider Review Score
                </h4>
                <ReviewScoreDisplayFormatted 
                    value={reviewScoreDisplay}
                    showAsNotApplicableOnNull={true}
                />
            </>;
    };

    const renderSingleHeader = () => {

        return widgetSize === 'large' ?
        <h4>
            Review Score for {singlePropertyName}
        </h4> 
        : 
        <>
            <h4>
                Your Review Score
            </h4>
            <ReviewScoreDisplayFormatted 
                value={reviewScoreDisplay}
                showAsNotApplicableOnNull={true}
            />
        </>;
    };

    const renderStats = () => {

        if (!reviewScoreFull) {
            return null;
        }

        switch(propertyMode) {
            case PropertyMode.MultiProperty:
            case PropertyMode.Org:
                return renderMultiStats();
                break;
            case PropertyMode.SingleProperty:
                return renderSingleStats();
                break;
            default:
                return null;
        }
    };

    const renderSingleStats = () => {

        const { 
            TotalCount, 
            TotalLessThan15PosReviewsIn24Mnths, 
        } = propertyCountData || {};

        const singlePropertyNHID = singleProperty?.NHID;

        const arr: [string, string, number, number, string, any][] = [
            [
                'Do you have less than the required number of positive reviews?', 
                '(click \'View breakdown\' to see the required number of positive reviews for maximum points)',
                TotalLessThan15PosReviewsIn24Mnths, TotalCount, 'score-card-2',
                { NHID: singlePropertyNHID}
            ]
        ];

        return arr.map((item: [string, string, number, number, string, any]) => (
            renderScoreCard(...item)));
    };

    const renderMultiStats = () => {
        const { 
            TotalCount, 
            TotalLessThan15PosReviewsIn24Mnths, 
            TotalNoReviewScore
        } = propertyCountData || {};
       
        const arr: [string, string, number, number, string, IReviewScoreFilterState][] = [
            [
                'Facilities with No Review Score', 
                '(as no valid reviews in the last 24 months)',
                TotalNoReviewScore, TotalCount, 'score-card-1',
                { NoReviewScore: true },
            ],
            [
                'Facilities with less than the required number of positive reviews', 
                '(click \'View Facilities\' to see the required number of positive reviews for maximum points)',
                TotalLessThan15PosReviewsIn24Mnths, TotalCount, 'score-card-3',
                { BelowReviewScorePositiveReviewCountThreshold: true }
            ]
        ];

        return arr.map((item: [string, string, number, number, string, IReviewScoreFilterState]) => (
            renderScoreCard(...item)));
    };

    const renderScoreCard = (hdr: string, detail: string, num: number, denom: number, key: string, 
        filters: IReviewScoreFilterState) => {

        const renderScoreCardTxt = () => {

            if (!reviewScoreDisplay) {
                return 'N/A';
            }

            const renderSinglePropertyText = (num: number) => (
                <>
                     { num ? <><em className="pulse">Yes</em></> : <><FontAwesomeIcon icon={faThumbsUp} className="me-3" />No</> }
                </>
            );

            const renderMutiPropertyText = (num: number, denom: number) => (
                <>
                    {
                     num ? <><em className="pulse">{num}</em> <span><FontAwesomeIcon icon={faSlashForward} />{denom}</span></> :
                         <><FontAwesomeIcon icon={faThumbsUp} className="me-3" />{num}<span><FontAwesomeIcon icon={faSlashForward} />{denom}</span></>
                    }
                </>
            );

            if (!denom) {
                return null;
            }

            return (
                <>
                    {
                        denom > 1 ? renderMutiPropertyText(num, denom) 
                            : renderSinglePropertyText(num)
                    }
                </>
            );
        };

        return (
            <>
                { widgetSize === 'large' ? 
                    <div key={key}>
                        { 
                            multiMode? 
                            <NavLink className="btn btn-primary text-nowrap"
                                to={'/review-score'}
                                state={{ filters }}
                            >
                                View Facilities
                            </NavLink> : null
                        }
                        <span className="card-stat text-nowrap">
                            { renderScoreCardTxt() }
                        </span>
                        <div>
                            <h4>{ hdr }</h4>
                            <small>{ detail }</small>
                        </div>
                    </div>
                :
                    <div className="provider-review-score-card" key={key}>
                        <span className="card-stat">
                            { renderScoreCardTxt() }
                        </span>
                        <small>{ hdr } <strong><NavLink to={'/review-score'} state={{ filters }}>View</NavLink></strong></small> 
                    </div>
                }
            </> 
        );
    };

    const inviteToReviewLink = (nhID: number) => (
        <NavLink to={'/invite-to-review'} state={{ NHID: nhID }}>
                <strong>Invite to Review</strong></NavLink>
    );

    const reviewCardLink = (nhID: number) => (
        <NavLink to={'/review-cards'} state={{ NHID: nhID }}><strong>Review Cards</strong></NavLink>
    );

    const reviewScoreDisplayText = () => (
        <ReviewScoreDisplayFormatted value={reviewScoreDisplay} showAsNotApplicableOnNull={true} showAsText={true} />
    );

    const buildMultiBullets = () => {

        const nhID = singleProperty && singleProperty.NHID;

        return [
            <p key={'multibullet-1'}>Your Provider Review Score is made up of the average of all your facility Review Scores.</p>,
            <p key={'multibullet-2'}>Each facility&apos;s Review Score is calculated using a combination of the Quantity of Reviews, and 
                the Average Rating of Reviews, using only Reviews published in the last 24 months.</p>,
            <p key={'multibullet-3'}>Your current Provider Review Score is { reviewScoreDisplayText() } (out of a maximum 10).</p>,
            <p key={'multibullet-4'}>To improve your Review Score, request new reviews using {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)}</p>
        ];
    };

    const buildSingleBullets = () => {

        const nhID = singleProperty && singleProperty.NHID;

        return [
            <p key={'singlebullet-1'}>Your Review Score is calculated using a combination of the Quantity of Reviews
            and the Average Rating of Reviews, using only Reviews published in the last 24 months.</p>,
            <p key={'singlebullet-2'}>Your current Review Score is { reviewScoreDisplayText() } (out of a maximum 10).</p>,
            <p key={'singlebullet-3'}>To improve your Review Score, request new reviews using {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)}</p>
        ];
    };

    const buildMultiBulletsNoReviewScore = () => {

        const nhID = singleProperty && singleProperty.NHID;
        const orgName: string = get(Properties, '[0].OrgName', '');

        return [
            <p key={'multibulletnoreviewscore-1'}>Your Provider Review Score is made up of the average of all your facility Review Scores.</p>,
            <p key={'multibulletnoreviewscore-2'}>Each facility&apos;s Review Score is calculated using a combination of the Quantity of Reviews, and 
                the Average Rating of Reviews, using only Reviews published in the last 24 months.</p>,
            <p key={'multibulletnoreviewscore-3'}>There is currently no Review Score for {orgName} as none of its facilities have a Review Score. Facilities require 1+ qualifying reviews in the last 24 months to obtain a Review Score. </p>,
            <p key={'multibulletnoreviewscore-4'}>We recommend generating some new reviews using {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)} in order to reap the benefits of having Review Scores for all of your facilities.</p>
        ];
    };

    const buildSingleBulletsNoReviewScore = () => {

        const nhID = singleProperty && singleProperty.NHID;

        return [
            <p key={'singlebulletnoreviewscore-1'}>Your Review Score is calculated using a combination of the Quantity of Reviews
                and the Average Rating of Reviews, using only Reviews published in the last 24 months.</p>,
            <p key={'singlebulletnoreviewscore-2'}>There is currently no Review Score for {singleProperty.Name} as it has no qualifying reviews in the last 24 months. </p>,
            <p key={'singlebulletnoreviewscore-3'}>We recommend generating some new reviews {inviteToReviewLink(nhID)} or {reviewCardLink(nhID)} in order to reap the benefits of having a Review Score.</p>
        ];
    };

    const buildMultiBulletsNoReviewScoreSmallView = () => buildMultiBulletsNoReviewScore().splice(2, 2);
    const buildSingleBulletsNoReviewScoreSmallView = () => buildSingleBulletsNoReviewScore().splice(1, 2);

    const buildBullets = () => {
        switch(propertyMode) {
            case PropertyMode.MultiProperty:
            case PropertyMode.Org:
                return reviewScoreFull ? buildMultiBullets() : buildMultiBulletsNoReviewScore();
            case PropertyMode.SingleProperty:
                return reviewScoreFull ? buildSingleBullets() : buildSingleBulletsNoReviewScore();
            default:
                return [];
        }
    };

    const buildBulletsSmallView = () => {

        switch(propertyMode) {
            case PropertyMode.MultiProperty:
            case PropertyMode.Org:
                return reviewScoreFull ? null : buildMultiBulletsNoReviewScoreSmallView();
            case PropertyMode.SingleProperty:
                return reviewScoreFull ? null : buildSingleBulletsNoReviewScoreSmallView();
            default:
                return [];
        }
    };

    return (
        <>
        { widgetSize === 'large' ?
            <div className="card" data-card="Provider Review Score">
                <div className="card-header">
                    {renderHeader()}
                    <div>
                        <NavLink className="btn btn-primary text-nowrap"
                            to={'/review-score'}
                            state={ singleProperty ? { filters: { NHID: singleProperty.NHID} } : null }
                        >

                            { multiMode ? 'View all Scores' : 'View breakdown' }

                        </NavLink>
                    </div>
                </div>
                <div className="card-body">
                    <div className="provider-review-score">
                        <ReviewScoreDisplayFormatted 
                            value={reviewScoreDisplay}
                            showAsNotApplicableOnNull={true}
                        />
                        <ul>
                            {
                                buildBullets().map((bullet: any, index: number) => (
                                    <li key={`bullet-${index}`}>
                                        {bullet}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="provider-review-score-cards">
                        { renderStats() }
                    </div>
                </div>
            </div>
            :
            <div className="card" data-card="Provider Review Score">
                <div className="card-header">
                    {renderHeader()}
                </div> 
                <div className="card-body">
                    {                        
                        !reviewScoreFull ? 
                        <ul>
                            {
                                buildBulletsSmallView().map((bullet: any, index: number) => (
                                    <li key={`bullet-${index}`}>
                                        {bullet}
                                    </li>
                                ))
                            }
                        </ul> : null
                    }
                    { renderStats() }
                </div>
                <div className="card-footer">
                    <NavLink className="btn btn-primary"
                        to={'/review-score'}
                    >
                        { multiMode ? 'View all Scores' : 'View breakdown' }
                    </NavLink>
                </div>
            </div>
        }
        </>
    );
};

export default ReviewScoreWidget;