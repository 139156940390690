import React, { useState, useEffect } from 'react';
import ReactDataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { ExpandableIcon } from 'react-data-table-component/dist/DataTable/types';
import { LoadingSpinner } from '../../pages/property_details/property_team/components';


export const INITIAL_ROWS_PER_PAGE = 100;

export enum DataTableSortOrder {
    Ascending = 'ASC',
    Descending = 'DESC'
}

export interface IDataTableOptions {
    Offset?: number;
    Limit?: number;
    SortField?: string;
    SortOrder?: DataTableSortOrder.Ascending | DataTableSortOrder.Descending;
}

interface onChangePage {
    (pageNumber: number): void;
}

interface onChangeRowsPerPage {
    (newPerPage: number, pageNumber: number): void;
}

interface onSort {
    (column: TableColumn<any>, sortOrder: SortOrder): void;
}

interface IProps {
    keyField?: string;
    columns: any[];
    data: any[];
    pagination?: boolean;
    paginationPerPage?: number;
    useTotalsRow?: boolean;
    isLoading?: boolean;
    paginationServer?: boolean;
    paginationTotalRows?: number;
    onChangePage?: onChangePage;
    onChangeRowsPerPage?: onChangeRowsPerPage;
    sortServer?: boolean;
    onSort?: onSort;
    conditionalRowStyles?: any[];
    disabled?: boolean;
    defaultSortFieldId?: number;
    expandableRows?: boolean;
    expandableRowsComponent?: any;
    expandableRowsComponentProps?: any;
    expandableRowDisabled?: any;
    expandableRowExpanded?: any;
    expandableIcon?: ExpandableIcon;
    paginationResetDefaultPage?: boolean;
    initialRowsPerPage?: number;
    noRowsPerPage?: boolean;
    fixedHeader?: boolean;
    fixedHeaderScrollHeight?: string;
    noDataComponent?: any;
    defaultExpanded?: boolean;
    defaultExpandedIDProp?: string;
}

interface IPaginationOptions {
    noRowsPerPage?: boolean;
    rowsPerPageText?: string;
    rangeSeparatorText?: string;
    selectAllRowsItem?: boolean;
    selectAllRowsItemText?: string;
}

const DataTable = ({
    keyField,
    columns,
    data = [],
    isLoading = false,
    pagination,
    paginationServer,
    paginationTotalRows = 0,
    onChangePage,
    onChangeRowsPerPage,
    sortServer,
    conditionalRowStyles,
    onSort,
    disabled,
    defaultSortFieldId,
    useTotalsRow = false,
    expandableRows,
    expandableRowsComponent,
    expandableRowsComponentProps,
    expandableRowDisabled,
    expandableRowExpanded,
    expandableIcon,
    paginationResetDefaultPage,
    initialRowsPerPage = INITIAL_ROWS_PER_PAGE,
    noRowsPerPage,
    fixedHeader,
    fixedHeaderScrollHeight,
    noDataComponent: NoDataComponent,
    defaultExpanded,
    defaultExpandedIDProp
}: IProps) => {


    const paginationRowsPerPageOptions = [10, 20, 50, 100];

    const [ allExpanded, setAllExpanded ] = useState<boolean>(defaultExpanded);
    const [ expandItem, setExpandItem ] = useState<any>({});

    useEffect(() => {

        if (!defaultExpandedIDProp) {
            return;
        }

        const item: any = {};

        if (allExpanded) {
            (data || []).forEach((row: any) => item[row[defaultExpandedIDProp]] = 1);
        }

        setExpandItem(item);

    },[data]);

    /**
     * This totals row sort function keeps the 'TotalsRow' row 
     * first in the table on client side sort, all other rows sort 
     * as usual.
     **/ 
    const sortFn = useTotalsRow ? (
        rows: any[],
        selector: (row: any) => any,
        direction: 'asc' | 'desc'
    ) => {
        return rows.sort((rowA: any, rowB: any) => {
            if (rowA.TotalsRow === 1) return -1;
            if (rowB.TotalsRow === 1) return 1;

            const aField = selector(rowA);
            const bField = selector(rowB);

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    } : null;

    const paginationOptions: IPaginationOptions = {};

    if (noRowsPerPage) {
        paginationOptions.noRowsPerPage = noRowsPerPage;
    }

    if (expandableRows && !expandableRowsComponent) {
        throw new Error('If expandableRows is set then an expandableRowsComponent value must be provided');
    }

    const handleExpandableRowExpanded = (row: any) => {
        return expandItem[row[defaultExpandedIDProp]] === 1;
    };

    const handleRowExpandToggled=(row: any) => {

        if (expandItem[row[defaultExpandedIDProp]] === 1) {
            delete expandItem[row[defaultExpandedIDProp]];
        } else {
            expandItem[row[defaultExpandedIDProp]] = 1;
        }
    };

    const handleSetAllExpanded=() => {

        if (allExpanded) {
            setExpandItem({});
        } else {
            const item: any = {};
            data.forEach((row: any) => item[row[defaultExpandedIDProp]] = 1);
            setExpandItem(item);
        }

        setAllExpanded(!allExpanded);
    };

    const loadingComponent = <div style={{height: '300px'}}>
        <LoadingSpinner />
    </div>;

    return (
    <>
        {
            defaultExpandedIDProp && (data || {}).length?
            <button 
                className="expand-btn btn-icon btn-sm btn-light ml-1"
                type="button"
                onClick={() => handleSetAllExpanded()}>
                { 
                    allExpanded ? 

                    <FontAwesomeIcon
                        icon={faMinus}
                        color={'#000000'}
                        size="lg"
                    />
                    : 
                    <FontAwesomeIcon
                        icon={faPlus}
                        color={'#000000'}
                        size="lg"
                    />
                }
            </button> : null      
        }

        <ReactDataTable
            columns={columns}
            conditionalRowStyles={conditionalRowStyles}
            data={data || []}
            defaultSortFieldId={defaultSortFieldId}
            disabled={disabled}
            expandableIcon={expandableIcon}
            expandableRowDisabled={expandableRowDisabled}
            expandableRows={expandableRows}
            expandableRowsComponent={expandableRowsComponent}
            expandableRowsComponentProps={expandableRowsComponentProps}
            expandableRowExpanded={ 
                defaultExpandedIDProp ? 
                    (row: any) => handleExpandableRowExpanded(row) 
                    : expandableRowExpanded || undefined
            }
            expandOnRowClicked={expandableRows}
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight={fixedHeaderScrollHeight}
            keyField={keyField}
            noDataComponent={NoDataComponent}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onRowExpandToggled={
                defaultExpandedIDProp ? 
                (bool, row) => handleRowExpandToggled(row) 
                : undefined
            }
            onSort={onSort}
            pagination={pagination}
            paginationComponentOptions ={paginationOptions}
            paginationPerPage={initialRowsPerPage}
            paginationResetDefaultPage={paginationResetDefaultPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationServer={paginationServer}
            paginationTotalRows={paginationTotalRows}
            progressPending={isLoading}
            progressComponent={loadingComponent}
            sortFunction={sortFn} 
            sortServer={sortServer}
        />
    </>
    );
};

export default DataTable;
