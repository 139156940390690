import React from 'react';
import { ProfileScoreType } from '../../interfaces/property';
import ProfileCompletenessDial from './profile_completeness_dial';
import ProfileCompletenessPhotoFilter from '../../components/profile_completeness/profile_completeness_photo_filter';
import ProfileCompletenessSingle from '../../components/profile_completeness/profile_completeness_single';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    profileScoreType: ProfileScoreType;
    handleProfileCompletenessFilter: any;
    handlePhotoNotPublishedFilter: any;
    nonPublished: boolean;
    profileCompletenessFilterValue: boolean | null;
}

const ProfileCompletenessPhotoHeader = ({ 
    selNHID, 
    nhIDs, 
    orgNHID,
    profileScoreType, 
    handleProfileCompletenessFilter,
    handlePhotoNotPublishedFilter,
    nonPublished,
    profileCompletenessFilterValue
}: IProps) => {

    const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);

    return (
        <>
            {
                singleNHID ?  
                    <ProfileCompletenessSingle
                        NHID={singleNHID}
                        profileScoreType={profileScoreType}
                    /> 
                :
                <div className="alert flex-column flex-md-row alert-primary d-flex p-4 p-xl-6 m-0 mb-4 justify-content-between">
                    <ProfileCompletenessDial
                        selNHID={selNHID}
                        nhIDs={nhIDs}
                        orgNHID={orgNHID}
                        profileScoreType={profileScoreType}
                    />
                    <ProfileCompletenessPhotoFilter
                        handleProfileCompletenessFilter={handleProfileCompletenessFilter}
                        handlePhotoNotPublishedFilter={handlePhotoNotPublishedFilter}
                        nonPublished={nonPublished}
                        profileCompletenessFilterValue={profileCompletenessFilterValue}
                    />
                </div>
            }

        </>
    );
};

export default ProfileCompletenessPhotoHeader;
