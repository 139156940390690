import { CareOfferedTab } from './sections/care_offered_tab';
import { PediatricResidentsTab } from './sections/pediatric_residents_tab';
import { RoomTypeTab } from './sections/room_type_tab';
import { ITab } from '../../../../hooks/use_create_tabs';

const coreDetailsTabSections: ITab[] = [
    {
        tabHeader: 'Care Offered',
        tabKey: 'care-offered',
        tabContent: CareOfferedTab
    },
    {
        tabHeader: 'Pediatric Residents',
        tabKey: 'pediatric-residents',
        tabContent: PediatricResidentsTab
    },
    {
        tabHeader: 'Room Type',
        tabKey: 'room-type',
        tabContent: RoomTypeTab
    }
];

export { coreDetailsTabSections };
