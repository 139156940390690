import React from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { IMenuItem, IMenuState, IMenuStateTotals } from '../../interfaces/menu';
import { ISessionState } from '../../interfaces/session';
import { getLinkTotal } from '../../utils/menu';
import { isDefined } from '../../utils/common';
import { get } from 'lodash';
import { findMenuItem } from './common';

interface IProps {
    menuPath?: string;
}

const MobileSelectMenu = ({ menuPath }: IProps) => {
    const navigate = useNavigate();

    const { Menu }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const menuTotals: IMenuStateTotals = useSelector(
        ({ MenuTotalsState }: any) => MenuTotalsState
    );
    
    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const currentPage: IMenuItem = findMenuItem(Menu, menuPath, location.pathname);
    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);

    if (!currentPage || (currentPage.IsTestFeature && !canViewTestFeatures)) {
        return;
    }

    const buildMobileSelectOptions = () => {
        const options: any[] = [];

        const addOption = (item: IMenuItem): any => {
            const option: any = { item, label: item.MenuText};

            if (item.IsHeader) {
                option.isDisabled = true;
            } else {
                option.value = item.Route;
            }

            return options.push(option);
        };

        const buildChildOptions = (item: IMenuItem): void => {
            if (!item.Children) {
                return;
            }

            item.Children.forEach((item: IMenuItem) => {
                addOption(item);
    
                if (item.IsHeader) {
                    buildChildOptions(item); 
                }  
            });
        };

        Menu?.forEach((item: IMenuItem) => {
            addOption(item);
            buildChildOptions(item);
        });

        return options;
    };

    const mobileSelectOptions = buildMobileSelectOptions(); 

    const formatOptionLabel = (option: any) => {
        const { IsHeader, ParentPageID, Slug } = option.item;

        const headerClass = IsHeader
            ? ParentPageID ? 'option-sub-header'  : 'option-header'
            : '';

        const linkTotal = getLinkTotal(menuTotals, Slug);

        return (
            <div className={headerClass}>{option.label} { isDefined(linkTotal) ? `(${linkTotal})` : null }</div>      
        );
    };

    const currentOption = mobileSelectOptions.find((option: any) => option.value === currentPage.Route);

    const renderMobileSelectMenu = () => {
        return (
            <>
                <Select
                    id="MobileSelectMenu"
                    options={mobileSelectOptions}
                    name="MobileSelectMenu"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    formatOptionLabel={formatOptionLabel}
                    onChange={(option: any) => handlePageOption(option.value)}
                    defaultValue={currentOption}
                />
            </>
        );
    };
    
    const handlePageOption = (route: string) => {
        navigate(route);
    };

    return (
        <>
            { renderMobileSelectMenu () }
        </>
    );
};

export default MobileSelectMenu;
 