import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn, Controller } from 'react-hook-form';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string;
    inline?: boolean;
    description?: ReactNode;
    labelStyle?: 'normal' | 'bold';
    inputAction?: ReactNode;
    suffix?: ReactNode;
    prefix?: string;
    allowedPattern?: string;
    showErrorStyle?: boolean;
    info?: ReactNode;
    inlineLabelWidth?: 'small' | 'large';
    maxWidth?: string;
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const FormInput: FC<Props> = (props) => {
    const {
        name,
        id,
        label,
        className,
        inline = true,
        description,
        suffix,
        prefix,
        labelStyle = 'normal',
        inlineLabelWidth = 'small',
        info,
        inputAction,
        maxWidth,
        showErrorStyle = false,
        allowedPattern,
        style = {},
        onChange,
        ...rest
    } = props;

    const inlineLabelWidths = {
        small: 150,
        large: 250
    };

    return (
        <ConnectForm>
            {({
                formState,
                control,
                isRequired
            }: UseFormReturn & FieldRequired) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute">
                        {fieldError?.message}
                    </small>
                );

                const inputClassName: string = cx('form-control', {
                    'border border-danger': fieldError || showErrorStyle
                });

                const inputId: string = id || name;

                const inputWrapperClassName = cx(
                    'd-flex flex-column input-group',
                    {
                        'mt-3': label,
                        'flex-sm-row justify-items-center': inline
                    }
                );

                const labelClassName = cx('fs-md-5', {
                    'd-flex justify-content-center me-sm-5 flex-column': inline,
                    'fw-label': labelStyle === 'bold',
                    'mb-1': !inline
                });

                const inputGroupClassName = cx('input-group', className);

                const inputGroupStyle = {...style, ...(maxWidth ? { maxWidth } : {})};

                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {label && (
                            <div
                                className={cx(labelClassName)}
                                style={{
                                    width: inline
                                        ? `${inlineLabelWidths[inlineLabelWidth]}px`
                                        : '100%',
                                    maxWidth: '100%'
                                }}
                            >
                                <label htmlFor={inputId}>
                                    {label}
                                    {isRequired(name) && '*'}
                                </label>
                                {description && (
                                    <span className="fw-normal fst-italic fs-6 lh-sm text-muted">
                                        {description}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className={cx('position-relative flex-fill')}>
                            <div className="d-flex flex-row" style={style}>
                                <div className={inputGroupClassName} style={inputGroupStyle}>
                                    {prefix && (
                                        <span className="input-group-text">
                                            {prefix}
                                        </span>
                                    )}
                                    <Controller
                                        render={({ field }) => (
                                            <input
                                            id={inputId}
                                            className={inputClassName}
                                            {...rest}
                                            {...field}
                                            onKeyDown={(event: any) => {
                                                if (rest.type === 'number' && ['e'].includes(event.key)) {
                                                    event.preventDefault();
                                                }
                                                rest?.onKeyDown?.(event);
                                            }}
                                            onChange={(event: any) => {
                                                const regex = new RegExp(allowedPattern);
                                                if (!allowedPattern || regex.test(event.target.value) || event.target.value === '') {
                                                    field.onChange(event.target.value);
                                                    onChange?.(event);
                                                }
                                            }}
                                        />
                                        )}
                                        control={control}
                                        name={name}
                                    />
                                    {suffix && (
                                        <span className="input-group-prepend input-group-text">
                                            {suffix}
                                        </span>
                                    )}
                                </div>
                                { inputAction && (
                                    <span className='position-absolute top-0 bottom-0 d-flex align-items-center end-0 me-3 fs-6'>
                                        {inputAction}
                                    </span>
                                )}
                                {info && (
                                    <div className="d-flex align-items-center ms-2">
                                        {info}
                                    </div>
                                )}
                            </div>
                            {fieldError && errorMessage}
                        </div>
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormInput };
