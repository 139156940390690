import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';

import { LoadingSpinner } from '../../components/common/loading_spinner';
import PageCommunitySelect from '../../components/common/page_community_select';
import { MEDIA_CREATOR_BASE_URL } from '../../config/keys';
import { ISessionState } from '../../interfaces/session';
import { getSingleProperty } from '../../utils/common';
import * as reviewAPI from '../../api/review';
import PremiumLink from '../../components/common/premium_sign_up_link';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const defaultInviteLinkIDs = {
    inviteToReviewViaClientLinkID: 0,
    inviteToReviewViaClientEmailID: 0,
    inviteToReviewViaClientFacebookID: 0,
    inviteToReviewViaClientWhatsAppID: 0
};

const InviteToReview = ({nhIDs, orgNHID}: IProps) => {

    const [busyLoadingProperty, setBusyLoadingProperty] = useState<boolean>(false);
    const [property, setProperty] = useState<any>();
    const [selectedNHID, setSelectedNHID] = useState<number>(null);

    const [inviteLinkIDs, setInviteLinkIDs] = useState(defaultInviteLinkIDs);

    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const location = useLocation();
    const stateNHID = location?.state?.NHID || null;

    useEffect(() => {
        (async () => {
            const resp: any = await reviewAPI.getCollectionSources();
            const arr = resp?.data || [];

            const inviteToReviewViaClientLink = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-link');
            const inviteToReviewViaClientEmail = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-email');
            const inviteToReviewViaClientFacebook = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-facebook');
            const inviteToReviewViaClientWhatsApp = arr.find((item: any) => item.KeyName === 'invite-to-review-via-client-whatsapp');

            const inviteLinkIDs = {
                inviteToReviewViaClientLinkID: inviteToReviewViaClientLink?.CollectionSourceID || 0,
                inviteToReviewViaClientEmailID: inviteToReviewViaClientEmail?.CollectionSourceID || 0,
                inviteToReviewViaClientFacebookID: inviteToReviewViaClientFacebook?.CollectionSourceID || 0,
                inviteToReviewViaClientWhatsAppID: inviteToReviewViaClientWhatsApp?.CollectionSourceID || 0,
            };

            setInviteLinkIDs(inviteLinkIDs);
        })();
        
    }, []);

    useEffect(() => {

        setProperty(null);

        if (orgNHID && !stateNHID) {
            return;
        }

        const NHID = nhIDs?.length === 1 ? nhIDs[0] : stateNHID;

        if (NHID) {   
            setSelectedNHID(NHID);
            setSingleNHID();
        }
    }, [orgNHID, nhIDs]);

    useEffect(() => {
        setSingleNHID();
    }, [selectedNHID]);

    const setSingleNHID = () => {
        if (!selectedNHID) {
            return;
        }

        (async () => {
            setBusyLoadingProperty(true);
            try {

                const { SessionType } = Session;
                const property = getSingleProperty(SessionType, selectedNHID, Properties);

                if (property) {
                    setProperty(property);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingProperty(false);
            }
        })();     
    };

    const emailMessage = (): string => {
        return `Leave a review for ${property.Name} on www.nursinghomes.com`;
    };

    const facebookMessage = (): string => {
        return `Submit a review for ${property.Name}`;
    };

    const whatsappMessage = (): string => {
        return `${property.Name} would love your feedback. Please click the link to leave us a review`;
    };

    const onPropertySelection = (NHID: number | null) => {
        if (!NHID) {
            setProperty(null);
            setSelectedNHID(null);
            return;
        }
        setSelectedNHID(NHID);
    };

    const pageBody = (): JSX.Element => {

        const reviewSubmissionURL = property?.ReviewSubmissionURL || '';

        const generalReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientLinkID}`;
        const emailReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientEmailID}`;
        const facebookReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientFacebookID}`;
        const whatsappReviewSubmissionUrl = `${reviewSubmissionURL}/sourceid/${inviteLinkIDs.inviteToReviewViaClientWhatsAppID}`;

        return (
            <>
                {
                    orgNHID || nhIDs?.length > 1 ?
                        <span>
                            <label>
                                <strong>
                                    Select a facility for review:
                                </strong>
                            </label>
                            <PageCommunitySelect handleSelect={onPropertySelection} selNHID={selectedNHID}/>
                        </span>
                        : null
                }
                {
                    busyLoadingProperty ?
                        <LoadingSpinner show={true}/>
                        :
                        property ?
                            property.IsPremium ?
                                <div>
                                    <p>
                                        This link goes directly to your facility's review form on NursingHomes.com
                                    </p>
                                    <div>
                                        <p>
                                            <strong>Either</strong> copy the link:
                                        </p>
                                        <div>
                                            <input
                                                type="text"
                                                disabled={true}
                                                value={generalReviewSubmissionUrl}
                                            />
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => navigator.clipboard.writeText(generalReviewSubmissionUrl)}>
                                                    Copy
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <p>
                                            <strong>Or</strong> share the link via:
                                        </p>
                                        <div>
                                            <EmailShareButton
                                                url={emailReviewSubmissionUrl}
                                                title="Email"
                                                subject={emailMessage()}
                                            >
                                                <a href="#" className="btn btn-email" title="Email">
                                                    <i className="fa fa-envelope"></i>Email
                                                </a>
                                            </EmailShareButton>
                                            <FacebookShareButton
                                                url={facebookReviewSubmissionUrl}
                                                title={facebookMessage()}
                                            >
                                                <a href="#" className="btn btn-facebook" title="Facebook">
                                                    <i className="fa fa-facebook"></i>Facebook
                                                </a>
                                            </FacebookShareButton>
                                            <WhatsappShareButton
                                                url={whatsappReviewSubmissionUrl}
                                                title={whatsappMessage()}
                                            >
                                                <a className="btn btn-whatsapp" title="Whatsapp">
                                                    <i className="fa fa-whatsapp"></i>WhatsApp
                                                </a>
                                            </WhatsappShareButton>
                                        </div>
                                    </div>
                                </div>
                                : 
                                <div className="text-center mt-5">
                                    <p className="fw-bold">Invite to Review is only available for Premium Subscribers</p>
                                    <p><PremiumLink websiteUrl={property.WebsiteURL} /></p>
                                </div>
                            : null
                }
            </>
        );
    };

    return (
        <div className="widget">
            <div className="widget-body">
                <div className="card" data-card="Request Reviews">
                    <div className="card-header">
                        <h4>Request Reviews</h4>
                    </div>
                    <div className="card-body">
                        <div className="card-info-box">
                            <h5>
                                How to Request a Review
                            </h5>
                            <ol>
                                {
                                    orgNHID || nhIDs?.length > 1 ?
                                        <li>
                                            <p>
                                                Select which facility you want to be reviewed.
                                            </p>
                                        </li> : null
                                }

                                <li>
                                    <div>
                                        <p>
                                            { orgNHID || nhIDs?.length > 1 ? 'Then' : '' } <strong>Either</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                Copy and paste the invite link into a new email on your email client (e.g. Outlook or Gmail)
                                            </li>
                                            <li>
                                                Add some personal text above the link in the email, asking them to click the link to leave a review on NursingHomes.com
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p>
                                            <strong>Or</strong>
                                        </p>
                                        <ul>
                                            <li>
                                                Click Email, Facebook or WhatsApp 
                                            </li>
                                            <li>
                                                Add some personal text asking them to click the link to leave a review on NursingHomes.com
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <div className="card-info">
                            {pageBody()}
                            {
                                !property && (orgNHID || nhIDs?.length > 1) ?
                                    <a href={`${MEDIA_CREATOR_BASE_URL}/csvexport?${orgNHID ? `OrgNHID=${orgNHID}` : `NHID=${nhIDs.join()}`}`}>
                                        For multiple facilities please download a CSV of the facilities and their invite links
                                    </a> 
                                    : null
                            }                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default (InviteToReview);
