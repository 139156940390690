import React from 'react';
// import React, { useEffect, useState, useContext } from 'react';
// import { Modal } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import { getWinners } from '../../api/award';
// import * as csvEportAPI from '../../api/csv_export';
// import DataTable from '../../components/common/data_table';
// import AwardTableExpandableRows from '../../components/awards/award_table_expandable_rows';
// import PageCommunitySelect from '../../components/common/page_community_select';
// import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
// import { APFM_URL } from '../../config/keys';
// import { AwardKeyName, AwardType, IAwardWinnersData } from '../../interfaces/award';
// import { ISessionMember, ISessionState, SessionType as EnumSessionType } from '../../interfaces/session';
// import { formatFileNameForCsvExport } from '../../utils/file';
// import { formatWinnerCollateral, getAwardImageURLByKeyName } from '../../components/awards/awards_common';

// import AppContext from '../../context/app_context';

// interface IProps {
//     memberIDs: number[];
//     groupID: number;
// }

// const Awards = ({ memberIDs, groupID }: IProps) => {
    const Awards = () => {
    return (<></>);  

//     const appContext: any = useContext(AppContext);

//     const location = useLocation();
//     const state = location?.state as any;
//     const stateMemberID = state?.MemberID || null;

//     const [ selMemberID, setSelMemberID ] = useState<number>(stateMemberID);

//     const [ winners, setWinners ] = useState<IAwardWinnersData[]>();
//     const [ modalData, setModalData ] = useState<IAwardWinnersData>();
//     const [ showModal, setShowModal] = useState<boolean>(false);
//     const [ hasUserCopied, setHasUserCopied] = useState<boolean>(false);

//     const { Session, Group, Members }: ISessionState = useSelector(
//         ({ SessionState }: any) => SessionState
//     );
    
//     const { SessionType } = Session;

//     const handleSelect = (memberID: number | null) => {
//         setSelMemberID(memberID);
//     };

//     useEffect(() => {      
//         handleSelect(stateMemberID);
//     }, [ stateMemberID ]);

//     useEffect(() => {        

//         const fetchWinners = async (): Promise<void> => {    
//             try {

//                 const winners: IAwardWinnersData[] = await getWinners(selMemberID);
//                 setWinners(winners);
//             } catch (e) {
//                 console.error(e);
//             }
//         };

//         fetchWinners();

//     }, [ selMemberID ]);

   
//     const isSingleMember = (() => memberIDs?.length === 1)();

//     const formatAwardTitle = ({ AwardTitle, AwardYear}: IAwardWinnersData) => `${AwardTitle} ${AwardYear}`;

//     const formatWinner = ({ EntityName, Address }: IAwardWinnersData) => {
//         return (
//             <div className="py-3">
//                 <span className="fw-bold">{ EntityName }</span>{ Address ? `, ${Address}` : '' }
//             </div>
//         );
//     };

//     const columns = [
//         {
//             name: 'Award Title',
//             selector: (row: IAwardWinnersData) => row.AwardTitle,
//             cell: (row: IAwardWinnersData) => formatAwardTitle(row),
//             sortable: true
//         },
//         {
//             name: 'Winner',
//             selector: (row: IAwardWinnersData) => row.EntityName,
//             cell: (row: IAwardWinnersData) => formatWinner(row),
//             sortable: true,
//         },
//         {
//             name: 'Award Winners Collateral',
//             cell: (row: IAwardWinnersData) => formatWinnerCollateral(row, showAwardLogoModal)
//         }
//     ];

//     const handleCopyClicked = (copyText: string) => {
//         navigator.clipboard.writeText(copyText);
//         setHasUserCopied(true);
//     };

//     const awardLogoModal = (): JSX.Element => {
//         if (!modalData) return null;

//         const {
//             AwardTitle,
//             AwardYear,
//             KeyName,
//             AwardTypeTag,
//             EntityID,
//             EntityName,
//             City
//         } = modalData;

//         const awardTitle = `${AwardTitle} (${AwardYear})`;

//         const useHighRes = [
//             AwardKeyName.BestOfMealsAndDining,
//             AwardKeyName.BestOfActivities
//         ].includes(KeyName) ? true : false;

//         // Formatting of linkHTML code important (used in <pre> below)
//         const linkHTML =`<a href="${APFM_URL}/${AwardTypeTag === AwardType.Group ? 'providers' :'community'}/${EntityID}" target="_blank" rel="noreferrer">
//     <img src="${getAwardImageURLByKeyName(KeyName, useHighRes)}" alt="${EntityName}${City ? `, ${City}` : ''} - ${AwardTitle} ${AwardYear}" width="160" />
// </a>`;

//         const apfmSiteName = APFM_URL.split('/').pop().replace('www.', '');

//         return (
//             <Modal show={showModal} centered onHide={() => setShowModal(false)} backdrop="static" size="xl">
//                 <Modal.Header closeButton>
//                     <Modal.Title>
//                         Award Logo for { awardTitle }
//                     </Modal.Title>
//                 </Modal.Header>
//                 <form>
//                     <Modal.Body>
//                         <div className="mb-4">
//                             { AwardTypeTag === AwardType.Group ? 'Providers' : 'Communities' } are only allowed to display this { awardTitle } image on their 
//                             website if they have recieved a { awardTitle } award from { apfmSiteName }. The awards logo must be linked back to { apfmSiteName }.
//                         </div>

//                         <div className="mb-2">
//                             <button 
//                                 className="btn btn-primary b-block mb-4"
//                                 type="button" 
//                                 onClick={() => handleCopyClicked(linkHTML)}
//                             >
//                                 Click here to copy the Award image HTML below
//                             </button>
//                         </div>
                    
//                         <em>After copying, you may then paste this HTML into your home page or email. <span className="fw-bold">{ hasUserCopied ? 'Copied!' : ''}</span></em>
                            
//                         <pre className={`code-wrapper ${hasUserCopied ? 'user-copied' : ''}`}>
//                             <code className="ms-2">
//                                 { linkHTML }
//                             </code>
//                         </pre>
//                     </Modal.Body>
//                 </form>
//             </Modal>
//         );
//     };

//     const showAwardLogoModal = (modalData: IAwardWinnersData) => {
//         setHasUserCopied(false);
//         setModalData(modalData);
//         setShowModal(true);
//     };

//     const startExport = async (): Promise<string> => {
//         return csvEportAPI.startExport('/award/winners/export', { memberID: selMemberID });
//     };

//     const selectedMember = ((): ISessionMember => {
//         return Members.find((m: ISessionMember) => m.MemberID === selMemberID);
//     })();

//     const currentFileName = ((): string => {
//         const name = selMemberID
//             ? selectedMember?.Name 
//             : SessionType === EnumSessionType.SessionTypeGroup  
//                 ? Group?.Name
//                 : isSingleMember ? Members[0]?.Name : 'multiple_members';

//         const fileName = `${name}_award_collateral`;

//         return formatFileNameForCsvExport(fileName);
//     })();

//     return (
//         <>    
//             <div className="widget widget-fullwidth">
//                 <div className="row widget-body">
//                     <div className="col-12">
//                         <div className="card">
//                             <div className="card-body p-0">
//                                 <div className="table-info p-4 pb-4">
//                                     <div className="row align-items-center">
//                                         <div className="col-12 col-md-8 col-xl-9 mb-3 mb-md-0">
//                                             <PageCommunitySelect
//                                                 handleSelect={handleSelect}
//                                                 selMemberID={selMemberID}
//                                             />
//                                         </div>
//                                         <div className="col-12 col-md-4 col-xl-3 text-center text-md-end">
//                                             <CsvExport 
//                                                 startExportFunction={startExport}
//                                                 modalTitleSuffix={'Award Winners Collateral'}
//                                                 label="Export as CSV"
//                                                 displayType={DisplayType.Button}
//                                                 fileName={currentFileName}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="awards_dt sticky_dt table_dt expander_dt">
//                                     <DataTable
//                                         columns={columns}
//                                         data={winners}
//                                         pagination={true}
//                                         fixedHeader={false}
//                                         expandableRows={appContext.isMobile}
//                                         expandableRowsComponentProps={{
//                                             showAwardLogoModal
//                                         }} 
//                                         expandableRowsComponent={AwardTableExpandableRows}
//                                         defaultExpanded={true}
//                                         defaultExpandedIDProp={'AwardTitle'}
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             { awardLogoModal() }
//         </>
//     );
};

export default Awards;