import React from 'react';

import { 
    IPropertyInsuranceTypesPayloadItem,
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailBasic,
    IPropertyCoreDetailPayload,
    IPropertyCoreDetailNHID
} from '../../../interfaces/property';

import * as nhAPI from '../../../api/property';
import Swal from 'sweetalert2';
import { isBoolean } from '../../../utils/common';
import { numericOnly } from './helpers';

type DurationUnit =  'years' | 'days' | 'months';

interface IFormInputData { 
    Label: string;
    Key: keyof IPropertyCoreDetailPayload;
    IncludedInProfileCompleteness?: boolean;
    DurationUnit?: DurationUnit;
    MaxLength?: number;
    MinIntegerValue?: number;
    MaxIntegerValue?: number;
}

const defaultCoreDetails: any = {
    LongTermCare: null,
    ShortTermCare: null,
    RespiteCare: null,
    OtherFacilityTypes: null,
    AffiliatedHospitals: null,
    AvgShortTermResidentsPc: null,
    AvgShortStayDuration: null,
    AvgLongTermResidentsPc: null,
    AvgLongTermStayDuration: null,
    MinAgeRequirement: null,
    MinAge: null,
    AdmitPediatricResidents: null,
    PediatricAvgShortTermResidentsPc: null,
    PediatricAvgShortStayDuration: null,
    PediatricAvgLongTermResidentsPc: null,
    PediatricAvgLongTermStayDuration: null,
    PediatricMaxAgeRequirement: null,
    PediatricMaxAge: null,
    RoomTypeOther: null,
    RoomTypeSemiPrivate: null,
    RoomTypeShared: null,
    RoomTypePrivateSuite: null,
    RoomTypePrivateRoom: null,
    LongTermSharedCostPerDay: null,
    LongTermPrivateCostPerDay: null,
    LongTermSemiPrivateCostPerDay: null,
    LongTermPrivateSuiteCostPerDay: null,
    LongTermRatesAllInclusive: null,
    LongTermRatePackagesAvailable: null,
    ShortTermSharedCostPerDay: null,
    ShortTermPrivateCostPerDay: null,
    ShortTermSemiPrivateCostPerDay: null,
    ShortTermPrivateSuiteCostPerDay: null,
    ShortTermRatesAllInclusive: null,
    ShortTermRatePackagesAvailable: null,
    RespiteSharedCostPerDay: null,
    RespitePrivateCostPerDay: null,
    RespiteSemiPrivateCostPerDay: null,
    RespitePrivateSuiteCostPerDay: null, 
    RespiteMinStayRequirement: null,
    RespiteMinStay: null,
    RespiteRatesAllInclusive: null,
    RespiteRatePackagesAvailable: null,
    AcceptMedicareAdvantage: null,
    AcceptMedicaidPending: null,
    BedsAllDualLicenced: null,
    BedsMedicareCount: null,
    BedsMedicaidCount: null,
    PrivateInsurancePc: null,
    ManagedCareContractsInNetwork: null,
    FacilityHasMemoryCareUnit: null,
    FacilityHasBehaviouralHealthUnit: null,
};

const getRadioValue = (value: boolean) => value === true ? '1' : '0';

const setCoreDetailsDefaults = (coreDetails: IPropertyCoreDetailBasic) => {
    let formDefaults: any = {};

    if (coreDetails) {
        for (const [key, value] of Object.entries(coreDetails)) {
            formDefaults[key] = isBoolean(value) ? getRadioValue(value) : value;
        }
    } else {
        formDefaults = { ...defaultCoreDetails };
    }

    return formDefaults;
};

export const renderRadios = (register: any, key: string, readOnly: boolean) =>
    <>
        <div className="me-4">
            <div className='form-check'>
                <label className="form-check-label text-nowrap">
                    <input 
                        className="form-check-input"
                        {...register(key as any)}
                        id={key}
                        name={key}
                        type="radio"
                        key={key}
                        value={1}
                        disabled={readOnly}
                    />
                    Yes
                    <span></span>
                </label>
            </div>
        </div>
        <div className="me-4">
            <div className="form-check">
                <label className="form-check-label text-nowrap">
                    <input 
                        className="form-check-input"
                        {...register(key as any)}
                        id={key}
                        name={key}
                        type="radio"
                        key={key}
                        value={0}
                        disabled={readOnly}
                    />
                    No
                    <span></span>
                </label>                          
            </div>
        </div>
    </>;

export const renderRadioGroup = (register: any, setValue: any, data: IFormInputData[], readOnly: boolean) => 
    data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
        <div className="row mb-4" key={`key-${index}`}>
            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                { Label }
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    { renderRadios(register, Key, readOnly) }
                    <div>
                        <button
                            title='reset'
                            onClick={(e) => { 
                                e.preventDefault();
                                setValue(Key, null, { shouldDirty: true });    
                            }}
                            className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1"
                        >
                            <i className="text-primary fa fa-history" />
                        </button>
                    </div>
                    { 
                        IncludedInProfileCompleteness
                            ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                            : null
                    }
                </div>
            </div>
        </div>
    );

export const renderPercent = (register: any, errors: any, data: IFormInputData[], readOnly: boolean) => 
    data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
        <div className="row mb-4" key={`key-${index}`}>
            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                { Label }
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    <div className="input-group">
                        <input 
                            {...register(Key as any)} 
                            onKeyDown={numericOnly}
                            type="number" 
                            className="form-control" 
                            maxLength={3} 
                            max={100}
                            min="0"
                            size={1}
                            disabled={readOnly}
                        />
                        <span className="input-group-text">%</span>
                    </div>
                    { 
                        IncludedInProfileCompleteness
                            ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                            : null
                    }
                </div>
            </div>
            {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null }         
        </div>
    );

export const renderDurationInput = (register: any, key: string, unit: DurationUnit, readOnly: boolean) =>
    <div className="input-group">
        <input 
            {...register(key as any)} 
            type="number" 
            className="form-control w-75px" 
            maxLength={4} 
            size={1}
            disabled={readOnly}
        />
        <span className="input-group-text">{ unit }</span>
    </div>;

export const renderDuration = (register: any, errors: any, { Label, Key, DurationUnit }: IFormInputData, readOnly: boolean) =>
    <div className="row mb-4">
        <div className="col-sm-12 col-md-3 d-flex align-items-center">
            { Label }
        </div>
        <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
            <div className='d-flex align-items-center'>
                { renderDurationInput(register, Key, DurationUnit, readOnly) }
            </div>
        </div>
        {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}   
    </div>;

export const renderText = (register: any, errors: any, data: IFormInputData[], doubleWidth = false) => 
    data.map(({ Label, Key }: IFormInputData) => 
        <div className="row mb-4" key={`${Label}-${Key}`}>
            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                { Label }
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                <div className={`align-items-center ${doubleWidth ? 'w-50' : ''}`}>
                        <input 
                            {...register(Key as any)} 
                            type="text" 
                            className="form-control"
                        />
                </div>
            </div>
            {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}   
        </div>
    );

export const renderOptionalDuration = (register: any, errors: any, setValue: any, watch: any, { Label, Key, DurationUnit, IncludedInProfileCompleteness }: IFormInputData, readOnly: boolean) => {
    const requirementKey = `${Key}Requirement`;

    return (
        <div className="row mb-4">
            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                { Label }
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    { renderRadios(register, requirementKey, readOnly) }
                    <div>
                        <button
                            title='reset'
                            onClick={(e) => { 
                                e.preventDefault();
                                setValue(requirementKey, null, { shouldDirty: true });    
                            }}
                            className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1"
                        >
                            <i className="text-primary fa fa-history" />
                        </button>
                    </div>
                    { renderDurationInput(register, Key, DurationUnit, readOnly || watch(requirementKey) !== '1') }
                </div>
                
                { 
                        IncludedInProfileCompleteness
                            ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                            : null
                    }
            </div>
            {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}   
        </div>           
    );
};

export const renderCostPerDay = (register: any, errors: any, data: IFormInputData[], readOnly: boolean) => 

    data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
        <div className="row mb-4" key={`key-${index}`}>
            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                <strong>{ Label }</strong>
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                            {...register(Key as any)} 
                            type="number" 
                            className="form-control" 
                            maxLength={4}
                            size={1}
                            disabled={readOnly}
                        />
                        <span className="input-group-text">starting price per day</span>
                    </div> 
                    { 
                        IncludedInProfileCompleteness
                            ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                            : null
                    }
                </div>
            </div>
            {errors[Key] ? <p className="text-danger">{ errors[Key].message }</p> : null}     
        </div>
    );

    export const renderInteger = (register: any, errors: any, data: IFormInputData[], readOnly: boolean) => 
        data.map(({ Label, Key, MinIntegerValue, MaxIntegerValue, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
            <div className="row mb-4" key={`key-${index}`}>
                <div className="col-sm-12 col-md-3 d-flex align-items-center">
                    <strong>{ Label }</strong>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <div className="input-group">
                            <input 
                                {...register(Key as any, {
                                    validate: { minValidFn: (value: string) => parseInt(value, 10) >= MinIntegerValue,
                                        maxValidFn: (value: string) => parseInt(value) <= MaxIntegerValue },
                                })} 
                                type="number" 
                                className="form-control" 
                                maxLength={3} 
                                size={1}
                                disabled={readOnly}
        
                            />
                        </div>
                        { 
                            IncludedInProfileCompleteness
                                ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                                : null
                        }
                    </div>
                </div>
                {errors[Key] ? <p className="text-danger">{ errors[Key].message }</p> : null }         
            </div>
        );

const setInsuranceTypeDefaults = (insuranceTypes: IPropertyCoreDetailInsuranceType[]) => {
    if (!insuranceTypes) {
        return null;
    }

    const formDefaults: any = {};

    insuranceTypes.forEach(({ PropertyInsuranceTypeID, IsActive }: IPropertyCoreDetailInsuranceType) =>
        formDefaults[`InsuranceType_${PropertyInsuranceTypeID}`] = isBoolean(IsActive) ? getRadioValue(IsActive) : IsActive
    );
    
    return formDefaults;
};

export const getFormDefaults = async (nhID: number): Promise<any> => {

    let formDefaults = {};
    let insuranceTypes: any;

    try {

        const rows = await nhAPI.getPropertyCoreDetails();

        if (rows?.length) {

            const data = rows.find((item: IPropertyCoreDetailNHID) => item.NHID === nhID);

            if (data) {

                const { InsuranceTypes, CoreDetails } = data;
                
                formDefaults = { 
                    ...setCoreDetailsDefaults(CoreDetails),
                    ...setInsuranceTypeDefaults(InsuranceTypes)
                };

                insuranceTypes = InsuranceTypes;

                return [formDefaults, insuranceTypes];
            } else {
                throw new Error(`Core details not found for nhid ${nhID}`);
            }
        }

        return [null, null];

    } catch(err) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Error occurred getting Core Details: ${(err as any).message}`
        });  
    }
};

export const onSubmit = async (nhID: number, coreDetails: any, isEdit: boolean) => {

    const insuranceTypes: IPropertyInsuranceTypesPayloadItem[] = [];
    Object.keys(coreDetails).forEach((key: string) => {
        if (key.startsWith('InsuranceType')) {
            const propertyInsuranceTypeID = Number(key.split('_')[1]);
            const isActive = coreDetails[key];

            if (isActive) {
                insuranceTypes.push({
                    PropertyInsuranceTypeID: propertyInsuranceTypeID,
                    IsActive: isActive === '1' ? true : false
                });                    
            }

            delete coreDetails[key];
        }
    });

    delete coreDetails.LastUpdated;

    try {
        await nhAPI[`${isEdit ? 'update' : 'create'}PropertyCoreDetailData`](nhID, coreDetails);
        
        Swal.fire({
            title: `Successfully ${isEdit ? 'Updated' : 'Created'} Core Details`,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
        });
    } catch(err) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error occurred saving Core Details'
        });  
    } 
};