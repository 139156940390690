import React, { useEffect, useState } from 'react';

import { mapProfileDatatoDisplay, getOrgProfileScoreFromProfileData } from '../../utils/profile_completeness';
import { useSelector } from 'react-redux';

import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    ProfileScoreType
} from '../../interfaces/property';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    profileScoreType: ProfileScoreType;
}

const ProfileCompletenessDial = ({ selNHID, nhIDs, orgNHID, profileScoreType }: IProps) => {

    const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, selNHID);
        const orgScore = getOrgProfileScoreFromProfileData(profileCompletenessState);

        const scoreItem = scoreArray.find((item: IScoreDisplayItem) => 
            item.KeyName === profileScoreType);

        setScoreItem(scoreItem);
    }, [ orgNHID, nhIDs, selNHID, profileCompletenessState ]);


    const facilityCount = scoreItem?.FacilityCount || 0;
    const achievedScoreCount = scoreItem?.AchievedScoreCount || 0;
    const fieldDescription = scoreItem?.Description || '';
    const availableScore = scoreItem?.AvailableScore || 0;
    const scorePC = scoreItem?.ScorePC || 0;
    const label = scoreItem?.Label || '';

    const pcFull = 100 * (facilityCount > 0 ? achievedScoreCount / facilityCount : 0);
    const relativePC = availableScore * (facilityCount > 0 ? achievedScoreCount / facilityCount : 0);
    const realtivePCRounded = Math.round(relativePC * 10) / 10;

    const pcDisplay = Math.round(pcFull);

    const ringText = selNHID ? <>{pcDisplay}<tspan>%</tspan></>
        : <>{realtivePCRounded}/{availableScore}<tspan>%</tspan></>;

    return (
        <>
            <div className="d-flex p-0">
                <div className="profile_completeness_single_chart pe-4">
                    <svg viewBox="0 0 36 36" className="profile_completeness_circular_chart green smalltext m-0">
                        <path className="profile_completeness_circle_bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                        <path className="profile_completeness_circle" strokeDasharray ={`${pcFull},100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                        <text x="18" y="11.2" className="profile_completeness_percentage-before">Profile</text>
                        <text x="18" y="21" className="profile_completeness_percentage">{ringText}</text>
                        <text x="18" y="27" className="profile_completeness_percentage-after">Complete</text>
                    </svg>
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <h4 className="mb-0 e-xl-6 fs-5 lh-base">{achievedScoreCount}/{facilityCount} Facilities are Profile Complete for {label} </h4>
                    <span>{fieldDescription}</span>
                </div>
            </div>
        </>
    );
};

export default ProfileCompletenessDial;
