import React, { useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import PropertyServiceTotalsTable from '../../../components/amenities/property_service_totals_table';
import PropertyServiceForm from '../../../components/amenities/property_service_form';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { ProfileScoreType } from '../../../interfaces/property';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyAmenities = ({ nhIDs, orgNHID }: IProps) => {

    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);

    const singlePropertySession = nhIDs?.length === 1;
    const singleNHID = singlePropertySession ? nhIDs[0] : selNHID;

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });
    
    return (
        <>
            <PageCommunitySelectWrapper
                label={'All Services & Amenities for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            <div className="widget" data-widget-height="auto">
                <div className="widget-body">                
                    <ProfileCompletenessHeader
                        selNHID={selNHID}
                        nhIDs={nhIDs}
                        orgNHID={orgNHID}
                        profileScoreType={ProfileScoreType.ServicesAndAmenities}
                        handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                        profileCompletenessFilterValue={profileCompletenessFilterValue}
                        title="for Services & Amenities"
                    />
                </div>
            </div>

            <div className="widget">
                <div className="widget-body">
                    { 
                        !singlePropertySession && !selNHID
                            ? <PropertyServiceTotalsTable 
                                selNHID={singleNHID} 
                                nhIDs={nhIDs} 
                                orgNHID={orgNHID} 
                                handleSelect={handleSelect} 
                                profileCompletenessFilterValue={profileCompletenessFilterValue}
                            />
                            : 
                            <PropertyServiceForm 
                                selNHID={singleNHID} 
                                nhIDs={nhIDs} 
                                orgNHID={orgNHID} 
                            />
                    }
                </div>
            </div>
        </>
    );
};

export default PropertyAmenities;