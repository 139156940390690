import React from 'react';
import Router from './routes/router';

const Main = () => {
    return (
        <Router />
    );
};

export default Main;
