import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import MultiOrgPropertyLogos from '../../components/assets/multi_org_property_logos';
import IndividualPropertyLogos from '../../components/assets/individual_property_logos';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../interfaces/session';
import { IGetPortalPropertyWebAndCardLogoAsset } from '../../interfaces/asset';
import { useSelector } from 'react-redux';
import * as assetApi from '../../api/assets';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyLogos = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [busyLoadingAsset, setBusyLoadingAsset] = useState<boolean>(true);
    const [selNHID, setSelNHID] = useState<number>(defaultNHID);
    const [propertyLogos, setPropertyLogos] = useState<any[]>([]);
    const [noLogoPropertyFilter, setNoLogoPropertyFilter] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });
    
    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType: sessionType } = Session;

    const loadPropertyLogos = async () => {
        if (!selNHID) {
            setPropertyLogos(null);
            setBusyLoadingAsset(true);
            try {
                const propertyLogos = sessionType === SessionType.SessionTypeOrg
                    ? await getOrgPropertiesLogos( noLogoPropertyFilter )
                    : await getPropertiesLogos( noLogoPropertyFilter );

                    setPropertyLogos(propertyLogos);
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        } else {
            setBusyLoadingAsset(false);
        }
    };

    useEffect(() => {
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        if (singleNHID) {
            setSelNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if (!selNHID) {
            loadPropertyLogos();
        }

        setBusyLoadingAsset(true);
        (async () => {
            try {
                if (selNHID && selNHID !== orgNHID) {
                    const propertyLogos = await getPropertyLogos([selNHID]);
                    if (propertyLogos) {
                        setPropertyLogos(propertyLogos);
                    }
                } 
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        })();
    }, [selNHID]);

    useEffect(() => {
        loadPropertyLogos();
    }, [noLogoPropertyFilter]);

    const getOrgPropertiesLogos = async ( noLogoPropertyFilter: boolean ): Promise<IGetPortalPropertyWebAndCardLogoAsset[]> => {
        if (!orgNHID) {
            console.error('getOrgPropertiesLogos: Invalid orgNHID');
            return null;
        }

        const filters = new Map<string, any>();
        if (noLogoPropertyFilter !== null) {
            filters.set('NoLogo', noLogoPropertyFilter);
        }
        return await assetApi.getOrgPropertiesLogos(orgNHID, filters);
    };

    const getPropertiesLogos = async ( noLogoPropertyFilter: boolean ): Promise<IGetPortalPropertyWebAndCardLogoAsset[]> => {
        const filters = new Map<string, any>();
        if (noLogoPropertyFilter !== null) {
            filters.set('NoLogo', noLogoPropertyFilter);
        }
        return await assetApi.getPropertiesLogos(nhIDs, filters);
    };

    const getPropertyLogos= async ( nhID: number[] ): Promise<IGetPortalPropertyWebAndCardLogoAsset[]> => {
        if(nhID[0] === orgNHID) {
           return await getOrgPropertiesLogos(false);
        } 
        return await assetApi.getPropertiesLogos(nhID);
    };

     return (
        <> 
            <PageCommunitySelectWrapper
                label={'Logo for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            {
                nhIDs?.length !== 1 && !selNHID ?
                    <MultiOrgPropertyLogos
                        handleSelNHID={setSelNHID}
                        orgNHID={orgNHID}
                        nhIDs={nhIDs}
                        selNHID={selNHID}
                        noLogoPropertyFilter={noLogoPropertyFilter}
                        handleNoLogoPropertyFilter={setNoLogoPropertyFilter}
                        propertyLogos={propertyLogos}
                        setPropertyLogos={setPropertyLogos}
                        busyLoadingAsset={busyLoadingAsset}
                        setBusyLoadingAsset={setBusyLoadingAsset}
                        handleReloadPropertyLogos={getPropertyLogos}
                    />
                :
                    null
            }
            {
                nhIDs?.length === 1 || selNHID ?
                    <IndividualPropertyLogos
                        handleReloadPropertyLogos={getPropertyLogos}
                        selNHID={selNHID}
                        propertyLogos={propertyLogos}
                        setPropertyLogos={setPropertyLogos}
                        busyLoadingAsset={busyLoadingAsset}
                        setBusyLoadingAsset={setBusyLoadingAsset}
                    />
                :
                    null
            }
        </>
    );
};

export default PropertyLogos;