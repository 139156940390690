import * as yup from 'yup';
import { validationHelpers } from '../../../helpers';

const {
    yupNullableZeroableStringToNumberLT1000,
    yupNullableStringToBool
} = validationHelpers;

const schema = yup.object().shape({
    BedsAllDualLicenced: yupNullableStringToBool,
    BedsMedicaidCount: yupNullableZeroableStringToNumberLT1000,
    BedsMedicareCount: yupNullableZeroableStringToNumberLT1000,
});

export default schema;
