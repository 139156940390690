import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReviewReplyChartWidget } from '../../components/charts/review_reply_chart_widget';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { ReviewsWidget } from '../../components/dashboard/reviews_widget';
import ReviewScoreWidget from '../../components/dashboard/review_score_widget';
import { ShowcaseReviewsWidget } from '../../components/dashboard/showcase_reviews_widget';
import { getCommunityMode } from '../../utils/common';

import { LeadsWidget } from '../../components/dashboard/leads_widget';
import { ProfileCompletnessWidget } from '../../components/dashboard/profile_completness_widget';
import { ProfileCompletnessWidgetOther } from '../../components/dashboard/profile_completness_widget_other';
import { LeadsEmailLog } from '../../components/dashboard/leads_email_log';
import { PageType } from '../../interfaces/page';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const Dashboard = ({ nhIDs, orgNHID }: IProps) => { 
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ reviewWidgetIsLoading, setReviewWidgetIsLoading ] = useState<boolean>(false);
    const [ showcaseReviewsWidgetIsLoading, setShowcaseReviewsWidgetIsLoading ] = useState<boolean>(false);
    const [ ratingsWidgetIsLoading, setRatingsWidgetIsLoading ] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const propertyMode = getCommunityMode(orgNHID, nhIDs, selNHID);

    const renderBody = () => {
        return (
            <>
                <div className="widget" data-widget="Leads & Profile Completness" data-widget-header="0" data-widget-footer="0" data-widget-display="grid" data-widget-columns="2">
                    <div className="widget-body">
                        <LeadsWidget selNHID={selNHID} />
                        <div className="card" data-card="Profile Completeness">
                            <div className="card-header">
                                <h4>
                                    Profile Content
                                </h4>
                            </div>
                            <div className="card-body flex-col pb-0">
                                <ProfileCompletnessWidget
                                    orgNHID={orgNHID}
                                    nhIDs={nhIDs}
                                    selNHID={selNHID}
                                    setIsLoading={setReviewWidgetIsLoading}
                                    isLoading={reviewWidgetIsLoading}
                                    widgetSize={'small'}
                                    propertyMode={propertyMode}
                                /> 
                            </div>
                        </div> 
                        <LeadsEmailLog selNHID={selNHID} /> 
                        <ProfileCompletnessWidgetOther
                            orgNHID={orgNHID}
                            nhIDs={nhIDs}
                            selNHID={selNHID}
                            setIsLoading={setReviewWidgetIsLoading}
                            isLoading={reviewWidgetIsLoading}
                            widgetSize={'small'}
                            propertyMode={propertyMode}
                        /> 
                    </div>
                </div>

                <div className="widget" data-widget="Reviews Collection" data-widget-page="Dashboard" data-widget-header="1" data-widget-footer="0">
                    <div className="widget-header">
                        <h3>
                            Reviews and Review Score
                        </h3>
                    </div>
                    <div className="widget-body">
                        <div>
                            <ReviewsWidget
                                orgNHID={orgNHID}
                                nhIDs={nhIDs}
                                selNHID={selNHID}
                                setIsLoading={setReviewWidgetIsLoading}
                                isLoading={reviewWidgetIsLoading}
                            />
                        </div>
                        <div>
                            <ReviewScoreWidget
                                orgNHID={orgNHID}
                                nhIDs={nhIDs}
                                selNHID={selNHID}
                                setIsLoading={setRatingsWidgetIsLoading}
                                isLoading={ratingsWidgetIsLoading}
                                propertyMode={propertyMode}
                                widgetSize={'large'}
                            />
                        </div>
                    </div>
                </div>

                <div className="widget" data-widget="Generate & Showcase Reviews" data-widget-page="Dashboard" data-widget-header="1" data-widget-footer="0" data-widget-columns="4">
                    <ShowcaseReviewsWidget
                        orgNHID={orgNHID}
                        nhIDs={nhIDs}
                        selNHID={selNHID}
                        setIsLoading={setShowcaseReviewsWidgetIsLoading}
                        isLoading={showcaseReviewsWidgetIsLoading}
                        showPortletArray={['postcards','invite-to-review','qr-code-posters','review-score-certificate']}
                    />
                </div>

                <div className="widget widget-chart" data-widget="Reviews & Replies" data-widget-page="Dashboard" data-widget-header="1" data-widget-footer="0">
                    <div className="widget-header">
                        <h3>
                            Reviews &amp; Replies
                        </h3>
                        <div>
                            <Link 
                                to ="/reviews-replies"
                                state={{ MemberID: selNHID}}
                            >
                                <div  className="btn btn-primary">
                                    View Insights
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="widget-body">
                        <ReviewReplyChartWidget
                            orgNHID={orgNHID}
                            nhIDs={nhIDs}
                            selNHID={selNHID}
                            cumulativeDefault={false}
                            showFilters={false}
                        />
                    </div>
                </div>

                {/* <div className="widget" data-widget="ratings" data-widget-header="1" data-widget-footer="1">
                    <RatingsWidget
                        orgNHID={orgNHID}
                        nhIDs={nhIDs}
                        selNHID={selNHID}
                        setIsLoading={setRatingsWidgetIsLoading}
                        isLoading={ratingsWidgetIsLoading}
                    />
                </div> */}

            </>
        );
    };
    
    return (
        <div className="mt-4">
            <PageCommunitySelectWrapper    
                label={'Dashboard for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
                pageType={PageType.Dashboard}
            />
            <div className="kt-portlet">
                <div className="kt-portlet__body">
                    { renderBody() }
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
 