import { FilterType, IFilterSet, INotEnteredOption } from '../interfaces/filters';
import { 
    formatFromDateForAPIRequestUTC, 
    formatToDateForAPIRequestUTC, 
    getClientFormattedDate 
} from '../utils/date';

import { ExpressionType, SqlRange } from '../interfaces/filter_matrix';

const _ = require('lodash');

class FilterMatrix {

    constructor (public filters: IFilterSet[]) {
        this.filters.forEach(filter => {
            switch (filter.Type) {
                case FilterType.Group:
                    filter.PropFormatter = this.groupPropFormatter;
                    filter.TextFormatter = this.groupTextFormatter;
                    filter.FormFieldFormatter = this.groupFormFieldFormatter;
                    break;
                case FilterType.Member:
                    filter.PropFormatter = this.memberPropFormatter;
                    filter.TextFormatter = this.memberTextFormatter;
                    filter.FormFieldFormatter = this.memberFormFieldFormatter;
                    break;
                case FilterType.User:
                    filter.PropFormatter      = this.userPropFormatter;
                    filter.TextFormatter      = this.userTextFormatter;
                    filter.FormFieldFormatter = this.userFormFieldFormatter;
                    break;
                case FilterType.String:
                    filter.PropFormatter = this.stringPropFormatter;
                    filter.TextFormatter = (filter.Options || {}).stringTextFormatter ? 
                        filter.Options.stringTextFormatter: this.stringTextFormatter;
                    break;
                case FilterType.Number:
                    filter.PropFormatter = this.numberPropFormatter;
                    filter.TextFormatter = this.numberTextFormatter;
                    break;
                case FilterType.Showing:
                    filter.PropFormatter = this.showingPropFormatter;
                    filter.TextFormatter = this.showingTextFormatter;
                    break;
                case FilterType.YesNo:
                    filter.PropFormatter = this.yesnoPropFormatter;
                    filter.TextFormatter = this.yesnoTextFormatter;
                    break;
                case FilterType.Boolean:
                    filter.PropFormatter = this.booleanPropFormatter;
                    filter.TextFormatter = this.booleanTextFormatter;
                    break;
                case FilterType.DateFromUTC:
                    filter.PropFormatter = this.dateFromUTCPropFormatter;
                    filter.TextFormatter = this.dateTextFormatter;
                    break;
                case FilterType.DateToUTC:
                    filter.PropFormatter = this.dateToUTCPropFormatter;
                    filter.TextFormatter = this.dateTextFormatter;
                    break;
                case FilterType.LabelValue:
                    filter.PropFormatter = this.labelValuePropFormatter;
                    filter.TextFormatter = this.labelValueTextFormatter;
                    break;
                case FilterType.ToEmail:
                    filter.PropFormatter = this.toEmailPropFormatter;
                    filter.TextFormatter = this.toEmailTextFormatter;
                    break;
                case FilterType.FromEmail:
                    filter.PropFormatter = this.fromEmailPropFormatter;
                    filter.TextFormatter = this.fromEmailTextFormatter;
                    break;
                case FilterType.FromEmailName:
                    filter.PropFormatter = this.fromEmailNamePropFormatter;
                    filter.TextFormatter = this.fromEmailNameTextFormatter;
                    break;
                case FilterType.SentEmailStatus:
                    filter.PropFormatter = this.sentEmailStatusPropFormatter;
                    filter.TextFormatter = this.sentEmailStatusTextFormatter;
                    break;
                case FilterType.LeadID:
                    filter.PropFormatter = this.leadIDPropFormatter;
                    filter.TextFormatter = this.leadIDTextFormatter;
                    break;
                case FilterType.Range:
                    filter.PropFormatter = this.rangePropFormatter;
                    filter.TextFormatter = this.rangeTextFormatter;
                    break;
                case FilterType.StringArray:
                    filter.PropFormatter = this.stringArrayPropFormatter;
                    filter.TextFormatter = this.stringArrayTextFormatter;
                    break;
                default:
                filter.PropFormatter = null;
                filter.TextFormatter = null;

            }
            filter.Value = filter.Value ?? '';
        });
    }

    reset = () => {
        this.filters.forEach((filter: any) => {
            if (filter.DefaultValue !== undefined) {
                filter.Value = filter.DefaultValue;
            } else {
                filter.Value = '';
            }
        });
    };

    setValue = (key: string, value: any) => {
        const filter = this.findFilter(key);
        if (filter) {
            filter.Value = value;
        }
    };

    getNotEnteredOption = (key: string, notEnteredDefault = true): INotEnteredOption => {
        const { 
            Options: { showNotEnteredOption = notEnteredDefault } = { notEnteredLabel: notEnteredDefault }, 
            FilterFormLabel: notEnteredLabel 
        } = this.findFilter(key);

        return { showNotEnteredOption, notEnteredLabel };
    };

    getActiveCount = () => {
        return this.filters.reduce((acc, filter) =>
            acc + (filter.PropFormatter(filter) && !filter.Hidden ? 1 : 0), 0);
    };

    findFilter = (key: string) => {
        return this.filters.find(item => item.Key === key);
    };

    getFormFieldValue = (key: string): any => {

        const filter = this.findFilter(key);

        if (!filter) {
            return null;
        }

        return (filter.FormFieldFormatter) ?
            filter.FormFieldFormatter(filter) : filter.Value;
    };

    getRequestParams = (): any => {
        const params: any = {};
        this.filters.forEach((filter: any) => {
            if (filter.Value !== undefined && filter.PropFormatter) {
                const propVal = filter.PropFormatter(filter);
                if (propVal !== undefined && propVal !== '') {
                    params[filter.FilterPropName] = filter.PropFormatter(filter);
                }
            }
        });

        return params;
    };

    getCurrentState = (): any[] => {
        const state: any[] = [];
        this.filters.forEach((filter: any) => {
            const { Key, Value, Type } = filter;
            if (Value) {
                state.push({ Key, Value, Type });
            }
        });

        return state;
    };

    getTextLabels = (): string[] => {

        const labels: string[] = [];
        this.filters.forEach((filter: any) => {
            const label = this.getTextLabel(filter);

            if (label) {
                labels.push(label);
            }
        });
        return labels;
    };

    getTextLabel = (filter: any) => {
        if (filter.Value && filter.PropFormatter && !filter.Hidden) {
            const propVal = filter.TextFormatter(filter);
            if (propVal) {
                return filter.TextFormatter(filter);
            }
        }
    };

    // Prop formatters
    memberPropFormatter = (filter: any) => {
        return (filter || {}).Value ? filter.Value.MemberID : null;
    };

    groupPropFormatter = (filter: any) => {
        return (filter || {}).Value ? filter.Value.GroupID : null;
    };

    toEmailPropFormatter = (filter: any) => {
        return (filter || {}).Value ? filter.Value.ToEmailAddress : null;
    };

    fromEmailPropFormatter = (filter: any) => {
        return (filter || {}).Value ? filter.Value.FromEmailAddress : null;
    };

    fromEmailNamePropFormatter = (filter: any) => {
        return (filter || {}).Value ? filter.Value.FromEmailName : null;
    };

    sentEmailStatusPropFormatter = (filter: any) => {
        return (filter || {}).Value ? filter.Value.SentEmailStatus : null;
    };

    leadIDPropFormatter = (filter: any) => {
        const value = filter.Value;

        if ((value || {}).length) {
            const splitArray = value.split('-');

            if (splitArray[0] === 'exists' || 
                (splitArray[0] === 'value' && (splitArray[1] || {}).length)) {
                return value;
            }
        }

        return null;
    };

    userPropFormatter = (filter: any) => {
        const multiSelect = _.get(filter, 'Options.multi');
        const user = (filter || {}).Value;

        if (multiSelect) {
            if (!(user || {}).length) {
                return null;
            }

            return user
                .map((userItem: any) => userItem.UserID)
                .filter((userID: number) => userID)
                .join(',');
        } else {
            if (!user) {
                return null;
            }

            return user.UserID ? user.UserID.toString() : null;
        }
    };

    labelValuePropFormatter = (filter: any) => {
        const value = (filter || {}).Value;

        if (!(value || {}).length) {
            return null;
        }

        return value
            .map((valueItem: any) => valueItem.value)
            .filter((valueID: number) => valueID)
            .join(',');
    };

    stringPropFormatter = (filter: any) => {
        return filter ? filter.Value : null;
    };

    stringArrayPropFormatter = (filter: any) => {
        const value = (filter || {}).Value;
    
        if (!value?.trim().length) return null;

        const { Delimiter = ',', MaxLength } = filter.Options || {};

        const stringArray = value.trim().split(Delimiter);

        if (MaxLength && MaxLength < stringArray.length) {
            return stringArray.splice(0, MaxLength);
        }

        return stringArray;
    };

    stringArrayTextFormatter = (filter: any) => {
        const value = (filter || {}).Value;
    
        if (!(value || []).length) return null;

        if (Array.isArray(value)) {
            const { Delimiter = ',', MaxLength } = filter.Options || {};

            if (MaxLength && MaxLength < value.length) {
                return value.splice(0, MaxLength).join(Delimiter);
            }

            return value.join(Delimiter);
        } 

        return value;
    };

    rangePropFormatter = (filter: any) => {
        const opStr = _.get(filter, 'Value.operation') || '';
        if (opStr === ExpressionType.NotEntered) {
            return opStr;
        }

        if (opStr.length === 2) {
            if (opStr === ExpressionType.Between) {
                const valueFromStr = _.get(filter, 'Value.from');
                const valueFrom = parseFloat(valueFromStr);

                const valueToStr = _.get(filter, 'Value.to');
                const valueTo = parseFloat(valueToStr);

                return !isNaN(valueFrom) && !isNaN(valueTo) ? `${opStr}${valueFrom},${valueTo}` : null;
            } else if (opStr === ExpressionType.In) {
                const valueFromStr = _.get(filter, 'Value.from') || '';

                const valArray = valueFromStr
                    .split(',')
                    .filter((val: string) => !isNaN(parseFloat(val)));

                if (valArray.length > 0) {
                    return `${opStr}${valArray.join(',')}`;
                }

                return null;
            } else {
                const valueStr = _.get(filter, 'Value.from');
                const value = parseFloat(valueStr);
                return (!isNaN(value)) ? `${opStr}${value}` : null;
            }
        }
        return null;
    };

    numberPropFormatter = (filter: any) => {
        return filter ? filter.Value : null;
    };

    showingPropFormatter = (filter: any) => {
        return (filter || {}).Value ? 1 : 0;
    };

    yesnoPropFormatter = (filter: any): any => {

        const value = (filter || {}).Value;

        if (value && !isNaN(value)) {
            return value;
        } else {
            return null;
        }
    };

    booleanPropFormatter = (filter: any): any => {

        const value = (filter || {}).Value;

        if (value === true || value === false) {
            return value;
        } else {
            return null;
        }
    };

    dateFromUTCPropFormatter = (filter: any) => {
        return (filter || {}).Value ? formatFromDateForAPIRequestUTC(filter.Value) : null;
    };

    dateToUTCPropFormatter = (filter: any) => {
        return (filter || {}).Value ? formatToDateForAPIRequestUTC(filter.Value) : null;
    };

    // Text formatters
    memberTextFormatter = (filter: any) => {

        const valText =  (filter || {}).Value ? filter.Value.Name : '';
        return `${filter.FilterFormLabel}: ${valText}`;
    };

    groupTextFormatter = (filter: any) => {

        const valText =  (filter || {}).Value ? filter.Value.Name : '';
        return `${filter.FilterFormLabel}: ${valText}`;
    };

    toEmailTextFormatter = (filter: any) => {
        const valText =  (filter || {}).Value ? filter.Value.ToEmailAddress : '';
        return `${filter.FilterFormLabel}: ${valText}`;
    };

    fromEmailTextFormatter = (filter: any) => {
        const valText =  (filter || {}).Value ? filter.Value.FromEmailAddress : '';
        return `${filter.FilterFormLabel}: ${valText}`;
    };

    fromEmailNameTextFormatter = (filter: any) => {
        const valText =  (filter || {}).Value ? filter.Value.FromEmailName : '';
        return `${filter.FilterFormLabel}: ${valText}`;
    };

    sentEmailStatusTextFormatter = (filter: any) => {
        const valText =  (filter || {}).Value ? filter.Value.SentEmailStatus : '';
        return `${filter.FilterFormLabel}: ${valText}`;
    };

    leadIDTextFormatter = (filter: any) => {

        const value = filter.Value;
        if ((value || {}).length) {

            const splitArray = value.split('-');
            if (splitArray[0] === 'exists') {
                return `${filter.FilterFormLabel}: ${splitArray[1] === '0' ? 'Not Present' : 'Present'}`;
            } else if (splitArray[0] === 'value' && (splitArray[1] || {}).length) {
                return `${filter.FilterFormLabel}: ${splitArray[1]}`;
            }
        }

        return null;
    };

    userTextFormatter = (filter: any) => {

        let valText;
        const user = (filter || {}).Value;
        if (!user) {
            return '';
        }

        if (Array.isArray(user)) {

            if (user.length === 0) {
                return null;
            }

            valText = user
                .map((userItem: any) => `${userItem.FirstName} ${userItem.LastName}`)
                .join(',');
        } else {
            valText = `${user.FirstName} ${user.LastName}`;
        }

        return `${filter.FilterFormLabel}: ${valText}`;
    };

    labelValueTextFormatter = (filter: any) => {

        const value = (filter || {}).Value;
        if (!(value || {}).length) {
            return null;
        }

        const valText = value
            .map((valueItem: any) => valueItem.label)
            .join(', ');

        return `${filter.FilterFormLabel}: ${valText}`;
    };

    stringTextFormatter = (filter: any) => {
        return filter ? `${filter.FilterFormLabel}: ${filter.Value}` : '';
    };

    rangeTextFormatter = (filter: any) => {
        const mapOpCodeToText = (opCode: string) => {
            switch (opCode) {
                case ExpressionType.GreaterThan:
                    return 'Greater than';
                case ExpressionType.GreaterThanOrEqual:
                    return 'Greater than or equal to';
                case ExpressionType.LessThan:
                    return 'Less than';
                case ExpressionType.LessThanOrEqual:
                    return 'Less than or equal to';
                case ExpressionType.Equal:
                    return 'Equal to';
                case ExpressionType.NotEqual:
                    return 'Not equal to';
                case ExpressionType.Between:
                    return 'Between';
                case ExpressionType.In:
                    return 'In';
                default:
                    return null;                                            
            }
        };

        if (!filter.Value) {
            return '';
        }

        if (filter.Value.operation === ExpressionType.NotEntered) {
            return `No ${filter.FilterFormLabel}`;
        }

        const opCodeText = mapOpCodeToText(filter.Value.operation);
        const ratingFrom = filter.Value.from;
        const ratingTo = filter.Value.to;

        if (opCodeText) {
            if (filter.Value.operation === ExpressionType.Between) {
                return ratingFrom && ratingTo ? `${filter.FilterFormLabel}: ${opCodeText} ${ratingFrom} and ${ratingTo}` : '';
            } else {
                return ratingFrom ? `${filter.FilterFormLabel}: ${opCodeText} ${ratingFrom}` : '';
            }
        }

        return '';
    };

    numberTextFormatter = (filter: any) => {
        return filter ? `${filter.FilterFormLabel}: ${filter.Value}` : '';
    };

    showingTextFormatter = (filter: any) => {
        const valText = (filter || {}).Value ?  'Showing' : 'Not Showing';
        return filter ? `${filter.FilterFormLabel}: ${valText}` : '';
    };

    yesnoTextFormatter = (filter: any): any => {
        const val = parseInt((filter || {}).Value || 0, 10);
        const valText = val ? 'Yes' : 'No';
        return filter ? `${filter.FilterFormLabel}: ${valText}` : '';
    };

    booleanTextFormatter = (filter: any): any => {
        const val = (filter || {}).Value;
        return val ? filter.FilterFormLabel : '';
    };

    dateTextFormatter = (filter: any) => {
        const valText = (filter || {}).Value ? getClientFormattedDate(filter.Value) : null;
        return filter ? `${filter.FilterFormLabel}: ${valText}` : '';
    };

    // Form field formatters
    userFormFieldFormatter = (filter: any): any => {

        const multiSelect = _.get(filter, 'Options.multi');
        const user = (filter || {}).Value;

        if (multiSelect) {

            if (!(user || {}).length) {
                return [];
            }

            return user;
        } else {

            if (!user) {
                return [];
            }

            return [user];
        }
    };

    memberFormFieldFormatter = (filter: any): any => {

        const member = (filter || {}).Value;

        if (!member) {
            return [];
        }
        return [member];
    };

    groupFormFieldFormatter = (filter: any): any => {

        const group = (filter || {}).Value;

        if (!group) {
            return [];
        }
        return [group];
    };

    getRangeValue = (filterKey: string): SqlRange => {
        const value = this.getFormFieldValue(filterKey);
        return value === '' ? new SqlRange(undefined) : value;
    };
}

export default FilterMatrix;

