import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/common/data_table';
import ReplyModal from '../../components/reviews/reply_modal';
import PublishModal from '../../components/reviews/publish_modal';
import ReviewTableExpandableRows from '../../components/reviews/review_table_expandable_rows';
import ReviewListReplyButton from '../../components/reviews/review_list_reply_button';
import NoReviewData from '../../components/reviews/no_review_data';
import { ISessionState } from '../../interfaces/session';
import { IPortalReplyItem, IReply } from '../../interfaces/reply';
import {
    buildColumns,
    fetchReviewData,
    getReviewDataParams,
    createOrUpdateReply
} from './reviews_list_common';

import { scrollToTop } from '../../utils/common';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { ReviewPublicationStatusType } from '../../interfaces/review';
import { updateReviewPublicationAuxStatus } from '../../api/review';
import { LoadingSpinner } from '../../components/common/loading_spinner';

interface IReviewsListProps {
    nhIDs: number[];
    orgNHID: number;
}

const defaultSortOrder = 'DESC';
const defaultSortField = 'LastPublishedDate';
const defaultPageLimit = 100;
const defaultPageOffset = 0;

const ReviewsListPending = ({ nhIDs, orgNHID }: IReviewsListProps) => {
    const navigate = useNavigate();

    const { reviewID: reviewIDParam } = useParams();

    if (reviewIDParam && isNaN(Number(reviewIDParam))) {
        navigate('/reviews');
    }

    const [ busy, setBusy ] = useState<boolean>(false);
    const [ activeReply, setActiveReply ] = useState<any>(null);
    const [ curReview, setCurReview ] = useState<any | null>(null);
    const [ hasReply, setHasReply ] = useState<boolean>(false);
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const [ reviews, setReviews ] = useState<any[]>([]);
    const [ totalReviewCount, setTotalReviewCount ] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(defaultPageOffset);
    const [ pageLimit, setPageLimit ] = useState<number>(defaultPageLimit);
    const [ sortOrder, setSortOrder ] = useState<string>(defaultSortOrder);
    const [ sortField, setSortField ] = useState<string>(defaultSortField);

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const canReplyToReview = get(sessionState, 'Session.Perms.CanReplyToReview', false);

    useEffect(() => {
        setPageOffset(defaultPageOffset);
        getReviewDataWithDefaultParams();
        setResetPaginationToggle(!resetPaginationToggle);
    }, [JSON.stringify(nhIDs), orgNHID]);

    const getReviewData = async (
        pageOffset: number,
        pageLimit: number,
        sortField: string,
        sortOrder: string
    ) => {

        setBusy(true);

        const params: any = getReviewDataParams(
            {},
            pageOffset,
            pageLimit,
            sortField,
            sortOrder,
            ReviewPublicationStatusType.PendingPublication,
            false
        );

        const [ tableData, , totalFilteredRows]: 
            [any[], number, number] = await fetchReviewData(params);
        
        if (reviewIDParam && !tableData.length) {
            getReviewData(0, pageLimit, sortField, sortOrder);
            navigate('/reviews');
        }

        setReviews(tableData);
        setTotalReviewCount(totalFilteredRows);
        setBusy(false); 
    };

    const getReviewDataWithDefaultParams = () => getReviewData(
        defaultPageOffset, 
        defaultPageLimit, 
        defaultSortField, 
        defaultSortOrder
    );

    const renderPublishButton = (row: any) => {

        return (
            <button className="review-replybutton btn btn-outline-primary btn-outline my-2"
                disabled={busy}
                onClick={ () => setCurReview(row) }
            >
                Publish with no Response
            </button>
        );
    };

    const renderPublishWithReplyButton = (row: any) => {

        if (canReplyToReview && !row.Reply) {
            return (
                <div className="my-2">
                    <ReviewListReplyButton
                        data={row}
                        setActiveReply={setActiveReply}
                        setHasReply={setHasReply}
                        updateReplyBtnText={'Publish with a Response'}
                        createReplyBtnText={'Publish with a Response'}
                    />
                </div>
            );
        }
    };

    const columns: any = buildColumns('pending');

    columns.push({
        name: '',
        width: '240px',
        cell: (row: any) => (
            <div className="clearFix">
                { renderPublishButton(row) }
                { renderPublishWithReplyButton(row)}
            </div>
    )});



    const handlePublishReview = async (reviewID: number) => {

        try {
            setBusy(true);
            await publishReview(reviewID);
            setCurReview(null);

        } catch(err) {
            console.log('Failed to publish review');
        } finally {
            setBusy(false);
        }
    };

    const publishReview = async (reviewID: number) => {

        try {
            await updateReviewPublicationAuxStatus(reviewID, ReviewPublicationStatusType.Published);

            const mutatedReviews = reviews
            .filter((review: any) => review.ReviewID !== reviewID)
            .map((review: any) => {
                const reviewCopy = { ...review };
                reviewCopy.uuid = uuidv4();
                return reviewCopy;
            });

            setReviews(mutatedReviews);
            setTotalReviewCount(Math.max(0, totalReviewCount - 1));

        } catch(err) {
            console.log('Failed to publish review');
        } 
    };

    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset((pageNumber - 1) * pageLimit);
            getReviewData(newPageOffset, pageLimit, sortField, sortOrder);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset(defaultPageOffset);
            setPageLimit(newPerPage);
            getReviewData(defaultPageOffset, newPerPage, sortField, sortOrder);
            scrollToTop();
        }
    };

    const onSortChange = (column: any, sortOrderParam: any) => {
        if (column?.sortField && sortOrder) {
            sortOrderParam = sortOrderParam.toUpperCase();
            setSortField(column.sortField);
            setSortOrder(sortOrderParam);
            getReviewData(pageOffset, pageLimit, column.sortField, sortOrderParam);
            scrollToTop();
        }
    };
  
    const handleCreateOrUpdateReply = async (reviewID: number, replyData: IReply) => {

        const savedReply = await createOrUpdateReply(reviewID, replyData);
        updateReplyInReviewList(reviewID, savedReply);
        await publishReview(reviewID);
        setActiveReply(null);
    };

    const updateReplyInReviewList = (reviewID: number, replyData: IPortalReplyItem | null) => {

        const review = reviews.find((review: any) => review.ReviewID === reviewID);
        if (review) {
            review.Reply = replyData;

            const mutatedReviews: any[] = reviews.map((review: any) => {
                const reviewCopy = { ...review };
                reviewCopy.uuid = uuidv4();
                return reviewCopy;
            });

            setReviews(mutatedReviews);
        } else {
            console.error(`reviewID: ${reviewID} not found`);
        }
    };

    let totalStr = '';
    switch (totalReviewCount) {
        case 0: 
            totalStr = 'No Reviews Pending Publication';
            break;
        case 1: 
            totalStr = '1 Review Pending Publication';
            break;
        default:
            totalStr = `All ${totalReviewCount} Reviews Pending Publication`;
    }

    return (
        <>
            <div id='reviews_list_top'>

                { busy ? <LoadingSpinner show={true} /> : null }

                <div className="widget widget-table" data-widget-header="1" data-widget-footer="0">
                    <div className="widget-body">
                        <div className="card">

                            <p className="my-4 mx-4"><strong>{totalStr}</strong> </p>

                            <div className="card-body">
                                <div className="reviews_dt sticky_dt table_dt expander_dt overflow_dt">

                                    <DataTable
                                        columns={columns}
                                        data={reviews}
                                        expandableRows={true}
                                        expandableRowsComponent={ReviewTableExpandableRows}
                                        expandableRowsComponentProps={{published: false, setActiveReply, setHasReply}} 
                                        pagination={true}
                                        paginationServer={true}
                                        paginationTotalRows={totalReviewCount}
                                        onChangePage={onPageChange}
                                        onChangeRowsPerPage={onLimitChange}
                                        onSort={onSortChange}
                                        paginationResetDefaultPage={resetPaginationToggle}
                                        fixedHeader={false}
                                        noDataComponent={<NoReviewData loading={busy} />}
                                        defaultExpanded={true}
                                        defaultExpandedIDProp={'ReviewID'}
                                    /> 
                                    {
                                        activeReply ?
                                        <ReplyModal
                                            reply={activeReply}
                                            hasReply={hasReply}
                                            handleClose={() => setActiveReply(null)}
                                            handleDeleteReply={null}
                                            handleCreateOrUpdateReply={handleCreateOrUpdateReply}
                                            cancelText={'Publish'}
                                            saveText={['Publish', 'Review']}
                                        />
                                        : <></>
                                    }
                                    {
                                        curReview ?
                                        <PublishModal
                                            review={curReview}
                                            handleClose={() => setCurReview(null)}
                                            handlePublishReview={handlePublishReview}
                                            cancelText={'Publish'}
                                            saveText={['Publish', 'Review']}
                                        />
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReviewsListPending;
