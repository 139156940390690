import React from 'react';

export interface IReviewScoreFullFormattedProps {
    value?: number;
    showAsNotApplicableOnNull?: boolean;
    showAsPlaceholder?: boolean;
}

const ReviewScoreFullFormatted = ({    
    value,
    showAsNotApplicableOnNull = false,
    showAsPlaceholder = false
}: IReviewScoreFullFormattedProps) => {  
    if (isNaN(value)) {
        return <></>;
    }

    if (showAsPlaceholder) {
        return <>X.X</>;
    }

    if (!value) {
        if (showAsNotApplicableOnNull) {
            return <>N/A</>;
        }

        return <></>;
    }

    const displayValue = value < 10 ? value.toFixed(3) : 10;
        
    return <>{ displayValue }</>;
};

export default ReviewScoreFullFormatted;

