import React, { useContext, useEffect, useState } from 'react';
import * as csvExportApi from '../../api/csv_export';
import CsvExport, { DisplayType } from '../csv_export/csv_export';
import { getLeadsBreakdown } from '../../api/leads';
import { formatFileNameForCsvExport } from '../../utils/file';
import DataTable from '../common/data_table';
import { LoadingSpinner } from '../common/loading_spinner';
import { formatAddress } from '../../utils/property';
import AppContext from '../../context/app_context';

type Props = {
    orgNHID?: number;
    nhids?: number[];
    selNHID?: number | null;
};

interface IFilters {
    Name?: string;
}

export default function LeadsBreakdownTable(props: Props) {
    const [busy, setBusy] = useState<boolean>(false);

    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<string>('');

    const [tableData, setTableData] = useState<any[]>([]);

    const [filters, setFilters] = useState<IFilters>({});

    const updateFilter = (key: keyof IFilters, value: any) => setFilters((prev) => ({
        ...prev,
        [key]: value
    }));

    const getData = async () => {
        const params: any = {...filters};

        if (!props.selNHID) {
            if (props.orgNHID) {
                params.OrgNHID = props.orgNHID;
            }
    
            if (props.nhids) {
                params.NHIDs = props.nhids;
            }
        } else {
            params.NHIDs = [props.selNHID];
        }

        if (sortField && sortOrder) {
            params.SortOrder = sortOrder;
            params.SortField = sortField;
        }

        const resp = await getLeadsBreakdown(params);
        setTableData(resp);
    };

    const appContext: any = useContext(AppContext);

    const columns = [
        {
            name: 'Provider / Facility Name',
            sortField: 'Name',
            width: appContext.isMobile ? '230px' : '440px',
            cell: (row: any, index: number) => (
                <div style={{overflowWrap: 'break-word', wordBreak: 'break-word'}} className="py-2">
                    <strong>{row.Name}</strong>
                    <br />
                    { props.orgNHID && index === 0 ? null : <><small>{ formatAddress(row) }</small>&nbsp;</> }
                    <a
                        className="small text-link text-link-blue text-nowrap p-0"
                        href={`${row.WebsiteURL}/${row.StateSlug}/${row.CitySlug}/${row.Slug}`}
                        target="_blank"
                    >
                        view on website
                    </a>
                </div>
            ),
            sortable: true
        },
        {
            name: <>Page<br />Views</>,
            sortField: 'PageViews',
            selector: (row: any) => row.PageViews,
            sortable: true,
            center: true,
            width: appContext.isMobile ? '110px' : '180px'
        },
        {
            name: <>Website<br />Referrals</>,
            sortField: 'WebsiteReferrals',
            selector: (row: any) => row.WebsiteReferrals,
            sortable: true,
            center: true,
            width: appContext.isMobile ? '110px' : '180px'
        },
        {
            name: 'Phone Number Views',
            sortField: 'PhoneNumberViews',
            cell: (row: any, index: any) => {
                if (props.orgNHID && index === 0) {
                    return 'N/A';
                }

                return row.PhoneNumberViews;
            },
            sortable: true, 
            center: true,
            width: appContext.isMobile ? '130px' : '180px'
        },
        {
            name: <>Email<br />Inquiries *</>,
            sortField: 'EmailInquiries',
            cell: (row: any, index: any) => {
                if (props.orgNHID && index === 0) {
                    return 'N/A';
                }

                return row.EmailInquiries;
            },
            sortable: true,
            center: true,
            width: appContext.isMobile ? '110px' : '180px'
        },
        {
            name: <>Brochure<br />Inquiries *</>,
            sortField: 'BrochureInquiries',
            cell: (row: any, index: any) => {
                if (props.orgNHID && index === 0) {
                    return 'N/A';
                }

                return row.BrochureInquiries;
            },
            sortable: true,
            center: true,
            width: appContext.isMobile ? '120px' : '180px'
        }
    ];

    const handleSortChange = (column: any, order: string) => {
        if (column.sortField && order) {
            setSortField(column.sortField);
            setSortOrder(order);
        }
    };

    const startExport = async (): Promise<string> => {
        const params: any = {...filters};

        if (!props.selNHID) {
            if (props.orgNHID) {
                params.OrgNHID = props.orgNHID;
            }
    
            if (props.nhids) {
                params.NHIDs = props.nhids;
            }
        } else {
            params.NHIDs = [props.selNHID];
        }

        return csvExportApi.startExport('/lead/breakdown/export', params);
    };

    useEffect(() => {
        setBusy(true);
        getData();
        setBusy(false);
    }, [props.selNHID, filters, sortField, sortOrder]);

    if (!tableData?.length) {
        return null;
    }

    return (
        <>
            <div className="mb-2 mb-md-4 px-3">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <input
                            type="text"
                            id="Name"
                            className="form-control"
                            onChange={(e) => updateFilter('Name', e.target.value)}
                            placeholder="Search by Name"
                        />
                    </div>
                    <div className="col-md-8 d-flex justify-content-end ">
                        <div className="d-flex flex-column align-items-end">
                            <CsvExport 
                                startExportFunction={startExport}
                                modalTitleSuffix="Leads Breakdown"
                                label="Export as CSV"
                                displayType={DisplayType.Button}
                                fileName={`${formatFileNameForCsvExport('leads_breakdown')}`}
                            />
                            <p className="mt-2 mb-0"><strong>*Email Inquiries & Brochure Inquiries launched on April 1st 2024</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.orgNHID && !props.selNHID ? 'first-row-ghostwhite' : ''}>
                {
                    busy ? <LoadingSpinner />
                    : <DataTable
                        onSort={handleSortChange}
                        useTotalsRow={true}
                        data={tableData}
                        columns={columns}
                        sortServer={true}
                    />
                }
            </div>
        </>
    );
}
